
export function formatChoices(choices: { value: any, text: string }[] | string[]) {
    let array = choices

    if (array && Array.isArray(array) && array.length > 0 && (array as Array<any>).every(x => typeof x !== 'object'))
        array = (array as string[]).map(x => { return { value: x, text: x } })
    else
        array = (array as { value: any, text: string }[])?.map(x => ({ value: x.value?.toString(), text: x.text?.toString() }))

    return (array ?? []) as { value: string, text: string }[]
}