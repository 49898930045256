import React from 'react'
import { withStyles, createStyles, FormControl, Input, InputAdornment } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasFeature } from '../../../infrastructure/feature'
import { DatePicker } from '../customComponents'
import { t } from '../../../infrastructure/i18nextHelper'

type SearchFieldStyleProps = {
    root?
}

type SearchFieldProps = {
    label?: string
    text: string
    overrideStyle?: SearchFieldStyleProps
    disableNewStyle?: boolean
    onClick?: () => void
    onChange: (e) => void
}

function _SearchField({ label, text, onChange, classes, onClick, overrideStyle, disableNewStyle }: SearchFieldProps & MuiProps) {
    let valueText = text != null ? text : ''
    let variant = !disableNewStyle ? { variant: 'filled' } as any : {}
    return (
        <FormControl {...variant}
            classes={{ root: overrideStyle?.root }} className={classes.form}>
            <Input
                className={'searchbar'}
                placeholder={label}
                value={valueText}
                onKeyDown={onChange}
                onChange={onChange}
                startAdornment={
                    <InputAdornment position='start'>
                        <Icons.Search style={{ cursor: onClick ? 'pointer' : 'arrow', marginBottom: '0.5em', width: '0.8em', height: '0.8em' }} onClick={onClick} />
                    </InputAdornment>} />
        </FormControl>
    )
}

let styles = theme =>
    createStyles({
        form: {
            maxWidth: '15.3em',
            minWidth: '5.3em',
        },
    })

export let SearchField = withStyles(styles, muiOptions)(_SearchField)