import React from 'react';
import { withStyles } from '@material-ui/core'
import { ThemeProvider, createStyles } from '@material-ui/styles'
import { KeyboardTimePicker } from '@material-ui/pickers'
import moment from 'moment';
import { MuiProps, muiOptions } from '../../../../infrastructure/materialUiThemeProvider'
import { useTimePickerState } from './state'
import { fieldStatuses } from '../../styles'
import { customDatePickerTheme } from '../datePicker/theme'
import { t } from '../../../../infrastructure/i18nextHelper'
import { BaseFieldProps } from '../../fieldProps'

type TimePickerStyleProps = {
    timepicker?
}

type TimePickerProps = {
    time: string | null
    setTime: (newTime: string | null) => void
    auto?: boolean
    errormessage?: string
    maxTime?: string
    label: string
    placeholder?: string
    disableFuture?: boolean
    disableNewStyle?: boolean
    hideUnderline?: boolean
    smallIcon?: boolean
    onLostFocus?: (e) => void
    classesOverride?: TimePickerStyleProps
    size?: 'small' | 'standard'
} & BaseFieldProps & MuiProps

function _TimePicker({
    classes,
    classesOverride,
    time,
    placeholder,
    onLostFocus,
    setTime,
    disableFuture,
    auto,
    status,
    onError,
    disableNewStyle,
    hideUnderline,
    smallIcon,
    ...props
}: TimePickerProps) {
    let state = useTimePickerState(time, setTime, onError);

    let highlight = status === 'info' ? classes.fieldStatusInfo
        : status === 'warning' ? classes.fieldStatusWarning : '';

    let inputVariant = !disableNewStyle ? { inputVariant: 'filled' } as any : {};

    let sizeClass = props.size === 'standard' ? classes.standard : props.size === 'small' ? classes.small : ''

    return (
        <ThemeProvider theme={customDatePickerTheme}>
            <KeyboardTimePicker
                className={`${classesOverride ? classesOverride.timepicker : classes.picker} ${highlight} ${sizeClass}`}
                error={status === 'alert' || state.isOnError}
                clearable
                {...inputVariant}
                variant="dialog"
                onBlur={onLostFocus}
                autoOk={true}
                value={time}
                placeholder={
                    props.disabled
                        ? ''
                        : (placeholder ? moment(placeholder) : moment()).format('HH:mm')
                }
                onChange={state.onChange}
                maxTime={props.maxTime}
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: hideUnderline || props.disabled }}
                format="HH:mm"
                helperText={
                    status === 'alert' || onError
                        ? props.errormessage || t('components.error')
                        : auto
                            ? t('components.autoCalculated')
                            : null
                }
                KeyboardButtonProps={smallIcon ? { size: 'small' } : undefined}
                {...props}
            />
        </ThemeProvider>
    );
}

let styles = _ =>
    createStyles({
        small: { width: '8em !important' },
        standard: { width: '10.3em !important' },
        picker: {
            width: '15.3em',
            margin: '0em 0.5em',
            "& .Mui-disabled": {
                backgroundColor: 'white',
                "& span": {
                    color: 'white'
                }
            }
        },
        ...fieldStatuses
    })

export let TimePicker = withStyles(styles, muiOptions)(_TimePicker)
