import React, { useImperativeHandle, useState, useRef, RefObject, Fragment } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, createStyles, withStyles } from '@material-ui/core'
import { muiOptions, defaultStyles, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { api } from '../../../infrastructure/api'
import { ImportTable } from './table'
import { ImportLoader } from './loader'
import { Button } from '../customComponents'

function _ImportDialog(props) {
    let [open, setOpen] = useState<boolean>(false)
    let [success, setSuccess] = useState<Success>('none')
    let [lines, setLines] = useState<Line[]>([])

    dialogRef = useRef<DialogOptions>(null)
    useImperativeHandle(dialogRef, () => ({
        open: openWithoutLoading,
        openWithLoading: openWithLoading,
        openFromCorrelationId: openFromCorrelationId
    }))

    function openWithoutLoading(result: FunctionResult) {
        setSuccess(result.success)
        setLines(result.lines)
        setOpen(true)
    }

    function openWithLoading(func: AsyncFunction) {
        setSuccess('none')
        setLines([])
        setOpen(true)

        func().then(result => {
            setLines(result.lines)
            setSuccess(result.success)
        })
    }

    function openFromCorrelationId(correlationId: string) {
        api.get<Report[]>('report?correlationId=' + correlationId).then(results => {
            if (!results.length) return

            let lines = extractLines(results)
            setSuccess('fail')
            setLines(lines)
            setOpen(true)
        })
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            scroll={'paper'}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'>
            <DialogTitle id='scroll-dialog-title'>
                {t('report.dialog.title')} -&nbsp;
                <span className={props.classes.titleResult + ' result-' + success}>{success === 'none' ? 'Pending'
                    : success === 'success' ? 'Success'
                        : success === 'fail' ? 'Failed'
                            : success === 'partial' ? 'Partially successful' : ""
                }</span>
            </DialogTitle>
            <DialogContent
                dividers={true}
                className={props.classes.noPadding + ' ' +
                    props.classes.minSize +
                    (lines.length > 0 ? ' ' + props.classes.noBottomBorder : '')}>
                {success === 'none'
                    ? <ImportLoader />
                    : <ImportTable lines={lines} />}
            </DialogContent>
            <DialogActions>
                <Button className={props.classes.closeButton}
                    label={t('components.dialogClose')}
                    onClick={() => setOpen(false)}
                    color='primary' />
            </DialogActions>
        </Dialog>
    )
}

type DialogOptions = {
    open: (lines: FunctionResult) => void
    openWithLoading: (func: AsyncFunction) => void
    openFromCorrelationId: (correlationId: string) => void
}
type AsyncFunction = () => Promise<FunctionResult>
type FunctionResult = { success: Success, lines: Line[] }
type Success = 'success' | 'fail' | 'partial' | 'none'

type Report = {
    correlationId: string
    translationCode: string
    level: 'success' | 'error' | 'warning' | 'info',
    vars: any
}

export type Line = {
    number: number
    errors: { value: string, text: string }[]
}

function extractLines(reports: Report[]): Line[] {
    let extractedLines = reports.reduce((lines, cur) => {
        let isReport = !!cur.vars['value']
        let withFromDate = !!cur.vars['from']
        let number = isReport ? parseInt(cur.vars['lineNumber']) : 0
        let error = isReport && !withFromDate ? { value: cur.vars['value'], text: t(cur.translationCode) } 
                                              : { value: '', text: t(cur.translationCode, cur.vars) }

        let lineIndex = lines.findIndex(x => x.number === number)

        if (lineIndex > -1)
            lines[lineIndex].errors.push(error)
        else
            lines.push({ number, errors: [error] })

        return lines
    }, [] as Line[])

    extractedLines.sort((a, b) => a.number - b.number)
    return extractedLines
}

let dialogRef: RefObject<DialogOptions> | null = null

export let importDialog: DialogOptions = {
    open: (result: FunctionResult) => dialogRef?.current?.open(result),
    openWithLoading: (func: AsyncFunction) => dialogRef?.current?.openWithLoading(func),
    openFromCorrelationId: (correlationId: string) => dialogRef?.current?.openFromCorrelationId(correlationId)
}

let styles = _ => createStyles({
    noPadding: {
        padding: '0'
    },
    minSize: {
        minWidth: '16em',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column'
    },
    closeButton: {
        ...defaultStyles.dialogCloseButton
    },
    noBottomBorder: {
        borderBottom: 'none'
    },
    titleResult: {
        '&.result-fail': { color: defaultColors.red.main.color },
        '&.result-success': { color: defaultColors.green.main.color },
        '&.result-partial': { color: defaultColors.orange.main.color }
    }
})

export let ImportDialog = withStyles(styles, muiOptions)(_ImportDialog)