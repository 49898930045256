import React from 'react'
import { Guid } from '@guid'

export function useTableEditionMode<T>() {
    let [isOn, setIsOn] = React.useState<boolean>(false)
    let [editedLines, setEditedLines] = React.useState<EditableLine<T>[]>([])

    function addOrUpdateLine(item: EditableLine<T>) {
        let existingItemIndex = editedLines.findIndex(x => x.id === item.id)

        if (existingItemIndex > -1)
            editedLines.splice(existingItemIndex, 1)

        editedLines.push(item)
        setEditedLines(editedLines)
    }

    function clear() { setEditedLines([]) }

    return { isOn, setIsOn, clear, editedLines: editedLines as T[], addOrUpdateLine }
}

export type TableEditionModeContainer = ReturnType<typeof useTableEditionMode>

type EditableLine<T> = T & { id: Guid }