import React from 'react'
import { NotificationView } from './models'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { t } from '../../i18nextHelper'

export let NotificationItem = React.memo(({ notification, classes }: Props) => {
    return (
        <div className={classes.line + ' error'} onClick={notification.open}>
            <div className={classes.lineLeft}>
                <div className={classes.lineLeftTitle}>
                    <span>{t('notification.PastMovementNotActual.title')}</span>
                </div>
                <div className={classes.lineLeftSubTitle}>
                    <span>{notification.subtitle}</span>
                </div>
            </div>
            <div className={classes.lineRight}>
                <div>
                    <span>{t('notification.PastMovementNotActual.action')}</span>
                </div>
                <div>
                    <span>{notification.daysAgo}</span>
                </div>
            </div>
            <div className={classes.help}>
                <div className={classes.helpText}>
                    <span>{t('notification.PastMovementNotActual.help')}</span>
                </div>
                <div className={classes.helpIcon}>
                    <HelpOutlineIcon />
                </div>
            </div>
        </div>
    )
}, (prev, next) =>
    prev.notification.subtitle === next.notification.subtitle &&
    prev.notification.daysAgo === next.notification.daysAgo)

type Props = {
    notification: NotificationView
    classes: any
}