import { storageLanguageKey } from '../i18nextHelper'
import { Profile } from './models'

export function getAuthToken(): string | null {
    return localStorage.getItem('auth_token')
}

export function getProfile(): Profile | null {
    let profile = localStorage.getItem('profile')
    if (profile != null) {
        return JSON.parse(profile) as Profile
    } else {
        return null
    }
}

export function setAuthToken(authToken: string | null): void {
    localStorage.setItem('auth_token', authToken || '')
}

export function setProfile(profile: Profile | null): void {
    localStorage.setItem('profile', JSON.stringify(profile))
}

export function clearStorage() {
    let keys = { ...localStorage }

    for (let key in keys) {
        if (!key.startsWith('persistent_') && key !== storageLanguageKey) {
            localStorage.removeItem(key)
        }
    }
}