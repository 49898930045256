import React from 'react'
import { Paper, Typography, Stepper, Step, StepLabel, StepContent, StepConnector, Collapse } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import { TruckTransportContainer } from '../../truckStore'
import { getTradCode } from '../truckForm'
import { useActionDebounce } from '../../../common/debounce'
import { _TruckEventStepSubtitle } from './_truckEventSubtitle'
import { useScroller } from './_truckScroller'
import { _TruckEventStepIcon, _TruckEventStep } from './_truckEventSteps'
import { Button } from '../../../common/customComponents'

export function _TruckEventsSection({ classes }: { classes?: any }) {
    let store = TruckTransportContainer.useContainer()
    let form = store.form
    let steps = form.eventSteps
    let scroller = useScroller()

    let getTrad = (label: string, params?: any) => { return store.t(getTradCode(label), params) }

    let activeStepIndex = form.truck.truckStatus ? steps.indexOf(form.truck.truckStatus) + 1 : 0

    let goToPreviousStep = useActionDebounce(async () => {
        if (!form.checkMandatoryFields()) return

        if (activeStepIndex !== 1)
            await form.save({ ...form.truck, truckStatus: steps[activeStepIndex - 2] })
        else
            form.setTruck({ ...form.truck, truckStatus: null })

        scroller.scrollTo(form.truck.truckStatus)
    }, 300)

    let goToNextStep = useActionDebounce(async () => {
        if (!form.checkMandatoryFields()) return

        let change = { ...form.truck, truckStatus: steps[activeStepIndex] }
        await form.save(change)

        scroller.scrollTo('end')
    }, 300)

    let stepContentClasses = (index: number) => ({
        root: index === steps.length - 1 ? null
            : index >= activeStepIndex ? classes.connectorInactive : classes.connectorActive
    })

    return (
        <Paper className={classes.paper} ref={scroller.getRef('start')}>
            <div>
                <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                    {getTrad('eventTitle')}
                </Typography>
            </div>
            <div className={classes.stepperContainer}>
                <Stepper activeStep={activeStepIndex} orientation='vertical' connector={<StepConnector classes={{ line: classes.stepperConnector }} />}>
                    {steps.map((truckStatus, index) =>
                        <Step key={index} expanded={index === activeStepIndex}>
                            <StepLabel icon={
                                <_TruckEventStepIcon
                                    truckStatus={steps[index]}
                                    state={index === activeStepIndex ? 'current' : index < activeStepIndex ? 'active' : 'disabled'}
                                    classes={classes}
                                    isTrain={store.isRailCar} />}>
                                <span className={index < activeStepIndex ? classes.labelCompleted : ''}>
                                    <span className={classes.stepTitle}>
                                        {getTrad('steps.' + truckStatus)}
                                    </span>
                                    <_TruckEventStepSubtitle currentStep={truckStatus} classes={classes} />
                                </span>
                            </StepLabel>

                            <StepContent className={classes.stepContent} classes={stepContentClasses(index)}>
                                <div className={classes.stepperFieldContainer}>
                                    <_TruckEventStep truckStatus={truckStatus} />
                                </div>

                                <Collapse in={index === activeStepIndex}>
                                    <div className={classes.stepperButtonContainer} >
                                        <Button
                                            label={getTrad('steps.previousButton')}
                                            className={classes.previousStepButton + ' ' + (index === activeStepIndex && activeStepIndex > 0 ? classes.shown : classes.almostHidden)}
                                            onClick={goToPreviousStep.execute} />
                                        <Button
                                            label={index < steps.length - 1 ? getTrad('steps.nextButton') : getTrad('steps.finishButton') + ' '}
                                            img={<Done />}
                                            className={classes.nextStepButton}
                                            onClick={goToNextStep.execute} />
                                    </div>
                                    <div ref={scroller.getRef(steps[index])}></div>
                                </Collapse>
                            </StepContent>
                        </Step>
                    )}
                </Stepper>
                <Collapse in={activeStepIndex === steps.length}>
                    <div className={classes.stepperButtonContainer} style={{ marginBottom: '1em' }} >
                        <Button className={classes.previousStepButton + ' ' + classes.lastPreviousButton}
                            label={getTrad('steps.editButton')}
                            onClick={goToPreviousStep.execute} />
                    </div>
                    <div ref={scroller.getRef('end')}></div>
                </Collapse>
            </div>
        </Paper>
    )
}