import React, { useState, useEffect } from 'react'
import { withStyles, createStyles, Typography, Checkbox } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { api } from '../../../infrastructure/api'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, Select, TableItem, TextField } from '../../common/customComponents'
import { Company, DutyStatus, Product, Site } from './models'
import { MasterDataShell, MasterDataItem, createExcelLines } from './masterDataShell'

type Plant = {
    id: string,
    plantCode: string,
    plantName: string,
    product: string,
    company: string,
    site: string,
    dutyStatus: string,
    sapCompany: string,
    isDefault: boolean,
    defaultStorageLocation: string | null
}

let zero = (): Plant => {
    return {
        id: '',
        plantCode: '',
        plantName: '',
        product: '',
        company: '',
        site: '',
        dutyStatus: '',
        sapCompany: '',
        isDefault: false,
        defaultStorageLocation: null
    }
}

type PlantFilters = {
    plantCode: string | null,
    plantName: string | null,
    product: string | null,
    company: string | null,
    site: string | null,
    dutyStatus: string | null,
    sapCompany: string | null,
    isDefault: string | null,
    defaultStorageLocation: string | null
}

let nullFilters: PlantFilters = {
    plantCode: null,
    plantName: null,
    product: null,
    company: null,
    site: null,
    dutyStatus: null,
    sapCompany: null,
    isDefault: null,
    defaultStorageLocation: null
}

function PlantMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [displayedPlants, setDisplayedPlants] = useState<TableItem<Plant>[]>([])
    let [filters, setFilters] = useState<PlantFilters>(nullFilters)
    let [companys, setCompanys] = useState<Company[]>([])
    let [sites, setSites] = useState<Site[]>([])
    let [dutyStatuss, setDutyStatuss] = useState<DutyStatus[]>([])
    let [products, setProducts] = useState<Product[]>([])

    let isManager = hasClaim(Claims.MasterdataPlantManager)
    let plantLabel = t('admin.masterdata.plant.plant')

    let getSiteNameFromCode = (code: string): string => sites.find(x => x.code == code)?.name ?? ''

    let load = async () => {
        let existingPlants = await api.get<TableItem<Plant>[]>('masterdata/plant')
        let companys = await api.get<Company[]>('masterdata/stockCapacity/company')
        let sites = await api.get<Site[]>('masterdata/stockCapacity/site')
        let products = await api.get<Product[]>('masterdata/stockCapacity/product')

        let tmpPlants: TableItem<Plant>[] = []

        existingPlants.forEach(x => {
            tmpPlants.push({
                id: x.id,
                plantCode: x.plantCode,
                plantName: x.plantName,
                company: x.company,
                product: x.product,
                site: x.site,
                dutyStatus: x.dutyStatus,
                sapCompany: x.sapCompany,
                isDefault: x.isDefault,
                defaultStorageLocation: x.defaultStorageLocation,
                isModified: false
            })
        })

        setSites(sites)
        setCompanys(companys)
        setDutyStatuss(companys.flatMap(x => x.dutyStatuses).distinct())
        setDisplayedPlants(tmpPlants)
        setProducts(products)
    }

    let applyFilters = () => (plant: Plant) => {
        let unfiltered = true

        if (filters.plantCode)
            unfiltered = unfiltered && plant.plantCode.toLowerCase().contains(filters.plantCode!.toLowerCase())

        if (filters.plantName)
            unfiltered = unfiltered && plant.plantName.toLowerCase().contains(filters.plantName!.toLowerCase())

        if (filters.company)
            unfiltered = unfiltered && getCompanyNameFromCode(plant.company).toLowerCase().contains(filters.company!.toLowerCase())

        if (filters.product)
            unfiltered = unfiltered && plant.product.toLowerCase().contains(filters.product!.toLowerCase())

        if (filters.dutyStatus)
            unfiltered = unfiltered && plant.dutyStatus.toLowerCase().contains(filters.dutyStatus!.toLowerCase())

        if (filters.site)
            unfiltered = unfiltered && getSiteNameFromCode(plant.site).toLowerCase().contains(filters.site!.toLowerCase())

        if (filters.isDefault)
            unfiltered = unfiltered && getIsDefaultLabel(plant.isDefault).toLowerCase().contains(filters.isDefault!.toLowerCase())

        return unfiltered
    }

    let onDelete = async (ids: string[]) => {
        if (ids.length === 0) return false
        await api.post('masterdata/plant/delete', { ids: ids })
        await load()
        return true
    }

    useEffect(() => { let effect = async () => { await load() }; effect() }, [])
    useEffect(() => { applyFilters() }, [filters])

    let onSave = async (item: Plant) => {
        await api.post('masterdata/plant', item)
        await load()
        return true
    }

    let getCompanyNameFromCode = (code: string): string => companys.find(x => x.code == code)?.name ?? ''

    let getIsDefaultLabel = (value: boolean): string => value
        ? t('admin.masterdata.plant.isDefaultYes')
        : t('admin.masterdata.plant.isDefaultNo')

    let columns: ColumnDescriptor<TableItem<Plant>>[] = [
        {
            name: t('admin.masterdata.plant.code'), value: x => x.plantCode,
            columnFilter: { value: filters.plantCode ?? '', onChange: (code: string) => setFilters({ ...filters, plantCode: code }) }
        },
        {
            name: t('admin.masterdata.plant.name'), value: x => x.plantName,
            columnFilter: { value: filters.plantName ?? '', onChange: (name: string) => setFilters({ ...filters, plantName: name }) }
        },
        {
            name: t('admin.masterdata.plant.product'), value: x => x.product,
            columnFilter: { value: filters.product ?? '', onChange: (product: string) => setFilters({ ...filters, product: product }) }
        },
        {
            name: t('admin.masterdata.plant.company'), value: x => getCompanyNameFromCode(x.company),
            columnFilter: { value: filters.company ?? '', onChange: (company: string) => setFilters({ ...filters, company: company }) }
        },
        {
            name: t('admin.masterdata.plant.site'), value: x => getSiteNameFromCode(x.site), valueForExport: x => x.site,
            columnFilter: { value: filters.site ?? '', onChange: (site: string) => setFilters({ ...filters, site: site }) }
        },
        {
            name: t('admin.masterdata.plant.dutyStatus'), value: x => x.dutyStatus,
            columnFilter: { value: filters.dutyStatus ?? '', onChange: (dutyStatus: string) => setFilters({ ...filters, dutyStatus: dutyStatus }) }
        },
        {
            name: t('admin.masterdata.plant.sapCompany'), value: x => getCompanyNameFromCode(x.sapCompany),
            columnFilter: { value: filters.sapCompany ?? '', onChange: (sapCompany: string) => setFilters({ ...filters, sapCompany: sapCompany }) }
        },
        {
            name: t('admin.masterdata.plant.isDefault'), value: x => getIsDefaultLabel(x.isDefault),
            columnFilter: { value: filters.isDefault ?? '', onChange: (isDefault: string) => setFilters({ ...filters, isDefault: isDefault }) }
        },
        {
            name: t('admin.masterdata.plant.defaultStorageLocation'), value: x => x.defaultStorageLocation,
            columnFilter: { value: filters.defaultStorageLocation ?? '', onChange: (defaultStorageLocation: string) => setFilters({ ...filters, defaultStorageLocation: defaultStorageLocation }) }
        }
    ]

    let getItems = () => displayedPlants.filter(applyFilters()).map(toTableItem)

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'Plant.xlsx',
            sheets: [{ name: 'Plant', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let importExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/plant/import'
        api.upload(uploadUrl, file, 'import', { withReport: 'dialog' }).then(_ => load())
    }

    let toTableItem = (plant: Plant): TableItem<MasterDataItem<Plant>> => {
        return {
            id: plant.id,
            plantCode: plant.plantCode,
            plantName: plant.plantName,
            product: plant.product,
            company: plant.company,
            site: plant.site,
            dutyStatus: plant.dutyStatus,
            sapCompany: plant.sapCompany,
            isDefault: plant.isDefault,
            defaultStorageLocation: plant.defaultStorageLocation,
            isModified: false
        }
    }

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={`masterdata-table-${plantLabel}`}
                headerLabel={plantLabel}
                itemLabel={plantLabel}
                isManager={isManager}
                onExportExcel={exportExcel}
                onImportExcel={importExcel}
                onNew={zero}
                onDelete={onDelete}
                onSave={onSave}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <TextField label={t('admin.masterdata.plant.code')}
                                text={selectedItem.plantCode}
                                onChange={event => setSelectedItem({ ...selectedItem, plantCode: event.target.value })} />

                            <TextField label={t('admin.masterdata.plant.name')}
                                text={selectedItem.plantName}
                                onChange={event => setSelectedItem({ ...selectedItem, plantName: event.target.value })} />

                            <Select label={t('admin.masterdata.plant.company')}
                                value={selectedItem.company}
                                choices={companys.map(x => ({ value: x.code, text: `${x.code} - ${x.name}` }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, company: val }) }} />

                            <Select label={t('admin.masterdata.plant.product')}
                                value={selectedItem.product}
                                choices={products.map(x => ({ value: x.code, text: x.code }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, product: val }) }} />

                            <Select label={t('admin.masterdata.plant.site')}
                                value={selectedItem.site}
                                choices={sites.map(x => ({ value: x.code, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, site: val }) }} />

                            <Select label={t('admin.masterdata.plant.dutyStatus')}
                                value={selectedItem.dutyStatus}
                                choices={dutyStatuss.map(x => ({ value: x, text: x }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, dutyStatus: val }) }} />

                            <Select label={t('admin.masterdata.plant.sapCompany')}
                                value={selectedItem.sapCompany}
                                choices={companys.map(x => ({ value: x.code, text: `${x.code} - ${x.name}` }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, sapCompany: val }) }} />

                            <TextField label={t('admin.masterdata.plant.defaultStorageLocation')}
                                text={selectedItem.defaultStorageLocation}
                                onChange={event => setSelectedItem({ ...selectedItem, defaultStorageLocation: event.target.value })} />

                            <div className={classes.isDefaultContainer}>
                                <Typography variant='subtitle1'>{t('admin.masterdata.plant.isDefault')}</Typography>
                                <Checkbox checked={selectedItem.isDefault} onChange={x => setSelectedItem({ ...selectedItem, isDefault: x.target.checked })} />
                            </div>
                        </>
                    )}
            </MasterDataShell>
        </div>)
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
        isDefaultContainer: {
            ...defaultStyles.flexRow,
            width: '15.3em',
            justifyContent: 'space-between',
            "& >span": { padding: '0' }
        }
    })

export default withStyles(styles, muiOptions)(PlantMasterData)