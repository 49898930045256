import { AccountingPeriodStatus } from '../stock/stockModels'
import guid, { Guid } from './../../infrastructure/guid'

type StringDate = string

export type DutyStatus = string

export type MktSaleForecast = {
    date: StringDate | null
    daysInPeriod: number | null
    company: string | null
    values: MktSaleForecastValue[] | null
    snapshotsInfos: MktSaleForecastSnapshotInfo[] | null
}

export type MktSaleForecastSnapshotInfo = {
    id: Guid
    type: string
    createdAt: StringDate
}

export type SupplyProduct = {
    id: Guid
    code: string
    unit: string
    productGroup: string | null
    conversionFactor: number
    defaultQuotationCode: string
}

export type CustomerSegment = {
    code: string
    site: string
    company: string
    dutyStatus: DutyStatus
    products: CustomerSegmentProduct[]
}

export type CustomerSegmentProduct = {
    id: string
    code: string
    unit: string
    hasStock: boolean
    periodCoeff: number
}

export type Site = {
    code: string
    name: string
    siteGroup: string
    isSellingPoint: boolean
    dutyStatuses: DutyStatus[]
}

export type MktSaleForecastValue = {
    perimeter: MktSaleForecastValuePerimeter
    volume: number | null
}

export type MktSaleForecastValuePerimeter = {
    channel: string | null
    productId: Guid | null
    customerSegment: string | null
    site: string | null
}

export type Company = {
    code: string
    name: string
    dutyStatuses: DutyStatus[]
    customerSegments: string[]
}

export type ChannelCustomerSegment = {
    company: string
    channel: string
    customerSegment: string
    dutyStatus: string
}

export type MktSales = {
    movementItems: MktSaleMovement[]
    chart: MktSaleChart
}

export type MktSaleMovement = {
    id: Guid
    date: StringDate
    customerSegment: string | null
    snapshotVolume: number | null
    forecastQuantity: number | null
    plannedQuantity: number | null
    siteQuantity: number | null
    accountingPeriodStatus: AccountingPeriodStatus | null
    sapQuantity: number | null
    quantity: number | null
    status: MovementStatus
    productId: string
    company: string
    site: string
    hasDemandPlanChange: boolean
}

export type MktSaleChart = {
    forecasts: ChartPoint[]
    otsForecasts: ChartPoint[]
    actualSales: ChartPoint[]
    sites: ChartPoint[]
    saps: ChartPoint[]
}

export let emptyChart: MktSaleChart = { forecasts: [], otsForecasts: [], actualSales: [], sites: [], saps: [] }

export type ChartPoint = {
    date: Date
    volume: number
}

export enum MovementStatus {
    Forecast = 0,
    Planned = 1,
    Actual = 2
}

export type MarketSalesFilters = {
    companies?: string[] | null
    site?: string[] | null
    month?: string | null
    productId?: Guid[] | null
    customerSegments?: string[] | null
}

export type MktSaleDate = {
    date: string,
    isOpen: boolean,
    quantity: number | null,
    snapshotVolume: number | null,
    forecastQuantity: number | null,
    plannedQuantity: number | null,
    siteQuantity: number | null,
    accountingPeriodStatus: AccountingPeriodStatus | null,
    sapQuantity: number | null,
    status: MovementStatus,
    movements: MktSaleMovement[]
}

export type MktSaleShipTosFilters = {
    companys: string[] | null
    sites: string[] | null
    date: string | null
    productIds: Guid[] | null
    customerSegments: string[] | null
}

export type MktSaleForecastShipToDetails = {
    id: Guid
    date: Date
    company: string
    companyName: string
    productId: Guid
    product: string
    customerSegment: string
    dutyStatus: string
    site: string
    shipTo: string
    forecastQuantity: number
    plannedQuantity: number
    status: MovementStatus
    dealReferences: { [dealId: string]: string }
    snapshotVolume: number | null,
    quantity: number | null
    siteQuantity: number | null
    sapQuantity: number | null
    shipToName: string | null
    soldTo: string | null
    soldToName: string | null
    destination: string | null
    flattenedDealReferences: string
}

export type ExportMktSalesArgs = {
    filename: string,
    month?: string | null,
    site?: string[] | null
    productId?: Guid[] | null
    companies?: string[] | null
    customerSegments?: string[] | null
}

export type ShipToDetailsFilters = {
    companyName: string | null
    product: string | null
    customerSegment: string | null
    site: string | null
    shipTo: string | null
    shipToName: string | null
    soldTo: string | null
    soldToName: string | null
    destination: string | null
    dealRef: string | null
    flattenedDealReferences: string | null
}

export let defaultShipToDetailsFilters: ShipToDetailsFilters = {
    companyName: null,
    product: null,
    customerSegment: null,
    site: null,
    shipTo: null,
    shipToName: null,
    soldTo: null,
    soldToName: null,
    destination: null,
    dealRef: null,
    flattenedDealReferences: null
}

export type ForecastSnapshotParam = {
    company: string,
    month: string
}

export type MktSalesDemandPlanChange = {
    dateFrom: StringDate,
    dateTo: StringDate,
    days: number,
    weightedDays: number,
    productId: Guid,
    site: string,
    company: string | null
    dutyStatus: DutyStatus | null,
    customerSegment?: string | null,
    takeForecastValue: boolean,
    plannedVolumePerDay: number,
    totalPlannedVolume: number,
    comment: string,
    id: Guid
}

export let defaultDemandPlanChange = (): MktSalesDemandPlanChange => ({
    dateFrom: '',
    dateTo: '',
    days: 0,
    weightedDays: 0,
    productId: guid.empty,
    site: '',
    company: null,
    dutyStatus: null,
    customerSegment: null,
    takeForecastValue: true,
    plannedVolumePerDay: 0,
    totalPlannedVolume: 0,
    comment: '',
    id: guid.empty
})