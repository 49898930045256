import React from 'react'
import { TableCell, Typography, } from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'
import { NumberField } from '../../../common/customComponents'
import { t } from '../../../../infrastructure/i18nextHelper'
import { defaultColors, defaultStyles, customStyledComponent } from '../../../../infrastructure/materialUiThemeProvider'

export let Cell = customStyledComponent(TableCell)({ "white-space": "nowrap", "padding": "3px 0px", "width": "9%" }, {})
export let RightAlignedCell = customStyledComponent(TableCell)({ "white-space": "nowrap", "padding": "3px 0px", "width": "9%", "text-align": "right" }, {})

export let commonStyle = createStyles({
    quantities: {
        ...defaultStyles.flexRow,
        alignItems: 'center'
    },
    purchaseMovementHeader: {
        ...defaultStyles.flexRow,
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%'
    },
    purchaseMovementTriggerTable: {
        margin: '2em 0em'
    },
    purchaseMovementTriggerHeader: {
        ...defaultStyles.flexRow,
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%'
    },
    purchaseMovementTriggerActionsHeader: {
        ...defaultStyles.flexRow,
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%'
    },
    headerActionAndQuantity: defaultStyles.flexRow,
    headerActionAndQuantityFullWidth: {
        ...defaultStyles.flexRow,
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: '0.5em'
    },
    purchaseMovementHeaderCombination: {
        color: defaultColors.red.main.color,
        fontWeight: 'bold',
        marginRight: '1.5em'
    },
    quantitiesBottom: {
        ...defaultStyles.flexRow,
        alignItems: 'center',
        '& > *': {
            marginRight: '2em',
            '& :last-child': {
                margin: 0
            }
        }
    },
    quantitiesBottomTrigger: {
        ...defaultStyles.flexRow,
        alignItems: 'center',
        '& > *': {
            marginRight: '0.6em',
            '& :last-child': {
                margin: 0
            }
        }
    },
    nominatedQuantityPercentage: {
        ...defaultStyles.flexRow,
        margin: '0',
        width: '10em'
    },
    percentage: {
        alignSelf: 'flex-end',
        width: '2em'
    },
    percentageText: {
        textAlign: 'center',
    },
    percentageAlertText: {
        textAlign: 'center',
        color: defaultColors.red.light.color + ' !important',
        backgroundColor: 'white !important',
    },
    percentageOkText: {
        textAlign: 'center',
        color: defaultColors.green.light.color + ' !important',
        backgroundColor: 'white !important',
    },
    purchaseMovementActions: {
        ...defaultStyles.flexRow,
        width: '20em',
        justifyContent: 'space-between'
    },
    headerButton: {
        ...defaultStyles.secondaryButton,
        minWidth: '10em'
    },
    globalCell: {
        verticalAlign: 'top',
        paddingTop: '1em'
    },
    siteInput: {
        maxWidth: '9em',
        minWidth: '6em'
    },
    dateInput: {
        maxWidth: '11em',
        minWidth: '8em'
    },
    nominatedInput: {
        maxWidth: '8em',
        minWidth: '6em'
    },
    select: {
        textAlign: 'left',
        maxWidth: '12em'
    },
    sitePurchaseMvtRow: {
        verticalAlign: "top"
    }
})

type TotalDispatchedQuantityProps = {
    nominatedQuantity: number | null
    updateNominatedQuantity: (newQuantity: number | null) => void
    quantity: number | null
    percentage: number | null
    inAlert: boolean | null
    dispatchedLabel: string
    withoutDispatchedQuantity: boolean | null
    classes: any
}

export function TotalDispatchedQuantity(props: TotalDispatchedQuantityProps) {
    return (
        <div className={props.classes.quantities}>
            <div className={props.classes.quantitiesBottom}>
                <Typography variant='subtitle2'>{t('vessels.label.purchaseMovement.nominatedQuantity')}</Typography>
                <NumberField disableNewStyle
                    label={''}
                    overrideStyle={{ root: props.classes.nominatedInput }}
                    onChange={vol => props.updateNominatedQuantity(vol)}
                    text={props.nominatedQuantity} />
            </div>
            {!props.withoutDispatchedQuantity &&
                <div className={props.classes.quantitiesBottom}>
                    <Typography variant='subtitle2'>{props.dispatchedLabel}</Typography>
                    <Typography className={props.classes.nominatedQuantityPercentage} variant='subtitle1'>
                        {props.quantity ?? '-'}&nbsp;<TotalDispatchedQuantityPercentage {...props} />
                    </Typography>
                </div>}
        </div >
    )
}

type UnbalancesProps = {
    mainUnbalance: number | null
    productUnitUnbalance: number | null
    mainUnit: string | null
    productUnit: string | null
    classes: any
}

export function Unbalances(props: UnbalancesProps) {
    return (
        <div className={props.classes.quantities}>
            <div className={props.classes.quantitiesBottom}>
                <Typography variant='subtitle2'>{t('vessels.label.purchaseMovement.unbalance')}</Typography>
                <NumberField disableNewStyle disabled
                    label={''} overrideStyle={{ root: props.classes.nominatedInput }}
                    onChange={_ => { }}
                    text={props.mainUnbalance}
                    unit={props.mainUnit}
                />
            </div>
            <div className={props.classes.quantitiesBottom}>
                <Typography variant='subtitle2'>{t('vessels.label.purchaseMovement.unbalance')}</Typography>
                <NumberField disableNewStyle disabled
                    label={''} overrideStyle={{ root: props.classes.nominatedInput }}
                    onChange={_ => { }}
                    text={props.productUnitUnbalance}
                    unit={props.productUnit} />
            </div>
        </div>
    )
}

function TotalDispatchedQuantityPercentage(props: TotalDispatchedQuantityProps) {
    if (props.percentage == null)
        return (<div className={props.classes.percentage}>{'- %'}</div>)

    let formatedPercentage = `${Math.ceil(props.percentage * 100)}%`

    let inputClass =
        props.inAlert == null ? props.classes.percentageText
            : props.inAlert ? props.classes.percentageAlertText
                : props.classes.percentageOkText

    return (
        <p className={inputClass}>
            {"( " + formatedPercentage + " )"}
        </p>
    )
}