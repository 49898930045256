import moment from 'moment'
import { useState, useContext, useEffect } from 'react'
import { Filters, TruckStatus } from '../../truckModels'
import { TransportationType } from '../../truckStore'
import { setInLocalStorage } from '../../../../infrastructure/localStorage'
import { compareAndFixPeriod } from '../../../common/components/datePicker/tools'

export function useFilters(transportationTypeContext: React.Context<TransportationType>) {
    let transportationType = useContext(transportationTypeContext)

    let [fields, setFields] = useState<Filters>(overrideWithDefaultDates(transportationType.meanOfTransportation ?? null))
    let [searchQuery, setSearchQuery] = useState<string | null>(null)
    let [filterDatesOnError, setFilterDatesOnError] = useState<boolean>(false)

    useEffect(getLocalStorageFilters, [])
    useEffect(() => setInLocalStorage('truck-filters', fields), [fields])

    let applySearchQuery = () => {
        setFields({ ...fields, query: searchQuery })
    }

    let toggleOnGoing = () => {
        setFields({ ...fields, inTransit: !fields.inTransit })
    }

    type TruckFiltersDateProps = Pick<Filters, 'inTransitAt'>
    let changeDate = (newDate: string | null, prop: keyof TruckFiltersDateProps) => {
        let truckFilters: Filters = { ...fields }
        truckFilters[prop] = newDate
        truckFilters = { ...truckFilters, startPeriod: moment(newDate).startOf('month').format('MM/DD/YYYY') }
    }

    let changePeriod = (start: string | null, end: string | null) => {
        let truckFilters: Filters = { ...fields }
        
        let newPeriod = compareAndFixPeriod(fields.startPeriod, start, fields.endPeriod, end)
        truckFilters.startPeriod = newPeriod.start
        truckFilters.endPeriod = newPeriod.end

        setFields(truckFilters)
        setFilterDatesOnError(false)
    }

    function changeTruckStatuses(status: TruckStatus) {
        if (fields.truckStatuses.some(x => x === status)) {
            let index = fields.truckStatuses.indexOf(status)
            if (index >= 0) {
                fields.truckStatuses.splice(index, 1)
                setFields({ ...fields, truckStatuses: fields.truckStatuses })
            }
        }
        else {
            fields.truckStatuses.push(status)
            setFields({ ...fields, truckStatuses: fields.truckStatuses })
        }
    }

    function getLocalStorageFilters() {
        let jsonFilters = localStorage.getItem('truck-filters')
        if (!jsonFilters) return
        let filters = JSON.parse(jsonFilters)
        fields.startPeriod = filters.startPeriod
        fields.endPeriod = filters.endPeriod
        setFields(fields)
    }

    return {
        fields, setFields, filterDatesOnError, setFilterDatesOnError,
        searchQuery, setSearchQuery, applySearchQuery, toggleOnGoing,
        changeDate, changePeriod, changeTruckStatuses
    }
}

let overrideWithDefaultDates = (mot: string | null): Filters => {
    return {
        startPeriod: defaultStartDate(),
        endPeriod: defaultEndDate(),
        inTransitAt: defaultInTransitAt(),
        meanOfTransportation: mot,
        productIds: [],
        originSites: null,
        destinationSites: null,
        customerSegments: null,
        query: null,
        inTransit: false,
        truckStatuses: []
    }
}

export const defaultStartDate = () => moment.utc().startOf('month').format()
export const defaultEndDate = () => moment.utc().startOf('month').add(1, 'M').add(-1, 'day').format()
export const defaultInTransitAt = () => moment.utc().format()