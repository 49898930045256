import { createStyles, withStyles } from '@material-ui/core'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import React, { useEffect, useState } from 'react'
import { ColumnDescriptor, Select, TableItem, TextField } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import * as api from '../../../infrastructure/api'
import guid, { Guid } from '../../../infrastructure/guid'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { MasterDataItem, MasterDataShell } from './masterDataShell'

type Counterparty = {
    id: Guid
    name: string
}

type Company = {
    id: Guid
    code: string
    name: string
    country: string
}

type DealType = {
    country: string
    dealType: string
}

type SapCounterpartyDealType = {
    id: Guid
    counterpartyId: Guid
    company: string
    dealType: string
    exchangeNumber: string
    purchaseOrganization: string | null
    purchaseGroup: string | null
    salesOrganization: string | null
    salesOffice: string | null
    salesGroup: string | null
    distributionChannel: string | null
}

let emptySapCounterpartyDealType = () => ({
    id: guid.empty,
    counterpartyId: guid.empty,
    company: null,
    dealType: null,
    exchangeNumber: null,
    purchaseOrganization: null,
    purchaseGroup: null,
    salesOrganization: null,
    salesOffice: null,
    salesGroup: null,
    distributionChannel: null,
})

let toTableItem = (item: SapCounterpartyDealType): TableItem<MasterDataItem<SapCounterpartyDealType>> => {
    return {
        id: item.id,
        counterpartyId: item.counterpartyId,
        company: item.company,
        dealType: item.dealType,
        exchangeNumber: item.exchangeNumber,
        purchaseOrganization: item.purchaseOrganization,
        purchaseGroup: item.purchaseGroup,
        salesOrganization: item.salesOrganization,
        salesOffice: item.salesOffice,
        salesGroup: item.salesGroup,
        distributionChannel: item.distributionChannel,
        isModified: false
    }
}

type SapCounterpartyDealTypeFilter = {
    counterpartyName: string | null
    company: string | null
    dealtType: string | null
    exchangeNumber: string | null
    purchaseOrganization: string | null
    purchaseGroup: string | null
    salesOrganization: string | null
    salesOffice: string | null
    salesGroup: string | null
    distributionChannel: string | null
}

let noFilters: SapCounterpartyDealTypeFilter = {
    counterpartyName: null,
    company: null,
    dealtType: null,
    exchangeNumber: null,
    purchaseOrganization: null,
    purchaseGroup: null,
    salesOrganization: null,
    salesOffice: null,
    salesGroup: null,
    distributionChannel: null
}

let applyFilters = (items: SapCounterpartyDealType[], filters: SapCounterpartyDealTypeFilter, counterpartys: Counterparty[]): SapCounterpartyDealType[] => {
    if (filters.counterpartyName)
        items = items.filter(x => counterpartys.find(c => c.id === x.counterpartyId)?.name?.toLowerCase().contains(filters.counterpartyName!.toLowerCase()))

    if (filters.company)
        items = items.filter(x => x.company.toLowerCase().contains(filters.company!.toLowerCase()))

    if (filters.dealtType)
        items = items.filter(x => x.dealType?.toLowerCase().contains(filters.dealtType!.toLowerCase()))

    if (filters.exchangeNumber)
        items = items.filter(x => x.exchangeNumber?.toLowerCase().contains(filters.exchangeNumber!.toLowerCase()))

    if (filters.purchaseOrganization)
        items = items.filter(x => x.purchaseOrganization?.toLowerCase().contains(filters.purchaseOrganization!.toLowerCase()))

    if (filters.purchaseGroup)
        items = items.filter(x => x.purchaseGroup?.toLowerCase().contains(filters.purchaseGroup!.toLowerCase()))

    if (filters.salesOrganization)
        items = items.filter(x => x.salesOrganization?.toLowerCase().contains(filters.salesOrganization!.toLowerCase()))

    if (filters.salesOffice)
        items = items.filter(x => x.salesOffice?.toLowerCase().contains(filters.salesOffice!.toLowerCase()))

    if (filters.salesGroup)
        items = items.filter(x => x.salesGroup?.toLowerCase().contains(filters.salesGroup!.toLowerCase()))

    if (filters.distributionChannel)
        items = items.filter(x => x.distributionChannel?.toLowerCase().contains(filters.distributionChannel!.toLowerCase()))

    return items
}

function SapCounterpartyDealTypeMasterData({ classes }: MuiProps) {
    let [counterpartys, setCounterpartys] = useState<Counterparty[]>([])
    let [companys, setCompanys] = useState<Company[]>([])
    let [dealTypes, setDealTypes] = useState<DealType[]>([])
    let [sapCounterpartyDealTypes, setSapCounterpartyDealTypes] = useState<SapCounterpartyDealType[]>([])
    let [filters, setFilters] = useState<SapCounterpartyDealTypeFilter>(noFilters)
    let [countryByCompany, setCountryByCompany] = useState<{ [key: string]: string }>({})

    useEffect(() => {
        setCountryByCompany(companys.reduce((acc, x) => { acc[x.code] = x.country; return acc }, {}))
    }, [companys])

    let load = async () => {
        let counterpartys = api.get<Counterparty[]>('masterdata/sapCounterpartyDealType/counterparty')
        let companys = api.get<Company[]>('masterdata/sapCounterpartyDealType/company')
        let dealTypes = api.get<DealType[]>('masterdata/sapCounterpartyDealType/dealType')
        let sapCounterpartyDealTypes = api.get<SapCounterpartyDealType[]>('masterdata/sapCounterpartyDealType')

        setCounterpartys(await counterpartys)
        setCompanys(await companys)
        setDealTypes(await dealTypes)
        setSapCounterpartyDealTypes(await sapCounterpartyDealTypes)
    }

    useEffect(() => { load() }, [])

    let onSave = async (item: SapCounterpartyDealType) => {
        await api.post('masterdata/sapCounterpartyDealType', item)
        await load()
        return true
    }

    let onDelete = async (ids: string[]) => {
        await api.del('masterdata/sapCounterpartyDealType', { ids: ids })
        await load()
        return true
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<SapCounterpartyDealType>>>[] = [
        {
            name: t('admin.masterdata.sapCounterpartyDealType.company'), value: x => x.company,
            columnFilter: { value: filters.company ?? '', onChange: (company: string) => setFilters({ ...filters, company }) }
        },
        {
            name: t('admin.masterdata.sapCounterpartyDealType.counterparty'), value: x => counterpartys.find(c => c.id === x.counterpartyId)?.name ?? '-',
            columnFilter: { value: filters.counterpartyName ?? '', onChange: (counterpartyName: string) => setFilters({ ...filters, counterpartyName }) }
        },
        {
            name: t('admin.masterdata.sapCounterpartyDealType.dealType'), value: x => x.dealType,
            columnFilter: { value: filters.dealtType ?? '', onChange: (dealtType: string) => setFilters({ ...filters, dealtType }) }
        },
        {
            name: t('admin.masterdata.sapCounterpartyDealType.exchangeNumber'), value: x => x.exchangeNumber,
            columnFilter: { value: filters.exchangeNumber ?? '', onChange: (exchangeNumber: string) => setFilters({ ...filters, exchangeNumber }) }
        },
        {
            name: t('admin.masterdata.sapCounterpartyDealType.purchaseOrganization'), value: x => x.purchaseOrganization,
            columnFilter: { value: filters.purchaseOrganization ?? '', onChange: (code) => setFilters({ ...filters, purchaseOrganization: code }) }
        },
        {
            name: t('admin.masterdata.sapCounterpartyDealType.purchaseGroup'), value: x => x.purchaseGroup,
            columnFilter: { value: filters.purchaseGroup ?? '', onChange: (code) => setFilters({ ...filters, purchaseGroup: code }) }
        },
        {
            name: t('admin.masterdata.sapCounterpartyDealType.salesOrganization'), value: x => x.salesOrganization,
            columnFilter: { value: filters.salesOrganization ?? '', onChange: (code) => setFilters({ ...filters, salesOrganization: code }) }
        },
        {
            name: t('admin.masterdata.sapCounterpartyDealType.salesOffice'), value: x => x.salesOffice,
            columnFilter: { value: filters.salesOffice ?? '', onChange: (code) => setFilters({ ...filters, salesOffice: code }) }
        },
        {
            name: t('admin.masterdata.sapCounterpartyDealType.salesGroup'), value: x => x.salesGroup,
            columnFilter: { value: filters.salesGroup ?? '', onChange: (code) => setFilters({ ...filters, salesGroup: code }) }
        },
        {
            name: t('admin.masterdata.sapCounterpartyDealType.distributionChannel'), value: x => x.distributionChannel,
            columnFilter: { value: filters.distributionChannel ?? '', onChange: (code) => setFilters({ ...filters, distributionChannel: code }) }
        }
    ]

    let isManager = hasClaim(Claims.MasterdataSapCounterpartyManager)

    return (
        <div className={classes.container}>
            <MasterDataShell
                headerLabel={t('admin.masterdata.sapCounterpartyDealType.sapCounterpartyDealTypes')}
                itemLabel={t('admin.masterdata.sapCounterpartyDealType.sapCounterpartyDealType')}
                isManager={isManager}
                onNew={emptySapCounterpartyDealType}
                onDelete={onDelete}
                onSave={onSave}
                items={applyFilters(sapCounterpartyDealTypes, filters, counterpartys).map(toTableItem)}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <Select label={t('admin.masterdata.sapCounterpartyDealType.company')}
                                disabled={!isManager}
                                value={selectedItem.company}
                                choices={companys.map(x => ({ value: x.code, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, company: val }) }} />
                            <Select label={t('admin.masterdata.sapCounterpartyDealType.counterparty')}
                                disabled={!isManager}
                                value={selectedItem.counterpartyId}
                                choices={counterpartys.map(x => ({ value: x.id, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, counterpartyId: val }) }} />
                            <Select label={t('admin.masterdata.sapCounterpartyDealType.dealType')}
                                disabled={!isManager}
                                value={selectedItem.dealType}
                                choices={dealTypes.filter(x => x.country == countryByCompany[selectedItem.company]).map(x => x.dealType)}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, dealType: val }) }} />
                            <TextField label={t('admin.masterdata.sapCounterpartyDealType.exchangeNumber')}
                                disabled={!isManager}
                                text={selectedItem.exchangeNumber}
                                onChange={event => setSelectedItem({ ...selectedItem, exchangeNumber: event.target.value })} />
                            <TextField label={t('admin.masterdata.sapCounterpartyDealType.purchaseOrganization')}
                                disabled={!isManager}
                                text={selectedItem.purchaseOrganization}
                                onChange={event => setSelectedItem({ ...selectedItem, purchaseOrganization: event.target.value })} />
                            <TextField label={t('admin.masterdata.sapCounterpartyDealType.purchaseGroup')}
                                disabled={!isManager}
                                text={selectedItem.purchaseGroup}
                                onChange={event => setSelectedItem({ ...selectedItem, purchaseGroup: event.target.value })} />
                            <TextField label={t('admin.masterdata.sapCounterpartyDealType.salesOrganization')}
                                disabled={!isManager}
                                text={selectedItem.salesOrganization}
                                onChange={event => setSelectedItem({ ...selectedItem, salesOrganization: event.target.value })} />
                            <TextField label={t('admin.masterdata.sapCounterpartyDealType.salesOffice')}
                                disabled={!isManager}
                                text={selectedItem.salesOffice}
                                onChange={event => setSelectedItem({ ...selectedItem, salesOffice: event.target.value })} />
                            <TextField label={t('admin.masterdata.sapCounterpartyDealType.salesGroup')}
                                disabled={!isManager}
                                text={selectedItem.salesGroup}
                                onChange={event => setSelectedItem({ ...selectedItem, salesGroup: event.target.value })} />
                            <TextField label={t('admin.masterdata.sapCounterpartyDealType.distributionChannel')}
                                disabled={!isManager}
                                text={selectedItem.distributionChannel}
                                onChange={event => setSelectedItem({ ...selectedItem, distributionChannel: event.target.value })} />
                        </>
                    )}
            </MasterDataShell>
        </div>
    )
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
    })

export default withStyles(styles, muiOptions)(SapCounterpartyDealTypeMasterData)