import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { AttachMoney, ShoppingCart, SwapHoriz, TrendingFlat, FileCopyOutlined, Link, GavelOutlined, DeleteOutlined } from '@material-ui/icons'
import {
    Table, TableBody, TableHead,
    TableRow, IconButton, Tooltip, Badge, Typography
} from '@material-ui/core'
import { muiOptions, MuiProps } from '../../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../../infrastructure/i18nextHelper'
import { Guid } from '../../../../infrastructure/guid'
import { popupNavigator } from '../../../../infrastructure/popupNavigator'
import { hasFeature } from '../../../../infrastructure/feature'
import { Claims } from '../../../../infrastructure/signIn/models'
import { hasClaim } from '../../../../infrastructure/signIn/userContext'
import { TextField, NumberField, Select, DatePicker, DeltaNumberLabel } from '../../../common/customComponents'
import { commonStyle, Cell, RightAlignedCell } from './_common'
import { VesselEditContainer } from '../vesselEditStore'
import { PurchaseMovement, SitePurchaseMovement, MovementType } from '../../vesselModels'
import { PurchaseMovementTableProps } from './priceMovementsContainer'

let tBase = 'vessels.label.purchaseMovement.'

type PricingNeedDeltasBySiteId = {
    [siteId: string]: number | null
}

function _PriceSeveralMovementTypesTable(props: PurchaseMovementTableProps & MuiProps & { purchaseMovementSite: string }) {
    let { classes, vesselProduct, purchaseMovement, isFixedPrice, duplicateSite, addSite,
        setPurchaseMovementToDelete, setSitePurchaseMovementToDelete, purchaseMovementSite } = props

    let initPricingNeedDeltas = (): PricingNeedDeltasBySiteId =>
        purchaseMovement.sites.reduce((acc, curr) => {
            acc[curr.id] = 0
            return acc
        }, {})
    let [pricingNeedDeltas, setPricingNeedDeltas] = useState<PricingNeedDeltasBySiteId>(initPricingNeedDeltas())
    let vessel = VesselEditContainer.useContainer()

    useEffect(() => {
        let deltas = initPricingNeedDeltas()
        for (var key in deltas)
            deltas[key] = pricingNeedDeltas[key] ?? 0

        setPricingNeedDeltas(deltas)
    }, [purchaseMovement.sites])

    let hasExpositionSimulation = hasFeature('VesselMultiplePricingPeriod')
        && !isFixedPrice
        && hasClaim(Claims.MarketRiskSimulatorReader)

    let movementTypes: { value: MovementType, text: string }[] =
        [
            { value: MovementType.SaleType, text: t(tBase + 'saleType') },
            { value: MovementType.PurchaseType, text: t(tBase + 'purchaseType') },
            { value: MovementType.TransferType, text: t(tBase + 'transferType') },
        ]

    let parseMovementType = (value: string): MovementType => {
        let type = parseInt(value)
        if (type == 2)
            return MovementType.TransferType
        if (type == 0)
            return MovementType.SaleType
        return MovementType.PurchaseType
    }

    let movementTypeIcon = (type: MovementType | null): JSX.Element | null => {
        if (type == null)
            return null
        if (type == MovementType.TransferType)
            return <SwapHoriz />
        if (type == MovementType.SaleType)
            return <AttachMoney />
        return <ShoppingCart />
    }

    let sites = (purchaseMvt: PurchaseMovement): { value: string, text: string }[] => {
        if (!vessel.movementStock || !vessel.sites)
            return []

        let purchaseMovementStockSites = vessel.movementStock
            .filter(x => x.company === purchaseMvt.companyCode
                && x.dutyStatus === purchaseMvt.dutyStatus
                && x.productId === vesselProduct.productId)
            .map(x => x.site)
        return vessel.sites
            .filter(x => purchaseMovementStockSites.includes(x.code) && x.code != purchaseMovementSite)
            .map(x => ({ value: x.code, text: x.name }))
    }

    let changeDelta = (siteId: Guid, oldValue: number | null, newValue: number | null) => {
        let deltas = pricingNeedDeltas
        deltas[siteId] = (newValue ?? 0) - ((oldValue ?? 0) - (pricingNeedDeltas[siteId] ?? 0))
        setPricingNeedDeltas(deltas)
    }

    let updateQuantity = (purchaseMvt: PurchaseMovement, site: SitePurchaseMovement, newValue: number | null) => {
        if (vesselProduct.productUnit == 'TO')
            changeDelta(site.id, site.quantity, newValue)
        updateSite(purchaseMvt, site, site => { return { ...site, quantity: newValue, quantityOverride: newValue != null } })
    }

    let updateVolume = (purchaseMvt: PurchaseMovement, site: SitePurchaseMovement, newValue: number| null) => {
        if (vesselProduct.productUnit != 'TO')
            changeDelta(site.id, site.volume, newValue)
        updateSite(purchaseMvt, site, site => { return { ...site, volume: newValue, volumeOverride: newValue != null } })
    }

    let updateContractualDensity = (purchaseMvt: PurchaseMovement, site: SitePurchaseMovement, newValue: number | null) => {
        updateSite(purchaseMvt, site, site => {
            return {
                ...site, contractualDensity: newValue,
                contractualDensityOverwritten: newValue != null
            }
        })
    }

    let updateSite = (purchaseMvt: PurchaseMovement, site: SitePurchaseMovement, update: (site: SitePurchaseMovement) => SitePurchaseMovement) => {
        let siteIndex = purchaseMvt.sites.findIndex(x => x.id === site.id)
        purchaseMvt.sites[siteIndex] = update(site)
        vessel.setPurchaseMovement(vesselProduct.id, purchaseMvt)
    }

    let handleDeleteSite = (site: SitePurchaseMovement) => {
        let isLastSite = purchaseMovement.sites.length == 1
        if (isLastSite)
            setPurchaseMovementToDelete()
        else {
            setSitePurchaseMovementToDelete(site)
        }
    }

    let handleAssignDeal = async (site: SitePurchaseMovement | null, purchaseMovement: PurchaseMovement | null) => {
        await vessel.openAssignableDeals(site?.movementId!)
        vessel.setSitePurchaseMovementWithAssignedDeal({ purchaseMovementId: purchaseMovement?.id!, sitePurchaseMovementId: site?.id!, productId: vesselProduct.id })
    }

    let shouldDisplayDelta = (siteId: string): boolean =>
        siteId in pricingNeedDeltas
        && siteId in vessel.pricingNeeds
        && vessel.pricingNeeds[siteId] !== null
    let isVolume = vesselProduct.productUnit !== 'TO'

    return (
        <>
            <Table style={{ tableLayout: 'auto' }} size='small'>
                <TableHead>
                    <TableRow>
                        <Cell>{t(tBase + 'movementType')}</Cell>
                        <Cell>{t(tBase + 'destination')}</Cell>
                        {hasFeature('PurchaseMovementTransporter') && <Cell>{t(tBase + 'transporter')}</Cell>}
                        <Cell>{t(tBase + 'availabilityDate')}</Cell>
                        <Cell>{t(tBase + 'quantityUnit')}</Cell>
                        {isVolume && <Cell>{t(tBase + 'volumeUnit', { unit: vesselProduct.productUnit })}</Cell>}
                        {vessel.isAuthorized('aseReference') && <Cell>{t(tBase + 'aseReference')}</Cell>}
                        {hasExpositionSimulation && <Cell>{t(tBase + 'simulationExposition')}</Cell>}
                        {hasFeature('PricingEscalation') && <Cell>{t(tBase + 'contractualDensity', { unit: vesselProduct.productUnit })}</Cell>}
                        {hasFeature('PurchaseMovementTransporter') && <Cell>{t(tBase + 'purchaseOrder')}</Cell>}
                        <Cell></Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {purchaseMovement.sites.map((site, i) =>
                        <TableRow className={classes.sitePurchaseMvtRow} key={i}>
                            <Cell key={'movementType'}>
                                <Select
                                    disableNewStyle
                                    disabled={site.movementId != null}
                                    classesOverride={{ form: classes.select, select: 'site-select' }}
                                    value={site.movementType}
                                    choices={movementTypes}
                                    startAdornment={movementTypeIcon(site.movementType) ?? undefined}
                                    onChange={val =>
                                        updateSite(purchaseMovement, site, s => { return { ...s, movementType: parseMovementType(val), transferDestinationSite: null } })
                                    } />
                            </Cell>
                            <Cell key={'destination'}>
                                {site.movementType == MovementType.TransferType
                                    ? <Select disableNewStyle
                                        classesOverride={{ form: classes.purchaseMovementHeaderCombination, select: 'site-select' }}
                                        value={site.transferDestinationSite}
                                        choices={sites(purchaseMovement)}
                                        onChange={val =>
                                            updateSite(purchaseMovement, site, s => { return { ...s, transferDestinationSite: val } })
                                        } />
                                    : undefined
                                }
                            </Cell>
                            {hasFeature('PurchaseMovementTransporter') && <Cell key={'transporter'}>
                                <Select
                                    disableNewStyle
                                    classesOverride={{ form: classes.select }}
                                    value={site.transporterId}
                                    choices={vessel.transporters[purchaseMovement.companyCode].map(x => { return { value: x.id, text: x.name } })}
                                    onChange={val =>
                                        updateSite(purchaseMovement, site, s => { return { ...s, transporterId: val } })} />
                            </Cell>}
                            <Cell key={'availabilityDate'}>
                                <DatePicker classesOverride={{ datepicker: classes.dateInput }}
                                    disableNewStyle
                                    date={site.availabilityDate}
                                    auto={!site.availabilityDateOverwritten}
                                    placeholder={vessel.state.availabilityDate ?? undefined}
                                    label={''}
                                    setDate={(newDate: string | null) =>
                                        updateSite(purchaseMovement, site, s => {
                                            return { ...s, availabilityDate: newDate, availabilityDateOverwritten: newDate != null }
                                        })}
                                />
                            </Cell>
                            <Cell key={'quantity'}>
                                <NumberField disableNewStyle
                                    key={site.id} label={''}
                                    overrideStyle={{ root: classes.siteInput }}
                                    onChange={vol => updateQuantity(purchaseMovement, site, vol)}
                                    text={site.quantity}
                                    precision={3} auto={!site.quantityOverride} />
                            </Cell>
                            {isVolume && <Cell key={'volume'}>
                                <NumberField disableNewStyle
                                    key={site.id} label={''}
                                    overrideStyle={{ root: classes.siteInput }}
                                    onChange={vol => updateVolume(purchaseMovement, site, vol)}
                                    text={site.volume}
                                    precision={3} auto={!site.volumeOverride} />
                            </Cell>}
                            {vessel.isAuthorized('aseReference') && <Cell key={'aseReference'}>
                                <TextField disableNewStyle
                                    label={''}
                                    overrideStyle={{ root: classes.siteInput }}
                                    onChange={e =>
                                        updateSite(purchaseMovement, site, site => { return { ...site, aseReference: e.target.value } })}
                                    text={site.aseReference} />
                            </Cell>}
                            {hasExpositionSimulation &&
                                <Cell key={'simulationExposition'}>{
                                    shouldDisplayDelta(site.id)
                                        ? <DeltaNumberLabel
                                            value={vessel.pricingNeeds[site.id]!}
                                            delta={pricingNeedDeltas[site.id]}
                                            align={'left'} />
                                        : <span>{t(tBase + 'noPricingNeedDelta')}</span>
                                }
                                </Cell>}
                            {hasFeature('PricingEscalation') && <Cell key={'contractualDensity'}>
                                <NumberField disableNewStyle
                                    key={site.id} label={''}
                                    overrideStyle={{ root: classes.siteInput }}
                                    onChange={value => updateContractualDensity(purchaseMovement, site, value)}
                                    text={site.contractualDensity}
                                    precision={3} auto={!site.contractualDensityOverwritten} />
                            </Cell>}
                            {hasFeature('PurchaseMovementTransporter') && <Cell key={'purchaseOrder'}>
                                <TextField disableNewStyle label={''}
                                    overrideStyle={{ root: classes.siteInput }}
                                    onChange={e =>
                                        updateSite(purchaseMovement, site, site => { return { ...site, purchaseOrder: e.target.value } })}
                                    text={site.purchaseOrder} />
                            </Cell>}
                            <RightAlignedCell key={'buttons'}>
                                <div>
                                    {!!site.dealId
                                        ? <Tooltip title={<Typography variant='body1'>{t(tBase + 'goToDeal', { reference: site.dealReferenceNumber })}</Typography>} placement='top'>
                                            <IconButton className={classes.iconButton} aria-label='go to deal' size={'small'}
                                                onClick={() => popupNavigator.open('deal', site.dealId)}>
                                                <GavelOutlined className='site-open-deal-icon' />
                                            </IconButton>
                                        </Tooltip>
                                        : !!site.movementId && (site.movementType == MovementType.PurchaseType || site.movementType == MovementType.SaleType)
                                            ? <IconButton className={classes.iconButton} aria-label='assign deal' size={'small'}
                                                onClick={() => handleAssignDeal(site, purchaseMovement)}>
                                                <Badge badgeContent={<Link />}><GavelOutlined className='site-open-deal-icon' /></Badge>
                                            </IconButton>
                                            : undefined}
                                    {!!site.movementId
                                        ? <Tooltip title={<Typography variant='body1'>{t(tBase + 'goToMovement')}</Typography>} placement='top'>
                                            <IconButton className={classes.iconButton} aria-label='go to movement' size={'small'}
                                                onClick={() => popupNavigator.open('movement', site.movementId)}>
                                                <TrendingFlat className='site-open-movement-icon' />
                                            </IconButton>
                                        </Tooltip>
                                        : undefined}
                                    {!isFixedPrice
                                        ? <Tooltip title={<Typography variant='body1'>{t(tBase + 'duplicate')}</Typography>} placement='top'>
                                            <IconButton className={classes.iconButton} aria-label='duplicate' size={'small'}
                                                onClick={() => addSite(purchaseMovement, duplicateSite(site))}>
                                                <FileCopyOutlined />
                                            </IconButton>
                                        </Tooltip>
                                        : undefined
                                    }
                                    <IconButton onClick={() => handleDeleteSite(site)} size={'small'}><DeleteOutlined /></IconButton>
                                </div>
                            </RightAlignedCell>
                        </TableRow>)}
                </TableBody>
            </Table>
        </>)
}

export let PriceSeveralMovementTypesTable = withStyles(commonStyle, muiOptions)(_PriceSeveralMovementTypesTable)