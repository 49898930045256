import { GuidedTourStep } from "../guidedTourButton";
import { hasFeature } from '../../../infrastructure/feature'
import { t } from '../../../infrastructure/i18nextHelper'

export let stockBoardSteps = (): GuidedTourStep[] => {
    let tPrefix = 'stock.guidedTour.stockBoardPage.'

    return [
        {
            key: 'stockBoardTitle',
            content: t(`${tPrefix}stockBoardTitle`)
        },
        {
            key: 'stockBoardPage',
            content: t(`${tPrefix}stockBoardPage`)
        },
        {
            key: 'stockProjectionTable',
            content: t(`${tPrefix}stockProjectionTable`)
        },
        {
            key: 'stockProjectionTableDateColumn',
            content: t(`${tPrefix}stockProjectionTableDateColumn`)
        },
        {
            key: 'stockProjectionTablePurchaseColumn',
            content: t(`${tPrefix}stockProjectionTablePurchaseColumn`)
        },
        {
            key: 'stockProjectionTableMktSaleColumn',
            content: t(`${tPrefix}stockProjectionTableMktSaleColumn`)
        },
        {
            key: 'stockProjectionTableVolumeOtherColumn',
            content: t(`${tPrefix}stockProjectionTableVolumeOtherColumn`)
        },
        {
            key: 'stockProjectionTableTransitVolumeColumn',
            isActive: hasFeature('TransitVolume'),
            content: t(`${tPrefix}stockProjectionTableTransitVolumeColumn`)
        },
        {
            key: 'stockProjectionTableProjectedColumn',
            content: t(`${tPrefix}stockProjectionTableProjectedColumn`)
        },
        {
            key: 'stockProjectionTableDaysColumn',
            content: t(`${tPrefix}stockProjectionTableDaysColumn`)
        },
        {
            key: 'stockProjectionTableActualColumn',
            content: t(`${tPrefix}stockProjectionTableActualColumn`)
        },
        {
            key: 'stockProjectionTableActualVolumesButton',
            content: t(`${tPrefix}stockProjectionTableActualVolumesButton`)
        },
        {
            key: 'stockProjectionTableDateColumn-row-0',
            content: t(`${tPrefix}stockProjectionTableDateColumn-row-0`),
            watchKeysForHighlight: ['movementTableDateFilter']
        },
        {
            key: 'stockBoardSimulationButton',
            isActive: hasFeature('PurchaseSimulations'),
            watchKeysForHighlight: ['stockBoardSimulationField'],
            content: t(`${tPrefix}stockBoardSimulationButton`)
        }
    ]
}
