import React from 'react'
import { withStyles, createStyles } from "@material-ui/core"
import { muiOptions } from '../../infrastructure/materialUiThemeProvider'
import { isMovementListItem, MovementListItem } from "./stockModels"
import { MovementStatusChip } from './statusChip'
import * as Icons from '@material-ui/icons'
import { DealMovementItem } from '../deals/dealModels'

export function MovementStatusIcon({ movementItem, classes }: { movementItem: MovementListItem | DealMovementItem, classes: any }) {
    if (movementItem.movementStatus == null) return (<></>)

    let isOnError = isMovementListItem(movementItem) ? movementItem.isExpiredForecastOrPlanned : false

    return (
        <div className={classes.flexRow}>
            <Icons.ErrorOutlineOutlined className={isOnError ? classes.red : classes.invisible} />
            <MovementStatusChip status={movementItem.movementStatus} fontSize='small' />
        </div>)
}

let styles = theme =>
    createStyles({
        flexRow: {
            display: 'flex',
            alignItems: 'center'
        }
    })

export default withStyles(styles, muiOptions)(MovementStatusIcon)