import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { defaultStyles, muiOptions } from '../../../../infrastructure/materialUiThemeProvider'
import { StockMovementContainer } from '../stockMovementStore'
import * as Component from './_movementComponents'
import { hasFeature } from '../../../../infrastructure/feature'

function _saleForm({ classes }) {
    let store = StockMovementContainer.useContainer()

    return (
        <div>
            <div className={classes.row + ' firstSaleRow'}>
                <Component.Date isIn={false} />
                <Component.Product isIn={false} />
                <Component.DutyStatus isIn={false} />
                <Component.Company />
                <Component.Volume />
                {hasFeature('ObservedQuantityTemperature')
                    && <>
                        <Component.ObservedTemperature />
                        <Component.ObservedVolume />
                    </>
                }
            </div>
            {hasFeature('ShowPurchaseNominatedVolume') || (hasFeature('DailyNomination') || hasFeature('WeeklyNomination'))
                ? <div className={classes.row}>
                    {(hasFeature('DailyNomination') || hasFeature('WeeklyNomination') || hasFeature('ShowPurchaseNominatedVolume')) &&
                        <div className={classes.alignedToVolume}>
                            <Component.NominatedVolume />
                        </div>}
                    {(hasFeature('DailyNomination') || hasFeature('WeeklyNomination')) && <Component.Nomination />}
                </div>
                : undefined}
            {hasFeature('MessageMovements') && store.stockMovement.meanOfTransportation === 'Pipe' &&
                <div>
                    <Component.OperationStartAt />
                    <Component.OperationEndAt />
                </div>
            }
            <div className={classes.row}>
                <Component.Site isIn={false} />
                <Component.Counterparty />
                <Component.Mot />
                <Component.Status />
            </div>
            <div className={classes.row}>
                <Component.Reference />
                <Component.ExternalReference />
                {hasFeature('MovementExternalNumber') && <Component.OriginExternalNumber />}
                {store.canCreateDeal() && <Component.CreateDealButton buttonClass={classes.secondaryButton} />}
                {store.canAssignDeal() && <Component.AssignDealButton buttonClass={classes.secondaryButton} />}
                <Component.MirrorMovementButton buttonClass={classes.secondaryButton} />
            </div>
            <div className={classes.row}>
                <Component.Comment />
            </div>
        </div>
    )
}

let styles = () =>
    createStyles({
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1em'
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            margin: "1em"
        }
    })

export default withStyles(styles, muiOptions)(_saleForm)
