import i18next, { TFunction, InitOptions, Resource } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { mergeLanguageFiles } from './languageFileMerger'
import { availableLanguages } from './shell/userSettings/userSettingsStore'

export let storageLanguageKey = 'i18nextLng'
export let storageUserCountry = 'userCountry'

export let changeCountryLanguage = () => i18next.reloadResources()

export let changeI18NextLanguage = (language: string) => {
    if (!language) return

    if (localStorage[storageLanguageKey] !== language) {
        localStorage.setItem(storageLanguageKey, language)
        i18next.changeLanguage(language)
    }
}

let getResourceLanguages = (): Resource => {
    let ctry = localStorage.getItem(storageUserCountry)
    let resources: Resource = {}

    availableLanguages.map(lng => {
        resources[lng.code] = ctry
            ? { 'translation': mergeLanguageFiles(lng.code, ctry) }
            : { 'translation': require(`../../static/lang/${lng.code}.json`) }
    })

    return resources
}

let settings: InitOptions = {
    fallbackLng: ['en'],
    interpolation: {},
    lng: localStorage.getItem(storageLanguageKey) ?? 'en',
    resources: getResourceLanguages(),
    detection: {
        order: ['localStorage'],
        lookupLocalStorage: storageLanguageKey,
    },
}

let loadI18Next = () =>
    i18next
        .use(LanguageDetector)
        .use(initReactI18next)
        .init(settings)

loadI18Next()

export default i18next

export type T = { t: TFunction }

export type Language = {
    code: string
    label: string
}

export let t: TFunction

export let replaceUnicodeCharacters = (string: string): string =>
    replaceAll(replaceAll(string, "&#39;", "'"), "&#x2F;", "/")

export function initTFunction(tFunction: TFunction) { t = (key: any, options?: any) => replaceUnicodeCharacters(tFunction(key, options)) }

function replaceAll(text: string, search: string, replace: string) {
    return text.split(search).join(replace);
}