import React, { useEffect } from 'react'
import { withStyles, createStyles, Typography, Tooltip, Paper, Badge } from '@material-ui/core'
import { FormatListNumberedOutlined, ShowChartOutlined, TocOutlined, MessageOutlined } from '@material-ui/icons'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { stockBoardSteps } from '../../infrastructure/guidedTour/steps/_stockBoard'
import { GuidedTourButton } from '../../infrastructure/guidedTour/guidedTourButton'
import { muiOptions, MuiProps, defaultStyles, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import { t } from '../../infrastructure/i18nextHelper'
import { hasFeature } from '../../infrastructure/feature'
import { CustomDialog } from '../common/customComponents'
import { StockMovementContainer } from './movementEdit/stockMovementStore'
import ActualStockEditPopup from './actualStockEdit/actualStockEditPopup'
import { Filters } from './filters/_stockFilters'
import { StockChart } from './chart/_stockChart'
import { StockBoardContainer } from './stockBoardStore'
import { MovementTable } from './_movementTable'
import { MovementMessageTable } from './_movementMessageTable'
import { StockProjectionTable } from './projectionTable'
import * as api from '../../infrastructure/api'
import { MessageMovementFilters } from './stockModels'
import { NotificationBell } from '../../infrastructure/shell/notifications/bell'

function StockBoard({ classes }: MuiProps) {
    let store = StockBoardContainer.useContainer()
    let stockEdit = StockMovementContainer.useContainer()

    let withStockTable = store.displayedBlocks.includes('stockTable')
    let withStockChart = store.displayedBlocks.includes('stockChart')
    let withMovementTable = store.displayedBlocks.includes('movementTable')
    let withMovementMessagesTable = store.displayedBlocks.includes('messageTable')
    let [movementMessageCount, setMovementMessageCount] = React.useState(0)

    useEffect(() => {
        let isMounted = true
        async function getMovementMessageCount() {
            let messageCount = await api.get<number>(`stock/movementMessage/count?${createMessageFilters()}`)
            if (isMounted)
                setMovementMessageCount(messageCount)
        }
        getMovementMessageCount()
        return () => {
            isMounted = false
        }
    }, [store.stockFilters.productIds])

    useEffect(() => {
        if (!stockEdit.isOpen) store.load()
    }, [stockEdit.isOpen])

    useEffect(() => {
        store.checkDates()
        store.loadMovements()
    },
        [store.stockFilters,
        store.switchFilter,
        store.selectedStatus,
        store.selectedMovementType,
        store.selectedMot,
        store.textFilter,
        store.dealFilter,
        store.warningFilter,
        store.nominationFilter])

    let createMessageFilters = () => {
        let filters: MessageMovementFilters = {
            productIds: store.stockFilters.productIds,
            type: null
        }

        let queries: string[] = []
        queries = queries.concat(filters.productIds?.map(x => `productIds=${x}`) ?? [])
        return queries.join('&')
    }

    let closeActualStockPopup = () => {
        store.setShowActualStockDisplay(false)
        store.load()
    }

    let gridTemplateAreasBuilder = (): string => {
        if (withStockTable) {
            if (withStockChart)
                return withMovementTable
                    ? withMovementMessagesTable
                        ? '"filters filters" "stockTable stockChart" "messageTable movementTable"'
                        : '"filters filters" "stockTable stockChart" "stockTable movementTable"'
                    : withMovementMessagesTable
                        ? '"filters filters" "stockTable stockChart" "messageTable stockChart"'
                        : '"filters filters" "stockTable stockChart" "stockTable stockChart"'
            else
                return withMovementTable
                    ? withMovementMessagesTable
                        ? '"filters filters" "stockTable stockTable" "messageTable movementTable"'
                        : '"filters filters" "stockTable stockTable" "movementTable movementTable"'
                    : withMovementMessagesTable
                        ? '"filters filters" "stockTable stockTable" "messageTable messageTable"'
                        : '"filters filters" "stockTable stockTable" "stockTable stockTable"'
        } else {
            if (withStockChart)
                return withMovementTable
                    ? withMovementMessagesTable
                        ? '"filters filters" "stockChart stockChart" "messageTable movementTable"'
                        : '"filters filters" "stockChart stockChart" "movementTable movementTable"'
                    : withMovementMessagesTable
                        ? '"filters filters" "stockChart stockChart" "messageTable messageTable"'
                        : '"filters filters" "stockChart stockChart" "stockChart stockChart"'
            else
                return withMovementTable
                    ? withMovementMessagesTable
                        ? '"filters filters" "messageTable movementTable" "messageTable movementTable"'
                        : '"filters filters" "movementTable movementTable" "movementTable movementTable"'
                    : withMovementMessagesTable
                        ? '"filters filters" "messageTable messageTable" "messageTable messageTable" '
                        : '"filters filters" "" ""'
        }
    }

    let movementPopupHalfBottom = hasFeature('EditMovementHalfScreen') &&
        store.displayedBlocks.some(d => d === 'messageTable') &&
        !store.displayedBlocks.some(d => d === 'stockChart' || d === 'stockTable')

    return (
        <div className={classes.pageContainer}
            style={{ gridTemplateAreas: gridTemplateAreasBuilder() }}
            data-tour={'stockBoardPage'}>
            <div className={classes.firstRow}>
                <div>
                    <div className={classes.titleTourContainer} data-tour={'stockBoardTitle'}>
                        <Typography className={classes.pageTitle} variant='h5' display='block' gutterBottom>{t('stock.name')}</Typography>
                        <GuidedTourButton steps={stockBoardSteps} />
                        {hasFeature('Notifications') && <NotificationBell />}
                    </div>
                    <Paper className={classes.displayToggles}>
                        <ToggleButtonGroup
                            value={store.displayedBlocks}
                            onChange={(_, newDisplayedBlocks) => { store.setDisplayedBlocks(newDisplayedBlocks) }}>
                            <Tooltip title={<Typography variant='body1'>{t('stock.label.displayStockTable')}</Typography>} placement='bottom'>
                                <ToggleButton value='stockTable' selected={withStockTable} classes={{ selected: classes.selected }}>
                                    <FormatListNumberedOutlined />
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip title={<Typography variant='body1'>{t('stock.label.displayStockChart')}</Typography>} placement='bottom'>
                                <ToggleButton value='stockChart' selected={withStockChart} classes={{ selected: classes.selected }}>
                                    <ShowChartOutlined />
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip title={<Typography variant='body1'>{t('stock.label.displayMovementTable')}</Typography>} placement='bottom'>
                                <ToggleButton value='movementTable' selected={withMovementTable} classes={{ selected: classes.selected }}>
                                    <TocOutlined />
                                </ToggleButton>
                            </Tooltip>
                            {hasFeature('MessageMovements')
                                && <Tooltip title={<Typography variant='body1'>{t('stock.label.displayMessageTable')}</Typography>} placement='bottom'>
                                    <ToggleButton value='messageTable' selected={withMovementMessagesTable} classes={{ selected: classes.selected }}>
                                        <Badge badgeContent={movementMessageCount} color='secondary' max={99}>
                                            <MessageOutlined />
                                        </Badge>
                                    </ToggleButton>
                                </Tooltip>
                            }
                        </ToggleButtonGroup>
                    </Paper>
                </div>
                <Filters isStockChartOrTableDisplayed={store.isStockChartOrTableDisplayed()} />
            </div>
            <div className={classes.secondRowTable} style={{ display: withStockTable ? '' : 'none' }}>
                <StockProjectionTable className={classes.projectionTable} />
            </div>
            <div className={classes.thirdRowTable + ' messageTableRow'} style={{ display: withMovementMessagesTable ? '' : 'none' }}>
                <MovementMessageTable className={classes.messageTableRow} movementPopupHalfBottom={movementPopupHalfBottom} />
            </div>
            <div className={classes.secondRowChart} style={{ display: withStockChart ? '' : 'none' }}>
                <StockChart className={classes.stockChart} />
            </div>
            <div className={classes.thirdRow + ' movementTableRow'} style={{ display: withMovementTable ? '' : 'none' }}>
                <MovementTable className={classes.movementTable} movementPopupHalfBottom={movementPopupHalfBottom} />
            </div>
            <ActualStockEditPopup closePopup={closeActualStockPopup} isOpen={store.showActualStockPopup} />
            <CustomDialog isOpen={store.tempFilters != null}
                title={t('stock.label.clearSimulation')}
                contentText={t('stock.label.clearSimulationContent')}
                confirmButtonText={t('stock.label.confirm')}
                onConfirm={() => store.confirmSetFilters()}
                onClose={() => store.setTempFilters(null)}
                onCancel={() => store.setTempFilters(null)} />
        </div>
    )
}

let styles = _ =>
    createStyles({
        titleTourContainer: {
            ...defaultStyles.flexRow,
        },
        firstRow: {
            'grid-area': 'filters',
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'start',
        },
        secondRowTable: {
            'grid-area': 'stockTable'
        },
        secondRowChart: {
            'grid-area': 'stockChart'
        },
        thirdRow: {
            'grid-area': 'movementTable'
        },
        thirdRowTable: {
            'grid-area': 'messageTable'
        },
        pageContainer: {
            display: 'grid',
            height: '100%',
            width: '100%',
            'grid-template-columns': 'minmax(0,1.8fr) minmax(0,2fr)',
            'grid-template-rows': '4em minmax(0, 1fr) minmax(0, 1fr)',
            'grid-template-areas': '"filters filters" "stockTable stockChart" "stockTable movementTable"',
            'grid-gap': '0.7em',
            'overflow': 'hidden',
            'box-sizing': 'border-box',
            '&> div': {
                height: '100%',
                width: '100%',
                boxSizing: 'border-box'
            }
        },
        pageTitle: {
            color: defaultColors.grey.dark.color,
            margin: '0'
        },
        selected: {
            "&&": {
                backgroundColor: defaultColors.lightBlue.main.color,
                color: 'white',
                '&:hover': {
                    backgroundColor: defaultColors.lightBlue.light.color,
                },
            }
        },
        displayToggles: {
            height: '2em',
            display: 'flex'
        }
    })

export default withStyles(styles, muiOptions)(StockBoard)