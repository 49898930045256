import { useState, useEffect, useContext, useRef, useImperativeHandle, RefObject } from 'react'
import guid, { Guid } from '../../../infrastructure/guid'
import { api } from '../../../infrastructure/api'
import { useActionDebounceWithArgs } from '../../common/debounce'
import { TruckTransportFormModel, TruckStep, truckStatusesWith1Border, truckStatuses, TruckStatus, TruckTransportEntryFormModel, truckStatusesNoBorder } from '../truckModels'
import { useFieldStatus } from '../../common/fieldsStatus'
import { snackbars } from '../../../infrastructure/snackbars'
import { TransportationType, TruckTransportReferential, MeanOfTransportation } from '../truckStore'
import { FeatureContainer } from '../../../infrastructure/feature'
import { usePrevious } from '../../../infrastructure/customHooks'

export function useFormState(referentialContext: React.Context<TruckTransportReferential>, transportationTypeContext: React.Context<TransportationType>) {
    let features = FeatureContainer.useContainer()
    let transportationType = useContext(transportationTypeContext)
    let t = transportationType.label

    let [truck, setTruck] = useState<TruckTransportFormModel>({ entrys: [] })
    let [defaultDestinationSite, setDefaultDestinationSite] = useState<string | null>(null)
    let [isOpen, setIsOpen] = useState<boolean>(false)
    let previousIsOpen = usePrevious(isOpen)
    let [eventSteps, setEventSteps] = useState<TruckStep[]>(getSteps())

    let referential = useContext(referentialContext)
    let fieldStatus = useFieldStatus<TruckTransportFormModel>()
    let handleSaveDebouncer = useActionDebounceWithArgs(save)

    useEffect(() => { if (!isOpen) setTruck({ entrys: [] }) }, [isOpen])
    useEffect(updateQuantityUnit, [truck.productId])
    useEffect(updateEventSteps, [truck.isOwnCollection])
    useEffect(() => { updateDefaultValues() },
        [truck.originSite, truck.company, truck.id, truck.customerSegment, truck.dutyStatus])
    useEffect(() => setEventSteps(getSteps()),
        [features.hasFeature('TruckBorder'), features.hasFeature('TruckBorder2'),
        features.hasFeature('RailBorder'), features.hasFeature('RailBorder2'),
        transportationType.transportationType])

    async function open(id?: Guid | null, meanOfTransportation?: MeanOfTransportation) {
        if (id)
            await load(id)
        else {
            truck = { id: guid.createNew(), meanOfTransportation: transportationType.meanOfTransportation ?? meanOfTransportation, entrys: [] }
            setTruck(truck)
        }

        if (meanOfTransportation == 'Train')
            window.history.pushState({}, '', '?openRailCar=' + truck.id)
        else
            window.history.pushState({}, '', '?openTruck=' + truck.id)

        fieldStatus.clearStatuses()
        if (meanOfTransportation)
            transportationType.updateTransportationFromMeanOfTransportation(meanOfTransportation)
        setIsOpen(true)
    }

    async function close() {
        setIsOpen(false)
        setTruck({ entrys: [] })
        window.history.pushState({}, '', window.location.href.split('?')[0])
    }

    async function load(id: Guid) {
        await referential.init()
        let result = await api.get<TruckTransportFormModel>('truck/' + id)
        truck = result['version'] ? result : { id: id, entrys: [] }

        setTruck(truck)
        fieldStatus.initPropertyTracking(truck)
    }

    function checkMandatoryFields(): boolean {
        fieldStatus.clearStatuses()
        let emptyRequiredFields = mandatoryFields.filter(propName => truck[propName] == null)
        if (emptyRequiredFields.length !== 0) {
            fieldStatus.setStatus(emptyRequiredFields, 'alert')
            snackbars.warning(t('warnings.mandatoryFieldsBeforeSaving'))
        }
        return emptyRequiredFields.length === 0
    }

    async function save(truck: TruckTransportFormModel) {
        if (!truck.id) return
        if (features.hasFeature('NominationByCounterparty') && !truck.counterpartyId) {
            snackbars.warning(t('trucks.label.warning.counterpartyIsMandatory'))
            return;
        }

        await api.post('truck', { ...truck, entrys: truck.entrys.filter(x => x.quantity) })

        let newTruck = await api.get<TruckTransportFormModel>('truck/' + truck.id)
        setTruck(newTruck)

        setTimeout(() => snackbars.success(t('httpSuccess.truckTransportSaved')), 300)
    }

    function setValue(truckFieldName: keyof TruckTransportFormModel, value: TruckTransportFormModel[typeof truckFieldName]) {
        if (truckFieldName !== 'entrys') {
            truck[truckFieldName] = value as any
            setTruck({ ...truck })
        }
    }

    function updateQuantityUnit() {
        if (!truck.productId) return
        truck.quantityUnit = referential.products.find(x => x.id === truck.productId)!.transportUnit
        setValue('quantityUnit', truck.quantityUnit)
    }

    function addTruckFormEntry(entry: TruckTransportEntryFormModel) {
        let entrys = truck.entrys
        entrys.push(entry)
        setTruck({ ...truck, entrys: entrys })
    }

    function deleteTruckFormEntry(index: number) {
        truck.entrys.splice(index, 1)
        if (index > -1) {
            setTruck({ ...truck, entrys: truck.entrys })
        }
    }

    function shouldDisableOwnCollection(): boolean {
        return !!truck.truckStatus && truck.truckStatus !== 'loaded'
    }

    function changeEntryNumber(index: number, value: string) {
        truck.entrys[index].entryNumber = value
        setTruck({ ...truck })
    }

    function changeEntryQuantity(index: number, value: number | null) {
        truck.entrys[index].quantity = value
        setTruck({ ...truck })
    }

    async function updateDefaultValues() {
        if (!truck.originSite && !truck.customerSegment && !truck.company) return

        let query = '?' + [
            truck.originSite ? 'originSite=' + truck.originSite : '',
            truck.customerSegment ? 'customerSegment=' + truck.customerSegment : '',
            truck.customerSegment ? 'dutyStatus=' + truck.dutyStatus : '',
            truck.company ? 'company=' + truck.company : '',
            'meanOfTransportation=' + transportationType.meanOfTransportation
        ].filter(x => x).join('&')

        let route = `truck/defaultValues${query}`
        let defaultValues = await api.get<DefaultValues>(route)

        if (!defaultValues.dutyStatus && truck.customerSegment && truck.company)
            snackbars.warning(t('trucks.label.warning.invalidCombinationForDutyStatus'))

        setValue('dutyStatus', defaultValues.dutyStatus)

        setDefaultDestinationSite(defaultValues.destinationSite)

        if (!truck.destinationSite && defaultValues.destinationSite)
            setValue('destinationSite', defaultValues.destinationSite)
    }

    function updateEventSteps() {
        setEventSteps(!truck.isOwnCollection ? getSteps() : [getSteps()[0]])
    }

    function indexOf(status: TruckStatus) { return status ? eventSteps.indexOf(status) : -1 }

    function getSteps() {
        if (transportationType.transportationType == 'Truck')
            return features.hasFeature('TruckBorder')
                ? features.hasFeature('TruckBorder2')
                    ? [...truckStatuses]
                    : [...truckStatusesWith1Border]
                : [...truckStatusesNoBorder]
        if (transportationType.transportationType == 'Rail Car')
            return features.hasFeature('RailBorder')
                ? features.hasFeature('RailBorder2')
                    ? [...truckStatuses]
                    : [...truckStatusesWith1Border]
                : [...truckStatusesNoBorder]
        return []
    }

    dialogRef = useRef<OpenCloseDialogRef>(null)
    useImperativeHandle(dialogRef, () => ({ open, close }))

    return {
        truck, setTruck, setValue, save: handleSaveDebouncer.execute, load, eventSteps,
        checkMandatoryFields, isOpen, previousIsOpen, fieldStatus, defaultDestinationSite,
        indexOf, addTruckFormEntry, deleteTruckFormEntry, changeEntryNumber, changeEntryQuantity,
        shouldDisableOwnCollection
    }
}

type OpenCloseDialogRef = { open: (id: Guid, meanOfTransportation: MeanOfTransportation) => void, close: () => void }
let dialogRef: RefObject<OpenCloseDialogRef> | null = null
export let truckDialog = {
    open: (id, mot) => dialogRef?.current?.open(id, mot),
    close: () => dialogRef?.current?.close(),
}

const mandatoryFields: (keyof TruckTransportFormModel)[] = [
    'id', 'productId', 'originSite', 'company', 'destinationSite',
    'truckNumber', 'transporter', 'loadingDate', 'loadedQuantity',
    'quantityUnit', 'dutyStatus', 'customerSegment'
]

type DefaultValues = {
    destinationSite: string | null
    dutyStatus: string | null
}