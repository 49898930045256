import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { api } from '../../../infrastructure/api'
import { MasterDataShell, MasterDataItem, createExcelLines } from './masterDataShell'
import { ColumnDescriptor, MultipleSelect, TableItem, TextField } from '../../common/customComponents'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { t } from '../../../infrastructure/i18nextHelper'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Company } from './models'

type Persona = {
    id: string,
    name: string
}

type Profile = {
    userName: string
    country: string
    firstName: string
    lastName: string
    mail: string
    companys: { code: string, name: string }[]
    personas: Persona[]
}

type IsNew = { isNew: boolean }

type ProfileFilter = {
    userName: string | null
    firstName: string | null
    lastName: string | null
    mail: string | null
    companys: string | null
    personas: string | null
}

let noFilters: ProfileFilter = {
    userName: null,
    firstName: null,
    lastName: null,
    mail: null,
    companys: null,
    personas: null,
}

let toTableItem = (profile: Profile): TableItem<MasterDataItem<Profile>> => {
    return { ...profile, id: profile.userName, isModified: false }
}

function ProfileMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [allPersonas, setAllPersonas] = useState<Persona[]>([])
    let [allCompanys, setAllCompanys] = useState<Company[]>([])
    let [profiles, setProfiles] = useState<Profile[]>([])
    let [filters, setFilters] = useState<ProfileFilter>(noFilters)

    let applyFilters = (profiles: Profile[]) => {
        if (filters.userName)
            profiles = profiles.filter(x => x.userName.toLowerCase().contains(filters.userName!.toLowerCase()))

        if (filters.firstName)
            profiles = profiles.filter(x => x.firstName.toLowerCase().contains(filters.firstName!.toLowerCase()))

        if (filters.lastName)
            profiles = profiles.filter(x => x.lastName.toLowerCase().contains(filters.lastName!.toLowerCase()))

        if (filters.mail)
            profiles = profiles.filter(x => x.mail.toLowerCase().contains(filters.mail!.toLowerCase()))

        if (filters.companys) {
            let tokens = filters.companys!.split(' ')
            profiles = profiles.filter(x => tokens.every(token => x.companys.find(x => x.name.toLowerCase().contains(token.toLowerCase()))))
        }

        if (filters.personas) {
            let tokens = filters.personas.split(' ')
            profiles = profiles.filter(x => tokens.every(token => x.personas.find(persona => persona.name.toLowerCase().contains(token.toLowerCase()))))
        }

        return profiles
    }

    let init = async () => {
        let allPersonas = api.get<Persona[]>('masterdata/profile/persona')
        load()
        setAllPersonas(await allPersonas)
    }

    let load = async () => {
        let companys = await api.get<Company[]>('masterdata/profile/company')
        let profiles = await api.get<Profile[]>('masterdata/profile')
        setProfiles(profiles.sort((a, b) => a.userName.localeCompare(b.userName)))
        setAllCompanys(companys)
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<Profile>>>[] = [
        {
            name: t('admin.masterdata.profile.user'), value: x => x.userName,
            columnFilter: { value: filters.userName ?? '', onChange: (userName: string) => setFilters({ ...filters, userName }) }
        },
        {
            name: t('admin.masterdata.profile.firstName'), value: x => x.firstName,
            columnFilter: { value: filters.firstName ?? '', onChange: (firstName: string) => setFilters({ ...filters, firstName }) }
        },
        {
            name: t('admin.masterdata.profile.lastName'), value: x => x.lastName,
            columnFilter: { value: filters.lastName ?? '', onChange: (lastName: string) => setFilters({ ...filters, lastName }) }
        },
        {
            name: t('admin.masterdata.profile.mail'), value: x => x.mail,
            columnFilter: { value: filters.mail ?? '', onChange: (mail: string) => setFilters({ ...filters, mail }) }
        },
        {
            name: t('admin.masterdata.profile.companies'),
            value: x => {
                let codes = x.companys.sort((a, b) => a.name.localeCompare(b.name)).map(x => x.name)
                return (codes.length < 4 ? codes : codes.slice(0, 3).concat(["..."])).join(', ')
            },
            valueForExport: x => x.companys.sort((a, b) => a.name.localeCompare(b.name)).map(x => x.code).join(','),
            columnFilter: { value: filters.companys ?? '', onChange: (companys: string) => setFilters({ ...filters, companys }) }
        },
        {
            name: t('admin.masterdata.profile.personas'),
            value: x => {
                let codes = x.personas.sort((a, b) => a.name.localeCompare(b.name)).map(x => x.name)
                return (codes.length < 2 ? codes : codes.slice(0, 1).concat(["..."])).join(', ')
            },
            valueForExport: x => x.personas.sort((a, b) => a.name.localeCompare(b.name)).map(x => x.name).join(','),
            columnFilter: { value: filters.personas ?? '', onChange: (personas: string) => setFilters({ ...filters, personas }) }
        }
    ]

    let getItems = () => applyFilters(profiles).map(toTableItem)

    useEffect(() => { let effect = async () => { await init() }; effect() }, [])

    let profileLabel = t('admin.masterdata.profile.profile')

    let isManager = hasClaim(Claims.UserPersonaManager)

    let exportExcel = async () => {
        excelGenerator.generate({
            filename: 'profile.xlsx',
            sheets: [{ name: 'Profile', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let importExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/profile/import'
        api.upload(uploadUrl, file, 'import').then(_ => load())
    }

    let onSave = async (item: Profile) => {
        await api.post('masterdata/profile', { ...item, companys: item.companys.map(x => x.code), personaIds: item.personas.map(x => x.id) })
        await load()
        return true
    }

    let onCopy = (item: TableItem<MasterDataItem<Profile>>) => ({ ...item, userName: '', firstName: '', lastName: '', mail: '', isNew: true })

    let onDelete = async (names: string[]) => {
        if (names.length === 0) return false
        await api.del('masterdata/profile', { names: names })
        await load()
        return true
    }

    let zero = (): Profile & IsNew => ({
        userName: '',
        country: '',
        firstName: '',
        lastName: '',
        mail: '',
        companys: [],
        personas: [],
        isNew: true
    })

    return (
        <MasterDataShell
            headerLabel={profileLabel}
            itemLabel={profileLabel}
            isManager={isManager}
            onExportExcel={exportExcel}
            onImportExcel={importExcel}
            onDelete={onDelete}
            onNew={zero}
            onSave={onSave}
            onCopy={onCopy}
            items={getItems()}
            columns={columns}>{
                (selectedItem: Profile & IsNew, setSelectedItem) => (
                    <>
                        <TextField label={t('admin.masterdata.profile.user')} text={selectedItem.userName} disabled={!selectedItem.isNew}
                            onChange={x => setSelectedItem({ ...selectedItem, userName: x.target.value })} />
                        <TextField label={t('admin.masterdata.profile.firstName')} text={selectedItem.firstName}
                            onChange={x => setSelectedItem({ ...selectedItem, firstName: x.target.value })} />
                        <TextField label={t('admin.masterdata.profile.lastName')} text={selectedItem.lastName}
                            onChange={x => setSelectedItem({ ...selectedItem, lastName: x.target.value })} />
                        <TextField label={t('admin.masterdata.profile.mail')} text={selectedItem.mail}
                            onChange={x => setSelectedItem({ ...selectedItem, mail: x.target.value })} />
                        <MultipleSelect label={t('admin.masterdata.profile.companies')}
                            chips={true}
                            allWhenEmpty={false}
                            disabled={!isManager}
                            values={selectedItem.companys.map(x => x.code)}
                            choices={allCompanys.map(x => ({ value: x.code, text: x.name }))}
                            onChange={x => setSelectedItem({ ...selectedItem, companys: x.map(x => allCompanys.find(c => c.code === x)) })} />
                        <MultipleSelect label={t('admin.masterdata.profile.personas')}
                            chips={true}
                            allWhenEmpty={false}
                            disabled={!isManager}
                            values={selectedItem.personas.filter(x => x).map(x => x.id)} //hack: a bug appears with undefined persona but not reproducible locally
                            choices={allPersonas.map(x => ({ value: x.id, text: x.name }))}
                            onChange={personaIds => setSelectedItem({ ...selectedItem, personas: personaIds.map(id => allPersonas.find(persona => persona.id === id)) })} />
                    </>
                )}
        </MasterDataShell>)
}

let styles = (theme) =>
    createStyles({})

export default withStyles(styles, muiOptions)(ProfileMasterData)