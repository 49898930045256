import { FieldStatus } from './fieldsStatus'

export type BaseFieldProps = {
    disabled?: boolean
    onError?: boolean
    status?: FieldStatus
    hideTitle?: boolean
}

export let allowedExtensions = {
    accept: '.xls, .xlsx, .doc, .docx, .msg, .jpg, .png, .pdf, .csv, .txt'
}