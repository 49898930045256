export type Login = {
    username: string
    password: string
}

export type UserSettings = {
    language: string,
    dateFormat: string,
    decimalSeparator: string
}

export type Profile = {
    userName: string
    mail: string
    cli: string
    firstName: string
    lastName: string
}

export type TokenProfile = {
    token: string
    profile: Profile
}

export type UserClaim = {
    claim: Claims
    scope: string[]
}

export enum Claims {
    ActualStockSiteValidator = "ActualStockSiteValidator",
    ActualStockSiteReader = "ActualStockSiteReader",
    ActualStockSiteWriter = "ActualStockSiteWriter",
    StockManager = "StockManager",
    VesselWriter = "VesselWriter",
    VesselReader = "VesselReader",
    VesselManager = "VesselManager",
    VesselPurchaseDelegation = "VesselPurchaseDelegation",
    DealManager = "DealManager",
    DealReader = "DealReader",
    TruckManager = "TruckManager",
    TruckReader = "TruckReader",
    SalesManager = "SalesManager",
    SalesReader = "SalesReader",
    ChannelSalesManager = "ChannelSalesManager",
    FifoDashboardExport = "FifoDashboardExport",
    OverstayAgeingExport = "OverstayAgeingExport",
    MasterdataSecurityStockExport = "MasterdataSecurityStockExport",
    FeatureToggle = "FeatureToggle",
    Impersonate = "Impersonate",
    UserManager = "UserManager",
    Companies = "Companies",
    MasterdataStockCapacityReader = "MasterdataStockCapacityReader",
    MasterdataStockCapacityManager = "MasterdataStockCapacityManager",
    MasterdataPhysicalStockReader = "MasterdataPhysicalStockReader",
    MasterdataPhysicalStockManager = "MasterdataPhysicalStockManager",
    MasterdataProductCompanyWriter = "MasterdataProductCompanyWriter",
    MasterdataProductCompanyManager = "MasterdataProductCompanyManager",
    MasterdataPlantStorageLocationManager = "MasterdataPlantStorageLocationManager",
    MasterdataPlantStorageLocationWriter = "MasterdataPlantStorageLocationWriter",
    MasterdataPricingPeriodManager = "MasterdataPricingPeriodManager",
    MasterdataPlantManager = "MasterdataPlantManager",
    MasterdataCalendarManager = "MasterdataCalendarManager",
    MasterdataCalendarReader = "MasterdataCalendarReader",
    RefreshData = "RefreshData",
    MasterdataCounterpartyManager = "MasterdataCounterpartyManager",
    MasterdataCounterpartyReader = "MasterdataCounterpartyReader",
    MasterdataProductGroupSecurityStockLevel = "MasterdataProductGroupSecurityStockLevel",
    MasterdataLocalProduct = "MasterdataLocalProduct",
    MasterdataProductReader = "MasterdataProductReader",
    MasterdataProductWriter = "MasterdataProductWriter",
    MasterdataProductManager = "MasterdataProductManager",
    MasterdataSapCounterpartyManager = "MasterdataSapCounterpartyManager",
    MasterdataSapCounterpartyReader = "MasterdataSapCounterpartyReader",
    MasterdataSapShipToManager = "MasterdataSapShipToManager",
    MasterdataSapShipToReader = "MasterdataSapShipToReader",
    MasterdataSupplyOriginManager = "MasterdataSupplyOriginManager",
    MasterdataSupplyOriginReader = "MasterdataSupplyOriginReader",
    MasterdataSupplyDestinationManager = "MasterdataSupplyDestinationManager",
    MasterdataSupplyDestinationWriter = "MasterdataSupplyDestinationWriter",
    MasterdataSupplyDestinationReader = "MasterdataSupplyDestinationReader",
    MasterdataSecurityStockLevel = "MasterdataSecurityStockLevel",
    DghStockExport = "DghStockExport",
    PersonaManager = "PersonaManager",
    UserPersonaManager = "UserPersonaManager",
    MasterDataVesselDelayManager = "MasterDataVesselDelayManager",
    MasterDataVesselDelayReader = "MasterDataVesselDelayReader",
    PxcReportExport = "PxcReportExport",
    IntegrityCheckReport = "IntegrityCheckReport",
    MarketRiskSimulatorManager = 'MarketRiskSimulatorManager',
    MarketRiskSimulatorReader = 'MarketRiskSimulatorReader',
    MasterdataCompanyManager = 'MasterdataCompanyManager',
    MasterdataCompanyReader = 'MasterdataCompanyReader',
    MasterdataRegulatedPriceManager = "MasterdataRegulatedPriceManager",
    MasterdataRegulatedPriceReader = "MasterdataRegulatedPriceReader",
    IncrementsExport = "IncrementsExport",
    SupplyBalanceManager = "SupplyBalanceManager",
    SupplyBalanceReader = "SupplyBalanceReader",
    MasterdataSiteManager = 'MasterdataSiteManager',
    MasterdataSiteReader = 'MasterdataSiteReader',
    MasterdataCompanyDutyStatusManager = 'MasterdataCompanyDutyStatusManager',
    MasterdataCompanyDutyStatusReader = 'MasterdataCompanyDutyStatusReader',
    MasterdataJettyManager = "MasterdataJettyManager",
    MasterdataJettyReader = "MasterdataJettyReader",
    MasterdataJettyWriter = "MasterdataJettyWriter",
    MasterdataDealTypeManager = 'MasterdataDealTypeManager',
    MasterdataDealTypeReader = 'MasterdataDealTypeReader',
    MasterdataCommonReader = 'MasterdataCommonReader',
    MasterdataQuantityUnitPriceReader = 'MasterdataQuantityUnitPriceReader',
    MasterdataQuantityUnitPriceManager = 'MasterdataQuantityUnitPriceManager',
    MasterdataIncoTermReader = 'MasterdataIncoTermReader',
    TactixOutputReader = 'TactixOutputReader',
    ClearData = 'ClearData',
    ImportSamManager = "ImportSamManager",
    MasterdataSupplyPlantAssignmentReader = 'MasterdataSupplyPlantAssignmentReader',
    MasterdataSupplyPlantAssignmentManager = 'MasterdataSupplyPlantAssignmentManager',
    MasterdataDealValidatorEmailReader = "MasterdataDealValidatorEmailReader",
    MasterdataPaymentTermReader = "MasterdataPaymentTermReader",
    MasterdataSapPurchaseOrganizationReader = "MasterdataSapPurchaseOrganizationReader",
    MasterdataValidationProcessReader = "MasterdataValidationProcessReader",
    MasterdataQuotationReader = "MasterdataQuotationReader",
    MasterdataQuotationStubReader = "MasterdataQuotationStubReader",
    MasterdataPrimoMovementTypeReader = "MasterdataPrimoMovementTypeReader",
    MasterdataPrimoPricingAreaReader = "MasterdataPrimoPricingAreaReader",
    MasterdataPrimoPricingFormulaReader = "MasterdataPrimoPricingFormulaReader",
    MasterdataPrimoTranscoMotReader = "MasterdataPrimoTranscoMotReader",
    MasterdataPrimoTranscoProductReader = "MasterdataPrimoTranscoProductReader",
    MasterdataPrimoPriceIndexCodeReader = "MasterdataPrimoPriceIndexCodeReader",
    MasterdataProductSAPCodeReader = "MasterdataProductSAPCodeReader",
    MasterdataSapCustomerSegmentReader = "MasterdataSapCustomerSegmentReader",
    MasterdataSapDocumentTypeReader = "MasterdataSapDocumentTypeReader",
    MasterdataCustomerSegmentReader = "MasterdataCustomerSegmentReader",
    MasterdataCustomerSegmentProductReader = "MasterdataCustomerSegmentProductReader",
    MasterdataImportAradosReader = "MasterdataImportAradosReader",
    MasterdataMeanOfTransportationReader = "MasterdataMeanOfTransportationReader",
    MasterdataVesselFieldReader = "MasterdataVesselFieldReader",
    MasterdataSapCustomersReader = "MasterdataSapCustomersReader",
    MasterdataTranscosProductReader = "MasterdataTranscosProductReader",
    MasterdataTranscosCounterPartyReader = "MasterdataTranscosCounterPartyReader",
    MasterdataTranscosSiteReader = "MasterdataTranscosSiteReader",
    MasterdataMovementMessagesRulesReader = "MasterdataMovementMessagesRulesReader",
    MasterdataDocumentPathManager = "MasterdataDocumentPathManager",
    MasterdataDocumentPathReader = "MasterdataDocumentPathReader",
    RailCarsManager = "RailCarsManager",
    RailCarsReader = "RailCarsReader",
    MasterdataSapDealFieldPricingConditionsReader = "MasterdataSapDealFieldPricingConditionsReader",
    Diagnostics = "Diagnostics"
}