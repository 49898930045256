import React, { RefObject, useImperativeHandle, useRef, useState } from "react"
import moment from "moment"
import { Dialog, DialogContent, DialogTitle, DialogActions, createStyles, withStyles, Input, Typography } from '@material-ui/core'
import { PublishOutlined } from '@material-ui/icons'
import { t } from '../../../../infrastructure/i18nextHelper'
import { defaultColors, defaultStyles } from '../../../../infrastructure/materialUiThemeProvider'
import { api } from '../../../../infrastructure/api'
import { snackbars } from "../../../../infrastructure/snackbars"
import { Button, ColumnDescriptor, DataTable, LabelButton } from "../../../common/customComponents"

export type AccountingPeriodArgs = {
    country: string
    date: string
    site: string
}

type AccountingPeriod = {
    name: string
    plant: string
    status: string
    openedAt: string
    frozenAt: string
    closedAt: string
}

export let openedColor = defaultColors.grey.dark.color
export let frozenColor = defaultColors.orange.dark.color
export let closedColor = defaultColors.green.light.color

let openedStatus = { name: "Opened", color: openedColor }
let frozenStatus = { name: "Frozen", color: frozenColor }
let closedStatus = { name: "Closed", color: closedColor }

function _AccountingPeriodDialog(props) {
    let [dialogArgs, setDialogArgs] = useState<AccountingPeriodArgs | null>(null)
    let [openDialog, setOpenDialog] = useState<boolean>(false)
    let [accountingPeriods, setAccountingPeriods] = useState<AccountingPeriod[]>([])

    dialogRef = useRef<{ open: (args: AccountingPeriodArgs) => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: async (args: AccountingPeriodArgs) => {
            setDialogArgs(args)
            await fetchAccountingPeriods(args)
            setOpenDialog(true)
        }
    }))

    let getTrad = (text: string) => t('stock.accountingPeriodsDialog.' + text)

    let fetchAccountingPeriods = async (args: AccountingPeriodArgs) => {
        let result = await api.get<AccountingPeriod[]>(`stock/accountingPeriod`, args)
        setAccountingPeriods(result)
    }

    let close = () => {
        setDialogArgs(null)
        setAccountingPeriods([])
        setOpenDialog(false)
    }

    let importAccountingPeriods = async (e) => {
        await api.upload(`stock/accountingPeriod`, e.target.files[0], 'import', { withReport: 'dialog' })
            .then(_ => {
                snackbars.success(t('httpSuccess.importSuccessful'))
                if (dialogArgs)
                    fetchAccountingPeriods(dialogArgs)
            })
    }

    let formatDate = (date: string | null) =>
        !!date ? moment(date).format('MM/DD/yyyy HH:mm') : ''

    let getStatusColor = (status: string): string => {
        if (status === openedStatus.name)
            return openedStatus.color
        if (status === frozenStatus.name)
            return frozenStatus.color
        return closedStatus.color
    }

    let columns: ColumnDescriptor<AccountingPeriod>[] = [
        { name: getTrad('name'), value: x => x.name },
        { name: getTrad('plant'), value: x => x.plant },
        {
            name: getTrad('status'),
            htmlFor: x => {
                return <Typography variant="subtitle2" gutterBottom style={{ color: getStatusColor(x.status) }}>{x.status}</Typography>
            }
        },
        { name: getTrad('openedAt'), value: x => formatDate(x.openedAt) },
        { name: getTrad('frozenAt'), value: x => formatDate(x.frozenAt) },
        { name: getTrad('closedAt'), value: x => formatDate(x.closedAt) },
    ]

    return (
        <Dialog
            open={openDialog}
            onClose={close}
            scroll={'paper'}
            classes={{ paper: props.classes.dialog }}>
            <DialogTitle>
                <div className={props.classes.dialogHeader}>
                    {getTrad('title')}
                    <LabelButton className={props.classes.importButton}
                        classes={{ label: props.classes.buttonFullHeightLabel }}
                        img={<PublishOutlined />}
                        label={getTrad('import')}>
                        <Input style={{ display: 'none' }} type='file' inputProps={{ accept: '.csv' }} onChange={e => { importAccountingPeriods(e); e.target.value = ""; }} />
                    </LabelButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <DataTable
                    items={accountingPeriods}
                    isSelectable={false}
                    idSelector={(x: AccountingPeriod) => x.name + x.plant}
                    columns={columns} />
            </DialogContent>
            <DialogActions>
                <Button className={props.classes.closeButton}
                    label={t('history.close')}
                    onClick={() => setOpenDialog(false)}
                    color='primary' />
            </DialogActions>
        </Dialog>)
}

let styles = () => createStyles({
    dialog: {
        minWidth: '50%',
        minHeight: '50%'
    },
    importButton: defaultStyles.secondaryButton,
    dialogHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})

let dialogRef: RefObject<{ open: (args: AccountingPeriodArgs) => void }> | null = null

export let accountingPeriodDialog = { open: (args: AccountingPeriodArgs) => dialogRef?.current?.open(args) }
export let AccountingPeriodDialog = withStyles(styles)(_AccountingPeriodDialog)