import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { defaultStyles, muiOptions, defaultColors } from '../../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../../infrastructure/i18nextHelper'
import { FeatureContainer } from '../../../../infrastructure/feature'
import { StockMovementContainer } from '../stockMovementStore'
import * as Component from './_movementComponents'

function _loanForm({ classes }) {
    let { hasFeature } = FeatureContainer.useContainer()
    let store = StockMovementContainer.useContainer()
    let mvt = store.stockMovement

    return (
        <div>
            <div className={classes.row + ' firstSaleRow'}>
                <Component.ModeSwitcher
                    hideArrow={true}
                    initiallyDualMode={hasFeature('MirrorSwapMandatory')}
                    canSwitch={!hasFeature('MirrorSwapMandatory')}
                    forceDualMode={hasFeature('MirrorSwapMandatory')}
                    originComponent={<Component.OutputDate label={t('stock.label.movement.loanDate')} />}
                    destinationComponent={<Component.InputDate label={t('stock.label.movement.borrowDate')} disabled={!!mvt.stockInputDate && !!mvt.mirrorMovementId} />}
                    singleModeComponent={<Component.Date isIn={false} />}
                    onResetDualMode={() => !!mvt.mirrorMovementId ? store.setStockMovement({ ...store.stockMovement, stockInputDate: null }) : undefined}
                    tooltipText={t('stock.label.movement.changeDatesSelection')}
                    dualModeIconClassName={classes.switchModeButton}
                    singleModeIconClassName={classes.dateAdornment} />
                <Component.Product isIn={false} />
                <Component.DutyStatus isIn={false} />
                <Component.Company />
                <Component.Volume />
                {hasFeature('ObservedQuantityTemperature')
                    ? <>
                        <Component.ObservedTemperature />
                        <Component.ObservedVolume />
                    </>
                    : null}
            </div>
            <div className={classes.row}>
                <Component.ModeSwitcher
                    hideArrow={true}
                    canSwitch={true}
                    initiallyDualMode={!!mvt.originSite && !!mvt.destinationSite && mvt.originSite != mvt.destinationSite}
                    originComponent={<Component.OriginSite label={t('stock.label.movement.loanSite')} />}
                    destinationComponent={<Component.DestinationSite label={t('stock.label.movement.borrowSite')} />}
                    singleModeComponent={<Component.Site isIn={false} />}
                    onResetDualMode={() => store.setStockMovement({ ...mvt, destinationSite: null })}
                    tooltipText={t('stock.label.movement.changeSitesSelection')}
                    dualModeIconClassName={classes.switchModeButton}
                    singleModeIconClassName={classes.adornment} />
                <Component.Counterparty />
                <Component.Mot />
                <Component.Status />
            </div>
            <div className={classes.row}>
                <Component.Reference />
                <Component.ExternalReference />
                {hasFeature('MovementExternalNumber') && <Component.OriginExternalNumber />}
                <Component.MirrorMovementButton buttonClass={classes.secondaryButton} />
            </div>
            <div className={classes.row}>
                <Component.Comment />
            </div>
        </div>
    )
}

let styles = () =>
    createStyles({
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1em'
        },
        arrowTransition: {
            width: '1em',
            height: '1em',
            marginTop: '0.6em',
            color: defaultColors.grey.dark.color
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            margin: "1em"
        },
        dateAdornment: {
            position: 'relative',
            right: '6em',
            top: '0.5em',
            width: '0px'
        },
        adornment: {
            position: 'relative',
            right: '6em',
            top: '0.5em',
            width: '0px'
        },
        switchModeButton: {
            position: 'relative',
            top: '0.5em',
        },
        dateContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginRight: '-48px'
        }
    })

export default withStyles(styles, muiOptions)(_loanForm)
