import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { createStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Add } from '@material-ui/icons'
import {
    Paper, Button, Dialog, DialogTitle,
    DialogContent, DialogActions, Checkbox, ListItemText
} from '@material-ui/core'
import { muiOptions, MuiProps, defaultStyles, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import guid from '../../../infrastructure/guid'
import { hasSeveralPurchaseMovementTypesFeature, hasFeature } from '../../../infrastructure/feature'
import { CustomDialog } from '../../common/customComponents'
import { VesselEditContainer } from './vesselEditStore'
import { FixedPriceDataRow, FormulaPriceDataRow } from './_purchaseMovementDataRows'
import { PriceMovementContainer } from './purchaseMovementTables/priceMovementsContainer'
import { VesselProduct, PurchaseMovement, SitePurchaseMovement, Stock, PurchaseMovementType, Quotation } from '../vesselModels'
import { useActionDebounce } from '../../common/debounce'
import { TriggerPurchaseMovement } from './purchaseMovementTables/trigger/triggerPurchaseMovement'

let tBase = 'vessels.label.purchaseMovement.'

type PurchaseMovementProps = {
    vesselProduct: VesselProduct,
    type: PurchaseMovementType,
    hasOnlyOnePurchaseMovement: boolean,
    canDeletePurchaseMovement: boolean,
    removeBlock: (type: PurchaseMovementType) => void
}

function _PurchaseMovements({ classes, vesselProduct, type, hasOnlyOnePurchaseMovement, canDeletePurchaseMovement, removeBlock }: PurchaseMovementProps & MuiProps) {
    let vessel = VesselEditContainer.useContainer()
    let [shouldAutomaticalyAddOnlyPurchaseMovement, setShouldAutomaticalyAddOnlyPurchaseMovement] = useState<boolean>(hasOnlyOnePurchaseMovement)
    let [uniqueStockToAdd, setUniqueStockToAdd] = useState<Stock | null>(null)

    let [purchaseMovementsDisplayed, setPurchaseMovementsDisplayed] = useState<PurchaseMovement[]>([])
    let [stocksToAddAsPurchaseMovement, setStocksToAddAsPurchaseMovement] = useState<Stock[]>([])
    let [isAddPurchaseMovementDialogOpen, setIsAddPurchaseMovementDialogOpen] = useState<boolean>(false)

    let [purchaseMovementToDelete, setPurchaseMovementToDelete] = useState<PurchaseMovement | null>(null)
    let [isDeletePurchaseMovementOpen, setIsDeletePurchaseMovementOpen] = useState<boolean>(false)
    let [sitePurchaseMovementToDelete, setSitePurchaseMovementToDelete] = useState<SitePurchaseMovement | null>(null)
    let [isDeleteSiteDialogOpen, setIsDeleteSiteDialogOpen] = useState<boolean>(false)

    let canHaveSeveralMovementTypes = !hasFeature('VesselAutoCreateDeal')
        && hasFeature("SalesAndTransfersInVessels")
        && hasOnlyOnePurchaseMovement

    useEffect(() => {
        if (!vessel.purchaseMovementsByVesselProductId[vesselProduct.id] || vessel.purchaseMovementsByVesselProductId[vesselProduct.id].length == 0)
            setPurchaseMovementsDisplayed([])
        else
            setPurchaseMovementsDisplayed(vessel.purchaseMovementsByVesselProductId[vesselProduct.id]
                .filter(x => x.type == type)
                .sort(comparePurchaseMovement)
                .filter(x => vessel.isVisible(x.companyCode, x.dutyStatus)))
    }, [vessel.purchaseMovementsByVesselProductId[vesselProduct.id], vessel.state])

    useEffect(() => {
        let companyDutyStatusDisplayed = purchaseMovementsDisplayed
            .filter(x => vessel.isVisible(x.companyCode, x.dutyStatus))
            .map(x => { return { company: x.companyCode, dutyStatus: x.dutyStatus } })
        let uniqueStock = (value: Stock, index: number, self: Stock[]) =>
            self.findIndex(x => x.company == value.company && x.dutyStatus == value.dutyStatus) === index

        setStocksToAddAsPurchaseMovement(vessel.movementStock
            .filter(x => vessel.isVisible(x.company, x.dutyStatus))
            .filter(x => x.productId == vesselProduct.productId)
            .filter(uniqueStock)
            .filter(x => !companyDutyStatusDisplayed.find(cds => cds.company == x.company && cds.dutyStatus == x.dutyStatus)))

        if (canHaveSeveralMovementTypes && purchaseMovementsDisplayed.length == 1) {
            let stock: Stock = {
                company: purchaseMovementsDisplayed[0].companyCode,
                dutyStatus: purchaseMovementsDisplayed[0].dutyStatus,
                productId: vesselProduct.productId,
                site: ''
            }
            setUniqueStockToAdd(stock)
        }
    }, [purchaseMovementsDisplayed.length, vessel.movementStock])

    useEffect(() => {
        if (shouldAutomaticalyAddOnlyPurchaseMovement && stocksToAddAsPurchaseMovement.length == 1) {
            if (canHaveSeveralMovementTypes)
                setUniqueStockToAdd(stocksToAddAsPurchaseMovement[0])
            addPurchaseMovementBlocks([stocksToAddAsPurchaseMovement[0]])
        }
    }, [stocksToAddAsPurchaseMovement.length])

    useEffect(() => { setIsDeletePurchaseMovementOpen(purchaseMovementToDelete != null) }, [purchaseMovementToDelete])
    useEffect(() => { setIsDeleteSiteDialogOpen(sitePurchaseMovementToDelete != null) }, [sitePurchaseMovementToDelete])

    let addNewTriggerPurchaseMovement = (newPurchaseMovement: PurchaseMovement) => {
        let displayedPurchaseMovements = [...purchaseMovementsDisplayed]
        displayedPurchaseMovements.push(newPurchaseMovement)
        setPurchaseMovementsDisplayed(displayedPurchaseMovements)
        vessel.setPurchaseMovements(vesselProduct.id, displayedPurchaseMovements)
    }

    let addUniqueMovementBlock = async () => {
        if (uniqueStockToAdd == null) return

        let displayedPurchaseMovements = [...purchaseMovementsDisplayed]
        await vessel.loadTransporter([uniqueStockToAdd!.company])

        let purchaseMovement = createPurchaseMovement(uniqueStockToAdd!)
        if (type === "FixedPrice")
            purchaseMovement.sites = [vessel.applyFixedPrice(vesselProduct.id, createSite(uniqueStockToAdd!.company, uniqueStockToAdd!.dutyStatus))]
        else
            purchaseMovement.sites = [createSite(uniqueStockToAdd!.company, uniqueStockToAdd!.dutyStatus)]
        displayedPurchaseMovements.push(purchaseMovement)

        setPurchaseMovementsDisplayed(displayedPurchaseMovements)
        vessel.setPurchaseMovements(vesselProduct.id, displayedPurchaseMovements)
    }

    let addPurchaseMovementBlocks = async (newStocks: Stock[]) => {
        let displayedPurchaseMovements = [...purchaseMovementsDisplayed]
        let hiddenStock = [...stocksToAddAsPurchaseMovement]

        let newCompanys = newStocks.map(x => x.company).distinct()
        await vessel.loadTransporter(newCompanys)

        for (let stock of newStocks) {
            let purchaseMovement = vessel.purchaseMovementsByVesselProductId[vesselProduct.id]
                .find(x => x.dutyStatus == stock.dutyStatus && x.companyCode == stock.company && x.type == type)
            if (!purchaseMovement) {
                purchaseMovement = createPurchaseMovement(stock)
                if (type === "FixedPrice")
                    purchaseMovement.sites = [vessel.applyFixedPrice(vesselProduct.id, createSite(stock.company, stock.dutyStatus))]
                else
                    purchaseMovement.sites = [createSite(stock.company, stock.dutyStatus)]
                displayedPurchaseMovements.push(purchaseMovement)
            }
            if (!canHaveSeveralMovementTypes)
                hiddenStock = hiddenStock.filter(x => x.dutyStatus != stock.dutyStatus || x.company != stock.company)
        }

        setPurchaseMovementsDisplayed(displayedPurchaseMovements)
        vessel.setPurchaseMovements(vesselProduct.id, displayedPurchaseMovements)
        setStocksToAddAsPurchaseMovement(hiddenStock)
    }

    let comparePurchaseMovement = (a: PurchaseMovement, b: PurchaseMovement) => {
        let compareByDeadlineDate = (baseResult: number, a: PurchaseMovement, b: PurchaseMovement) => {
            if (type == "Trigger") {
                if (!a.deadlineDate && !b.deadlineDate || !b.deadlineDate) {
                    if (a.id <= b.id)
                        return baseResult
                    return -1 * baseResult
                }
                if (!a.deadlineDate) return -1 * baseResult
                if (moment(a.deadlineDate).isBefore(moment(b.deadlineDate))) return baseResult
                return -1 * baseResult
            }
            return baseResult
        }

        if (a.dutyStatus === b.dutyStatus) {
            if (a.companyCode === b.companyCode) {
                if (a.id < b.id)
                    return compareByDeadlineDate(1, a, b)
                else
                    return compareByDeadlineDate(-1, a, b)
            }
            let companyA = vessel.companys[a.companyCode]
            let companyB = vessel.companys[b.companyCode]
            if (companyA < companyB)
                return compareByDeadlineDate(1, a, b)
            else
                return compareByDeadlineDate(-1, a, b)
        }
        else if (a.dutyStatus < b.dutyStatus)
            return compareByDeadlineDate(-1, a, b)
        else
            return compareByDeadlineDate(1, a, b)
    }

    let addPurchaseMovement = () => {
        setIsAddPurchaseMovementDialogOpen(true)
    }

    let createPurchaseMovement = (stock: Stock) => {
        let newPurchaseMovement: PurchaseMovement = {
            id: guid.createNew(),
            companyCode: stock.company,
            dutyStatus: stock.dutyStatus,
            type: type,
            percentageOfNominated: null,
            inAlert: null,
            nominatedQuantity: null,
            remainingQuantity: null,
            totalDispatchedQuantity: null,
            deadlineDate: null,
            purchaseContract: null,
            sites: [],
        }
        return newPurchaseMovement
    }

    let createSite = (company: string, dutyStatus: string) => {
        let jetty = vessel.state.jettyCode
        let availableSites = vessel.movementStock
            .filter(x => x.company === company
                && x.dutyStatus === dutyStatus
                && x.productId === vesselProduct.productId)
            .map(x => x.site)
        let defaultSite = vessel.jettys.find(x => x.code === jetty)?.defaultSiteCode
        if (!defaultSite || (defaultSite && !availableSites.includes(defaultSite)))
            defaultSite = availableSites[0]

        let newSitePurchaseMovement: SitePurchaseMovement = {
            id: guid.createNew(),
            isDefault: false,
            volume: null,
            volumeOverride: false,
            quantity: null,
            quantityOverride: false,
            siteCode: defaultSite,
            dealId: null,
            dealReferenceNumber: null,
            movementId: null,
            availabilityDate: vessel.state.availabilityDate,
            availabilityDateOverwritten: false,
            aseReference: null,
            premium: null,
            fixedDate: null,
            fixedPrice: null,
            pricingFrom: vesselProduct.pricingStartDate,
            pricingTo: vesselProduct.pricingEndDate,
            contractualDensity: null,
            contractualDensityOverwritten: false,
            purchaseOrder: null,
            transporterId: null,
            transferDestinationSite: null,
            movementType: 1
        }
        return newSitePurchaseMovement
    }

    let duplicateSite = (site: SitePurchaseMovement) => {
        let newSitePurchaseMovement: SitePurchaseMovement = {
            id: guid.createNew(),
            isDefault: false,
            volume: site.volume,
            volumeOverride: site.volumeOverride,
            quantity: site.quantity,
            quantityOverride: site.quantityOverride,
            siteCode: site.siteCode,
            dealId: null,
            dealReferenceNumber: null,
            movementId: null,
            availabilityDate: site.availabilityDate,
            availabilityDateOverwritten: site.availabilityDateOverwritten,
            aseReference: site.aseReference,
            premium: site.premium,
            fixedDate: site.fixedDate,
            fixedPrice: site.fixedPrice,
            pricingFrom: site.pricingFrom,
            pricingTo: site.pricingTo,
            contractualDensity: site.contractualDensity,
            contractualDensityOverwritten: site.contractualDensityOverwritten,
            purchaseOrder: site.purchaseOrder,
            transporterId: site.transporterId,
            transferDestinationSite: site.transferDestinationSite,
            movementType: site.movementType

        }
        return newSitePurchaseMovement
    }

    let handleDeletePurchaseMovement = async () => {
        if (!purchaseMovementToDelete) return
        setShouldAutomaticalyAddOnlyPurchaseMovement(false)
        await vessel.deletePurchaseMovement(purchaseMovementToDelete.id, vesselProduct.id)
        if (purchaseMovementsDisplayed.length == 1)
            removeBlock(type)
        setPurchaseMovementToDelete(null)
    }

    let handleDeleteSitePurchaseMovement = async () => {
        if (sitePurchaseMovementToDelete === null) return
        let purchaseMovementId = purchaseMovementsDisplayed.find(x => x.sites.findIndex(s => s.id == sitePurchaseMovementToDelete!.id) >= 0)!.id
        await vessel.deleteSitePurchaseMovement(sitePurchaseMovementToDelete.id, purchaseMovementId, vesselProduct.id)
        setSitePurchaseMovementToDelete(null)
    }

    let addSite = (purchaseMvt: PurchaseMovement, defaultSitePurchaseMovement: SitePurchaseMovement) => {
        if (purchaseMvt.sites == null)
            purchaseMvt.sites = [defaultSitePurchaseMovement]
        else
            purchaseMvt.sites.push(defaultSitePurchaseMovement)
        vessel.setPurchaseMovement(vesselProduct.id, purchaseMvt)
    }

    if (!purchaseMovementsDisplayed)
        return (<div></div>)

    return (
        <div>
            <div className={classes.purchaseMovementHeader}>
                <div className={classes.headerTitle}>
                    <Typography className={classes.mainTitle} variant='overline' display='block' gutterBottom>
                        {hasSeveralPurchaseMovementTypesFeature() ? t(tBase + `types.${type}`) : t(tBase + 'title')}
                    </Typography>
                </div>
                {stocksToAddAsPurchaseMovement.length > 0 && !hasOnlyOnePurchaseMovement
                    ? <Button onClick={() => addPurchaseMovement()} className={`${classes.addSiteButton} add-site-btn`} >
                        <Add /> {t(tBase + 'addPurchaseMovement')}
                    </Button >
                    : undefined}
                {canHaveSeveralMovementTypes && uniqueStockToAdd
                    ? <Button onClick={() => addUniqueMovementBlock()}
                        className={`${classes.addSiteButton} add-site-btn`} >
                        <Add /> {t(tBase + 'addSite')}
                    </Button >
                    : undefined}
            </div>
            {hasSeveralPurchaseMovementTypesFeature()
                ? type == 'FormulaPrice'
                    ? <FormulaPriceDataRow
                        classes={classes}
                        quotations={!!vessel.quotations ? vessel.quotations?.map(x => ({ value: x.medecoCode, text: x.longName })) : []}
                        changeProduct={vessel.changeProduct}
                        product={vesselProduct} />
                    : type == 'FixedPrice'
                        ? <FixedPriceDataRow
                            classes={classes}
                            value={vessel.fixedPriceByVesselProductId[vesselProduct.id]}
                            productUnit={vesselProduct.fixedPriceUnit ?? ''}
                            changeFixedPrice={(value) => vessel.updateFixedPrice(vesselProduct.id, value)} />
                        : undefined
                : undefined
            }
            {canHaveSeveralMovementTypes && type != "Trigger"
                ? purchaseMovementsDisplayed
                    .map((purchaseMvt, i) =>
                        <Paper className={classes.tablePaper} key={i}>
                            <PriceMovementContainer key={i}
                                vesselProduct={vesselProduct}
                                isFixedPrice={type == "FixedPrice"}
                                purchaseMovement={purchaseMvt}
                                setPurchaseMovementToDelete={() => setPurchaseMovementToDelete(purchaseMvt)}
                                setSitePurchaseMovementToDelete={(site) => setSitePurchaseMovementToDelete(site)}
                                canDeletePurchaseMovement={canDeletePurchaseMovement}
                                addSite={addSite}
                                createSite={createSite}
                                duplicateSite={duplicateSite} />
                        </Paper>
                    )
                : purchaseMovementsDisplayed
                    .groupBy(x => x.companyCode + x.dutyStatus + x.type, x => x)
                    .map((purchaseMvtGroup, i) =>
                        <Paper className={classes.tablePaper} key={i}>
                            {type == "Trigger"
                                ? <TriggerPurchaseMovement key={i}
                                    vesselProduct={vesselProduct}
                                    purchaseMovements={purchaseMvtGroup[1]}
                                    setPurchaseMovementToDelete={(purchaseMovement) => setPurchaseMovementToDelete(purchaseMovement)}
                                    setSitePurchaseMovementToDelete={(site) => setSitePurchaseMovementToDelete(site)}
                                    addTriggerPurchaseMovement={addNewTriggerPurchaseMovement}
                                    canDeletePurchaseMovement={canDeletePurchaseMovement}
                                    addSite={addSite}
                                    createSite={createSite} />
                                : <PriceMovementContainer key={i}
                                    vesselProduct={vesselProduct}
                                    isFixedPrice={type == "FixedPrice"}
                                    purchaseMovement={purchaseMvtGroup[1][0]}
                                    setPurchaseMovementToDelete={() => setPurchaseMovementToDelete(purchaseMvtGroup[1][0])}
                                    setSitePurchaseMovementToDelete={(site) => setSitePurchaseMovementToDelete(site)}
                                    canDeletePurchaseMovement={canDeletePurchaseMovement}
                                    addSite={addSite}
                                    createSite={createSite}
                                    duplicateSite={duplicateSite} />
                            }
                        </Paper>
                    )
            }
            <AddPurchaseMovementDialog isOpen={isAddPurchaseMovementDialogOpen}
                vesselStock={stocksToAddAsPurchaseMovement}
                companys={vessel.companys}
                onClose={() => setIsAddPurchaseMovementDialogOpen(false)}
                onSubmit={(stocks: Stock[]) => addPurchaseMovementBlocks(stocks)}
                classes={classes} />
            <CustomDialog
                title={t(tBase + 'deletePurchaseMovement')}
                contentText={t(tBase + 'deletePurchaseMovementText')}
                isOpen={isDeletePurchaseMovementOpen}
                onConfirm={() => handleDeletePurchaseMovement()}
                onCancel={() => setPurchaseMovementToDelete(null)}
                onClose={() => setPurchaseMovementToDelete(null)} />
            <CustomDialog
                title={t(tBase + 'deleteSitePurchaseMovement')}
                contentText={t(tBase + 'deleteSitePurchaseMovementText', { site: sitePurchaseMovementToDelete?.siteCode })}
                isOpen={isDeleteSiteDialogOpen}
                onConfirm={() => handleDeleteSitePurchaseMovement()}
                onCancel={() => setSitePurchaseMovementToDelete(null)}
                onClose={() => setSitePurchaseMovementToDelete(null)} />
        </div >
    )
}

type AddPurchaseMovementDialogProps = {
    vesselStock: Stock[]
    companys: { [companyCode: string]: string }
    isOpen: boolean
    onClose: () => void
    onSubmit: (stocks: Stock[]) => void
} & MuiProps

function AddPurchaseMovementDialog({ vesselStock, companys, isOpen, onClose, onSubmit, classes }: AddPurchaseMovementDialogProps) {
    let [selectedStocks, setSelectedStocks] = useState<Stock[]>([])
    let stockEquals = (stockA: Stock, stockB: Stock) => stockA.company == stockB.company && stockA.dutyStatus == stockB.dutyStatus
    let compareStock = (a: Stock, b: Stock) => {
        if (a.dutyStatus == null) return -1
        if (b.dutyStatus == null) return 1
        if (a.dutyStatus === b.dutyStatus) {
            if (a.company == null) return -1
            if (b.company == null) return 1
            let companyA = companys[a.company]
            let companyB = companys[b.company]
            return companyA < companyB ? 1 : -1
        }
        return a.dutyStatus < b.dutyStatus ? -1 : 1
    }

    let handleSelection = (stock: Stock) => {
        let stocks = [...selectedStocks]
        if (stocks.findIndex(x => stockEquals(x, stock)) >= 0)
            stocks = stocks.filter(x => !stockEquals(x, stock))
        else
            stocks.push(stock)

        setSelectedStocks(stocks)
    }

    let handleClose = () => {
        onClose()
        setSelectedStocks([])
    }

    let handleSubmitDebouncer = useActionDebounce(async () => {
        onSubmit(selectedStocks)
        handleClose()
    })

    return <Dialog open={isOpen} onClose={handleClose} >
        <DialogTitle>{t('vessels.label.purchaseMovement.selectionDialog.title')}</DialogTitle>
        <DialogContent>
            {vesselStock.sort(compareStock).map(x =>
                <div key={x.company + '' + x.dutyStatus} className={classes.stockPurchaseMovementChoice} onClick={() => handleSelection(x)}>
                    <Checkbox checked={selectedStocks.findIndex(s => s.company === x.company && s.dutyStatus == x.dutyStatus) >= 0}
                        classes={{ checked: classes.checkboxChecked, root: classes.noPadding }}
                        color='default'
                        onChange={_ => { }} />
                    <ListItemText primary={companys[x.company] + ' / ' + x.dutyStatus} />
                </div>)}
        </DialogContent>
        <DialogActions>
            <Button className={classes.closeButton} onClick={handleClose} color='primary'>{t('vessels.label.purchaseMovement.selectionDialog.cancelButton')}</Button>
            <Button className={classes.submitButton} onClick={handleSubmitDebouncer.execute} color='primary'>{t('vessels.label.purchaseMovement.selectionDialog.addButton')}</Button>
        </DialogActions>
    </Dialog>
}

let styles = _ =>
    createStyles({
        mainTitle: {
            color: defaultColors.red.main.color
        },
        headerTitle: { ...defaultStyles.flexRow },
        addSiteButton: {
            ...defaultStyles.secondaryButton,
            minWidth: '10em',
            marginRight: '5em',
            marginLeft: '5em'
        },
        tablePaper: {
            margin: '1em',
            padding: '1em'
        },
        stockPurchaseMovementChoice: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
        },
        purchaseMovementHeader: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            marginTop: '1em'
        },
        checkboxChecked: {
            color: defaultColors.red.main.color,
        },
        productDataRow: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1.0em'
        }
    })

export let PurchaseMovements = withStyles(styles, muiOptions)(_PurchaseMovements)