import { ChartData, ChartDataset, ChartOptions } from "chart.js"
import { t } from "i18next"
import moment from "moment"
import { prepareDataSet } from "../../common/components/chart"
import { options } from '../../common/components/chart'
import { defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { ProductVesselSimulation } from "../vesselModels"

type Props = {
    simulation: ProductVesselSimulation | null
    displayByDays: boolean
}

export function createVesselSimulationChart(props: Props): ChartData<'line', Array<number | null>, string> {
    let { simulation, displayByDays } = props

    if (!simulation) throw 'no line'

    let minVolumes = simulation?.minVolumes
    let maxVolumes = simulation?.maxVolumes

    let datasets: ChartDataset<'line', Array<number | null>>[] = []

    if (!displayByDays && minVolumes?.length)
        datasets.push(prepareDataSet({
            label: t('vessels.simulation.chart.min'),
            data: minVolumes.map(x => x.value),
            color: 'min',
            stack: 'min',
        }))

    if (!displayByDays && maxVolumes?.length)
        datasets.push(prepareDataSet({
            label: t('vessels.simulation.chart.max'),
            data: maxVolumes.map(x => x.value),
            color: 'max',
            stack: 'max',
        }))

    if (simulation.projections) {
        datasets.push(
            prepareDataSet({
                label: displayByDays ? t('vessels.simulation.chart.simulatedDays') : t('vessels.simulation.chart.simulatedChartLegend'),
                data: simulation.projections.map(x => {
                    let value = displayByDays ? x.simulatedDayLeft : x.simulatedStock
                    return value ? Math.round(value) : null
                }),
                color: simulatedBlue.line,
                backgroundColor: simulatedBlue.background,
                fill: 'start',
                stack: 'simulation',
            }))

        datasets.push(
            prepareDataSet({
                label: displayByDays ? t('vessels.simulation.chart.projectedDays') : t('vessels.simulation.chart.projectedChartLegend'),
                data: simulation.projections.map(x => {
                    let value = displayByDays ? x.dayLeft : x.projectedStock
                    return value ? Math.round(value) : null
                }),
                color: mainBlue.line,
                backgroundColor: mainBlue.background,
                fill: 'start',
                stack: 'simulation',
            }))
    }

    let labels = simulation.projections.map(x => x.date ?? '')

    return { labels, datasets }
}

export function createChartOptions(chart: ChartData<'line', Array<number | null>, string>): ChartOptions<'line'> {
    let today = moment().startOf('day').format('YYYY-MM-DD')
    let isTodayIsInChartLabels = chart.labels?.some(x => x.contains(today))
    let hasStack = chart.datasets.some(x => x.stack === 'stack')

    return {
        ...options,
        plugins: {
            ...options.plugins,
            legend: { display: false },
            annotation: {
                annotations: isTodayIsInChartLabels ? [
                    {
                        type: 'line',
                        borderColor: defaultColors.grey.main.color,
                        borderWidth: 3,
                        drawTime: 'beforeDatasetsDraw',
                        xMin: moment().startOf('day').toString(),
                        xMax: moment().startOf('day').toString(),
                    }
                ] : []
            }
        },
        scales: {
            x: { type: 'time', time: { unit: 'week', displayFormats: { week: 'MMM DD' } } },
            y: { type: 'linear', stacked: hasStack, },
        }
    }
}

const simulatedBlue = {
    line: 'rgb(73,101,181, 0.5)',
    background: 'rgb(73,101,181, 0.2)'
}

const mainBlue = {
    line: 'rgb(73,101,181, 0.9)',
    background: 'rgb(73,101,181, 0.4)'
}
