import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { defaultColors, defaultStyles, muiOptions, MuiProps } from "../../../infrastructure/materialUiThemeProvider";

export let reportStyles = createStyles({
    form: {
        ...defaultStyles.flexRow,
        '& > *': {
            marginRight: '1em',
            '& :last-child': {
                marginRight: 0
            }
        }
    },
    datePicker: {
        marginLeft: '3em'
    },
})

type reportShellProps = {
    title: string,
    children: any,
    displayError: boolean,
    errorMessage?: string
}

function _reportShell({ title, children, displayError, errorMessage, classes }: reportShellProps & MuiProps) {
    return (<Paper className={classes.reportContainer}>
        <Typography style={{ color: defaultColors.red.main.color }} variant='overline' display='block' gutterBottom>
            {title}
        </Typography>
        <div className={classes.spaceBetween}>
            {children}
        </div>
        {displayError
            ? <Typography style={{ color: defaultColors.red.main.color }} variant='subtitle1' display='block' gutterBottom>
                {errorMessage}
            </Typography>
            : ''}
    </Paper>)
}

let styles = _ => createStyles({
    reportContainer: {
        padding: '1.5em',
        paddingTop: '0.5em',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: '1em',
            '& :last-child': {
                marginBottom: 0
            }
        },
        maxWidth: '73em',
        width: '100%'
    },
    spaceBetween: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
})

export let ReportShell = withStyles(styles, muiOptions)(_reportShell)
