import React from 'react'
import { withStyles } from '@material-ui/core'
import { createStyles } from '@material-ui/styles'
import { muiOptions, defaultColors, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { TextField } from './textfield'
import { hasFeature } from '../../../infrastructure/feature'

type FactorFieldProps = {
    label: string
    text: number | null
    inputProps?: any
    disableNewStyle?: boolean
}

function _ToBBLFactorField({ classes, text, ...props }: FactorFieldProps & MuiProps) {
    return (
        <_FactorField text={text ? parseFloat(text.toFixed(5)) : null} {...props} />
    )
}

function _ToVolumeFactorField({ classes, text, ...props }: FactorFieldProps & MuiProps) {
    text = text === 0 ? 0
        : !text ? null
            : hasFeature('reverseConversionFactor') ? parseFloat((1 / text).toFixed(5))
                : parseFloat(text.toFixed(5))
    return (
        <_FactorField text={text ? parseFloat(text.toFixed(5)) : null} {...props} />
    )
}

function _FactorField({ text, label, inputProps, ...props }: FactorFieldProps) {
    return (
        <TextField {...props} label={label} text={text} inputProps={inputProps} disabled />
    )
}

let styles = theme => createStyles({})

export let ToBBLFactorField = withStyles(styles, muiOptions)(_ToBBLFactorField)
export let ToVolumeFactorField = withStyles(styles, muiOptions)(_ToVolumeFactorField)