import * as api from './api'
import { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'

export type FeatureCode = string

function useFeature() {
    let [features, setFeatures] = useState<Feature[]>([])

    useEffect(() => { loadFeatures() }, [])
    useEffect(loadRef, [features])
    function hasFeature(code: FeatureCode) {
        return !!features.find(x => x.name === code)?.isActive
    }

    async function loadFeatures() {
        setFeatures(await getFeatures())
    }

    function loadRef() {
        if (hasFeatureRef) hasFeatureRef = hasFeature
    }

    return { hasFeature, reload: loadFeatures, features }
}

export type Feature = { name: string, isActive: boolean }

export type FeatureCategoryDto = {
    name: string
    features: FeatureDto[]
}

export type FeatureDto = {
    name: string
    isActive: boolean
    scope: string[]
    isCore: boolean
    changedBy: string | null
    changedAt: string | null
    scopeIndexed: { [key: string]: boolean }
}

export let FeatureContainer = createContainer(useFeature)

export let getFeatures = async () => await api.get<Feature[]>('feature')

let hasFeatureRef = (code: FeatureCode) => false
export let hasFeature = (code: FeatureCode) => hasFeatureRef ? hasFeatureRef(code) : false

export let hasSeveralPurchaseMovementTypesFeature = () =>
    hasFeature('VesselAutoCreateDeal')
    && (hasFeature('PricingTrigger')
        || hasFeature('PricingFixedPrice'))

export let hasAutoCreateDealAndHasNotSelectPricingTypeFeature = () =>
    hasFeature('AutoCreateDealOnPurchase')
    && !hasFeature('SelectPricingType')
