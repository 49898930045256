import React, { useState } from 'react'
import moment from 'moment'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions, MuiProps, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { Button, DatePicker } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import { formatFilename } from '../../../infrastructure/excelExport'
import * as api from '../../../infrastructure/api'
import { reportStyles, ReportShell } from './_common'

function _stockDgh({ classes }: MuiProps) {
    let currentMonth = moment().format('MM/DD/YYYY')
    let [stockDghDate, setStockDghDate] = useState<string | null>(currentMonth)

    let generateStockDgh = async () => {
        let filename = formatFilename('StockDGH.xlsx')
        await api.post('report/stockDgh', { date: stockDghDate, filename: filename })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')
    }

    return (
        <ReportShell title={t('report.stockDgh')} displayError={false}>
            <div className={classes.form}>
                <Button className={classes.generateButton}
                    label={t('report.export')}
                    img='/static/images/excel_red.svg'
                    onClick={generateStockDgh} />
            </div>
            <DatePicker classesOverride={{ datepicker: classes.datePicker }}
                date={stockDghDate} label={t('report.date')}
                setDate={newDate => setStockDghDate(newDate)} />
        </ReportShell>
    )
}

let styles = theme => createStyles({
    generateButton: defaultStyles.secondaryButton,
    datePicker: reportStyles.datePicker,
    form: reportStyles.form,
})

export default withStyles(styles, muiOptions)(_stockDgh)