import React, { Fragment, useState, createContext, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import Header from './header'
import Menu from './menu'
import { muiOptions, defaultStyles } from '../materialUiThemeProvider'
import { SnackbarContainer } from '../snackbars'
import { CustomSnackBar } from '../../app/common/customComponents'
import { setApiSnackbarMethod, setApiSnackbarReportMethod } from '../api'
import { LoaderStatusContainer } from '../loader'

export let ShellContext = createContext((_: boolean): void => { })

function Loader() {
    let loader = LoaderStatusContainer.useContainer()
    return loader.isBackgroundActive
        ? <div id={"ui-test-loader-active"} style={{ opacity: 0.1 }} />
        : <div id={"ui-test-loader-inactive"} style={{ opacity: 0.1 }} />
}

function Notifications({ classes }) {
    let snackbar = SnackbarContainer.useContainer()

    useEffect(() => setApiSnackbarMethod(snackbar._show), [snackbar.snackbars])
    useEffect(() => setApiSnackbarReportMethod(snackbar.report), [snackbar.snackbars])

    return <div className={classes.notificationContainer}>
        {snackbar.snackbars.map((notif) =>
            <CustomSnackBar key={notif.index} {...notif} />
        )}
    </div>
}

function Shell(props) {
    let [isMenuOpen, setIsMenuOpen] = useState(false)
    let [isVisible, setIsVisible] = useState(false)
    let openDrawer = () => { setIsMenuOpen(true) }
    let closeDrawer = () => { setIsMenuOpen(false) }
    let toggleDrawer = () => { setIsMenuOpen(!isMenuOpen) }
    let { classes } = props

    return (
        <Fragment>
            <ShellContext.Provider value={setIsVisible}>
                {
                    isVisible
                        ? <Fragment>
                            <div className={classes.rootComponent}>
                                <Header toggleDrawer={toggleDrawer} />
                                <Menu openDrawer={openDrawer} closeDrawer={closeDrawer} isOpen={isMenuOpen} />
                                <main className={classes.mainContent}>
                                    {props.children}
                                    <Notifications classes={classes} />
                                    <Loader />
                                </main>
                            </div>
                        </Fragment>
                        : <Fragment>
                            <main className={classes.mainContent}>
                                {props.children}
                                <Notifications classes={classes} />
                                <Loader />
                            </main>
                        </Fragment>
                }
            </ShellContext.Provider>
        </Fragment>
    )
}

let styles = _ =>
    createStyles({
        mainContent: {
            paddingTop: '3em',
            paddingLeft: '4em',
            paddingRight: '0.4em',
            height: '100%',
            boxSizing: 'border-box'
        },
        notificationContainer: {
            ...defaultStyles.flexColumn,
            position: 'absolute',
            bottom: '0px',
            left: '0px',
            width: '0px',
            height: '0px',
            justifyContent: 'flex-end',
            alignItems: 'flex-start'
        }
    })

export default withStyles(styles, muiOptions)(Shell)