import React from 'react'

let now = Date.now()
let lastDomUpdate = now
let lastStatusUpdate = now

// checks dom status for Cypress loading tracking
export function useDomStateTracker() {
    let [status, setStatus] = React.useState<'stable' | 'unstable' | 'untracked'>('stable')

    React.useEffect(() => { ObserveDomChanges() }, [])

    function ObserveDomChanges() {
        if (!(window as any).Cypress) {
            setStatus('untracked')
            return
        }

        MutationObserver = window.MutationObserver

        let observer = new MutationObserver(function (mutations, observer) {
            lastDomUpdate = Date.now()
        })

        observer.observe(document.getElementById('page')!, {
            subtree: true,
            attributes: true
        })

        CheckStabilityLoop()
    }

    function CheckStabilityLoop() {
        setTimeout(() => {
            let now = Date.now()
            if (status === 'unstable' && now - lastDomUpdate > 1000) {
                setStatus('stable')
                status = 'stable'
                lastStatusUpdate = now
            }
            else if (status === 'stable' && now - lastStatusUpdate > 2000 && now - lastDomUpdate < 1000) {
                setStatus('unstable')
                status = 'unstable'
                lastStatusUpdate = now
            }

            CheckStabilityLoop()
        }, 500)
    }

    return { status }
}