import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions, MuiProps, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { Button, DatePicker } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import { formatFilename } from '../../../infrastructure/excelExport'
import * as api from '../../../infrastructure/api'
import { reportStyles, ReportShell } from './_common'

function _fifoDashboard({ classes }: MuiProps) {
    let [fifoFromDate, setFifoFromDate] = useState<string | null>(null)
    let [displayDateErrorMessage, setDisplayDateErrorMessage] = useState<boolean>(false)

    let setFifoDefaultStartDate = async () => {
        let context: { lastCalibrationDate: string | null } = await api.get('report/fifo/lastCalibrationDate')
        if (!context.lastCalibrationDate) setDisplayDateErrorMessage(true)
        setFifoFromDate(context.lastCalibrationDate ?? moment.utc().startOf('month').format())
    }

    let generateFifoDashboard = async () => {
        let filename = formatFilename('FIFO_Dashboard.zip')
        await api.post(`report/fifo`, { fromDate: fifoFromDate, filename: filename })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')
    }

    useEffect(() => { setFifoDefaultStartDate() }, [])

    return (
        <ReportShell title={t('report.fifoDashboard')}
            displayError={displayDateErrorMessage}
            errorMessage={t('report.lastCalibrationDateNotFound')}>
            <div className={classes.form}>
                <Button className={classes.generateButton}
                    label={t('report.export')}
                    img='/static/images/excel_red.svg'
                    onClick={generateFifoDashboard} />
            </div>
            <DatePicker classesOverride={{ datepicker: classes.datePicker }}
                date={fifoFromDate} label={t('report.fromDate')}
                setDate={newDate => setFifoFromDate(newDate)} />
        </ReportShell>
    )
}

let styles = theme => createStyles({
    generateButton: defaultStyles.secondaryButton,
    datePicker: reportStyles.datePicker,
    form: reportStyles.form
})

export default withStyles(styles, muiOptions)(_fifoDashboard)