import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { createStyles, withStyles, Paper, Typography, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core'
import { DirectionsBoatOutlined, GavelOutlined, LocalShippingOutlined, Train } from '@material-ui/icons'
import { defaultStyles, muiOptions, defaultColors, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { popupNavigator } from '../../../infrastructure/popupNavigator'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { hasAutoCreateDealAndHasNotSelectPricingTypeFeature, hasFeature } from '../../../infrastructure/feature'
import { Popup, AttachFileButton, Button, ButtonPopupParams } from '../../common/customComponents'
import { useActionDebounce } from '../../common/debounce'
import { dealDialog } from '../../deals/dealEdit/dealEditStore'
import { VesselEditDialogContainer } from '../../vessels/vesselEdit/vesselEditStore'
import { SelectAssignableDeals, ConfirmDealAssignation } from '../../common/assign/assignDealPopup'
import { ConfirmVesselAssignation, SelectAssignableVessels } from './assigns/assignVesselPopup'
import SelectPricingType from './assigns/selectPricingPopup'
import { StockBoardContainer } from '../stockBoardStore'
import { MovementType, MovementTypeNameFromMovementType, defaultMovementTypeSelectionOptions } from '../stockModels'
import { StockMovementContainer, movementDialog } from './stockMovementStore'
import MovementTypeSelection from './_stockMovementTypeSelection'
import { LinkVesselMovementContainer } from './movementForms/linkVesselContainer'
import {
    PurchaseForm, GainsLossesForm, SaleForm, TransferForm, StatusChangeForm,
    RebrandingForm, BatchForm, SapForm, Borrow, Loan, Untriggered
} from './movementForms/movementForms'
import guid from '../../../infrastructure/guid'
import { movementMessageHistoryDialog } from './movementMessageHistory'

function StockMovementEdit({ classes }) {
    let store = StockMovementContainer.useContainer()
    let vesselDialog = VesselEditDialogContainer.useContainer()
    let stockBoardStore = StockBoardContainer.useContainer()
    let linkVesselMovementContainer = LinkVesselMovementContainer.useContainer()

    let isBatch = (): boolean => store.batchMovement != null

    let onClickSaveDebouncer = useActionDebounce(async () => {
        await handleSave()
    })

    let movementForm = () => {
        if (store.stockMovement == null || store.stockMovement.movementType === MovementType.Unknown)
            return undefined
        if (store.stockMovement.movementType === MovementType.Purchase)
            return <PurchaseForm />
        if (store.stockMovement.movementType === MovementType.Untriggered)
            return <Untriggered />
        if (store.stockMovement.movementType === MovementType.Borrow)
            return <Borrow />
        if (store.stockMovement.movementType === MovementType.Loan)
            return <Loan />
        if (store.stockMovement.movementType === MovementType.Sale)
            return <SaleForm />
        if (store.stockMovement.movementType === MovementType.Transfer)
            return <TransferForm isIn={stockBoardStore.switchFilter === stockBoardStore.inValue} />
        if (store.stockMovement.movementType === MovementType.StatusChange)
            return <StatusChangeForm isIn={stockBoardStore.switchFilter === stockBoardStore.inValue} />
        if (store.stockMovement.movementType === MovementType.Rebranding)
            return <RebrandingForm isIn={stockBoardStore.switchFilter === stockBoardStore.inValue} />
        if (store.stockMovement.movementType === MovementType.Gains ||
            store.stockMovement.movementType === MovementType.Losses)
            return <GainsLossesForm isIn={stockBoardStore.switchFilter === stockBoardStore.inValue} />
    }

    let onMovementTypeSelected = async (type: MovementType, isIn: boolean) => {
        let newDate = moment()
        let pad = (number: number): string => number < 10 ? '0' + number : '' + number
        let currentDate = moment.utc(`${newDate.year()}${pad(newDate.month() + 1)}${pad(newDate.date())}`, 'YYYYMMDD').format()
        let site = stockBoardStore.stockFilters.sites && stockBoardStore.stockFilters.sites?.length > 0
            ? stockBoardStore.stockFilters.sites[0] : null
        let siteExist = !!site && store.sites.findIndex(x => x.code == site) !== -1
        if (!siteExist && stockBoardStore.sites.length > 0)
            site = stockBoardStore.sites[0].code

        let product = stockBoardStore.stockFilters.productIds && stockBoardStore.stockFilters.productIds?.length > 0
            ? stockBoardStore.stockFilters.productIds[0] : null

        let dutyStatus = stockBoardStore.stockFilters.dutyStatuses?.length == 1
            ? stockBoardStore.stockFilters.dutyStatuses[0] ?? null
            : null

        let stockMovement = store.stockMovement

        stockMovement.movementType = type
        stockMovement.mainSapFlow.movementType = type

        if (type == MovementType.Purchase || type == MovementType.Borrow) {
            stockMovement.destinationSite = site
            stockMovement.destinationProductId = product
            stockMovement.stockInputDate = currentDate
            stockMovement.destinationDutyStatus = dutyStatus
        }
        else if (type == MovementType.Sale || type == MovementType.Loan) {
            stockMovement.originSite = site
            stockMovement.originProductId = product
            stockMovement.stockOutputDate = currentDate
            stockMovement.originDutyStatus = dutyStatus
        }
        else if (type == MovementType.StatusChange) {
            stockMovement.originSite = site
            stockMovement.destinationSite = site
            stockMovement.originProductId = product
            stockMovement.destinationProductId = product
            stockMovement.stockInputDate = currentDate
            stockMovement.stockOutputDate = currentDate
        }
        else if (type == MovementType.Transfer) {
            if (isIn)
                stockMovement.destinationSite = site
            else
                stockMovement.originSite = site
            stockMovement.originProductId = product
            stockMovement.destinationProductId = product
            stockMovement.stockInputDate = currentDate
            stockMovement.stockOutputDate = currentDate
            stockMovement.destinationDutyStatus = dutyStatus
            stockMovement.originDutyStatus = dutyStatus
        }
        else if (type == MovementType.Rebranding) {
            if (isIn)
                stockMovement.destinationProductId = product
            else
                stockMovement.originProductId = product
            stockMovement.originSite = site
            stockMovement.destinationSite = site
            stockMovement.stockInputDate = currentDate
            stockMovement.stockOutputDate = currentDate
            stockMovement.destinationDutyStatus = dutyStatus
            stockMovement.originDutyStatus = dutyStatus
        }

        stockMovement.company = stockBoardStore.stockFilters.companies?.length === 1
            ? stockBoardStore.stockFilters.companies[0]
            : null

        store.setStockMovement({ ...stockMovement })
    }

    let handleSave = async () => {
        await store.trySave()
        if (store.stockMovement.movementType === MovementType.Purchase) {
            if (!store.associatedDeal && store.stockMovement.counterpartyId && hasAutoCreateDealAndHasNotSelectPricingTypeFeature())
                await store.createDealFromMovement()
        }
        movementDialog.close()
    }

    let openVessel = () => {
        if (linkVesselMovementContainer.associatedVessel) {
            let options = store.stockMovement.destinationProductId
                ? { productId: store.stockMovement.destinationProductId }
                : undefined

            popupNavigator.open('vessel', linkVesselMovementContainer.associatedVessel?.id, options)
        }
        setTimeout(() => movementDialog.close(), 1000)
    }

    let openTruck = () => {
        if (store.associatedTruckId) {
            if (store.stockMovement.meanOfTransportation == 'Train')
                popupNavigator.open('railCar', store.associatedTruckId)
            else
                popupNavigator.open('truck', store.associatedTruckId)
        }
    }

    useEffect(() => {
        if (store.associatedVesselId && !vesselDialog.isOpen && store.stockMovement)
            store.load(store.stockMovement.id)
    }, [vesselDialog.isOpen])


    let openDeal = () => {
        if (store.associatedDeal)
            dealDialog.open(store.associatedDeal.id)
        setTimeout(() => movementDialog.close(), 1000)
    }

    let openMovementMessageHistory = () => {
        if (store.stockMovement.reference)
            movementMessageHistoryDialog.open({ reference: store.stockMovement.reference })
    }

    let isBatchMovement = store.batchMovement != null

    let buttons: ButtonPopupParams[] = [{
        buttonEffect: store.stockMovement?.movementType !== null
            ? onClickSaveDebouncer.execute : undefined,
        buttonText: store.stockMovement?.movementType !== null ? isBatch()
            ? t('stock.batch.generate') : t('components.saveButton') : undefined,
    }]

    if (!isBatchMovement && !store.stockMovement.nomination
        && (store.stockMovement.movementType == MovementType.Purchase || store.stockMovement.movementType == MovementType.Sale)
        && hasFeature('TransferSapFlowInSaleAndPurchase')
        && store.stockMovement.secondSapFlow == null) {
        store.setStockMovement({
            ...store.stockMovement, secondSapFlow: {
                id: guid.createNew(),
                movementType: MovementType.Transfer,
                sapFlowType: null,
                referenceOrder: null,
                deliveryNote: null,
                materialDocument: null,
                materialDocDate: null,
                originPlant: null,
                originStorageLocation: null,
                destinationPlant: null,
                destinationStorageLocation: null,
                releaseIndicator: null,
                goodsIssue: null,
                vettingNumber: null,
                useObservedTemperatureInSap: hasFeature('UseObservedTemperatureByDefault'),
                postingDate: null,
            }
        })
    }

    return (
        <Popup title={t('stock.popup.title')}
            close={movementDialog.close}
            isOpen={store.isOpen}
            buttons={buttons}
            isBottomHalf={store.isHalfBottom}>
            {(store.stockMovement?.id === ''
                ? <></>
                : store.stockMovement?.movementType === null
                    ? <MovementTypeSelection onMovementTypeSelected={onMovementTypeSelected} options={defaultMovementTypeSelectionOptions} />
                    : <div>
                        {isBatchMovement
                            ? <Paper className={classes.movementInformation}>
                                <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                                    {t('stock.label.movement.frequency')}
                                </Typography>
                                <BatchForm />
                            </Paper>
                            : null}
                        <Paper className={classes.movementInformation}>

                            {
                                hasClaim(Claims.StockManager)
                                    ? <div className={classes.buttonDiv}>
                                        <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                                            {MovementTypeNameFromMovementType(store.stockMovement?.movementType)}
                                        </Typography>
                                        <div className={classes.buttonDiv2}>
                                            {store.stockMovement.reference && store.stockMovement.meanOfTransportation == 'Pipe' && hasFeature('MessageMovements') &&
                                                <Button className={classes.refreshButton} label={t('stock.label.movementMessageHistoryTitle')} onClick={openMovementMessageHistory} />}
                                            <AttachFileButton
                                                id='upload-doc-button'
                                                className={classes.documentButton}
                                                title={t('stock.label.movementDocumentDialogTitle')}
                                                disableDelOrAdd={!hasClaim(Claims.StockManager)}
                                                context={store.stockMovement.company}
                                                keyTemplate='movement-{stockMovementId}'
                                                keyParameters={{ stockMovementId: store.stockMovement?.id ? store.stockMovement.id.replace(/-/g, '') : '' }} />
                                        </div>
                                    </div>
                                    :
                                    <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                                        {MovementTypeNameFromMovementType(store.stockMovement?.movementType)}
                                    </Typography>
                            }
                            {movementForm()}
                            {store.associatedVesselId
                                ? <Button
                                    label={t('stock.popup.goToVessel')}
                                    img={<DirectionsBoatOutlined className={classes.buttonPictogram} />}
                                    onClick={openVessel} className={classes.buttonLink} />
                                : null}
                            {store.associatedTruckId
                                ? <Button
                                    label={store.stockMovement.meanOfTransportation == 'Train'
                                        ? t('stock.popup.goToTrain')
                                        : t('stock.popup.goToTruck')}
                                    img={store.stockMovement.meanOfTransportation == 'Train'
                                        ? <Train className={classes.buttonPictogram} />
                                        : <LocalShippingOutlined className={classes.buttonPictogram} />}
                                    onClick={openTruck}
                                    className={classes.buttonLink} />
                                : null}
                            {store.associatedDeal
                                ? <Button
                                    onClick={openDeal}
                                    label={t('stock.popup.goToDeal')}
                                    img={<GavelOutlined className={classes.buttonPictogram} />}
                                    className={classes.buttonLink} />
                                : null}
                        </Paper>
                        {!isBatchMovement && !store.stockMovement.nomination
                            && (store.stockMovement.movementType == MovementType.Sale)
                            && hasFeature('TransferSapFlowInSaleAndPurchase')
                            && store.stockMovement.secondSapFlow != null
                            && <Paper className={classes.movementInformation}>
                                <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                                    {`${t('stock.label.movement.sap')} - ${MovementTypeNameFromMovementType(store.stockMovement.secondSapFlow?.movementType)}`}
                                </Typography>
                                <SapForm isMainSapFlow={false} />
                            </Paper>
                        }

                        {!isBatchMovement && !store.stockMovement.nomination
                            && store.stockMovement.movementType !== MovementType.Untriggered
                            && <Paper className={classes.movementInformation}>
                                <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                                    {`${t('stock.label.movement.sap')} - ${MovementTypeNameFromMovementType(store.stockMovement.mainSapFlow.movementType)}`}
                                </Typography>
                                <SapForm isMainSapFlow={true} />
                            </Paper>
                        }
                        {!isBatchMovement && !store.stockMovement.nomination
                            && (store.stockMovement.movementType == MovementType.Purchase)
                            && hasFeature('TransferSapFlowInSaleAndPurchase')
                            && store.stockMovement.secondSapFlow != null
                            && <Paper className={classes.movementInformation}>
                                <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                                    {`${t('stock.label.movement.sap')} - ${MovementTypeNameFromMovementType(store.stockMovement.secondSapFlow?.movementType)}`}
                                </Typography>
                                <SapForm isMainSapFlow={false} />
                            </Paper>
                        }

                        {hasFeature('SelectPricingType')
                            ? <SelectPricingType classes={classes} pricingTypes={store.dealPricingTypes} />
                            : undefined}
                        {hasFeature('AssignDealFromMovement')
                            ? <SelectAssignableDeals classes={classes}
                                setOpenAssignDealPopup={store.setOpenAssignDealPopup}
                                setDealId={store.setDealIdToAssign}
                                setIsConfirmDealOpen={store.setIsConfirmDealOpen}
                                products={store.products.map(x => { return { code: x.code, id: x.id } })}
                                counterpartys={store.counterpartys.map(x => { return { name: x.name, id: x.id } })}
                                openAssignDealPopup={store.openAssignDealPopup}
                                assignableDealsFromMovement={store.assignableDealsFromMovement} />
                            : undefined}
                        <SelectAssignableVessels classes={classes} />
                        <ConfirmInexistantStockSave classes={classes} onConfirm={handleSave} />
                        <ConfirmDealAssignation
                            setIsConfirmDealOpen={store.setIsConfirmDealOpen}
                            isConfirmDealOpen={store.isConfirmDealOpen}
                            setOpenAssignDealPopup={store.setOpenAssignDealPopup}
                            setDealIdToAssign={store.setDealIdToAssign}
                            trySave={store.trySave}
                            createDealFromMovement={store.createDealFromMovement}
                            openAssignDealPopup={store.openAssignDealPopup}
                            assignableDealsFromMovement={store.assignableDealsFromMovement}
                            assignDealFromMovement={store.assignDealFromMovement}
                            shouldChangeStatus={store.stockMovement?.movementStatus == null}
                            stockMovement={store.stockMovement}
                            dealIdToAssign={store.dealIdToAssign}
                        />
                        <ConfirmVesselAssignation />
                    </div>)
            }
        </Popup>
    )
}

function ConfirmInexistantStockSave({ onConfirm, classes }: { onConfirm: () => void } & MuiProps) {
    let [isOpen, setIsOpen] = useState<boolean>(false)
    let stockBoardStore = StockBoardContainer.useContainer()
    let store = StockMovementContainer.useContainer()

    let close = () => { setIsOpen(false) }

    let confirm = () => {
        close()
        onConfirm()
    }

    let generateText = () => {
        let isIn = stockBoardStore.switchFilter === stockBoardStore.inValue
        let productId = isIn ? store.stockMovement.destinationProductId : store.stockMovement.originProductId
        let productIndex = store.products.findIndex(x => x.id == productId)
        let product = productIndex == -1 ? '' : store.products[productIndex].code
        let dutyStatus = isIn ? store.stockMovement.destinationDutyStatus : store.stockMovement.originDutyStatus
        let companyIndex = store.companys.findIndex(x => x.code == store.stockMovement.company)
        let company = companyIndex == -1 ? '' : store.companys[companyIndex].name
        let site = isIn ? store.stockMovement.destinationSite : store.stockMovement.originSite

        return t('stock.label.movement.confirmInexistingStockContent', { product: product, dutyStatus: dutyStatus, company: company, site: site })
    }

    return (
        <Dialog open={isOpen} onClose={close} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogTitle>{t('stock.label.movement.confirmInexistingStockTitle')}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant='body1'>
                    {generateText()}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.marginTop1em}>
                <Button onClick={close}
                    label={t('components.dialogClose')}
                    color='primary'
                    className={classes.cancelButton} />
                <Button onClick={confirm}
                    label={t('components.dialogConfirm')}
                    color='primary'
                    className={classes.confirmButton} />
            </DialogActions>
        </Dialog>
    )
}

let styles = () =>
    createStyles({
        paperTitle: {
            color: defaultColors.red.main.color
        },
        movementInformation: {
            padding: '0.5em',
            marginTop: '1em',
            marginBottom: '0.5em',
        },
        movementTypeSelection: {
            padding: '0.5em',
            marginTop: '1em',
            marginBottom: '0.5em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            margin: '1em'
        },
        buttonLink: {
            ...defaultStyles.primaryButton,
            marginTop: '2em',
            marginLeft: '1em'
        },
        buttonPictogram: {
            marginRight: '0.5em'
        },
        documentButton: {
            marginRight: '0.5em',
            marginLeft: '1em'
        },
        buttonDiv: {
            ...defaultStyles.flexRow,
            width: '100%',
            justifyContent: 'space-between'
        },
        buttonDiv2: {
            ...defaultStyles.flexRow,
        },
        confirmButton: { ...defaultStyles.dialogPrimaryButton },
        cancelButton: { ...defaultStyles.dialogCloseButton },
        dialogContent: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        cellPadding: {
            padding: '0px 15px 0px 15px !important'
        },
        headerPadding: {
            padding: '10px 15px 10px 15px !important'
        }
    })

export default withStyles(styles, muiOptions)(StockMovementEdit)
