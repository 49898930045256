import { muiOptions } from '../../../../infrastructure/materialUiThemeProvider'
import { withStyles, createStyles, Tooltip, Typography, IconButton } from '@material-ui/core'
import React from 'react'
import { TruckTransportContainer } from '../../truckStore'
import { MyLocation, PanTool, LocalShipping, VerifiedUser, CheckCircle, Train } from '@material-ui/icons'
import { truckStatuses } from '../../truckModels'
import { FeatureContainer } from '../../../../infrastructure/feature'
import { defaultColors, defaultStyles } from '../../../../infrastructure/materialUiThemeProvider'

function _TruckStatusFilter({ classes }) {
    let features = FeatureContainer.useContainer()
    let store = TruckTransportContainer.useContainer()
    let t = store.t
    let filtersStore = store.filters
    let tradPrefix = 'trucks.form.steps.'

    function isInFilters(stepStatus: typeof truckStatuses[number]) {
        let indexStepStatus = filtersStore.fields.truckStatuses.findIndex(x => x === stepStatus)
        return indexStepStatus >= 0
    }

    function getClass(stepStatus: typeof truckStatuses[number]) {
        return isInFilters(stepStatus)
            ? classes.activeStatusIcon
            : classes.disabledStatusIcon
    }

    function shouldAddBorderStatus(): boolean {
        if (store.isRailCar)
            return features.hasFeature('RailBorder')
        return features.hasFeature('TruckBorder')
    }

    function shouldAddBorder2Status(): boolean {
        if (store.isRailCar)
            return features.hasFeature('RailBorder2')
        return features.hasFeature('TruckBorder2')
    }

    return (
        <div className={classes.truckStatusFilterContainer}>
            <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'loaded')}</Typography>} placement='top'>
                <IconButton className={getClass('loaded')} onClick={_ => filtersStore.changeTruckStatuses('loaded')} size={'small'}>
                    {store.isRailCar
                        ? <Train />
                        : <LocalShipping />}
                </IconButton>
            </Tooltip>
            {shouldAddBorderStatus() ? <>
                <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'arrivedBorder')}</Typography>} placement='top'>
                    <IconButton className={getClass('arrivedBorder')} onClick={_ => filtersStore.changeTruckStatuses('arrivedBorder')} size={'small'}>
                        <PanTool />
                    </IconButton>
                </Tooltip>
                <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'passedBorder')}</Typography>} placement='top'>
                    <IconButton className={getClass('passedBorder')} onClick={_ => filtersStore.changeTruckStatuses('passedBorder')} size={'small'}>
                        <VerifiedUser />
                    </IconButton>
                </Tooltip></> : null
            }
            {shouldAddBorder2Status() ? <>
                <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'arrivedBorder2')}</Typography>} placement='top'>
                    <IconButton className={getClass('arrivedBorder2')} onClick={_ => filtersStore.changeTruckStatuses('arrivedBorder2')} size={'small'}>
                        <PanTool />
                    </IconButton>
                </Tooltip>
                <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'passedBorder2')}</Typography>} placement='top'>
                    <IconButton className={getClass('passedBorder2')} onClick={_ => filtersStore.changeTruckStatuses('passedBorder2')} size={'small'}>
                        <VerifiedUser />
                    </IconButton>
                </Tooltip></> : null
            }
            <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'arrived')}</Typography>} placement='top'>
                <IconButton className={getClass('arrived')} onClick={_ => filtersStore.changeTruckStatuses('arrived')} size={'small'}>
                    <MyLocation />
                </IconButton>
            </Tooltip>
            <Tooltip title={<Typography variant='body1'>{t(tradPrefix + 'offloaded')}</Typography>} placement='top'>
                <IconButton className={getClass('offloaded')} onClick={_ => filtersStore.changeTruckStatuses('offloaded')} size={'small'}>
                    <CheckCircle />
                </IconButton>
            </Tooltip>
        </div >
    )
}

let styles = theme =>
    createStyles({
        truckStatusFilterContainer: {
            ...defaultStyles.flexRow,
            margin: '0.3em 0 0.3em 0'
        },
        activeStatusIcon: {
            height: '2em',
            width: '2em',
            color: defaultColors.darkBlue.light.color
        },
        disabledStatusIcon: {
            height: '2em',
            width: '2em',
            color: defaultColors.grey.light.color
        },
    })

export let TruckStatusFilter = withStyles(styles, muiOptions)(_TruckStatusFilter)