import React from 'react'
import { withStyles, IconButton, createStyles } from '@material-ui/core'
import { muiOptions } from '../../materialUiThemeProvider'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined'
import { NotificationContainer } from './container'
import { DropDown } from './dropdown'
import { WithBadge } from './badge'
import { globalStyles } from './styles'

let _NotificationBell = React.memo(({ classes }: { classes?: any }) => {
    let state = NotificationContainer.useContainer()

    async function onClick() {
        state.load().then(_ => state.setIsOpen(!state.isOpen))
    }

    return (
        <WithBadge state={state}>
            <DropDown notifications={state.list} isOpen={state.isOpen} setIsOpen={state.setIsOpen} classes={classes}>
                <IconButton className={classes.bell + (state.list.length > 0 ? ' ' + classes.jiggleBell : '')} onClick={onClick}>
                    <NotificationsIcon />
                </IconButton>
            </DropDown>
        </WithBadge>
    )
}, () => true)

let notificationStyles = _ => createStyles(globalStyles)

export let NotificationBell = withStyles(notificationStyles, muiOptions)(_NotificationBell)