import { createStyles } from '@material-ui/core'
import { defaultStyles, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { stepperStyle } from '../../common/components/stepper'

export const styles = theme =>
    createStyles({
        paper: {
            padding: '1em',
            paddingTop: 0,
            marginBottom: '1em',
            '& > div': {
                marginTop: '1em'
            }
        },
        titleRow: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        documentButton: {
            marginTop: '0.5em'
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            marginRight: '3em'
        },
        stepperContainer: {
            width: '94%',
            marginLeft: '3em',
            '& *[class*="iconContainer"]': {
                [theme.breakpoints.up(900)]: {
                    marginLeft: '0.3em'
                },
                [theme.breakpoints.up(1400)]: {
                    marginLeft: 0
                }
            }
        },
        stepperFieldContainer: {
            margin: '1em 0'
        },
        stepContent: {
            paddingLeft: '3em',
            marginBottom: '-2em',
            paddingTop: '1em',
            paddingBottom: '2em'
        },
        labelCompleted: {
            color: defaultColors.darkBlue.light.color
        },
        stepperButtonContainer: {
            marginTop: '2em',
            '& > *': {
                marginRight: '2em'
            }
        },
        nextStepButton: {
            ...defaultStyles.primaryButton
        },
        previousStepButton: {
            transition: 'opacity 0.2s',
            ...defaultStyles.secondaryButton
        },
        lastPreviousButton: {
            marginLeft: '6em',
            marginTop: '-2em'
        },
        shown: {
            opacity: 1,
            pointer: 'cursor'
        },
        hidden: {
            opacity: 0,
            pointer: 'default',
            pointerEvents: 'none'
        },
        almostHidden: {
            opacity: 0.2,
            pointer: 'default',
            pointerEvents: 'none'
        },
        stepperConnector: {
            borderLeft: '0'
        },
        connectorInactive: {
            borderLeft: 'solid 4px #ccc'
        },
        connectorActive: {
            borderLeft: 'solid 4px ' + defaultColors.darkBlue.light.color
        },
        stepIcon: {
            width: '1em',
            height: '1em',
            marginRight: '0.5em',
            backgroundColor: '#ccc',
            color: 'white',
            borderRadius: '50%',
            padding: '0.5em',
            margin: '-0.5em 0',
            marginLeft: '-0.4em',
            zIndex: 2,
            boxSizing: 'content-box'
        },
        stepIconActive: {
            backgroundColor: defaultColors.darkBlue.light.color
        },
        stepIconCurrent: {
            backgroundColor: defaultColors.grey.light.color,
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
        },
        stepTitle: {
            fontSize: '115%'
        },
        stepSubtitle: {
            color: defaultColors.grey.main.color,
            fontWeight: 'normal',
        },
        buttonLink: {
            ...defaultStyles.primaryButton,
            marginTop: '2em',
            marginLeft: '1em'
        },
        ...stepperStyle,
        truckCommentRow: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '1em'
        }
    })