import { RefObject, useImperativeHandle, useRef, useState } from 'react'
import { createContainer } from 'unstated-next'
import { SnackBarVariant, CustomSnackbarProps, SnackbarButton } from '../app/common/customComponents'
import { get } from './api'
import { t } from './i18nextHelper'
import guid, { Guid } from './guid'

type SnackBarRef = {
    info: SnackbarCall
    success: SnackbarCall
    error: SnackbarCall
    warning: SnackbarCall
    _show: any
    closeCallback: any
    report: any
}

type SnackbarCall = (message: string, onClose?: () => void, button?: SnackbarButton) => void

let snacbarRef: RefObject<SnackBarRef> | null = null

export let snackbars = {
    info: (message, onClose?, button?) => snacbarRef?.current?.info(message, onClose, button),
    success: (message, onClose?, button?) => snacbarRef?.current?.success(message, onClose, button),
    error: (message, onClose?, button?) => snacbarRef?.current?.error(message, onClose, button),
    warning: (message, onClose?, button?) => snacbarRef?.current?.warning(message, onClose, button),
    _show: (message, onClose, button) => snacbarRef?.current?._show(message, onClose, button),
    closeCallback: (index) => snacbarRef?.current?.closeCallback(index),
    report: (message, onClose, button) => snacbarRef?.current?.report(message, onClose, button),
}

function useSnackbars() {
    let [snackbars, setSnackbars] = useState<CustomSnackbarProps[]>([])

    let closeCallback = (index: Guid, onClose?: () => void) => {
        let i = snackbars.findIndex(x => x.index === index)
        snackbars.splice(i, 1)
        setSnackbars(snackbars.map(x => x))
        if (onClose != undefined) onClose()
    }

    let _show = (message: string, variant: SnackBarVariant, onClose?: () => void, button?: SnackbarButton) => {
        let now = Date.now()
        let alreadyExists = (x: CustomSnackbarProps) => now - x.timeCreated < 1000 && message === x.message

        if (snackbars.some(alreadyExists))
            return

        snackbars.unshift({
            message: message,
            variant: variant,
            index: guid.createNew(),
            closeCallback: onClose,
            button: button,
            timeCreated: now
        })
        setSnackbars(snackbars.map(x => x))
    }

    let report = async (correlationId: Guid): Promise<boolean> => {
        let results = await get<Report[]>('report?correlationId=' + correlationId)
        results.forEach(r => {
            let show =
                r.level === 'error' ? error
                    : r.level === 'warning' ? warning
                        : r.level === 'success' ? success : info
                        
            let displayedMessage = r.vars.hasOwnProperty("__appendAfter__") ?
                t(r.translationCode) + " " + r.vars.__appendAfter__ :
                t(r.translationCode, r.vars)

            show(displayedMessage)
        })

        return results.length > 0
    }

    let info: SnackbarCall = (message, onClose, button) => _show(message, SnackBarVariant.Info, onClose, button)
    let success: SnackbarCall = (message, onClose, button) => _show(message, SnackBarVariant.Success, onClose, button)
    let error: SnackbarCall = (message, onClose, button) => _show(message, SnackBarVariant.Error, onClose, button)
    let warning: SnackbarCall = (message, onClose, button) => _show(message, SnackBarVariant.Warning, onClose, button)

    snacbarRef = useRef<SnackBarRef | null>(null)
    useImperativeHandle(snacbarRef, () => ({ info, success, error, warning, _show, closeCallback, report, }))
    return { snackbars, info, success, error, warning, _show, closeCallback, report }
}

export let SnackbarContainer = createContainer(useSnackbars)

type Report = {
    correlationId: Guid
    level: SuccessResult
    translationCode: string
    vars: any
    createAt: Date
}

type SuccessResult = 'error' | 'warning' | 'success' | 'info'