import React, { useState } from 'react'
import moment from 'moment'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions, MuiProps, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { Button, MonthPicker } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import { formatFilename } from '../../../infrastructure/excelExport'
import * as api from '../../../infrastructure/api'
import { reportStyles, ReportShell } from './_common'

function _securityStock({ classes }: MuiProps) {
    let currentMonth = moment().format('MM/DD/YYYY')
    let [securityStockFromDate, setsecurityStockFromDate] = useState<string | null>(currentMonth)

    let generateSecurityStock = async () => {
        if (!securityStockFromDate) return

        let filename = formatFilename(`${moment(securityStockFromDate).format('MM-YYYY')}_SecurityStock.xlsx`)
        await api.post('report/securityStock', { month: securityStockFromDate, filename: filename })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')
    }

    return (
        <ReportShell title={t('report.securityStock')} displayError={false}>
            <div className={classes.form}>
                <Button className={classes.generateButton}
                    label={t('report.export')}
                    img='/static/images/excel_red.svg'
                    onClick={generateSecurityStock} />
            </div>
            <MonthPicker classesOverride={{ datepicker: classes.datePicker }} date={securityStockFromDate}
                label={t('report.month')} setDate={setsecurityStockFromDate} />
        </ReportShell>
    )
}

let styles = theme => createStyles({
    generateButton: defaultStyles.secondaryButton,
    datePicker: reportStyles.datePicker,
    form: reportStyles.form,
})

export default withStyles(styles, muiOptions)(_securityStock)