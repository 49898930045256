import React, { useState } from 'react'
import { createStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Add } from '@material-ui/icons'
import {
    Button, Dialog, DialogTitle,
    DialogContent, DialogActions, Checkbox, ListItemText
} from '@material-ui/core'
import { muiOptions, MuiProps, defaultStyles, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { hasFeature } from '../../../infrastructure/feature'
import { PurchaseMovements } from './_vesselPurchaseMovementSection'
import { VesselProduct, PurchaseMovementType } from '../vesselModels'
import { VesselEditContainer } from './vesselEditStore'
import { useActionDebounce } from '../../common/debounce'

let tBase = 'vessels.label.purchaseMovement.'

function purchaseMovementTypes() {
    let allPurchaseMovementTypes: PurchaseMovementType[] = ["FormulaPrice"]
    if (hasFeature('PricingTrigger')) allPurchaseMovementTypes.push("Trigger")
    if (hasFeature('PricingFixedPrice')) allPurchaseMovementTypes.push("FixedPrice")
    return allPurchaseMovementTypes
}

function _PurchaseMovementTypes({ classes, product }: { product: VesselProduct } & MuiProps) {
    let vessel = VesselEditContainer.useContainer()
    let existingPurchaseMovementTypes = product.purchaseMovements.map(x => x.type).distinct()
    let allPurchaseMovementTypes = purchaseMovementTypes()
    let initialTypesToAdd = allPurchaseMovementTypes.filter(type => existingPurchaseMovementTypes.indexOf(type) == -1)
    let [purchaseMovementTypesDisplayed, setPurchaseMovementTypesDisplayed] = useState<PurchaseMovementType[]>(existingPurchaseMovementTypes)
    let [purchaseMovementTypesToAdd, setPurchaseMovementTypesToAdd] = useState<PurchaseMovementType[]>(initialTypesToAdd)
    let [isAddPurchaseTypeDialogOpen, setIsAddPurchaseTypeDialogOpen] = useState<boolean>(false)

    function addPurchaseMovementType() {
        setIsAddPurchaseTypeDialogOpen(true)
    }

    function addTypeBlocks(types: PurchaseMovementType[]) {
        setPurchaseMovementTypesDisplayed([...purchaseMovementTypesDisplayed].concat(types))
        setPurchaseMovementTypesToAdd([...purchaseMovementTypesToAdd.filter(type => types.indexOf(type) == -1)])
    }

    function handleRemoveBlock(type: PurchaseMovementType) {
        let blockToDisplay = purchaseMovementTypesDisplayed.filter(x => x != type)
        setPurchaseMovementTypesDisplayed(blockToDisplay)
        let typesToAdd = purchaseMovementTypesToAdd
        typesToAdd.push(type)
        setPurchaseMovementTypesToAdd([...typesToAdd])
        if (type === "FixedPrice")
            vessel.updateFixedPrice(product.id, null)
    }

    let shouldAddOnlyPurchaseMovement = vessel.movementStock.map(x => x.company).distinct().length == 1
        && vessel.movementStock.map(x => x.dutyStatus).distinct().length == 1

    if (!purchaseMovementTypesDisplayed)
        return (<div></div>)

    return (
        <div>
            <div className={classes.purchaseMovementHeader}>
                <Typography className={classes.mainTitle} variant='overline' display='block' gutterBottom>
                    {t(tBase + 'title')}
                </Typography>
                {purchaseMovementTypesToAdd.length > 0
                    ? <Button onClick={() => addPurchaseMovementType()} className={`${classes.addTypeButton} add-type-btn`} >
                        <Add /> {t(tBase + 'addPurchaseMovementType')}
                    </Button >
                    : undefined
                }
            </div>
            {
                purchaseMovementTypesDisplayed.map((type) =>
                    <PurchaseMovements key={type}
                        vesselProduct={product}
                        type={type}
                        hasOnlyOnePurchaseMovement={shouldAddOnlyPurchaseMovement}
                        canDeletePurchaseMovement={true}
                        removeBlock={handleRemoveBlock} />
                )
            }
            <AddPurchaseTypeDialog isOpen={isAddPurchaseTypeDialogOpen}
                types={purchaseMovementTypesToAdd}
                onClose={() => setIsAddPurchaseTypeDialogOpen(false)}
                onSubmit={(types: PurchaseMovementType[]) => addTypeBlocks(types)}
                classes={classes} />
        </div >
    )
}

type AddPurchaseTypeDialogProps = {
    isOpen: boolean
    types: PurchaseMovementType[]
    onClose: () => void
    onSubmit: (types: PurchaseMovementType[]) => void
} & MuiProps

function AddPurchaseTypeDialog({ isOpen, types, onClose, onSubmit, classes }: AddPurchaseTypeDialogProps) {
    let [selectedTypes, setSelectedTypes] = useState<PurchaseMovementType[]>([])

    let handleSelection = (type: PurchaseMovementType) => {
        let types = [...selectedTypes]
        if (types.findIndex(x => x == type) >= 0)
            types = types.filter(x => x != type)
        else
            types.push(type)

        setSelectedTypes(types)
    }

    let handleClose = () => {
        onClose()
        setSelectedTypes([])
    }

    let handleSubmitDebouncer = useActionDebounce(async () => {
        onSubmit(selectedTypes)
        handleClose()
    })

    return <Dialog open={isOpen} onClose={handleClose} >
        <DialogTitle>{t(tBase + 'typeDialog.title')}</DialogTitle>
        <DialogContent>
            {types.map(x =>
                <div key={x} className={classes.stockPurchaseMovementChoice} onClick={() => handleSelection(x)}>
                    <Checkbox checked={selectedTypes.findIndex(s => s == x) >= 0}
                        classes={{ checked: classes.checkboxChecked, root: classes.noPadding }}
                        color='default'
                        onChange={_ => { }} />
                    <ListItemText primary={t(tBase + 'types.' + x)} />
                </div>)}
        </DialogContent>
        <DialogActions>
            <Button className={classes.closeButton} onClick={handleClose} color='primary'>{t(tBase + 'typeDialog.cancelButton')}</Button>
            <Button className={classes.submitButton} onClick={handleSubmitDebouncer.execute}
                color='primary'>{t(tBase + 'typeDialog.addButton')}</Button>
        </DialogActions>
    </Dialog>
}

let styles = _ =>
    createStyles({
        mainTitle: {
            color: defaultColors.red.main.color
        },
        addTypeButton: {
            ...defaultStyles.secondaryButton,
            minWidth: '10em',
            marginRight: '5em'
        },
        tablePaper: {
            margin: '1em',
            padding: '1em'
        },
        stockPurchaseMovementChoice: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
        },
        purchaseMovementHeader: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between'
        },
        checkboxChecked: {
            color: defaultColors.red.main.color,
        }
    })

export let PurchaseMovementTypes = withStyles(styles, muiOptions)(_PurchaseMovementTypes)