var qs = require('qs');

type ArrayFormat = 'indices' | 'repeat'

export let queryStringBuilder = (object: any, arrayFormat?: ArrayFormat) => {
    let options = {
        encode: false,
        allowDots: true,
        arrayFormat: arrayFormat ?? 'indices',
        addQueryPrefix: true
    }

    return qs.stringify(object, options)
}