import { ChartData, ChartDataset } from 'chart.js'
import { t } from '../../../infrastructure/i18nextHelper'
import { prepareDataSet } from '../../common/components/chart'
import { ChartStockProjectionLine, ValueDate, StockProjectionMode } from '../stockModels'

export function createChart(props: Props): ChartData<'line', Array<number | null>, string> {
    return !props.lines || props.lines.length === 0 ? { labels: [], datasets: [] }
        : props.stockMode === StockProjectionMode.Each
            ? createEachModeData(props)
            : createSumModeData(props)
}

type Props = {
    lines: ChartStockProjectionLine[] | null
    securityStockLines: ValueDate[] | null
    minVolumes: ValueDate[] | null
    lowVolumes: ValueDate[] | null
    highVolumes: ValueDate[] | null
    maxVolumes: ValueDate[] | null
    displayByDays: boolean
    showLowHighs: boolean
    minDays: number | null
    stockMode: StockProjectionMode
    showTransitVolume: boolean
    withSimulation: boolean
}

function createSumModeData(props: Props): ChartData<'line', Array<number | null>, string> {
    let { lines, displayByDays, minDays, showTransitVolume, securityStockLines, minVolumes, lowVolumes, highVolumes, maxVolumes, showLowHighs } = props

    let line = lines?.find(x => x.withResellerRestriction)
    if (!lines || !line) throw 'no line'

    let datasets: ChartDataset<'line', Array<number | null>>[] = []

    if (displayByDays && minDays) {
        datasets.push(
            prepareDataSet({
                label: t('stock.chart.minDays'),
                data: lines[0].values.map(x => minDays),
                color: 'low'
            })
        )
    }

    if (minVolumes?.length && !displayByDays)
        datasets.push(prepareDataSet({
            label: t('stock.chart.min'),
            data: minVolumes.map(x => x.value),
            color: 'min',
            stack: 'min',
        }))

    if (lowVolumes?.length && !displayByDays && showLowHighs)
        datasets.push(prepareDataSet({
            label: t('stock.chart.low'),
            data: lowVolumes.map(x => x.value),
            color: 'low',
            stack: 'low',
        }))

    if (highVolumes?.length && !displayByDays && showLowHighs)
        datasets.push(prepareDataSet({
            label: t('stock.chart.high'),
            data: highVolumes.map(x => x.value),
            color: 'high',
            stack: 'high',
        }))

    if (maxVolumes?.length && !displayByDays)
        datasets.push(prepareDataSet({
            label: t('stock.chart.max'),
            data: maxVolumes.map(x => x.value),
            color: 'max',
            stack: 'max',
        }))

    let lineWtoRsl = lines?.find(x => !x.withResellerRestriction)
    if (lineWtoRsl)
        datasets.push(
            prepareDataSet({
                label: displayByDays ? t('stock.label.chart.days') : t('stock.chart.chartLegend'),
                data: lineWtoRsl.values.map(x => {
                    let value = displayByDays ? x.daysLeft : x.projectedStock
                    return value ? Math.round(value) : null
                }),
                color: mainBlue.line,
                backgroundColor: mainBlue.background,
                fill: 'start',
                stack: 'stack',
            }))

    let simulationLine = props.withSimulation ? lines?.find(x => x.simulatedValues.length > 0) : null
    if (simulationLine)
        datasets.push(
            prepareDataSet({
                label: displayByDays ? t('stock.label.chart.simulatedDays') : t('stock.chart.simulatedChartLegend'),
                data: simulationLine.simulatedValues.map(x => {
                    let value = displayByDays ? x.daysLeft : x.projectedStock
                    return value ? Math.round(value) : null
                }),
                color: simulatedBlue.line,
                backgroundColor: simulatedBlue.background,
                fill: 'start',
                dottedSmall: true,
                stack: 'simulation',
            }))

    datasets.push(
        prepareDataSet({
            label: (displayByDays ?
                t('stock.label.chart.days') : t('stock.chart.chartLegend')) +
                (lines.length > 1 ? ' (%Rsl)' : ''),
            data: line.values.map(x => displayByDays ? x.daysLeft : x.projectedStock),
            color: lineWtoRsl ? resellerBlue.line : mainBlue.line,
            backgroundColor: lineWtoRsl ? resellerBlue.background : mainBlue.background,
            fill: 'start',
            dotted: lines.length > 1,
            stack: 'stack'
        }))

    if (showTransitVolume && !displayByDays)
        datasets.push(
            prepareDataSet({
                label: t('stock.chart.inTransit'),
                data: line.values.map(x => (x.projectedStock ?? 0) >= 0 ? Math.round(x.volumeInTransit ?? 0) : null),
                color: transitLightBlue.line,
                backgroundColor: transitLightBlue.background,
                dotted: true,
                fill: '-1',
                stack: 'stack',
            })
        )

    if (securityStockLines?.length && !displayByDays)
        datasets.push(
            prepareDataSet({
                label: t('stock.chart.securityStock'),
                data: securityStockLines.map(x => x.value),
                color: '#000',
                stack: 'security',
            })
        )

    let labels = line.values.map(x => x.date ?? '')

    return { labels, datasets }
}

function createEachModeData(props: Props): ChartData<'line', Array<number | null>, string> {
    let { lines, displayByDays } = props
    if (!lines) throw 'no lines'

    let hasRslRestriction = lines.some(x => !x.withResellerRestriction)

    let sortBool = (a, b) => (a === b) ? 0 : a ? 1 : -1

    return {
        labels: lines[0].values.map(x => x.date ?? ''),
        datasets: lines
            .sort((a, b) => sortBool(a.withResellerRestriction, b.withResellerRestriction))
            .map(line =>
                prepareDataSet({
                    label: line.name
                        ? line.name + (hasRslRestriction && line.withResellerRestriction ? ' (%Rsl)' : '')
                        : t('stock.chart.chartLegend'),
                    data: line.values.map(val => displayByDays ? val.daysLeft : val.projectedStock),
                    color: line.colorCode || 'default',
                    dotted: hasRslRestriction && line.withResellerRestriction,
                }))
    }
}

const mainBlue = {
    line: 'rgb(73,101,181, 0.9)',
    background: 'rgb(73,101,181, 0.4)'
}

const simulatedBlue = {
    line: 'rgb(73,101,181, 0.5)',
    background: 'rgb(73,101,181, 0.2)'
}

const resellerBlue = {
    line: 'rgb(129,157,218, 0.9)',
    background: 'rgb(129,157,218, 0.2)'
}

const transitLightBlue = {
    line: 'rgb(184, 212, 254, 0.9)',
    background: 'rgb(184, 212, 254, 0.4)'
}