import React, { useEffect } from 'react'
import * as Icons from '@material-ui/icons'
import { Add } from '@material-ui/icons'
import { withStyles, createStyles, Typography, Paper } from '@material-ui/core'
import { muiOptions, defaultColors, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { formatFilename } from '../../../infrastructure/excelExport'
import * as api from '../../../infrastructure/api'
import { Button, SearchField, Menu } from '../../common/customComponents'
import { TruckFilter } from './filters/_truckFilters'
import { popupNavigator } from '../../../infrastructure/popupNavigator'
import { TruckTransportContainer } from '../truckStore'
import TruckTable from './table/truckTable'
import { TruckStatusFilter } from './filters/_truckStatusFilter'
import { Claims } from '../../../infrastructure/signIn/models';
import { hasClaim } from '../../../infrastructure/signIn/userContext';

function TruckTransportBoard({ classes }) {
    let store = TruckTransportContainer.useContainer()
    let t = store.t

    useEffect(() => {
        if (!store.form.isOpen && store.form.previousIsOpen)
            store.loadTable()
    }, [store.form.isOpen])

    let onValidatedQuery = () => store.filters.applySearchQuery()

    let OnQueryChanged = (e) => {
        if (e.key === 'Enter')
            return onValidatedQuery()
        else
            store.filters.setSearchQuery(e.target.value)
    }

    let uploadLoadingFile = e => {
        let uploadUrl = store.isRailCar
            ? 'truck/import/loadedRailCarTransport'
            : 'truck/import/loadedTruckTransport'

        api.upload(uploadUrl, e.target.files[0], 'import', { withReport: 'dialog' })
            .then(_ => store.loadTable())
    }

    let uploadOffloadingFile = e => {
        let uploadUrl = 'truck/import/offloadedTruckTransport'

        api.upload(uploadUrl, e.target.files[0], 'import', { withReport: 'dialog' })
            .then(_ => store.loadTable())
    }

    let exportTrucks = async () => {
        let truckIds = store.list.selectedIds

        if (truckIds.length === 0)
            truckIds = store.list.listItems.map(x => x.id)

        let filename = formatFilename('Truck.xlsx')
        await api.post('truck/export', { filename: filename, ids: truckIds })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')
    }

    return (
        <div className={classes.pageContainer}>
            <div className={classes.firstRow}>
                <Typography className={classes.pageTitle} variant='h5' display='block' gutterBottom>{t('trucks.name')}</Typography>
                <div><TruckFilter /></div>
            </div>
            <Paper className={classes.root + ' ' + classes.secondRow}>
                <div className={classes.tableHead}>
                    <div className={classes.leftButtonsContainer}>
                        <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>{t('trucks.table.title')}</Typography>
                        <TruckStatusFilter />
                    </div>
                    <div className={classes.rightButtonsContainer}>
                        <SearchField label={t('trucks.filters.search')} onChange={OnQueryChanged} onClick={onValidatedQuery} text={store.filters.searchQuery || ''} />
                        {hasClaim(Claims.TruckManager) && <Button
                            className={classes.openPopupButton}
                            label={t('trucks.table.createNewTruckTransport')}
                            img={<Add />}
                            onClick={() => popupNavigator.open('truck', null)} />}
                        <Menu
                            icon={<Icons.MoreVert />}
                            items={[
                                {
                                    text: t('trucks.table.uploadLoadingTruckTransport'),
                                    isUpload: true,
                                    onUpload: uploadLoadingFile,
                                    icon: <Icons.PublishOutlined className={classes.iconStyle} />,
                                },
                                {
                                    text: t('trucks.table.uploadOffloadingTruckTransport'),
                                    isUpload: true,
                                    onUpload: uploadOffloadingFile,
                                    icon: <Icons.PublishOutlined className={classes.iconStyle} />,
                                },
                                {
                                    text: t('trucks.table.export'),
                                    icon: <Icons.GetAppOutlined className={classes.iconStyle} />,
                                    onClick: exportTrucks,
                                    disabled: !store.list.listItems.length
                                }
                            ]}
                            classesOverride={{ button: classes.menuButton }} />
                    </div>
                </div>
                <TruckTable />
            </Paper>
        </div >
    )
}

let styles = _ =>
    createStyles({
        pageContainer: {
            display: 'grid',
            height: '100%',
            paddingBottom: '0.5em',
            'grid-template-areas': "'firstRow' 'secondRow'",
            'grid-template-columns': 'auto',
            'grid-template-rows': '4em minmax(0, 1fr)',
            'grid-gap': '0.8em',
            'overflow-y': 'hidden',
            'box-sizing': 'border-box',
            '&> div': {
                height: '100%',
                width: '100%'
            }
        },
        iconStyle: { height: '1.5rem', width: '1.5rem', marginTop: '-0.2rem' },
        pageTitle: {
            color: defaultColors.grey.dark.color,
            alignSelf: 'flex-start',
            marginRight: '2em'
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            padding: '0px',
            cursor: 'pointer',
            marginRight: '5px'
        },
        fileInput: {
            display: 'none'
        },
        buttonFullHeightLabel: {
            height: '100%',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
        },
        secondRow: {
            gridArea: 'secondRow'
        },
        firstRow: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'stretch',
            width: '100%',
            gridArea: 'firstRow',
            whiteSpace: 'nowrap'
        },
        table: {
            width: '100%',
            height: '100%'
        },
        tableHead: {
            ...defaultStyles.flexRow,
            paddingRight: '0',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.2em 1em'
        },
        paperTitle: {
            color: defaultColors.red.main.color
        },
        leftButtonsContainer: {
            ...defaultStyles.flexRow,
            '& > *': {
                marginLeft: '1em',
                '&:first-child': {
                    marginLeft: '0em'
                }
            }
        },
        rightButtonsContainer: {
            ...defaultStyles.flexRow,
            '& > *': {
                marginRight: '1em',
                '&:last-child': {
                    marginRight: '0em'
                }
            }
        },
        openPopupButton: {
            ...defaultStyles.primaryButton
        },
        root: {
            flexGrow: 2,
            width: '100%',
            overflow: 'auto'
        },
    })

export default withStyles(styles, muiOptions)(TruckTransportBoard)