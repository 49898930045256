import { useState } from "react";
import { get, post } from "../../../infrastructure/api";
import { createContainer } from "unstated-next";
import { changeI18NextLanguage, Language } from "../../../infrastructure/i18nextHelper";
import { UserSettings } from "../../../infrastructure/signIn/models";

export type UserSettingsChoice = { label: string, value: string, format: string, shortFormat?: string }

export const userSettingsKey = 'userSettings'

export const availableDateFormat: UserSettingsChoice[] = [
    { label: 'DD/MM/YYYY', value: 'Standard', format: 'DD/MM/YYYY', shortFormat: 'DD/MM' },
    { label: 'MM/DD/YYYY', value: 'US', format: 'MM/DD/YYYY', shortFormat: 'MM/DD' }]

export const availableDecimalSeparators: UserSettingsChoice[] = [
    { label: '123456.78', value: 'Dot', format: 'en-US' },
    { label: '123456,78', value: 'Comma', format: 'fr-FR' }]

export const availableLanguages: Language[] = [
    { code: 'en', label: 'English' },
    { code: 'fr', label: 'Français' },
    { code: 'pt', label: 'Português' }
]

const defaultDateFormat: UserSettingsChoice = availableDateFormat.find(x => x.value === 'US')!
const defaultDecimalSeparator: UserSettingsChoice = availableDecimalSeparators.find(x => x.value === 'Dot')!
const defaultUserSettings: UserSettings = { language: 'en', dateFormat: defaultDateFormat.value, decimalSeparator: defaultDecimalSeparator.value }

function useUserSettingsContext() {
    let [userSettings, setUserSettings] = useState<UserSettings>(getUserSettingsFromLocalStorage() ?? defaultUserSettings)

    async function changeUserSettings(newSettings: UserSettings) {
        applyUserSettingsLocaly(newSettings)
        await post('userSettings', newSettings)
    }

    async function fetchUserSettings() {
        var settings = await get<UserSettings | null>('userSettings')
        applyUserSettingsLocaly(settings ?? defaultUserSettings)
    }

    function applyUserSettingsLocaly(newSettings: UserSettings) {
        if (newSettings.language !== userSettings.language)
            changeLanguage(newSettings.language)

        setUserSettings(newSettings)
        localStorage.setItem(userSettingsKey, JSON.stringify(newSettings))
    }

    function changeLanguage(language: string) {
        changeI18NextLanguage(availableLanguages.find(x => x.code === language)?.code ?? 'en')
    }

    function propertyNullIfEmpty(prop: keyof UserSettings): string | null {
        return userSettings[prop] !== '' ? userSettings[prop] : null
    }

    function getUserSettingsFromLocalStorage(): UserSettings | null {
        let savedUserSettings = localStorage.getItem(userSettingsKey)
        return savedUserSettings ? JSON.parse(savedUserSettings) as UserSettings : null
    }

    function getUserSettingsDate(): UserSettingsChoice {
        let userSettings = getUserSettingsFromLocalStorage()
        return (availableDateFormat.find(x => x.value === userSettings?.dateFormat) ?? defaultDateFormat)
    }

    function getLanguage(): string { return propertyNullIfEmpty('language') ?? getUserSettingsFromLocalStorage()?.language ?? 'en' }

    function getDateFormat(): string { return propertyNullIfEmpty('dateFormat') ?? getUserSettingsDate().format }

    function getShortDateFormat(): string { return propertyNullIfEmpty('decimalSeparator') ?? getUserSettingsDate().shortFormat! }

    return { changeUserSettings, getDateFormat, getShortDateFormat, getLanguage, fetchUserSettings, ChangeLanguage: changeLanguage, userSettings }
}

export let UserSettingsContextContainer = createContainer(useUserSettingsContext)