import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { defaultStyles, muiOptions } from '../../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../../infrastructure/i18nextHelper'
import { FeatureContainer } from '../../../../infrastructure/feature'
import { StockMovementContainer } from '../stockMovementStore'
import * as Component from './_movementComponents'
import { switcherStyle } from './_commonStyle'

function _borrowForm({ classes }) {
    let { hasFeature } = FeatureContainer.useContainer()
    let store = StockMovementContainer.useContainer()
    let mvt = store.stockMovement

    return (
        <div>
            <div className={classes.row + ' firstPurchaseRow'}>
                <Component.ModeSwitcher
                    hideArrow={true}
                    initiallyDualMode={hasFeature('MirrorSwapMandatory')}
                    canSwitch={!hasFeature('MirrorSwapMandatory')}
                    forceDualMode={hasFeature('MirrorSwapMandatory')}
                    originComponent={<Component.InputDate label={t('stock.label.movement.borrowDate')} />}
                    destinationComponent={<Component.OutputDate label={t('stock.label.movement.loanDate')} disabled={!!mvt.stockOutputDate && !!mvt.mirrorMovementId} />}
                    singleModeComponent={<Component.Date isIn={true} />}
                    onResetDualMode={() => !!mvt.mirrorMovementId ? store.setStockMovement({ ...store.stockMovement, stockOutputDate: null }) : undefined}
                    tooltipText={t('stock.label.movement.changeDatesSelection')}
                    dualModeIconClassName={classes.switchModeButton}
                    singleModeIconClassName={classes.dateAdornment} />
                <Component.Product isIn={true} />
                <Component.DutyStatus isIn={true} />
                <Component.Company />
                <Component.Volume />
                {hasFeature('ObservedQuantityTemperature')
                    ? <>
                        <Component.ObservedTemperature />
                        <Component.ObservedVolume />
                    </>
                    : null}
            </div>
            <div className={classes.row + ' purchaseRow'}>
                <Component.ModeSwitcher
                    hideArrow={true}
                    canSwitch={true}
                    initiallyDualMode={!!mvt.originSite && !!mvt.destinationSite && mvt.originSite != mvt.destinationSite}
                    originComponent={<Component.DestinationSite label={t('stock.label.movement.borrowSite')} />}
                    destinationComponent={<Component.OriginSite label={t('stock.label.movement.loanSite')} />}
                    singleModeComponent={<Component.Site isIn={true} />}
                    onResetDualMode={() => store.setStockMovement({ ...mvt, originSite: null })}
                    tooltipText={t('stock.label.movement.changeSitesSelection')}
                    dualModeIconClassName={classes.switchModeButton}
                    singleModeIconClassName={classes.adornment} />
                <Component.Counterparty />
                <Component.Mot />
                <Component.Status />
            </div>
            <div className={classes.row}>
                <Component.Reference />
                <Component.ExternalReference />
                {hasFeature('MovementExternalNumber') && <Component.OriginExternalNumber />}
                <Component.MirrorMovementButton buttonClass={classes.secondaryButton} />
            </div>
            <div className={classes.row}>
                <Component.Comment />
            </div>
        </div>
    )
}

let styles = () =>
    createStyles({
        ...switcherStyle,
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1em'
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            margin: "1em"
        }
    })

export default withStyles(styles, muiOptions)(_borrowForm)
