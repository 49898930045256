import React, { useState, Fragment, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom';
import { Claims } from './models'
import { navigateTo, navigateBack } from '../navigation'
import { createStyles, withStyles, Paper, Input } from '@material-ui/core'
import { muiOptions, defaultStyles, MuiProps } from '../materialUiThemeProvider'
import { popupNavigator } from '../popupNavigator'
import { OpenPopup } from '../startup'
import { snackbars } from '../snackbars'
import { UserContextContainer, hasClaim, hasActualStockClaim } from './userContext'
import { api } from '../api'
import { FeatureContainer } from '../feature'
import { Button } from '../../app/common/customComponents';
import { UserSettingsContextContainer } from '../shell/userSettings/userSettingsStore';

const EnterKeyCode = 13

function _SignIn({ classes, location }: MuiProps & { location?: { state: { shouldNavigateBack: boolean } } }) {
    let userContext = UserContextContainer.useContainer()
    let userSettingsStore = UserSettingsContextContainer.useContainer()

    let feature = FeatureContainer.useContainer()
    let [canLogin, setCanLogin] = useState(true)
    let [username, setUsername] = useState('')
    let isDevEnvironment = useMemo(() => document.location.hostname.indexOf('localhost') != -1, [])

    let autoDev = async () => {
        try {
            setCanLogin(false)
            await userContext.authenticateAutoDev(username || 'admin')
            await userSettingsStore.fetchUserSettings()
            navigateFromClaim()
        } catch (ex) {
            if (ex.status === 401) {
                snackbars.error('Authentication failed!')
            } else if (ex.status === 403) {
                snackbars.error('Authentication problem, please contact the help desk at ms.digitalaccelerationteam@total.com')
            }
            setCanLogin(true)
        }
    }

    let signInDigitalPass = async () => {
        let { url } = await api.get<{ url: string }>('auth/digitalPass/url')
        window.location.href = url
    }

    let signInCognito = async () => {
        let { url } = await api.get<{ url: string }>('auth/cognito/url')
        window.location.href = url
    }

    let shouldNavigateBack = (): boolean =>
        !!location && !!location.state && !!location.state.shouldNavigateBack

    let navigateFromClaim = async () => {
        if (shouldNavigateBack())
            navigateBack()
        else if (hasClaim(Claims.ActualStockSiteReader) && !hasClaim(Claims.StockManager))
            navigateTo('/stock/input')
        else {
            navigateTo('/stock')
        }

        OpenPopup(popupNavigator.open)
    }

    return (
        <Fragment>
            <div className={classes.padding}>
                <Paper className={classes.linesContainer}>
                    <img className={classes.logo} src='../../../static/images/Logo.svg' />

                    {isDevEnvironment &&
                        <div className={classes.columnContainer + ' columnContainer'}>
                            <Input type='text'
                                value={username}
                                placeholder={'Login'}
                                onChange={x => setUsername(x.target.value)}
                            />
                            <Button
                                label='AUTO DEV'
                                className={classes.loginButton}
                                disabled={!canLogin}
                                onClick={autoDev} />
                        </div>
                    }
                    {feature.hasFeature('DigitalPass') ?
                        <div className={classes.columnContainer + ' columnContainer'}>
                            <Button label='Digital Pass'
                                className={classes.digitalPassButton}
                                onClick={signInDigitalPass} />
                        </div>
                        : null}
                    {feature.hasFeature('Cognito') ?
                        <div className={classes.columnContainer + ' columnContainer'}>
                            <Button label='Cognito'
                                className={classes.digitalPassButton}
                                onClick={signInCognito} />
                        </div>
                        : null}
                </Paper>
            </div>
        </Fragment>
    )
}

function _AuthCallback({ locationState }: MuiProps & { locationState?: { state: { shouldNavigateBack: boolean } } }) {
    let userContext = UserContextContainer.useContainer()
    let feature = FeatureContainer.useContainer()
    let location = useLocation()
    let [isLoaded, setIsLoaded] = useState<boolean>(false)

    useEffect(() => setIsLoaded(true), [])
    useEffect(() => { if (isLoaded) signIn() }, [isLoaded])

    let signIn = async () => {
        let authorizationCode = new URLSearchParams(location.search).get('code')
        if (authorizationCode == null) {
            snackbars.error('Authentication failed!')
            navigateTo('/signin')
            return
        }

        let state = new URLSearchParams(location.search).get('state')
        let isCognito = state != null

        try {
            if (!isCognito) {
                await userContext.authenticateDigitalPass(authorizationCode)
            } else {
                await userContext.authenticateCognito(authorizationCode, state!)
            }
            navigateFromClaim()
        } catch (ex) {
            if (ex.status === 401) {
                snackbars.error('Authentication failed!')
            } else if (ex.status === 403) {
                snackbars.error('Authentication problem, please contact the help desk at ms.digitalaccelerationteam@total.com')
            }
        }
    }

    let shouldNavigateBack = (): boolean =>
        !!locationState && !!locationState.state && !!locationState.state.shouldNavigateBack

    let navigateFromClaim = async () => {
        if (shouldNavigateBack())
            navigateBack()
        else if (hasActualStockClaim() && !hasClaim(Claims.StockManager))
            navigateTo('/stock/input')
        else {
            navigateTo('/stock')
        }

        await feature.reload()
        OpenPopup(popupNavigator.open)
    }

    return (<></>)
}

let styles = theme =>
    createStyles({
        padding: {
            paddingTop: '5em',
            boxSizing: 'border-box'
        },
        linesContainer: {
            ...defaultStyles.flexColumn,
            justifyContent: 'space-around',
            width: '30%',
            minWidth: '375px',
            margin: 'auto',
            padding: '2%',
            minHeight: '30em'
        },
        logo: {
            width: '60%',
            marginBottom: '1em'
        },
        columnContainer: {
            ...defaultStyles.flexColumn,
            marginTop: '2%'
        },
        loginButton: {
            ...defaultStyles.primaryButton,
            margin: '1em',
            marginTop: '1.2em'
        },
        digitalPassButton: {
            ...defaultStyles.primaryButton,
            marginTop: '1.2em'
        }
    })

export let AuthCallback = withStyles(styles, muiOptions)(_AuthCallback)
export let SignIn = withStyles(styles, muiOptions)(_SignIn)