import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, createStyles, withStyles } from '@material-ui/core'
import { muiOptions, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { Button } from '../customComponents'
import { useActionDebounce } from '../debounce'

type DialogProps = {
    id?: string
    isOpen: boolean
    content?: JSX.Element
    contentText?: string
    content2Text?: string
    title: string
    confirmButtonText?: string
    yesButtonText?: string
    noButtonText?: string
    yesNo?: boolean
    onConfirm?: () => void
    onYes?: () => void
    onNo?: () => void
    onCancel: () => void
    onClose: () => void
    classes?: any
}

function _CustomDialog(props: React.PropsWithChildren<DialogProps>) {
    let handleOnConfirm = props.onConfirm !== undefined
        ? useActionDebounce(() => props.onConfirm!())
        : null
    let handleOnYes = props.onYes !== undefined
        ? useActionDebounce(() => props.onYes!())
        : null
    let handleOnNo = props.onNo !== undefined
        ? useActionDebounce(() => props.onNo!())
        : null
    let handleOnCancel = props.onCancel !== undefined
        ? useActionDebounce(() => props.onCancel!())
        : null

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            data-testid={props?.id}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.content ? props.content :
                    <DialogContentText id='alert-dialog-description'>
                        {props.contentText}
                        {props.content2Text ? <div>{props.content2Text}</div> : undefined}
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button className={props.classes.closeButton}
                    label={t('components.dialogCancel')}
                    onClick={handleOnCancel?.execute}
                    color='primary' />
                {props.yesNo ?
                    <>
                        <Button className={props.classes.confirmButton}
                            label={props.noButtonText || t('components.dialogNo')}
                            onClick={handleOnNo?.execute}
                            color='primary' />
                        <Button className={props.classes.confirmButton}
                            label={props.yesButtonText || t('components.dialogYes')}
                            onClick={handleOnYes?.execute}
                            color='primary' />
                    </>
                    : <Button className={props.classes.confirmButton}
                        label={props.confirmButtonText || t('components.dialogConfirm')}
                        onClick={handleOnConfirm?.execute}
                        color='primary'
                        autoFocus />}

            </DialogActions>
        </Dialog>
    )
}

let styles = _ => createStyles({
    confirmButton: {
        ...defaultStyles.dialogPrimaryButton
    },
    closeButton: {
        ...defaultStyles.dialogCloseButton
    }
})

export let CustomDialog = withStyles(styles, muiOptions)(_CustomDialog)