import React, { useState, useEffect } from 'react'
import { withStyles, SnackbarContent, IconButton, Snackbar } from '@material-ui/core'
import { defaultColors, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { createStyles } from '@material-ui/styles'
import * as Icons from '@material-ui/icons'
import { snackbars } from '../../../infrastructure/snackbars'
import { Guid } from '../../../infrastructure/guid'
import { Button } from '../customComponents'

export enum SnackBarVariant {
    Success = "success",
    Warning = "warning",
    Info = "info",
    Error = "error"
}

export type CustomSnackbarProps = {
    message: string
    variant: SnackBarVariant
    index: Guid
    closeCallback?: () => void
    button?: SnackbarButton
    timeCreated: Ticks
}

export type SnackbarButton = { label: string, action: () => void }

function _CustomSnackBar(props: CustomSnackbarProps & { classes: any }) {
    let [isOpened, setIsOpen] = useState<boolean>(false)
    let [closing, setClosing] = useState<boolean>(false)

    useEffect(() => { setIsOpen(true) }, [])

    useEffect(() => {
        if (!isOpened) return

        let timeBeforeCloseInMs = getTimingFromType(props.variant)
        if (timeBeforeCloseInMs === 'permanent') return

        let timer = setTimeout(() => {
            setClosing(true)
        }, timeBeforeCloseInMs);
        return () => clearTimeout(timer)
    }, [isOpened])

    useEffect(() => {
        if (closing)
            handleClose(null, "timer")
    }, [closing])


    let handleClose = (_, reason) => {
        if (reason === 'clickaway') return
        if (props.closeCallback != undefined) props.closeCallback()
        setIsOpen(false)
        setClosing(false)
        snackbars.closeCallback(props.index)
    }

    let getTimingFromType = (snackbarType: SnackBarVariant): SnackbarDuration => {
        switch (snackbarType) {
            case SnackBarVariant.Info: return 5000
            case SnackBarVariant.Error: return 5000
            case SnackBarVariant.Success: return 3000
            case SnackBarVariant.Warning: return 5000
        }
    }

    type SnackbarDuration = number | 'permanent'

    let iconVariant = (variant: SnackBarVariant) => {
        switch (variant) {
            case SnackBarVariant.Error:
                return <Icons.ErrorOutline className={props.classes.iconVariant} />
            case SnackBarVariant.Success:
                return <Icons.DoneOutline className={props.classes.iconVariant} />
            case SnackBarVariant.Warning:
                return <Icons.WarningOutlined className={props.classes.iconVariant} />
            case SnackBarVariant.Info:
                return <Icons.InfoOutlined className={props.classes.iconVariant} />
        }
    }
    return (
        <Snackbar
            id={`snackbar-${props.variant}`}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            open={isOpened}
            className={props.classes.snackbar} >
            <SnackbarContent
                className={props.classes[props.variant]}
                classes={{ message: props.classes.contentMessage }}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={props.classes.message}>
                        {iconVariant(props.variant)}
                        {props.message}
                    </span>
                }
                action={[
                    props.button ?
                        <Button key='action'
                            label={props.button.label}
                            className={props.classes.whiteButton}
                            onClick={props.button.action} /> : null,
                    <IconButton key="close" aria-label="close" color="inherit" onClick={() => handleClose('', 'userClosed')}>
                        <Icons.CloseOutlined />
                    </IconButton>
                ]}
            />
        </Snackbar >
    )
}

let styles = _ =>
    createStyles({
        success: { backgroundColor: defaultColors.green.light.color, },
        error: { backgroundColor: defaultColors.red.light.color, },
        info: { backgroundColor: defaultColors.lightBlue.main.color, },
        warning: { backgroundColor: defaultColors.orange.light.color, },
        icon: { fontSize: 20, },
        iconVariant: {
            opacity: 0.9,
            marginRight: '1em',
        },
        contentMessage: {
        },
        message: {
            display: 'flex',
            alignItems: 'center'
        },
        snackbar: {
            position: 'relative',
            width: '40em',
            marginTop: '0.2em',
            '& > div': {
                width: '90%'
            }
        },
        whiteButton: {
            color: 'white'
        }
    });

export let CustomSnackBar = withStyles(styles, muiOptions)(_CustomSnackBar)

type Ticks = number