import React, { Fragment, RefObject, useImperativeHandle, useRef, useState } from 'react'
import { withStyles, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { Button } from '../../../app/common/customComponents'
import { muiOptions, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import LanguageSelector from '../selectors/languageSelector'
import { UserSettingsSelector } from '../selectors/userSettingsSelector'
import { availableDateFormat, availableDecimalSeparators } from '../userSettings/userSettingsStore'

let dialogRef: RefObject<{ open: () => void }> | null = null

function _userSettingsDialog(props) {
    let [open, setOpen] = useState<boolean>(false)

    dialogRef = useRef<{ open: () => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: () => {
            setOpen(true)
        }
    }))

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}>
                <DialogTitle>{t('shell.userSettings.dialogTitle')}</DialogTitle>
                <DialogContent dividers={true} className={props.classes.noPadding + ' ' + props.classes.minSize}>
                    <div className={props.classes.container}>
                        <div className={props.classes.row}>
                            <Typography className={props.classes.label}>{t('shell.userSettings.languageLabel')}</Typography>
                            <LanguageSelector />
                        </div>
                        <div className={props.classes.row}>
                            <Typography className={props.classes.label}>{t('shell.userSettings.dateFormatLabel')}</Typography>
                            <UserSettingsSelector userSettingsKey='dateFormat' choices={availableDateFormat} />
                        </div>
                        <div className={props.classes.row}>
                            <Typography className={props.classes.label}>{t('shell.userSettings.decimalSeparatorLabel')}</Typography>
                            <UserSettingsSelector userSettingsKey='decimalSeparator' choices={availableDecimalSeparators} />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className={props.classes.closeButton}
                        label={t('shell.userSettings.dialogClose')}
                        onClick={() => setOpen(false)}
                        color='primary' />
                </DialogActions>
            </Dialog>
        </>
    )
}

let styles = () => createStyles({
    container: { ...defaultStyles.flexColumn },
    row: {
        ...defaultStyles.flexRow,
        margin: "1em",
    },
    label: {
        marginRight: '1.5em',
        minWidth: "11em"
    }
})

export let UserSettingsDialog = withStyles(styles, muiOptions)(_userSettingsDialog)
export let userSettingsDialog = { open: () => dialogRef?.current?.open() }

