import React, { useState } from 'react'
import moment from 'moment'
import { withStyles, createStyles, FormControlLabel, Checkbox, FormGroup } from '@material-ui/core'
import { muiOptions, MuiProps, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import { t } from '../../infrastructure/i18nextHelper'
import * as api from '../../infrastructure/api'
import { hasFeature } from '../../infrastructure/feature'
import { SnackbarContainer } from '../../infrastructure/snackbars'
import { DatePicker, MonthPicker } from '../common/customComponents'

export type PrimoExportParam = {
    doExport: boolean | null
    exportFinishedHandler: (closeDialog: boolean) => void
    defaultForcastStartDate: string | null
    defaultFromMonth: string | null
}

let _primoExport = ({ classes, doExport, exportFinishedHandler,
    defaultForcastStartDate, defaultFromMonth }: PrimoExportParam & MuiProps) => {
    let dateFormat = 'YYYY-MM-DD'
    let initialFromMonth = defaultFromMonth ?? moment().startOf('month').add(-1).startOf('month').format(dateFormat)
    let initialToMonth = moment(initialFromMonth).add(2, 'M').format(dateFormat)

    let [forecastStart, setForecastStart] = useState<string | null>(defaultForcastStartDate)
    let [fromMonth, setFromMonth] = useState<string | null>(initialFromMonth)
    let [toMonth, setToMonth] = useState<string | null>(initialToMonth)
    let [withMassive, setWithMassive] = useState<boolean>(true)
    let [withNonMassive, setWithNonMassive] = useState<boolean>(true)

    let snackbar = SnackbarContainer.useContainer()

    let generatePrimo = async () => {
        if (!withMassive && !withNonMassive) {
            snackbar.info(t('report.primo.noExportWithoutSelection'))
            exportFinishedHandler(false)
            return
        }

        let filename = (await api.get<{ filename: string }>('report/primo/filename')).filename
        await api.post('report/primo', {
            fromDate: fromMonth, endDate: toMonth,
            forecastStartDate: forecastStart, filename: filename, withMassive: withMassive,
            withNonMassive: withNonMassive
        })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')

        exportFinishedHandler(true)
    }

    if (doExport)
        generatePrimo()

    return (
        <div className={classes.container}>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox style={{ color: defaultColors.red.main.color }} checked={withMassive}
                        onChange={() => setWithMassive(!withMassive)} />}
                    label={t('report.primo.withMassive')} />
                <FormControlLabel
                    control={<Checkbox style={{ color: defaultColors.red.main.color }} checked={withNonMassive}
                        onChange={() => setWithNonMassive(!withNonMassive)} />}
                    label={t('report.primo.withNonMassive')} />
            </FormGroup>
            {hasFeature('MarketRiskPastForecast') &&
                <DatePicker classesOverride={{ datepicker: classes.datePicker }} date={forecastStart}
                    label={t('report.forecastStart')} setDate={newDate => setForecastStart(newDate)} size='standard' />}
            <MonthPicker classesOverride={{ datepicker: classes.datePicker }} date={fromMonth}
                label={t('report.fromMonth')} setDate={setFromMonth} size='standard' />
            <MonthPicker classesOverride={{ datepicker: classes.datePicker }} date={toMonth}
                label={t('report.toMonth')} setDate={setToMonth} size='standard' />
        </div>
    )
}

let styles = theme => createStyles({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    datePicker: {
        marginLeft: '1em'
    }
})

export default withStyles(styles, muiOptions)(_primoExport)