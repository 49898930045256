import React, { useEffect } from 'react'
import { withStyles, createStyles, Dialog, Toolbar, IconButton, Typography, AppBar, Slide, Fade, LinearProgress } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { muiOptions, defaultColors, defaultStyles, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { UserContextContainer } from '../../../infrastructure/signIn/userContext'
import { LoaderStatusContainer } from '../../../infrastructure/loader'
import { TransitionProps } from '@material-ui/core/transitions';
import { Button } from '../customComponents'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />
})

type PopupProps = {
    isOpen: boolean,
    close: () => void
    title: string
    buttons?: ButtonPopupParams[]
    children: JSX.Element,
    isBottomHalf?: boolean,
}

export type ButtonPopupParams = {
    buttonEffect?: () => void
    buttonText?: string
    hideButton?: boolean,
}

// todo: export state isOpen via useRef and remove prop
function _Popup(props: MuiProps & PopupProps) {
    let classes = props.classes
    let userContext = UserContextContainer.useContainer()
    let isOpen = userContext.isLoggedIn && props.isOpen
    let loader = LoaderStatusContainer.useContainer()

    return (
        <Dialog
            onClose={props.close}
            fullScreen
            open={isOpen}
            TransitionComponent={Transition}
            classes={{ paperFullScreen: classes.dialogRoot }}
            style={{
                top: 'auto',
                transform: 'translateY(0%)',
                height: !!props.isBottomHalf ? `calc(50% - 4em)` : '100%',
            }}>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.Toolbar + ' popupToolbar'}>
                    <div className={classes.ToolbarLeftContent}>
                        <IconButton edge='start' color='inherit' onClick={props.close} aria-label='close'>
                            <Icons.ExpandMore />
                        </IconButton>
                        <Typography variant='h6' className={classes.title} onClick={props.close}>{props.title}</Typography>
                    </div>
                    {(!!props.buttons && props.buttons.length > 0) && <div>
                        {props.buttons!
                            .filter((item: ButtonPopupParams) => !item.hideButton)
                            .map((item: ButtonPopupParams, index: number) => <Button
                                label={item.buttonText}
                                className={classes.actionButton}
                                onClick={item.buttonEffect}
                                key={index} />)}
                    </div>}
                </Toolbar>
                <Fade in={loader.isActive}>
                    <LinearProgress classes={{ barColorPrimary: classes.progressBar }}
                        className={classes.loader} />
                </Fade>
            </AppBar>
            <div className={classes.mainContent}>
                {props.children}
            </div>
        </Dialog>
    )
}

let styles = theme =>
    createStyles({
        dialogRoot: {
            backgroundColor: 'aliceblue'
        },
        appBar: {
            ...defaultStyles.flexColumn,
            backgroundColor: defaultColors.red.main.color,
            height: '2.6em',
            alignItems: 'stretch'
        },
        title: {
            cursor: 'pointer'
        },
        Toolbar: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            width: '98%',
            marginLeft: '-0.5em',
            height: '95%',
            minHeight: '30px'
        },
        actionButton: {
            marginRight: '-0.8em',
            color: defaultColors.red.dark.text + ' !important',
            marginLeft: '2em'
        },
        ToolbarLeftContent: {
            ...defaultStyles.flexRow
        },
        mainContent: {
            marginLeft: '1em',
            marginRight: '1em'
        },
        progressBar: {
            backgroundColor: defaultColors.darkBlue.main.color
        },
        loader: {
            backgroundColor: 'white',
            height: '3px',
            width: '105%'
        }
    })

export let Popup = withStyles(styles, muiOptions)(_Popup)