import React from "react"
import * as Icons from '@material-ui/icons'
import { Typography, Tooltip } from '@material-ui/core'
import { t } from '../../infrastructure/i18nextHelper'
import { MovementType, MovementTypeNameFromMovementType, MovementStatus } from "./stockModels"
import { PopupType } from '../../infrastructure/popupNavigator'
import { MovementStatusChip } from './statusChip'
import { muiOptions } from '../../infrastructure/materialUiThemeProvider'
import { withStyles, createStyles } from "@material-ui/core"

export function MovementTypeIcon({ movementType }: { movementType: MovementType }) {
    let tooltipText = MovementTypeNameFromMovementType(movementType ?? undefined)
    let icon = <></>

    switch (movementType) {
        case MovementType.Purchase:
            icon = <Icons.ShoppingCart />
            break
        case MovementType.Borrow:
        case MovementType.Loan:
            icon = <Icons.ImportExport />
            break
        case MovementType.Gains:
        case MovementType.Losses:
            icon = <Icons.VerticalAlignCenter />
            break
        case MovementType.Transfer:
            icon = <Icons.SwapHoriz />
            break
        case MovementType.Rebranding:
        case MovementType.StatusChange:
            icon = <Icons.Transform />
            break
        case MovementType.Sale:
            icon = <Icons.AttachMoney />
            break
        case MovementType.Untriggered:
            icon = <Icons.RemoveShoppingCart />
            break
        case MovementType.MktSale:
            icon = <Icons.LocalAtm />
            break
        default:
            icon = <></>
            break
    }

    return (
        <Tooltip title={<Typography variant='body1'>{tooltipText}</Typography>} placement='top'>
            {icon}
        </Tooltip>
    )
}

export let getMeansOfTransportationName = (meanOfTransportation: string): string => {
    switch (meanOfTransportation) {
        case 'InTank':
            return t('stock.label.movement.inTank')
        case 'Pipe':
            return t('stock.label.movement.pipe')
        case 'Road':
            return t('stock.label.movement.road')
        case 'Ship':
            return t('stock.label.movement.ship')
        case 'Train':
            return t('stock.label.movement.train')
        case 'X Pump':
            return t('stock.label.movement.xpump')
        case 'Barge':
            return t('stock.label.movement.barge')
        default:
            return ''
    }
}

type MovementMotIconType = {
    meanOfTransportation: string,
    tooltip?: string | null,
    onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void | undefined
}

export function MovementMotIcon({ meanOfTransportation, tooltip, onClick }: MovementMotIconType) {
    let tooltipText = getMeansOfTransportationName(meanOfTransportation)
    let icon = <></>

    switch (meanOfTransportation) {
        case 'InTank':
            icon = <Icons.ExitToApp />
            break
        case 'Pipe':
            icon = <Icons.LinearScale />
            break
        case 'Road':
            icon = <Icons.LocalShipping />
            break
        case 'Ship':
            icon = <Icons.DirectionsBoatOutlined />
            break
        case 'Train':
            icon = <Icons.Train />
            break
        case 'X Pump':
            icon = <Icons.SwapCalls />
            break
        case 'Barge':
            icon = <Icons.DirectionsBoat />
            break
        default:
            icon = <></>
            break
    }

    const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if (onClick) {
            onClick(event)
        }
    }

    return (
        <Tooltip title={<Typography variant='body1'>{tooltip ?? tooltipText}</Typography>} placement='top'>
            {onClick ? (
                <span onClick={handleClick} style={{ cursor: 'pointer' }}>
                    {icon}
                </span>
            ) : (
                icon
            )}
        </Tooltip>
    )
}

export let meanOfTransportationPopupType = (meanOfTransportation: string) => {
    switch (meanOfTransportation) {
        case 'Road':
            return 'truck' as PopupType
        case 'Ship':
            return 'vessel' as PopupType
        default:
            return ''
    }
}

export function StatusIcon({ movementStatus, classes }: { movementStatus: MovementStatus, classes?: any }) {
    if (movementStatus == null || movementStatus == MovementStatus.Unknown) return (<></>)
    return (
        <div className={classes.flexRow}>
            <MovementStatusChip status={movementStatus} fontSize='small' />
        </div>
    )
}

let styles = theme =>
    createStyles({
        flexRow: {
            display: 'flex',
            alignItems: 'center'
        }
    })

export default withStyles(styles, muiOptions)(StatusIcon)
