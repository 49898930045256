import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { defaultStyles, muiOptions, MuiProps } from '../../../../infrastructure/materialUiThemeProvider'
import * as Component from './_movementComponents'
import { hasFeature } from '../../../../infrastructure/feature'

function _gainsLossesForm({ isIn, classes }: { isIn: boolean } & MuiProps) {
    return (
        <div>
            <div className={classes.row}>
                <Component.Date isIn={isIn} />
                <Component.Product isIn={isIn} />
                <Component.DutyStatus isIn={isIn} />
                <Component.Company />
                <Component.Volume />
                {hasFeature('ObservedQuantityTemperature')
                    ? <>
                        <Component.ObservedTemperature />
                        <Component.ObservedVolume />
                    </>
                    : null}
            </div>
            <div className={classes.row}>
                <Component.Site isIn={isIn} />
                <Component.Status />
            </div>
            <div className={classes.row}>
                <Component.Reference />
                {hasFeature('MovementExternalNumber') && <Component.OriginExternalNumber />}
            </div>
            <div className={classes.row}>
                <Component.Comment />
            </div>
        </div>
    )
}

let styles = () =>
    createStyles({
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1em'
        },
    })

export default withStyles(styles, muiOptions)(_gainsLossesForm)
