import React, { useState, useEffect } from 'react'
import { withStyles, createStyles, Typography, Checkbox } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { api } from '../../../infrastructure/api'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, TableItem, TextField, NumberField, Select } from '../../common/customComponents'
import { MasterDataItem, MasterDataShell, createExcelLines } from './masterDataShell'
import { Company, DutyStatus } from 'src/app/stock/stockModels'
import { applyFilters } from '../../common/filtersHelper'

function CompanyDutyStatusMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [companyDutyStatuses, setCompanyDutyStatuses] = useState<TableItem<CompanyDutyStatus>[]>([])
    let [dutyStatus, setDutyStatus] = useState<DutyStatus[]>([])
    let [companies, setCompanies] = useState<Company[]>([])
    let [filters, setFilters] = useState<CompanyDutyStatusFilters>(noFilters)

    const tBase = 'admin.masterdata.companyDutyStatus.'

    let load = async () => {
        let companyDutyStatuses = api.get<TableItem<CompanyDutyStatus>[]>('masterdata/companydutystatus')
        setCompanyDutyStatuses(await companyDutyStatuses);
        let companies = api.get<Company[]>('masterdata/companydutystatus/company')
        let companiesPromise = await companies
        setCompanies(companiesPromise)
        setDutyStatus(companiesPromise.flatMap(x => x.dutyStatuses).distinct())
    }

    let getItems = () => applyFilters(companyDutyStatuses, filters).map(toTableItem)

    useEffect(() => {
        load()
    }, [])

    let onSave = async (item: CompanyDutyStatus & IsNew) => {
        await api.post('masterdata/companydutystatus', { companyDutyStatus: item, isNew: item.isNew })
        await load()
        return true
    }

    let onDelete = async (ids: string[]) => {
        let toDelete = companyDutyStatuses.filter(x => ids.find(id => id === generateIdString(x)))
        if (toDelete.length === 0) return false
        await api.del('masterdata/companydutystatus', { toDelete })
        await load()
        return true
    }

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'CompanyDutyStatuses.xlsx',
            sheets: [{ name: 'CompanyDutyStatuses', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let onImportExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/companydutystatus/import'
        api.upload(uploadUrl, file, 'companyDutyStatus', { withReport: 'dialog' }).then(_ => load())
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<CompanyDutyStatus>>>[] = [
        {
            name: t(tBase + 'companyCode'),
            value: x => x.companyCode,
            columnFilter: { value: filters.companyCode ?? '', onChange: (companyCode: string) => setFilters({ ...filters, companyCode }) }
        },
        {
            name: t(tBase + 'dutyStatus'),
            value: x => x.dutyStatus,
            columnFilter: { value: filters.dutyStatus ?? '', onChange: (dutyStatus: string) => setFilters({ ...filters, dutyStatus }) }
        },
        {
            name: t(tBase + 'color'),
            value: x => x.color,
            columnFilter: { value: filters.color ?? '', onChange: (color: string) => setFilters({ ...filters, color }) }
        },
        {
            name: t(tBase + 'order'),
            value: x => x.order.toString()
        }
    ]

    let isManager = hasClaim(Claims.MasterdataCompanyDutyStatusManager);

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={'companyDutyStatus-table'}
                headerLabel={t(tBase + 'companyDutyStatuses')}
                itemLabel={t(tBase + 'companyDutyStatus')}
                isManager={isManager}
                onExportExcel={exportExcel}
                onImportExcel={onImportExcel}
                onNew={emptyCompanyDutyStatus}
                onDelete={onDelete}
                onSave={onSave}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <Select label={t(tBase + 'companyCode')}
                                disabled={!isManager || !selectedItem.isNew}
                                value={selectedItem.companyCode}
                                choices={companies.map(x => ({ value: x.code, text: x.code }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, companyCode: val }) }} />
                            <Select label={t(tBase + 'dutyStatus')}
                                disabled={!isManager || !selectedItem.isNew}
                                value={selectedItem.dutyStatus}
                                choices={dutyStatus.map(x => ({ value: x, text: x }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, dutyStatus: val }) }} />
                            <TextField label={t(tBase + 'color')}
                                disabled={!isManager}
                                text={selectedItem.color}
                                onChange={event => setSelectedItem({ ...selectedItem, color: event.target.value })} />
                            <NumberField label={t(tBase + 'order')}
                                disabled={!isManager}
                                text={selectedItem.order}
                                onChange={event => setSelectedItem({ ...selectedItem, order: event })} />
                        </>
                    )}
            </MasterDataShell>
        </div >)
}

type CompanyDutyStatus = {
    companyCode: string
    dutyStatus: string
    color: string
    order: number
}

type IsNew = { isNew: boolean }

let emptyCompanyDutyStatus = (): CompanyDutyStatus & IsNew => ({
    companyCode: '',
    dutyStatus: '',
    color: '',
    order: 1,
    isNew: true
})

let generateIdString = (item: CompanyDutyStatus | TableItem<CompanyDutyStatus>): string => item.companyCode.concat(item.dutyStatus)

let toTableItem = (companyDutyStatus: CompanyDutyStatus): TableItem<MasterDataItem<CompanyDutyStatus>> => {
    return {
        id: generateIdString(companyDutyStatus),
        companyCode: companyDutyStatus.companyCode,
        dutyStatus: companyDutyStatus.dutyStatus,
        color: companyDutyStatus.color,
        order: companyDutyStatus.order,
        isModified: false
    }
};

type CompanyDutyStatusFilters = {
    companyCode: string | null,
    dutyStatus: string | null,
    color: string | null,
    order: number | null
}

let noFilters: CompanyDutyStatusFilters = {
    companyCode: null,
    dutyStatus: null,
    color: null,
    order: null
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
        isBooleanContainer: {
            ...defaultStyles.flexRow,
            width: '15.3em',
            justifyContent: 'space-between',
            "& >span": { padding: '0' }
        }
    })
export default withStyles(styles, muiOptions)(CompanyDutyStatusMasterData)