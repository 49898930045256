import { Guid } from '../../infrastructure/guid'
import { TableItem } from '../common/customComponents'
import { CostingInformation } from './vesselCostingInformationModels'

type StringDate = string

export type Vessel = {
    id: Guid
    name: string
    quantity: number
    originalLaycanStartDate: StringDate | null
    originalLaycanEndDate: StringDate | null
    revisedLaycanStartDate: StringDate | null
    revisedLaycanStartDateOverwritten: boolean
    revisedLaycanEndDate: StringDate | null
    revisedLaycanEndDateOverwritten: boolean
    etaDate: StringDate | null
    norDate: StringDate | null
    norDateOverwritten: boolean
    etaDateOverwritten: boolean
    berthingDate: StringDate | null
    berthingDateOverwritten: boolean
    completionOfDischargeDate: StringDate | null
    completionOfDischargeDateOverwritten: boolean
    availabilityDate: StringDate | null
    availabilityDateOverwritten: boolean
    bankGuaranteeDate: StringDate | null
    bankGuaranteeDateOverwritten: boolean
    certificateOfConformity: number
    paymentTerms: number | null
    paymentTermsDate: StringDate | null
    paymentTermsDateOverwritten: boolean,
    vettingNumber: string | null,
    hasClearance: boolean
    jettyCode: string | null
    assigneeUserName: string | null
    counterpartySupplierId: Guid
    products: VesselProduct[]
    status: VesselStatus
    canEdit: boolean
    internationalMaritimeOrganizationNumber: string | null
}

export type VesselListItem = {
    id: Guid
    name: string | null,
    revisedLaycanStartDate: StringDate | null
    revisedLaycanEndDate: StringDate | null
    regulatedPricePeriodStart: StringDate | null
    berthingDate: StringDate | null
    completionOfDischargeDate: StringDate | null
    etaDate: StringDate | null
    availabilityDate: StringDate | null
    referenceNumber: string | null
    products: string | null
    jetty: string | null
    quantity: number | null
    status: VesselStatus
    vesselTransportId: Guid | null
    etaDateOverwritten: boolean
    berthingDateOverwritten: boolean
    completionOfDischargeDateOverwritten: boolean
    availabilityDateOverwritten: boolean
    revisedLaycanStartDateOverwritten: boolean
    revisedLaycanEndDateOverwritten: boolean
    internationalMaritimeOrganizationNumber: string | null
}

export type VesselEditChangedDate = {
    field: string
    value: StringDate
}

export type EditedVesselItem = {
    vesselId: Guid,
    Dates: VesselEditChangedDate[]
}

export type TableVesselListItem = TableItem<VesselListItem>

export const vesselDatesFieldsNames: (keyof VesselListItem)[] = [
    'revisedLaycanStartDate', 'revisedLaycanEndDate',
    'etaDate', 'berthingDate',
    'completionOfDischargeDate', 'availabilityDate']

export const vesselDatesOverridesFieldsNames: (keyof VesselListItem | null)[] = [
    'revisedLaycanStartDateOverwritten', 'revisedLaycanEndDateOverwritten',
    'etaDateOverwritten', 'berthingDateOverwritten',
    'completionOfDischargeDateOverwritten', 'availabilityDateOverwritten']

export type VesselErrorDates = {
    vesselId: Guid
    isCodOnError: boolean
    isEtbOnError: boolean
}

export type Jetty = {
    code: string
    defaultSiteCode: string
    country: string
}

export type Quotation = {
    medecoCode: string
    longName: string
}

export enum VesselStatus {
    Forecast = 0,
    Planned = 1,
    Actual = 2
}

export enum CertificateOfConformity {
    NotStarted = 0,
    InProgress = 1,
    Completed = 2
}

export type PaymentTerm = { code: string, value: PaymentTerms }

export enum PaymentTerms {
    FixedDate = 0,
    BLPlus30 = 1,
    CoDPlus2 = 2,
    EtbMoins2 = 3,
    CoDMoins2 = 4,
    BankGuaranteePlus90 = 5,
    CoDPlus75 = 6,
    BlPlus60 = 7,
    CoDPlus7 = 8,
    CoDPlus14 = 9,
    CoDPlus21 = 10,
    FirstDayLaycanPlus60 = 11,
    NorDatePlus60 = 12,
    NorDatePlus30 = 13,
    NorDatePlus50 = 14,
    BlPlus45 = 15,
    BlPlus15 = 16,
}

export type VesselUser = {
    login: string
    fullName: string
}

export type Counterparty = {
    id: string
    name: string
    isInternal: boolean
}

export type CompanyName = {
    [code: string]: string
}

export type ProductCodes = {
    [id: string]: string
}

export type VesselProduct = {
    id: Guid
    productId: Guid
    productUnit: string
    referenceNumber: string | null
    trader: string | null
    loadPort: string | null
    billOfLadingDate: StringDate | null
    pricingStartDate: StringDate | null
    pricingEndDate: StringDate | null
    premium: number | null
    premiumCurrency: string | null
    triggerUnit: string | null
    fixedPriceUnit: string | null
    quotationCode: string | null
    billOfLading: ProductQuantity
    outturn: ProductQuantity
    reception: ProductQuantity
    company1Outturn: ProductQuantity
    company1Reception: ProductQuantity
    company2Outturn: ProductQuantity
    company2Reception: ProductQuantity
    purchaseMovements: PurchaseMovement[]
    costingInformation: CostingInformation
    sharingLineLoss: number | null
    canRemove: boolean
}

export type ProductQuantity = {
    quantity: number | null
    quantityOverwritten: boolean
    barrelQuantityFactor: number | null
    barrel: number | null
    barrelOverwritten: boolean
    volumeQuantityFactor: number | null
    volume: number | null
    volumeOverwritten: boolean
    industryVolume: number | null
}

export type PurchaseMovementType = "FormulaPrice" | "Trigger" | "FixedPrice"

export type PurchaseMovement = {
    id: Guid
    type: PurchaseMovementType
    companyCode: string
    dutyStatus: string
    nominatedQuantity: number | null
    remainingQuantity: number | null
    totalDispatchedQuantity: number | null
    percentageOfNominated: number | null
    inAlert: boolean | null
    sites: SitePurchaseMovement[]
    deadlineDate: StringDate | null
    purchaseContract: string | null
}

export enum MovementType {
    SaleType = 0,
    PurchaseType = 1,
    TransferType = 2
}

export type SitePurchaseMovement = {
    id: Guid
    isDefault: boolean
    volume: number | null
    volumeOverride: boolean
    quantity: number | null
    quantityOverride: boolean
    siteCode: string
    dealId: Guid | null
    dealReferenceNumber: string | null
    movementId: Guid | null
    availabilityDate: StringDate | null
    availabilityDateOverwritten: boolean
    premium: number | null
    fixedPrice: number | null
    fixedDate: string | null
    aseReference: string | null
    pricingFrom: string | null
    pricingTo: string | null
    contractualDensity: number | null
    contractualDensityOverwritten: boolean
    transporterId: Guid | null
    purchaseOrder: string | null
    movementType: MovementType | null
    transferDestinationSite: string | null
}

export type VesselFilter = {
    start?: StringDate | null
    end?: StringDate | null
    query?: string | null
    productId?: Guid[] | null
    jetty?: string[] | null
    perimeter: 'assigned' | 'country' | 'all'
}

export type Site = {
    code: string
    name: string
}

export type Stock = {
    site: string
    productId: Guid
    dutyStatus: string
    company: string
}

export type ComplementaryVesselFields =
    'billOfLadingQuantity'
    | 'billOfLadingVolume'
    | 'billOfLadingIndustryVolume'
    | 'billOfLadingBarrel'
    | 'billOfLadingQuantityFactor'
    | 'billOfLadingVolumeQuantityFactor'
    | 'outturnQuantity'
    | 'outturnVolume'
    | 'outturnIndustryVolume'
    | 'outturnBarrel'
    | 'outturnQuantityFactor'
    | 'outturnVolumeQuantityFactor'
    | 'receptionQuantity'
    | 'receptionVolume'
    | 'receptionIndustryVolume'
    | 'receptionBarrel'
    | 'receptionQuantityFactor'
    | 'receptionVolumeQuantityFactor'
    | 'outturnCompany1'
    | 'outturnCompany2'
    | 'receptionCompany1'
    | 'receptionCompany2'
    | 'vesselClearance'

export type ValueDate = {
    date: StringDate | null
    value: number | null
}

export type VesselSimulationDetails = {
    id: string,
    name: string | null,
    jetty: string | null,
    date: string | null,
    products: VesselSimulationProduct[]
}

export type VesselSimulationProduct = {
    id: string,
    code: string,
    unit: string,
    order: number,
    quantity: number | null,
}

export type SimulateVessel = {
    vesselId: string,
    availabilityDate: string | null,
    startDate: string | null,
    endDate: string | null,
    productVolumes: ProductVolume[]
}

export type ProductVolume = {
    productId: string,
    volume: number | null
}

export type SimulateVesselResult = {
    vesselId: string
    simulations: ProductVesselSimulation[]
}

export type ApplyVesselSimulationArgs = {
    availabilityDate: string
}

export type ProductVesselSimulation = {
    productId: string,
    productCode: string,
    projections: StockProjection[],
    minVolumes: { date: string, value: number }[]
    maxVolumes: { date: string, value: number }[]
}

export type StockProjection = {
    date: string,
    projectedStock: number | null,
    simulatedStock: number | null,
    dayLeft: number | null,
    simulatedDayLeft: number | null
}

export type PricingNeed = {
    value: number | null
    sitePurchaseMovementId: Guid
}