import React, { useEffect, useState } from "react"
import { createStyles, withStyles } from "@material-ui/core"
import { MuiProps, muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { MasterDataItem, MasterDataShell, createExcelLines } from "./masterDataShell"
import { ColumnDescriptor, TableItem, TextField, Select } from '../../common/customComponents'
import * as api from '../../../infrastructure/api'
import { Guid } from '../../../infrastructure/guid'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { t } from '../../../infrastructure/i18nextHelper'
import { SnackbarContainer } from '../../../infrastructure/snackbars'
import { DealTypes } from '../../deals/dealModels'
import { applyFilters } from '../../common/filtersHelper'

type CountryDealType = {
    id: Guid | null
    country: string
    dealType: string
    order: number
}

type Country = {
    name: string
}

type CountryDealTypeFilters = {
    country: string | null,
    dealType: string | null
}

let noFilters: CountryDealTypeFilters = {
    country: null,
    dealType: null
}

let toTableItem = (countryDealType: CountryDealType): TableItem<MasterDataItem<CountryDealType>> => {
    return {
        id: countryDealType.id!,
        country: countryDealType.country,
        dealType: countryDealType.dealType,
        order: countryDealType.order,
        isModified: false
    }
}

let emptyCountryDealType = () => ({
    id: '',
    country: '',
    order: 1
})

function DealTypeMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()
    let snackbar = SnackbarContainer.useContainer()

    let [countryDealTypes, setCountryDealTypes] = useState<CountryDealType[]>([])
    let [filters, setFilters] = useState<CountryDealTypeFilters>(noFilters)
    let [countrys, setCountrys] = useState<Country[]>([])

    let dealTypes = Object.keys(DealTypes).map(x => DealTypes[x])

    let tBase = 'admin.masterdata.dealType.'

    let load = async () => {
        setCountrys(await api.get<Country[]>('masterdata/site/country'))
        setCountryDealTypes(await api.get<CountryDealType[]>('masterdata/dealType'))
    }

    useEffect(() => {
        load()
    }, [])

    let getItems = () => applyFilters(countryDealTypes, filters).map(toTableItem)

    let onSaveHandler = async (item: CountryDealType) => {
        if (!item.country || !item.dealType) {
            snackbar.info(t(tBase + 'mandatoryFields'))
            return false
        }

        if (!item.order)
            item.order = 1

        await api.post('masterdata/dealtype', item)
        await load()
        return true
    }

    let onDeleteHandler = async (ids: string[]) => {
        await api.del('masterdata/dealType', { ids: ids })
        await load()
        return true
    }

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'DealTypes.xlsx',
            sheets: [{ name: 'DealTypes', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let isManager = hasClaim(Claims.MasterdataDealTypeManager)

    let columns: ColumnDescriptor<TableItem<MasterDataItem<CountryDealType>>>[] = [
        {
            name: t(tBase + 'country'),
            value: x => x.country,
            columnFilter: { value: filters.country ?? '', onChange: (country: string) => setFilters({ ...filters, country }) }
        },
        {
            name: t(tBase + 'dealType'),
            value: x => x.dealType,
            columnFilter: { value: filters.dealType ?? '', onChange: (dealType: string) => setFilters({ ...filters, dealType }) }
        },
        {
            name: t(tBase + 'order'),
            value: x => x.order
        }
    ]
    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId="dealType-table"
                headerLabel={t(tBase + 'dealTypes')}
                itemLabel={t(tBase + 'dealType')}
                isManager={isManager}
                columns={columns}
                items={getItems()}
                onSave={onSaveHandler}
                onNew={emptyCountryDealType}
                onDelete={onDeleteHandler}
                onExportExcel={exportExcel}
            >{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <Select label={t(tBase + 'country')}
                                disabled={!isManager}
                                value={selectedItem.country}
                                choices={countrys.map(x => ({ value: x.name, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, country: val }) }} />
                            <Select label={t(tBase + 'dealType')}
                                disabled={!isManager}
                                value={selectedItem.dealType}
                                choices={dealTypes.sort().map(x => ({ value: x, text: x }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, dealType: val }) }} />
                            <TextField label={t(tBase + 'order')}
                                disabled={!isManager}
                                text={selectedItem.order}
                                onChange={event => setSelectedItem({ ...selectedItem, order: event.target.value })} />
                        </>
                    )
                }
            </MasterDataShell>
        </div>
    )
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' }
    })

export default withStyles(styles, muiOptions)(DealTypeMasterData)
