import React, { RefObject, useImperativeHandle, useRef } from 'react'
import { makeStyles, } from '@material-ui/core'
import { defaultColors } from '../../../infrastructure/materialUiThemeProvider'

type FieldHintEffectProps = {
    fieldHintEffectRef: RefObject<FieldHintEffectRef>
}

const ANIMATION_DELAY = 350

export function FieldHintEffect({ children, fieldHintEffectRef }: React.PropsWithChildren<FieldHintEffectProps>) {
    const ref = useRef<HTMLDivElement>(null);
    const classes = useStyles()

    useImperativeHandle(fieldHintEffectRef, () => ({
        animate: async () => {
            if (ref) {
                ref.current!.classList.remove(classes.animate)
                ref.current!.classList.add(classes.animate)
                setTimeout(() => ref.current!.classList.remove(classes.animate), ANIMATION_DELAY)
            }
        }
    }))

    return (<div ref={ref} className={classes.container}>{children}</div>)
}

const useStyles = makeStyles({
    container: {
        boxShadow: '0 0 0 0 transparent',
        transition: `box-shadow ${ANIMATION_DELAY}ms, transform ${ANIMATION_DELAY}ms ease-in-out`,
        transform: "translateY(0)"
    },
    animate: {

        transition: `box-shadow ${ANIMATION_DELAY}ms, transform ${ANIMATION_DELAY}ms ease-in-out`,
        boxShadow: `0 0 15px 7px ${defaultColors.grey.light.color}`,
        transform: "translateY(-8px)"
    },
})

export type FieldHintEffectRef = { animate: () => void }