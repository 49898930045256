import React from 'react'
import { withStyles, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Add } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { muiOptions, MuiProps } from '../../../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../../../infrastructure/i18nextHelper'
import guid from '../../../../../infrastructure/guid'
import { VesselEditContainer } from '../../vesselEditStore'
import { VesselProduct, PurchaseMovement, SitePurchaseMovement } from '../../../vesselModels'
import { commonStyle } from '../_common'
import { TriggerPurchaseMovementTable } from './triggerPurchaseMovementTable'

let tBase = 'vessels.label.purchaseMovement.'

type PurchaseMovementProps = {
    vesselProduct: VesselProduct
    purchaseMovements: PurchaseMovement[]
    canDeletePurchaseMovement: boolean
    addTriggerPurchaseMovement: (newPurchaseMovement: PurchaseMovement) => void
    addSite: (purchaseMvt: PurchaseMovement, defaultSitePurchaseMovement: SitePurchaseMovement) => void
    createSite: (company: string, dutyStatus: string) => SitePurchaseMovement
    setPurchaseMovementToDelete: (purchaseMovement: PurchaseMovement) => void
    setSitePurchaseMovementToDelete: (site: SitePurchaseMovement) => void
}

function _TriggerPurchaseMovement({ classes, vesselProduct, purchaseMovements, canDeletePurchaseMovement, createSite, addTriggerPurchaseMovement, addSite, setPurchaseMovementToDelete, setSitePurchaseMovementToDelete }: PurchaseMovementProps & MuiProps) {
    let vessel = VesselEditContainer.useContainer()
    let hasOnlyOneCompany = vessel.movementStock.map(x => x.company).distinct().length == 1
    let hasOnlyOneDutyStatus = vessel.movementStock.map(x => x.dutyStatus).distinct().length == 1
    let companyCode = purchaseMovements[0].companyCode
    let [company, dutyStatus] = [vessel.companys[companyCode], purchaseMovements[0].dutyStatus]

    let addPurchaseMovementDeadline = () => {
        let newPurchaseMovement: PurchaseMovement = {
            id: guid.createNew(),
            companyCode: companyCode,
            dutyStatus: dutyStatus,
            type: "Trigger",
            percentageOfNominated: null,
            inAlert: null,
            nominatedQuantity: null,
            remainingQuantity: null,
            totalDispatchedQuantity: null,
            deadlineDate: null,
            purchaseContract: null,
            sites: [createSite(companyCode, dutyStatus)],
        }
        addTriggerPurchaseMovement(newPurchaseMovement)
    }

    return (<div>
        <div className={classes.purchaseMovementTriggerHeader}>
            {hasOnlyOneCompany && hasOnlyOneDutyStatus
                ? <span />
                : <Typography className={classes.purchaseMovementHeaderCombination} variant='h6' display='block'>{hasOnlyOneCompany
                    ? dutyStatus
                    : hasOnlyOneDutyStatus
                        ? company
                        : company + " / " + dutyStatus}</Typography>}
            <Button
                onClick={() => addPurchaseMovementDeadline()}
                className={`${classes.headerButton} add-site-btn`}>
                <Add /> {t(tBase + 'addDeadline')}
            </Button>
        </div>
        <>
            {purchaseMovements.map((x, i) =>
                <TriggerPurchaseMovementTable
                    key={i}
                    vesselProduct={vesselProduct}
                    purchaseMovement={x}
                    canDeletePurchaseMovement={canDeletePurchaseMovement}
                    addSite={addSite}
                    createSite={createSite}
                    setPurchaseMovementToDelete={() => setPurchaseMovementToDelete(x)}
                    setSitePurchaseMovementToDelete={(site) => setSitePurchaseMovementToDelete(site)} />
            )}
        </>
    </div>)
}

let styles = createStyles({
    purchaseMovementHeaderCombination: commonStyle.purchaseMovementHeaderCombination,
    purchaseMovementTriggerHeader: commonStyle.purchaseMovementTriggerHeader,
    headerButton: commonStyle.headerButton
})

export let TriggerPurchaseMovement = withStyles(styles, muiOptions)(_TriggerPurchaseMovement)