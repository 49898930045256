import React from 'react'
import { muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { withStyles } from '@material-ui/core'
import { Popup, ButtonPopupParams } from '../../common/customComponents'
import { TruckTransportContainer } from '../truckStore'
import { truckDialog } from '../form/truckFormStore'
import { styles } from './style'
import { _TruckGeneralSection } from './_truckGeneralSection'
import { _TruckEventsSection } from './eventsSection/_truckEventsSection'
import { snackbars } from '../../../infrastructure/snackbars'

function TruckForm({ classes }: { classes?: any }) {
    let store = TruckTransportContainer.useContainer()

    let buttons: ButtonPopupParams[] = [{
        buttonEffect: save,
        buttonText: store.t(getTradCode('saveButton')),
    }]

    async function save() {
        if (!store.form.truck.truckStatus)
            snackbars.warning(store.t('trucks.label.warning.validateFirstStep'))
        else if (store.form.checkMandatoryFields())
            await store.form.save(store.form.truck)
    }
    return (
        <Popup title={store.t(getTradCode('popupTitle'))}
            close={() => truckDialog.close()}
            isOpen={store.form.isOpen}
            buttons={buttons}>
            <>
                <_TruckGeneralSection classes={classes} />
                <_TruckEventsSection classes={classes} />
            </>
        </Popup >
    )
}

export function getTradCode(code: string) {
    return 'trucks.form.' + code
}

export let TruckTransportForm = withStyles(styles, muiOptions)(TruckForm)