import { Line } from 'react-chartjs-2'
import { ChartOptions } from 'chart.js'
import React, { useEffect, useState } from 'react'
import { interval, Subject } from 'rxjs'
import { debounce, distinctUntilChanged, withLatestFrom } from 'rxjs/operators'
import produce from 'immer'
import { withStyles, createStyles, Typography, Paper, IconButton, Tooltip } from '@material-ui/core'
import { DateRangeOutlined } from '@material-ui/icons'
import { t } from '../../../infrastructure/i18nextHelper'
import { history, navigateTo } from '../../../infrastructure/navigation'
import { Button, DatePicker, NumberField, Switch, TextField } from '../../common/customComponents'
import { defaultColors, defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { api } from '../../../infrastructure/api'
import { queryStringBuilder } from '../../../infrastructure/queryStringBuilder'
import { createVesselSimulationChart, createChartOptions } from './createChart'
import {
    ProductVesselSimulation, SimulateVessel, SimulateVesselResult,
    VesselSimulationDetails, VesselSimulationProduct, ApplyVesselSimulationArgs
} from './../vesselModels'
import { snackbars } from '../../../infrastructure/snackbars'

type ProductOrder = {
    productId: string
    order: number
}

type ChartsPeriod = {
    start: string | null
    end: string | null
}

type SimulationSubject = {
    simulations: VesselSimulationDetails[]
    chartsPeriod: ChartsPeriod
}

function VesselSimulate({ classes }: MuiProps) {
    let [initialVesselDetails, setInitialVesselDetails] = useState<VesselSimulationDetails[]>([])
    let [modifiedVesselDetails, setModifiedVesselDetails] = useState<VesselSimulationDetails[]>([])
    let [simulatedVesselResults, setSimulatedVesselResults] = useState<SimulateVesselResult[]>([])
    let [allProductsOders, setAllProductsOders] = useState<ProductOrder[]>([])
    let [simulationChanged] = useState<Subject<SimulationSubject>>(new Subject<SimulationSubject>())
    let [chartsPeriod, setChartsPeriod] = useState<ChartsPeriod>({ start: null, end: null })
    let [displayByDays, setDisplayByDays] = useState<boolean>(false)

    let load = async () => {
        let query = new URLSearchParams(history.location.search.substring(1))
        let vesselIds = query.getAll('ids')

        if (!vesselIds.length) {
            snackbars.warning(t('vessels.simulation.errors.notFound'))
            navigateTo('/vessels');
        }

        let loadVesselDetails = vesselIds.map(x => api.get<VesselSimulationDetails>(`vessel/${x}/simulationDetails`))
        let vesselDetails = await Promise.all(loadVesselDetails)
        await simulateVessels(vesselDetails)

        setModifiedVesselDetails(vesselDetails)
        setInitialVesselDetails(vesselDetails)
        setAllProductsOders(allProductsOrders(vesselDetails))
    }

    let changeAvailabilityDate = (vesselId: string, newDate: string | null) => {
        if (!modifiedVesselDetails.length) return

        let modifiedVessels = produce(modifiedVesselDetails, draft => {
            const vessel = draft.find(x => x.id === vesselId)
            if (vessel) vessel.date = newDate
        })

        setModifiedVesselDetails(modifiedVessels)
        simulate(modifiedVessels)
    }

    let allProductsOrders = (vesselDetails: VesselSimulationDetails[]): ProductOrder[] => {
        if (!vesselDetails.length) return []

        let allProduct: ProductOrder[] = vesselDetails
            .flatMap(x => x.products)
            .distinctBy((x, y) => x.id === y.id)
            .map(x => ({ productId: x.id, order: x.order }))
            .sort((p1, p2) => p1.order > p2.order ? 1 : -1)

        return allProduct
    }

    let changeDisplayByDayToggle = () => {
        setDisplayByDays(!displayByDays)
    }

    let changeProductValue = (vesselId: string, productId: string, newValue: number | null) => {

        if (!modifiedVesselDetails.length) return

        let allVesselsUpdated = produce(modifiedVesselDetails, draft => {
            const vessel = draft.find(x => x.id === vesselId)
            const product = vessel?.products.find(x => x.id === productId)
            if (product) product.quantity = newValue
        })

        setModifiedVesselDetails(allVesselsUpdated)
        simulate(allVesselsUpdated)
    }

    let changeChartsPeriods = (period: ChartsPeriod) => {
        setChartsPeriod(period)

        if ((period.start && period.end) || (!period.start && !period.end))
            simulate(modifiedVesselDetails, period)
    }

    let reset = () => {
        if (modifiedVesselDetails && initialVesselDetails) {
            setModifiedVesselDetails(initialVesselDetails)
            simulate(initialVesselDetails)
        }
    }

    let simulate = (simulations: VesselSimulationDetails[], period?: ChartsPeriod) => {
        simulationChanged.next({ simulations: simulations, chartsPeriod: period ?? chartsPeriod })
    }

    let mapToSimulateVessel = (vesselSimulationDetails: VesselSimulationDetails, chartsPeriod?: ChartsPeriod): SimulateVessel => {
        return {
            vesselId: vesselSimulationDetails.id,
            availabilityDate: vesselSimulationDetails.date,
            startDate: chartsPeriod?.start ?? null,
            endDate: chartsPeriod?.end ?? null,
            productVolumes: vesselSimulationDetails.products.map(x => ({ productId: x.id, volume: x.quantity }))
        }
    }

    let simulateVessels = async (simulatedVessels: VesselSimulationDetails[], chartsPeriod?: ChartsPeriod) => {
        if (simulatedVessels.length && simulatedVessels.every(x => x.date)) {
            let promises = simulatedVessels.map(x => {
                const queryString = queryStringBuilder(mapToSimulateVessel(x, chartsPeriod))
                return api.get<SimulateVesselResult>(`vessel/${x.id}/simulation${queryString}`)
            })

            let newVesselResults = await Promise.all(promises)
            setSimulatedVesselResults(newVesselResults)
        }
    }

    let saveSimulation = async () => {
        if (modifiedVesselDetails && modifiedVesselDetails.every(x => x.date)) {

            let saveVessels = modifiedVesselDetails.map(x => {
                const vesselSimulationArgs: ApplyVesselSimulationArgs = { availabilityDate: x.date! }
                return api.patch(`vessel/${x.id}/simulation`, vesselSimulationArgs)
            })

            await Promise.all(saveVessels)
            navigateTo('/vessels')
        }
    }

    useEffect(() => {
        let subscription = simulationChanged
            .pipe(
                debounce(() => interval(500)),
                distinctUntilChanged(),
                withLatestFrom(simulationChanged))
            .subscribe(([x, _]) => simulateVessels(x.simulations, x.chartsPeriod))

        load()

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    if (!modifiedVesselDetails.length) return (<div></div>)

    return (
        <div className={classes.pageContainer}>
            <MoreContentRow products={modifiedVesselDetails.map(x => x.products)}
                changeChartsPeriods={changeChartsPeriods}
                chartsPeriod={chartsPeriod}
                displayByDays={displayByDays}
                changeDisplayByDayToggle={changeDisplayByDayToggle}
                reset={reset} saveSimulation={saveSimulation}
                isSaveButtonEnabled={modifiedVesselDetails.every(x => x.date)} classes={classes} />

            {modifiedVesselDetails.length && modifiedVesselDetails.map(x =>
                <VesselSimulation key={x.id} modifiedVesselDetail={x}
                    allProductsOrders={allProductsOders}
                    displayByDays={displayByDays}
                    simulatedVesselResult={simulatedVesselResults.find(vr => vr.vesselId === x.id)}
                    changeAvailabilityDate={changeAvailabilityDate}
                    changeProductvalue={changeProductValue}
                    classes={classes} />)}
        </div>)
}

type MoreContentRowProps = {
    products: VesselSimulationProduct[][]
    isSaveButtonEnabled: boolean
    chartsPeriod: ChartsPeriod
    displayByDays: boolean
    reset: () => void
    saveSimulation: () => void
    changeDisplayByDayToggle: () => void
    changeChartsPeriods: (chartsPeriod: ChartsPeriod) => void
    classes: any
} & MuiProps
function MoreContentRow(props: MoreContentRowProps) {
    let [isChartSettingOpened, setIsChartSettingOpened] = useState<boolean>(false)

    let summedProducts = (): VesselSimulationProduct[] => {
        let allProducts = props.products.flatMap(x => x)
        let allProductsDistinct = allProducts.distinctBy((x, y) => x.id === y.id)

        if (!allProducts.length) return []

        let result: VesselSimulationProduct[] = []

        allProductsDistinct.forEach(p => {
            let quantities = allProducts
                .filter(x => x.id === p.id)
                .map(x => x.quantity)

            if (quantities.length) {
                let sum = quantities.reduce((sum, current) => (sum ?? 0) + (current ?? 0))
                let sumWithPrecision = sum ? Number.parseFloat(sum.toFixed(3)) : null
                result.push({ ...p, quantity: sumWithPrecision })
            }
        })

        return result
    }

    return (
        <Paper className={props.classes.MoreContentRowContainer}>
            <div className={props.classes.rowSpaceBetween}>
                <Tooltip title={<Typography variant='body1'>{t('vessels.simulation.chart.periodTooltip')}</Typography>} placement='top'>
                    <IconButton onClick={_ => setIsChartSettingOpened(!isChartSettingOpened)}>
                        <DateRangeOutlined />
                    </IconButton>
                </Tooltip>
                {
                    isChartSettingOpened &&
                    <div className={props.classes.rowSpaceBetween}>
                        <DatePicker
                            date={props.chartsPeriod.start}
                            classesOverride={{ datepicker: props.classes.datePickerField }}
                            label={t('vessels.simulation.chart.chartStart')}
                            setDate={newDate => props.changeChartsPeriods({ ...props.chartsPeriod, start: newDate })} />
                        <DatePicker
                            date={props.chartsPeriod.end}
                            classesOverride={{ datepicker: props.classes.datePickerField }}
                            label={t('vessels.simulation.chart.chartEnd')}
                            setDate={newDate => props.changeChartsPeriods({ ...props.chartsPeriod, end: newDate })} />
                    </div>
                }
                <Switch form changeCallback={props.changeDisplayByDayToggle} isChecked={props.displayByDays}
                    offText={t('vessels.simulation.chart.volume')} onText={t('vessels.simulation.chart.days')} />
            </div>
            <div>
                {summedProducts().map(x =>
                    <TextField
                        key={x.id} disabled
                        label={t('vessels.simulation.productSum', { productCode: x.code, unit: x.unit })}
                        text={x.quantity} />)}
            </div>
            <div className={props.classes.rowSpaceBetween}>
                <Button label={t('vessels.simulation.clear')}
                    className={props.classes.clearButton}
                    onClick={props.reset} />
                <Button label={t('vessels.simulation.save')}
                    className={props.classes.saveButton}
                    onClick={props.saveSimulation}
                    disabled={!props.isSaveButtonEnabled} />
            </div>

        </Paper>)
}

type SimulatedVessel = {
    allProductsOrders: ProductOrder[]
    modifiedVesselDetail: VesselSimulationDetails
    simulatedVesselResult?: SimulateVesselResult | null
    displayByDays: boolean
    changeAvailabilityDate: (vesselId: string, newDate: string | null) => void
    changeProductvalue: (vesselId: string, productId: string, newValue: number | null) => void
    classes: any
} & MuiProps
function VesselSimulation(props: SimulatedVessel) {

    let findProductWithOrder = (productId: string): number | undefined => {
        return props.allProductsOrders.find(x => x.productId === productId)?.order
    }

    return (
        <Paper className={props.classes.simulatedVesselContainer}>
            <HeaderChart modifiedVesselDetail={props.modifiedVesselDetail}
                findProductWithOrder={findProductWithOrder}
                changeAvailabilityDate={props.changeAvailabilityDate}
                changeProductvalue={props.changeProductvalue}
                classes={props.classes} />

            <div className={props.classes.chartsContainer}>
                {props.simulatedVesselResult && props.allProductsOrders.map(x => {
                    let simulatedVesselResult = props.simulatedVesselResult?.simulations.find(vs => vs.productId === x.productId)

                    if (!simulatedVesselResult) return <div key={x.productId + props.simulatedVesselResult?.vesselId} style={{ width: '100%' }} />

                    return <ProductChart key={x.productId + props.simulatedVesselResult?.vesselId}
                        shouldDisplayByDay={props.displayByDays}
                        productVesselSimulation={simulatedVesselResult}
                        classes={props.classes} />
                })}
            </div>
        </Paper>)
}

type HeaderChartProps = {
    modifiedVesselDetail: VesselSimulationDetails
    findProductWithOrder: (productId: string) => number | undefined
    changeAvailabilityDate: (vesselId: string, newDate: string | null) => void
    changeProductvalue: (vesselId: string, productId: string, newValue: number | null) => void
    classes: any
} & MuiProps
function HeaderChart(props: HeaderChartProps) {
    return (
        <div className={props.classes.rowSpaceBetween}>
            <div className={props.classes.rowSpaceBetween}>
                <TextField label={t('vessels.simulation.name')}
                    text={props.modifiedVesselDetail.name} disabled />
                <TextField label={t('vessels.simulation.jetty')}
                    text={props.modifiedVesselDetail.jetty} disabled />
                <DatePicker
                    date={props.modifiedVesselDetail.date}
                    onError={!props.modifiedVesselDetail.date}
                    errormessage={t('vessels.simulation.errors.availabilityDateNotValid')}
                    label={t('vessels.simulation.availabilityDate')}
                    setDate={newDate => props.changeAvailabilityDate(props.modifiedVesselDetail.id, newDate)} />
            </div>

            <VesselProducts changeProductvalue={props.changeProductvalue}
                vesselId={props.modifiedVesselDetail.id}
                products={[...props.modifiedVesselDetail.products]
                    .sort((p1, p2) => props.findProductWithOrder(p1.id)! > props.findProductWithOrder(p2.id)! ? 1 : -1)}
                classes={props.classes} />
        </div>)
}

type VesselProductsProps = {
    vesselId: string
    products: VesselSimulationProduct[],
    changeProductvalue: (vesselId: string, productId: string, newValue: number | null) => void
    classes: any
} & MuiProps
function VesselProducts({ vesselId, products, changeProductvalue, classes }: VesselProductsProps) {

    if (!products.length) return (<div></div>)
    return (
        <div className={classes.rowSpaceBetween}>
            {products.map(x =>
                <div key={x.id} >
                    <NumberField
                        overrideStyle={{ root: classes.field }}
                        label={t('vessels.simulation.product', { productCode: x.code, unit: x.unit })}
                        onChange={val => changeProductvalue(vesselId, x.id, val)}
                        text={x.quantity} precision={3} />
                </div>)
            }
        </div>)
}

type ProductChartProps = {
    productVesselSimulation: ProductVesselSimulation
    shouldDisplayByDay: boolean
    classes: any
}
function ProductChart({ productVesselSimulation, shouldDisplayByDay, classes }: ProductChartProps) {
    let chart = React.useMemo(() => createVesselSimulationChart({
        simulation: productVesselSimulation,
        displayByDays: shouldDisplayByDay
    }), [productVesselSimulation, shouldDisplayByDay])

    let chartOptions: ChartOptions<'line'> = React.useMemo(() => createChartOptions(chart), [chart.datasets])

    return (<div className={classes.chartContainer}>
        <div className={classes.rowSpaceBetween}>
            <Typography className={classes.productTitle} variant='overline' display='block' gutterBottom>
                {productVesselSimulation.productCode}
            </Typography>
        </div>
        <Line data={chart} options={chartOptions} />
    </div>
    )
}

let styles = () =>
    createStyles({
        pageContainer: {
            paddingRight: '0.5em',
            width: '100%',
            height: '100%',
            overflowX: 'hidden',
        },
        MoreContentRowContainer: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            margin: '0.5em 0 1em 0',
            padding: '0.5em'
        },
        simulatedVesselContainer: {
            margin: '1em 0',
            padding: '0.5em'
        },
        chartsContainer: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '1em 0.5em 0.5em',
            height: '15.5em',
        },
        rowSpaceBetween: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
        },
        productTitle: {
            color: defaultColors.red.main.color,
        },
        chartContainer: {
            height: '80%',
            width: '100%',
            paddingRight: '0.5em',
            paddingLeft: '0.5em'
        },
        field: {
            width: '10em',
            margin: '0em 0.5em',
        },
        datePickerField: {
            width: '11em',
            margin: '0em 0.5em',
        },
        saveButton: {
            ...defaultStyles.primaryButton,
            marginLeft: '0.5em',
        },
        clearButton: {
            ...defaultStyles.secondaryButton,
            marginRight: '0.5em',
        }
    })

export default withStyles(styles, muiOptions)(VesselSimulate)