import React, { RefObject, useRef, useImperativeHandle, useState, useEffect } from 'react'
import { createStyles, withStyles, Dialog, DialogContent, DialogActions, DialogTitle, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from '@material-ui/core'
import { defaultStyles, muiOptions, MuiProps } from '../../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../../infrastructure/i18nextHelper'
import { Button } from '../../../common/customComponents'
import { StockMovementContainer } from '../stockMovementStore'
import { PricingType } from '../../stockModels'
import { LinkVesselMovementContainer } from '../movementForms/linkVesselContainer'

let selectPricingTypeDialogRef: RefObject<{ open: () => void }> | null = null

function SelectPricingType({ classes, pricingTypes }: { pricingTypes: PricingType[] } & MuiProps) {
    let defaultType = 'Formula'
    let [isOpen, setIsOpen] = useState<boolean>(false)
    let [selectedType, setSelectedType] = useState<string>(defaultType)
    let store = StockMovementContainer.useContainer()
    let linkVesselStore = LinkVesselMovementContainer.useContainer()

    selectPricingTypeDialogRef = useRef<{ open: () => void }>(null)

    useImperativeHandle(selectPricingTypeDialogRef, () => ({
        open: () => { setIsOpen(true) }
    }))

    useEffect(() => {
        if (store.selectPricingTypeFor != null)
            selectPricingTypeDialogRef?.current?.open()
    }, [store.selectPricingTypeFor])

    let close = () => {
        setSelectedType(defaultType)
        store.setSelectPricingTypeFor(null)
        setIsOpen(false)
    }

    let confirm = async () => {
        store.setDealPricingType(selectedType)
        if (store.selectPricingTypeFor == 'vessel')
            await linkVesselStore.openAssignableVessels()
        else
            store.setIsConfirmDealOpen(true)
        close()
    }

    return (
        <Dialog open={isOpen} onClose={close} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogTitle>{t('stock.label.movement.selectPricingType')}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <FormControl>
                    <FormLabel>{t('stock.label.movement.pricingTypes')}</FormLabel>
                    <RadioGroup onChange={(_, v) => setSelectedType(v)}
                        value={selectedType}
                        name="radio-buttons-group">
                        {pricingTypes.map(x =>
                            <FormControlLabel key={x.name} value={x.name} control={<Radio />} label={t('stock.label.movement.' + x.name)} />
                        )}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions className={classes.marginTop1em}>
                <Button onClick={close}
                    label={t('components.dialogClose')}
                    color='primary'
                    className={classes.cancelButton} />
                <Button onClick={confirm}
                    label={t('components.dialogConfirm')}
                    color='primary'
                    className={classes.confirmButton} />
            </DialogActions>
        </Dialog>
    )
}

let styles = () =>
    createStyles({
        confirmButton: { ...defaultStyles.dialogPrimaryButton },
        cancelButton: { ...defaultStyles.dialogCloseButton },
        dialogContent: {
            display: 'flex',
            justifyContent: 'space-between'
        },
    })


export default withStyles(styles, muiOptions)(SelectPricingType)