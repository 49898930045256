import React from 'react'
import moment from 'moment'
import { muiOptions, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import { withStyles, createStyles, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core'
import { t } from '../../infrastructure/i18nextHelper'
import { ExposureBalance } from './models'
import { hasFeature } from '../../infrastructure/feature'

type MarketRisksTableProps = {
    exposureBalance: ExposureBalance | null
    classes: any
    showPurchaseDetails: boolean
}

function _MarketRisksTable({ exposureBalance, classes, showPurchaseDetails }: MarketRisksTableProps) {

    let separator = ' ' + classes.verticalSeparator

    return (
        <div >
            <Table stickyHeader aria-label='sticky table' >
                <TableHead className={classes.table}>
                    <TableRow>
                        <TableCell align='center'>{t('mktRiskExposure.table.pricingFrom')}</TableCell>
                        <TableCell align='center'>{t('mktRiskExposure.table.pricingEnd')}</TableCell>
                        {(!hasFeature('MarketRiskCombinedPurchasesColumns') || (hasFeature('MarketRiskCombinedPurchasesColumns')
                            && showPurchaseDetails))
                            && <><TableCell align='right'>{t('mktRiskExposure.table.purchases')}</TableCell>
                                <TableCell align='right'>{t('mktRiskExposure.table.localPurchases')}</TableCell></>}
                        {hasFeature('MarketRiskCombinedPurchasesColumns') && !showPurchaseDetails
                            && <TableCell align='center' colSpan={2}>{t('mktRiskExposure.table.sumOfPurchases')}</TableCell>}
                        <TableCell align='right'>{t('mktRiskExposure.table.mktSales')}</TableCell>
                        <TableCell align='right'>{t('mktRiskExposure.table.loansAndBorrows')}</TableCell>
                        <TableCell align='right'>{t('mktRiskExposure.table.gainsAndLossses')}</TableCell>
                        <TableCell align='right'>{t('mktRiskExposure.table.imbalance')}</TableCell>
                        <TableCell align='right'>{t('mktRiskExposure.table.estimatedExposure')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={classes.exposureRow}>
                        <TableCell align='center' className={classes.exposureRowLabels}>{t('mktRiskExposure.table.exposureDate')}</TableCell>
                        <TableCell align='center' className={classes.exposureRowLabels}>{moment(exposureBalance?.exposureDate).format('MM/DD/yyyy')}</TableCell>
                        <TableCell className={classes.exposureRowLabels} colSpan={6}></TableCell>
                        <TableCell className={classes.exposureRowLabels} align='right'>{exposureBalance?.exposure}</TableCell>
                    </TableRow>
                    {
                        exposureBalance?.exposures.map((x, i) =>
                            <TableRow className={x.isPast ? classes.isPastPeriodRow : classes.exposurePeriodRow} key={i}>
                                <TableCell className={classes.exposurePricingPeriodCell} align='center'>
                                    {moment(x.pricingStart).format('MM/DD/yyyy')}
                                </TableCell>
                                <TableCell className={classes.exposurePricingPeriodCell} align='center'>
                                    {moment(x.pricingEnd).format('MM/DD/yyyy')}
                                </TableCell>
                                {(!hasFeature('MarketRiskCombinedPurchasesColumns') || (hasFeature('MarketRiskCombinedPurchasesColumns')
                                    && showPurchaseDetails))
                                    && <><TableCell className={classes.exposurePeriodCell + separator} align='right'>
                                        {x.purchases.toFixed()}
                                    </TableCell>
                                        <TableCell className={classes.exposurePeriodCell} align='right'>
                                            {x.localPurchases.toFixed()}
                                        </TableCell></>}
                                {hasFeature('MarketRiskCombinedPurchasesColumns') && !showPurchaseDetails
                                    && <TableCell className={classes.exposurePeriodCell + separator} align='right' colSpan={2}>
                                        {(x.purchases + x.localPurchases).toFixed()}
                                    </TableCell>}
                                <TableCell className={classes.exposurePeriodCell} align='right'>
                                    {x.sales.toFixed()}
                                </TableCell>
                                <TableCell className={classes.exposurePeriodCell} align='right'>
                                    {x.loansAndBorrows.toFixed()}
                                </TableCell>
                                <TableCell className={classes.exposurePeriodCell} align='right'>
                                    {x.gainsAndLosses.toFixed()}
                                </TableCell>
                                <TableCell className={classes.imbalanceCell + separator} align='right'>
                                    {x.imbalance.toFixed()}
                                </TableCell>
                                <TableCell className={classes.estExposureCell + separator} align='right'>
                                    {x.estimatedExposure.toFixed()}
                                </TableCell>
                            </TableRow>)
                    }
                </TableBody>
            </Table>
        </div>
    )
}

let styles = _ =>
    createStyles({
        verticalSeparator: { borderLeft: '1px solid #e0e0e0' },
        exposureRow: { backgroundColor: defaultColors.focus.main.color },
        isPastPeriodRow: { backgroundColor: 'whiteSmoke' },
        exposureRowLabels: {
            fontWeight: 'bold',
            padding: '12px 11px 12px 8px',
            whiteSpace: 'nowrap'
        },
        exposurePricingPeriodCell: {
            backgroundColor: defaultColors.focus.main.color,
            padding: '8px 8px',
            whiteSpace: 'nowrap'
        },
        exposurePeriodCell: {
            padding: '8px 8px',
            whiteSpace: 'nowrap'
        },
        imbalanceCell: {
            backgroundColor: defaultColors.focus.main.color,
            fontWeight: 'bold',
            padding: '8px 8px',
            whiteSpace: 'nowrap'
        },
        estExposureCell: {
            fontWeight: 'bold',
            padding: '8px 11px',
            whiteSpace: 'nowrap',
            marginRight: '1em',
        }
    })

export let MarketRisksTable = withStyles(styles, muiOptions)(_MarketRisksTable)