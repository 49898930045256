import React from 'react'
import { createStyles, withStyles, Tooltip, Typography } from '@material-ui/core'
import { muiOptions, defaultColors, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'

export type VerticalProgressbarProps = {
    percentage?: number | null | undefined
    value: number | null | undefined
    tooltip?: string
    showPercentage?: boolean
} & MuiProps

function _VerticalProgressbar(props: VerticalProgressbarProps) {
    let percentage = props.percentage ?? 0
    let value = props.value ?? '-'
    let showTooltip = props.tooltip || (props.showPercentage && percentage)
    let tooltipText = props.tooltip ? props.tooltip : ''
    if (props.showPercentage && percentage) {
        tooltipText = tooltipText + ', ' + t('stock.label.percentage', { percentage: percentage })
    }

    return (
        <div className={props.classes.progressbarWrapper} >
            <div className={props.classes.progressbar} style={{ height: `${percentage}%` }}>
                {showTooltip
                    ? <Tooltip title={
                        <Typography variant='body1'>{tooltipText}</Typography>
                    }>
                        <span className={props.classes.percentageValue}>
                            {value}
                        </span>
                    </Tooltip>
                    : <span className={props.classes.percentageValue}>
                        {value}
                    </span>
                }
            </div>
        </div>
    )
}

let styles = () =>
    createStyles({
        progressbarWrapper: {
            width: '100%',
            height: '4em',
            transform: 'rotate(180deg)',
            margin: '0 auto',
            position: 'relative'
        },
        progressbar: {
            width: '100%',
            background: defaultColors.lightBlue.light.color,
            textAlign: 'center'
        },
        percentageValue: {
            display: 'inline-block',
            position: 'relative',
            top: '1em',
            transform: 'rotate(180deg)',
            fontSize: '1rem',
            fontWeight: 'bold'
        },
        progressContainer: {
            padding: 0
        }
    })

export let VerticalProgressbar = withStyles(styles, muiOptions)(_VerticalProgressbar)



