import * as api from './api'
import { createContainer } from 'unstated-next'
import guid from './guid'

export type ExcelFileContents = { filename: string, sheets: ExcelSheet[] }
export type ExcelSheet = { name: string, lines: ExcelLine[] }
export type ExcelLine = { cells: ExcelCell[] }
export type ExcelCell = { value: string, type: 'number' | 'date' | 'string' | 'stringDate'}

export function formatFilename(filename: string) {
    return `${new Date().toISOString().slice(0, 10).replace(/-/g, '')}-${guid.createNew().slice(0, 4)}-${filename}`
}

export function useExcelGenerator() {

    async function generate(contents: ExcelFileContents) {
        contents.filename = formatFilename(contents.filename),
            await api.post('excel', contents)

        let url = `${api.url}download/${contents.filename}`

        window.open(url, '_blank')
    }

    return { generate }
}

export let ExcelGeneratorContainer = createContainer(useExcelGenerator)