import React, { Fragment } from 'react'
import { muiOptions } from '../../materialUiThemeProvider'
import { withStyles } from '@material-ui/core'
import { Select } from '../../../app/common/customComponents'
import { availableLanguages, UserSettingsContextContainer } from '../userSettings/userSettingsStore'

function LanguageSelector() {
    let userSettingsStore = UserSettingsContextContainer.useContainer()

    return (
        <>
            <Select value={userSettingsStore.getLanguage()}
                choices={availableLanguages.map(x => { return { value: x.code, text: x.label } })}
                onChange={val => userSettingsStore.changeUserSettings({ ...userSettingsStore.userSettings, language: val })} />
        </>
    )
}

export default withStyles({}, muiOptions)(LanguageSelector)