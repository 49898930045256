import React from 'react'
import { withStyles, createStyles, Paper, Tooltip, Typography } from '@material-ui/core'
import { Room } from '@material-ui/icons'
import * as api from '../../../infrastructure/api'
import { muiOptions, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { Popup, Stepper, AttachFileButton, Button, ButtonPopupParams } from '../../common/customComponents'
import { VesselStatus } from '../vesselModels'
import { FirstLine, SecondLine, ThirdLine, FourthLine, FifthLine } from './_vesselSection'
import ProductsSection from './_vesselProductSection'
import { snackbars } from '../../../infrastructure/snackbars'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { useActionDebounce } from '../../common/debounce'
import { VesselEditContainer, VesselEditDialogContainer, vesselDialog } from './vesselEditStore'
import { VesselStatusChip } from '../statusChip'

function VesselEdit({ classes }) {
    let vessel = VesselEditContainer.useContainer()
    let vesselDialogStore = VesselEditDialogContainer.useContainer()
    let handleSaveVesselDebouncer = useActionDebounce(save)

    async function save() {
        vessel.highlightErrors()
        if (vessel.fieldStatus.getFieldsWithStatus('alert').length === 0) {
            await vessel.trySave()
            return
        }
        snackbars.warning(t('warnings.mandatoryFieldsBeforeSaving'))
    }

    let handleChangeStatus = (newStatus: VesselStatus) => {
        if (!vessel.state) return
        if (!vessel.state.counterpartySupplierId && newStatus === VesselStatus.Actual)
            snackbars.warning(t('vessels.message.counterpartyEmpty'))
        else
            vessel.setState({ ...vessel.state, status: newStatus })
    }

    let buttons: ButtonPopupParams[] = [{
        buttonEffect: vessel.canEdit ? handleSaveVesselDebouncer.execute : undefined,
        buttonText: vessel.canEdit ? t('components.updateButton') : '',
    }]

    return (
        <Popup title={t('vessels.popup.title')}
            close={vesselDialog.close}
            isOpen={vesselDialogStore.isOpen}
            buttons={buttons}>
            {!vessel.state ? <div />
                : <div>
                    <Paper className={classes.stepperAndDocsDiv}>
                        <div className={classes.stepper}>
                            <Stepper steps={['', '', '']}
                                icons={
                                    [VesselStatus.Forecast, VesselStatus.Planned, VesselStatus.Actual].map(x =>
                                        <VesselStatusChip key={x} status={x} disabled={vessel.state.status !== x} hoverable />)}
                                activeStep={vessel.state.status as number}
                                ignoreStepStyles
                                classesOverride={{ root: classes.vesselStepper }}
                                onStepChange={(index: number) => handleChangeStatus(index as VesselStatus)} />
                        </div>
                        {!!vessel.state.internationalMaritimeOrganizationNumber &&
                            <Tooltip title={<Typography variant='body1'>{t('vessels.label.localizeVessel')}</Typography>}
                                placement='bottom'>
                                <span>
                                    <Button
                                        label={t('vessels.label.trackVessel')}
                                        img={<Room />}
                                        className={classes.trackVesselButton}
                                        onClick={() => {
                                            window.open(`https://www.vesselfinder.com/?imo=${vessel.state.internationalMaritimeOrganizationNumber}`)
                                        }} />
                                </span>
                            </Tooltip>}
                        {hasClaim(Claims.VesselReader) || hasClaim(Claims.VesselWriter) || hasClaim(Claims.VesselManager)
                            ? <AttachFileButton
                                id='upload-doc-button'
                                className={classes.documentButton}
                                title={t('vessels.label.documentDialogTitle')}
                                disableDelOrAdd={!hasClaim(Claims.VesselWriter) && !hasClaim(Claims.VesselManager)}
                                context={vessel.jettys.find(x => x.code === vessel.state.jettyCode)?.country ?? ''}
                                keyTemplate='vessel-{vesselId}'
                                keyParameters={{ vesselId: vessel.state?.id ? vessel.state.id.replace(/-/g, '') : '' }} />
                            : null
                        }
                    </Paper>

                    <Paper className={classes.vesselInformations}>
                        <FirstLine />
                        <SecondLine />
                        <ThirdLine />
                        <FourthLine />
                        <FifthLine />
                    </Paper>
                    <Paper className={classes.productInformation}>
                        <ProductsSection productCodeByProductId={vessel.products} />
                    </Paper>
                </div>}
        </Popup>
    )
}

let styles = () =>
    createStyles({
        vesselInformations: {
            padding: '0.5em',
            marginTop: '1em',
            marginBottom: '0.5em'
        },
        vesselStepper: {
            backgroundColor: 'white'
        },
        stepperAndDocsDiv: {
            ...defaultStyles.flexRow,
            marginTop: '1em',
            width: '100%'
        },
        stepper: { width: '97%' },
        productInformation: {
            padding: '0.5em',
            marginTop: '1em',
            marginBottom: '0.5em'
        },
        documentButton: {
            marginRight: '0.5em'
        },
        trackVesselButton: {
            ...defaultStyles.secondaryButton,
            marginRight: '0.5em'
        }
    })

export default withStyles(styles, muiOptions)(VesselEdit)