import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions, MuiProps, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { Button, MonthPicker } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import { formatFilename } from '../../../infrastructure/excelExport'
import * as api from '../../../infrastructure/api'
import { reportStyles, ReportShell } from './_common'
import companyCodes from './companies'

function _overstayAgeing({ classes }: MuiProps) {
    let currentMonth = moment().format('MM/DD/YYYY')
    let [overstayMonth, setOverstayMonth] = useState<string | null>(currentMonth)

    let generateOverstayAgeing = async (target: 'total' | 'gapco') => {
        let filename = formatFilename(`OverstayAgeing_${target.toUpperCase()}.xlsm`)
        let targetCompanies = target == 'total' ? [companyCodes.TKL, companyCodes.TUL] : [companyCodes.GKL]
        await api.post('kenya/overstay', { fromDate: overstayMonth, filename: filename, companies: targetCompanies })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')
    }

    useEffect(() => { if (overstayMonth == null) setOverstayMonth(currentMonth) }, [overstayMonth])

    return (
        <ReportShell title={t('report.overstayAgeing')} displayError={false}>
            <div className={classes.form}>
                <Button className={classes.generateButton}
                    label={t('report.exportTotal')}
                    img='/static/images/excel_red.svg'
                    onClick={() => generateOverstayAgeing('total')} />
                <Button className={classes.generateButton}
                    label={t('report.exportGapco')}
                    img='/static/images/excel_red.svg'
                    onClick={() => generateOverstayAgeing('gapco')} />
            </div>
            <MonthPicker classesOverride={{ datepicker: classes.datePicker }} date={overstayMonth}
                label={t('report.month')} setDate={setOverstayMonth} />
        </ReportShell>
    )
}

let styles = theme => createStyles({
    generateButton: defaultStyles.secondaryButton,
    datePicker: reportStyles.datePicker,
    form: reportStyles.form,
})

export default withStyles(styles, muiOptions)(_overstayAgeing)