import React, { useEffect, useState } from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import * as api from '../../../infrastructure/api'
import guid, { Guid } from '../../../infrastructure/guid'
import { formatFilename } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, MultipleSelect, TableItem, TextField } from '../../common/customComponents'
import { MasterDataItem, MasterDataShell } from './masterDataShell'

type Product = {
    id: Guid,
    code: string,
}

type ProductGroup = {
    id: Guid,
    name: string,
    productIds: string[]
}

let emptyProductGroup = () => {
    return {
        id: guid.empty,
        name: '',
        productIds: []
    }
}

let toTableItem = (productGroup: ProductGroup): TableItem<MasterDataItem<ProductGroup>> => {
    return {
        id: productGroup.id,
        name: productGroup.name,
        productIds: productGroup.productIds,
        isModified: false
    }
}

type ProductGroupFilter = {
    name: string | null,
    products: string | null,
}

let noFilters: ProductGroupFilter = {
    name: null,
    products: null
}

let applyFilters = (productGroups: ProductGroup[], filters: ProductGroupFilter, products: Product[]): ProductGroup[] => {
    let getProductCodeFromId = (id: Guid): string => products.find(x => x.id == id)?.code ?? ''

    if (filters.name)
        productGroups = productGroups.filter(x => x.name.toLowerCase().contains(filters.name!.toLowerCase()))
    if (filters.products)
        productGroups = productGroups.filter(x =>
            x.productIds.map(p => getProductCodeFromId(p).toLowerCase())
                .some(m => m.contains(filters.products!.toLowerCase())))

    return productGroups
}

function ProductGroupMasterData({ classes }: MuiProps) {
    let [products, setProducts] = useState<Product[]>([])
    let [productGroups, setProductGroups] = useState<ProductGroup[]>([])
    let [filters, setFilters] = useState<ProductGroupFilter>(noFilters)

    let load = async () => {
        let products = await api.get<Product[]>('masterdata/productgroup/countryproduct')
        let productGroups = await api.get<ProductGroup[]>('masterdata/productgroup')

        setProducts(products)
        setProductGroups(productGroups)
    }

    useEffect(() => {
        let effect = async () => {
            await load()
        }
        effect()
    }, [])

    let onExportExcel = async () => {
        let filename = formatFilename('ProductGroup.xlsx')
        await api.post('masterdata/productgroup/export', { filename: filename })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')
    }

    let onImportExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/productgroup/import'
        api.upload(uploadUrl, file, 'import').then(_ => load())
    }

    let onSave = async (item: ProductGroup) => {
        await api.post('masterdata/productgroup', item)
        await load()
        return true
    }

    let onDelete = async (ids: string[]) => {
        await api.del('masterdata/productgroup', { ids: ids })
        await load()
        return true
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<ProductGroup>>>[] = [
        {
            name: t('admin.masterdata.productgroup.name'), value: x => x.name,
            columnFilter: { value: filters.name ?? '', onChange: (name: string) => setFilters({ ...filters, name: name }) }
        },
        {
            name: t('admin.masterdata.productgroup.products'), value: x => x.productIds.map(id => products.first(p => p.id === id).code).join(', '),
            columnFilter: { value: filters.name ?? '', onChange: (name: string) => setFilters({ ...filters, products: name }) }
        }
    ]

    let isManager = true

    let availableProductsForProductGroup = productGroup =>
        products.filter(x => productGroups.every(p => productGroup.productIds.includes(x.id) || !p.productIds.includes(x.id))).map(x => ({ value: x.id, text: x.code }))

    return (
        <div className={classes.container}>
            <MasterDataShell
                headerLabel={t('admin.masterdata.productgroup.productgroups')}
                itemLabel={t('admin.masterdata.productgroup.productgroup')}
                isManager={isManager}
                onImportExcel={onImportExcel}
                onExportExcel={onExportExcel}
                onNew={emptyProductGroup}
                onDelete={onDelete}
                onSave={onSave}
                items={applyFilters(productGroups, filters, products).map(toTableItem)}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <TextField label={t('admin.masterdata.productgroup.name')}
                                text={selectedItem.name}
                                onChange={event => setSelectedItem({ ...selectedItem, name: event.target.value })} />
                            {availableProductsForProductGroup(selectedItem).length > 0 ?
                                <MultipleSelect label={t('admin.masterdata.productgroup.products')}
                                    chips={true}
                                    allWhenEmpty={false}
                                    disabled={!isManager}
                                    values={selectedItem.productIds}
                                    choices={availableProductsForProductGroup(selectedItem)}
                                    onChange={productIds => setSelectedItem({ ...selectedItem, productIds: productIds })} />
                                : <div>{t('admin.masterdata.productgroup.noproductsavailable')}</div>
                            }
                        </>
                    )}
            </MasterDataShell>
        </div >)
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
    })

export default withStyles(styles, muiOptions)(ProductGroupMasterData)