import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { t } from '../../../../infrastructure/i18nextHelper'
import { defaultStyles, muiOptions, MuiProps } from '../../../../infrastructure/materialUiThemeProvider'
import { hasFeature } from '../../../../infrastructure/feature'
import { StockMovementContainer } from '../stockMovementStore'
import * as Component from './_movementComponents'
import { switcherStyle } from './_commonStyle'

function _statusChangeForm({ isIn, classes }: { isIn: boolean } & MuiProps) {
    let store = StockMovementContainer.useContainer()

    if (!store.stockMovement.destinationDutyStatus || !store.stockMovement.originDutyStatus) {
        store.setStockMovement({
            ...store.stockMovement,
            destinationDutyStatus: t('stock.dutyStatus.local'),
            originDutyStatus: t('stock.dutyStatus.export')
        })
    }

    return (
        <div>
            <div className={classes.row + ' firstTransferRow'}>
                <Component.Date isIn={isIn} />
                <Component.Product isIn={isIn} />
                <Component.Company />
                <Component.Site isIn={isIn} />
                <Component.Volume />
                {hasFeature('ObservedQuantityTemperature')
                    ? <>
                        <Component.ObservedTemperature />
                        <Component.ObservedVolume />
                    </>
                    : null}
            </div>
            <div className={classes.row}>
                <Component.Mot />
                <Component.Status />
                <Component.RequestedDate />
            </div>
            <div className={classes.row}>
                <Component.ModeSwitcher
                    initiallyDualMode={true}
                    canSwitch={false}
                    forceDualMode={true}
                    originComponent={<Component.OriginDutyStatus />}
                    destinationComponent={<Component.DestinationDutyStatus />}
                    singleModeComponent={<></>}
                    onResetDualMode={() => undefined}
                    tooltipText={''}
                    dualModeIconClassName={classes.switchModeButton}
                    singleModeIconClassName={classes.adornment} />
                <Component.Reference />
                <Component.ExternalReference />
                {hasFeature('MovementExternalNumber') && <Component.OriginExternalNumber />}
                <Component.MirrorMovementButton buttonClass={classes.secondaryButton} />
            </div>
            <div className={classes.column}>
                <Component.Instruction />
                <Component.Comment />
            </div>
        </div>
    )
}

let styles = theme =>
    createStyles({
        ...switcherStyle,
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1em'
        },
        column: {
            ...defaultStyles.flexColumn,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1em',
            "& > div": {
                marginTop: "0.5em",
                marginBottom: "0.5em"
            }
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            margin: "1em"
        }
    })

export default withStyles(styles, muiOptions)(_statusChangeForm)
