import React, { useState, useEffect } from 'react'
import {
    withStyles, createStyles, Tabs, Tab, Typography, Dialog, DialogTitle,
    DialogContent, DialogActions, Checkbox, ListItemText, Tooltip, Grid
} from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { MuiProps, muiOptions, defaultColors, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import * as api from '../../../infrastructure/api'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { hasFeature, hasSeveralPurchaseMovementTypesFeature } from '../../../infrastructure/feature'
import { DatePicker, TextField, Select, ToBBLFactorField, ToVolumeFactorField, NumberField, Button, CustomDialog } from '../../common/customComponents'
import { PurchaseMovementTypes } from './_vesselPurchaseMovementTypeSection'
import { PurchaseMovements } from './_vesselPurchaseMovementSection'
import KenyaCostingInformation from './_vesselCostInformation'
import { VesselProduct, ProductQuantity, ComplementaryVesselFields, Quotation } from '../vesselModels'
import { VesselEditContainer } from './vesselEditStore'
import { useActionDebounce } from '../../common/debounce'

const tPrefix = 'vessels.label.product.'

type ProductSectionProps = {
    productCodeByProductId: { [productId: string]: string }
}

function ProductsSection({ productCodeByProductId, classes }: ProductSectionProps & MuiProps) {
    let vessel = VesselEditContainer.useContainer()
    let productsIndex = vessel.state.products.map((x, i) => { return { product: x, index: i } })
    let initTabIndex = productsIndex.find(x => x.product.productId == vessel.activeProductId)?.index ?? 0
    let [tabIndex, setTabIndex] = useState(initTabIndex)
    let [isProductDialogOpen, setIsProductDialogOpen] = useState<boolean>(false)
    let [isRemoveProductDialogOpen, setIsRemoveProductDialogOpen] = useState<boolean>(false)

    let currentProduct = vessel.state.products.length > 0 && tabIndex >= 0 && tabIndex < vessel.state.products.length
        && vessel.state.products[tabIndex] && vessel.state.products[tabIndex] || null

    return !vessel.state ? <div /> : (
        <div>
            <Grid container spacing={1} alignItems="center" justify='space-between'>
                <Grid item>
                    <Tabs classes={{ indicator: classes.TabsIndicatorColor }} value={tabIndex} onChange={(_, i) => setTabIndex(i)}>
                        {productsIndex.map(x =>
                            <Tab key={x.index} classes={{ textColorInherit: classes.TabTextColor }}
                                label={productCodeByProductId[x.product.productId]} />
                        )}
                    </Tabs>
                </Grid>
                {hasClaim(Claims.VesselWriter) || hasClaim(Claims.VesselManager) &&
                    <Grid item>
                        <Grid container spacing={2}>
                            {currentProduct && (hasClaim(Claims.VesselWriter) || hasClaim(Claims.VesselManager)) &&
                                <Grid item>
                                    <Tooltip placement='top' title={currentProduct.canRemove ? '' :
                                        <Typography variant='body1'>{t('vessels.label.product.removeProductQuantityImpossibleTooltip')}</Typography>}>
                                        <span>
                                            <Button className={classes.tertiaryButton}
                                                onClick={() => setIsRemoveProductDialogOpen(true)}
                                                disabled={!(currentProduct.canRemove ?? false)}
                                                label={t('vessels.label.product.removeProduct', { product: productCodeByProductId[currentProduct.productId] })}
                                                img={<Icons.DeleteOutlined />}
                                                color='primary' />
                                        </span>
                                    </Tooltip>
                                    <CustomDialog isOpen={isRemoveProductDialogOpen}
                                        title={t('vessels.label.product.confirmRemoveProduct', { product: productCodeByProductId[currentProduct.productId] })}
                                        contentText={t('vessels.label.product.confirmRemoveProductText', { product: productCodeByProductId[currentProduct.productId] })}
                                        onConfirm={() => {
                                            setIsRemoveProductDialogOpen(false)
                                            vessel.removeProduct(vessel.state.products[tabIndex])
                                        }}
                                        onClose={() => setIsRemoveProductDialogOpen(false)}
                                        onCancel={() => setIsRemoveProductDialogOpen(false)} />
                                </Grid>}
                            <Grid item>
                                <Tooltip placement='top' title={!vessel?.state?.jettyCode
                                    ? <Typography variant='body1'>{t(tPrefix + 'noJettyWarning')}</Typography>
                                    : ''}>
                                    <div>
                                        <Button className={classes.secondaryButton}
                                            onClick={() => { vessel?.state?.jettyCode && setIsProductDialogOpen(true) }}
                                            label={t('vessels.label.product.addProduct')}
                                            img={<Icons.AddOutlined />}
                                            color='primary' />
                                    </div>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>}
            </Grid>
            {productsIndex.map(x =>
                tabIndex !== x.index
                    ? undefined
                    : <div key={x.index}
                        role='tabpanel'
                        aria-labelledby={`wrapped-tab-${x.index}`}>
                        <ProductForm product={x.product} classes={classes} />
                    </div>)}
            <ProductSelectionDialog
                isOpen={isProductDialogOpen}
                handleClose={(newTabIndex) => { setIsProductDialogOpen(false); setTabIndex(newTabIndex) }}
                activeProductId={productsIndex.find(x => x.index == tabIndex)?.product.productId}
                classes={classes} />
        </div>
    )
}

type ProductSelectionDialogProps = {
    isOpen: boolean
    handleClose: (newIndex: number) => void,
    activeProductId?: string
} & MuiProps

function ProductSelectionDialog({ classes, isOpen, handleClose, activeProductId }: ProductSelectionDialogProps) {
    let vessel = VesselEditContainer.useContainer()
    let [selectedProducts, setSelectedProducts] = useState<{ code: string, id: string }[]>([])

    let products = Object.keys(vessel.products)
        .map((x) => { return { code: vessel.products[x], id: x } })
        ?.filter(p => vessel.state && vessel.state.products.findIndex(vp => vp.productId === p.id) < 0)

    let handleProductSelection = (product) => {
        let index = selectedProducts.findIndex(sp => sp.id === product.id)

        if (index > -1) selectedProducts.splice(index, 1)
        else selectedProducts.push(product)

        setSelectedProducts([...selectedProducts])
    }

    let handleSubmitDebouncer = useActionDebounce(async () => {
        if (!vessel.state.jettyCode) return
        let vesselProductsFormsPromises: Promise<VesselProduct>[] =
            selectedProducts.map(x => api.get<VesselProduct>(`vessel/${vessel.state?.id}/product/${x.id}?jetty=${vessel.state.jettyCode}`))
        let newProductsForms: VesselProduct[] = (await Promise.all(vesselProductsFormsPromises)).map(x => ({ ...x, canRemove: true }))
        vessel.updateVesselProducts(newProductsForms)
        vessel.updateCostingInformations(newProductsForms)
        setSelectedProducts([])
        handleClose(!activeProductId ? 0 : vessel.state.products.findIndex(x => x.productId === activeProductId))
    })

    return (
        <Dialog open={isOpen} onClose={handleClose} >
            <DialogTitle>{t('vessels.label.product.selectionDialog.title')}</DialogTitle>
            <DialogContent>
                {
                    products.map(x =>
                        <div key={x.id} className={classes.productItem} onClick={_ => handleProductSelection(x)}>
                            <Checkbox checked={selectedProducts.findIndex(sp => sp.id === x.id) >= 0}
                                classes={{ checked: classes.checkboxChecked, root: classes.noPadding }}
                                color='default'
                                onChange={_ => { }} />
                            <ListItemText primary={x.code} />
                        </div>)
                }
            </DialogContent>
            <DialogActions>
                <Button className={classes.closeButton}
                    label={t('vessels.label.product.selectionDialog.cancelButton')}
                    onClick={() => handleClose(!activeProductId ? 0 : vessel.state.products.findIndex(x => x.productId === activeProductId))}
                    color='primary' />
                <Button className={classes.submitButton}
                    label={t('vessels.label.product.selectionDialog.addButton')}
                    onClick={handleSubmitDebouncer.execute}
                    disabled={selectedProducts.length === 0}
                    color='primary' />
            </DialogActions>
        </Dialog>
    )
}

type InputProps = { prop: keyof VesselProduct, notifyChange: () => void }
type ProductInputProps = { maxLength?: number, isNumber?: boolean, unsigned?: boolean, allowNegative?: boolean, product: VesselProduct, label?: string } & InputProps

let DateInput = ({ product, prop, notifyChange, ...props }: ProductInputProps) =>
    <DatePicker {...props} date={product[prop] ? product[prop] as string : null}
        label={t(tPrefix + prop)}
        setDate={newDate => {
            product[prop as string] = newDate ?? null
            notifyChange()
        }} />

let TextInput = ({ product, prop, maxLength, isNumber, notifyChange, allowNegative, label, ...props }: ProductInputProps) =>
    isNumber
        ? <NumberField {...props} label={label ?? t(tPrefix + prop)}
            maxLength={maxLength}
            onChange={x => {
                product[prop as string] = x;
                notifyChange()
            }}
            text={product[prop] as (number | null)}
            allowNegative={allowNegative} />
        : <TextField {...props} label={label ?? t(tPrefix + prop)}
            maxLength={maxLength}
            onChange={e => {
                product[prop as string] = e.target.value;
                notifyChange()
            }}
            text={product[prop] as string} />

type QuantityTextInputProps = {
    quantity: ProductQuantity
    unit: string
    quantityProp: keyof ProductQuantity
    overrideProp?: keyof ProductQuantity
    removeAuto?: boolean
    disabled?: boolean
} & InputProps

let QuantityTextInput = ({ quantity, quantityProp, notifyChange, prop, overrideProp, removeAuto, unit, ...props }: QuantityTextInputProps) => {

    let hasOverride = () => overrideProp && quantity[overrideProp as string]

    return (<NumberField {...props} label={t(tPrefix + prop + '.' + quantityProp, { unit: unit })}
        text={quantity[quantityProp] as number}
        onChange={value => {
            if (overrideProp)
                quantity[overrideProp as string] = value !== null
            quantity[quantityProp as string] = value
            notifyChange()
        }}
        auto={!removeAuto && !hasOverride() && quantity[quantityProp] != null} />)
}

type FieldKind = 'Volume' | 'IndustryVolume' | 'Quantity' | 'BBL' | 'QuantityFactor' | 'VolumeQuantityFactor'
type ProductQuantityForm = { product: VesselProduct, notifyChange: () => void, prop: keyof VesselProduct } & MuiProps & InputProps
function ProductQuantityForm({ product, classes, ...props }: ProductQuantityForm) {
    let quantity = product[props.prop as string]
    let store = VesselEditContainer.useContainer()

    let isPropAuthorized = (prop: keyof VesselProduct, kind: FieldKind): boolean => {
        let property = (prop === 'company1Outturn' || prop === 'company2Outturn')
            ? 'outturn'
            : (prop === 'company1Reception' || prop === 'company2Reception')
                ? 'reception'
                : prop

        switch (property) {
            case 'billOfLading': return isFieldAuthorized('billOfLading', kind)
            case 'outturn': return isFieldAuthorized('outturn', kind)
            case 'reception': return isFieldAuthorized('reception', kind)
            default: return false
        }
    }

    let isFieldAuthorized = (prefix: string, kind: FieldKind): boolean => {
        switch (kind) {
            case 'Quantity': return store.isAuthorized(`${prefix}Quantity` as ComplementaryVesselFields)
            case 'Volume': return store.isAuthorized(`${prefix}Volume` as ComplementaryVesselFields)
            case 'IndustryVolume': return store.isAuthorized(`${prefix}IndustryVolume` as ComplementaryVesselFields)
            case 'BBL': return store.isAuthorized(`${prefix}Barrel` as ComplementaryVesselFields)
            case 'QuantityFactor': return store.isAuthorized(`${prefix}QuantityFactor` as ComplementaryVesselFields)
            case 'VolumeQuantityFactor': return store.isAuthorized(`${prefix}VolumeQuantityFactor` as ComplementaryVesselFields)
        }
    }

    if (product.productUnit === 'TO')
        return (<>{isPropAuthorized(props.prop, 'Quantity') &&
            <QuantityTextInput quantity={quantity} quantityProp={'quantity'} overrideProp={'quantityOverwritten'} {...props} unit={product.productUnit} />}</>)
    else if (hasFeature('ProductQuantityShowVolumeFirst'))
        return (<div className={classes.ProductDataRow}>
            {isPropAuthorized(props.prop, 'Volume') &&
                <QuantityTextInput quantity={quantity} quantityProp={'volume'} overrideProp={'volumeOverwritten'} {...props} unit={product.productUnit} />}
            {isPropAuthorized(props.prop, 'VolumeQuantityFactor') &&
                <ToVolumeFactorField label={t(tPrefix + props.prop + '.volumeQuantityFactor', { unit: product.productUnit })} text={quantity.volumeQuantityFactor} {...props} />}

            {isPropAuthorized(props.prop, 'Quantity') &&
                <QuantityTextInput quantity={quantity} quantityProp={'quantity'} overrideProp={'quantityOverwritten'} {...props} unit={product.productUnit} />}

            {isPropAuthorized(props.prop, 'QuantityFactor') &&
                <ToBBLFactorField key={`${props.prop}-quantity-factor`} label={t(tPrefix + props.prop + '.barrelQuantityFactor', { unit: product.productUnit })} text={quantity.barrelQuantityFactor} {...props} />}
            {isPropAuthorized(props.prop, 'BBL') &&
                <QuantityTextInput key={`${props.prop}-quantity-barrel`} quantity={quantity} quantityProp={'barrel'} overrideProp={'barrelOverwritten'} {...props} unit={product.productUnit} />}

            {isPropAuthorized(props.prop, 'IndustryVolume') &&
                <QuantityTextInput removeAuto quantity={quantity} quantityProp={'industryVolume'} {...props} unit={product.productUnit} />}

        </div>)
    else
        return (<div className={classes.ProductDataRow}>
            {isPropAuthorized(props.prop, 'Quantity') &&
                < QuantityTextInput quantity={quantity} quantityProp={'quantity'} overrideProp={'quantityOverwritten'} {...props} unit={product.productUnit} />}

            {isPropAuthorized(props.prop, 'QuantityFactor') &&
                <ToBBLFactorField key={`${props.prop}-quantity-factor`} label={t(tPrefix + props.prop + '.barrelQuantityFactor', { unit: product.productUnit })} text={quantity.barrelQuantityFactor} {...props} />}
            {isPropAuthorized(props.prop, 'BBL') &&
                <QuantityTextInput key={`${props.prop}-quantity-barrel`} quantity={quantity} quantityProp={'barrel'} overrideProp={'barrelOverwritten'} {...props} unit={product.productUnit} />}

            {isPropAuthorized(props.prop, 'VolumeQuantityFactor') &&
                <ToVolumeFactorField label={t(tPrefix + props.prop + '.volumeQuantityFactor', { unit: product.productUnit })} text={quantity.volumeQuantityFactor} {...props} />}
            {isPropAuthorized(props.prop, 'Volume') &&
                <QuantityTextInput quantity={quantity} quantityProp={'volume'} overrideProp={'volumeOverwritten'} {...props} unit={product.productUnit} />}

            {isPropAuthorized(props.prop, 'IndustryVolume') &&
                <QuantityTextInput removeAuto quantity={quantity} quantityProp={'industryVolume'} {...props} unit={product.productUnit} />}
        </div>)
}

type ProductFormProps = { product: VesselProduct }
function ProductForm({ product, classes }: ProductFormProps & MuiProps) {
    let store = VesselEditContainer.useContainer()
    let state = store.state
    if (!state) return <div />

    useEffect(() => {
        (async () => { if (!!state?.jettyCode && !!product.productId) await store.updateQuotations(product.productId) })()
    }, [state?.jettyCode, product.productId])

    let notifyChange = () => store.changeProduct({ ...product })

    let shouldAddOnlyPurchaseMovement = store.movementStock.map(x => x.company).distinct().length == 1
        && store.movementStock.map(x => x.dutyStatus).distinct().length == 1

    return (
        <div>
            <div className={classes.ProductDataRow}>
                <TextInput product={product} prop={'referenceNumber'} maxLength={20} notifyChange={notifyChange} />
                <TextInput product={product} prop={'trader'} maxLength={30} notifyChange={notifyChange} />
                <DateInput product={product} prop={'billOfLadingDate'} notifyChange={notifyChange} />
                <TextInput product={product} prop={'loadPort'} maxLength={30} notifyChange={notifyChange} />
            </div>
            {!hasFeature('PricingTrigger') && hasFeature("VesselAutoCreateDeal")
                && <div className={classes.ProductDataRow}>
                    <Select label={t(tPrefix + 'quotationCode')} value={(product.quotationCode)}
                        choices={store.quotations.map(x => ({ value: x.medecoCode, text: x.longName }))}
                        onChange={val => { product.quotationCode = val; notifyChange() }} />
                    {!hasFeature('VesselMultiplePricingPeriod') ?
                        [<DateInput key={'pricingStartDate'} product={product} prop={'pricingStartDate'} notifyChange={notifyChange} />,
                        <DateInput key={'pricingEndDate'} product={product} prop={'pricingEndDate'} notifyChange={notifyChange} />]
                        : undefined
                    }
                    {product.costingInformation && !hasFeature('VesselMultiplePremium') &&
                        <TextInput product={product} prop={'premium'} notifyChange={notifyChange} isNumber={true}
                            allowNegative={true} label={t(tPrefix + 'premium', { unit: product.premiumCurrency })} />}
                </div>
            }
            {product.billOfLading &&
                <ProductQuantityForm product={product} prop={'billOfLading'} notifyChange={notifyChange} classes={classes} />}
            {product.outturn &&
                <ProductQuantityForm product={product} prop={'outturn'} notifyChange={notifyChange} classes={classes} />}
            {product.reception &&
                <ProductQuantityForm product={product} prop={'reception'} notifyChange={notifyChange} classes={classes} />}
            {store.isAuthorized('outturnCompany1') && product.company1Outturn &&
                <ProductQuantityForm product={product} prop={'company1Outturn'} notifyChange={notifyChange} classes={classes} />}
            {store.isAuthorized('receptionCompany1') && product.company1Reception &&
                <ProductQuantityForm product={product} prop={'company1Reception'} notifyChange={notifyChange} classes={classes} />}
            {store.isAuthorized('outturnCompany2') && product.company2Outturn &&
                <ProductQuantityForm product={product} prop={'company2Outturn'} notifyChange={notifyChange} classes={classes} />}
            {store.isAuthorized('receptionCompany2') && product.company2Reception &&
                <ProductQuantityForm product={product} prop={'company2Reception'} notifyChange={notifyChange} classes={classes} />}
            {store.isAuthorized('sharingLineLoss') &&
                < NumberField label={t(tPrefix + 'sharingLineLoss', { unit: product.premiumCurrency })}
                    text={product.sharingLineLoss}
                    onChange={value => { product.sharingLineLoss = value; notifyChange() }} />}
            {store.costingInformationByVesselProductId && store.costingInformationByVesselProductId[product.id] && hasFeature("VesselAutoCreateDeal")
                ? <KenyaCostingInformation costingInformation={store.costingInformationByVesselProductId[product.id]} productUnit={product.productUnit} />
                : undefined
            }
            {hasSeveralPurchaseMovementTypesFeature()
                ? <PurchaseMovementTypes product={product} />
                : <PurchaseMovements vesselProduct={product}
                    type={"FormulaPrice"}
                    hasOnlyOnePurchaseMovement={shouldAddOnlyPurchaseMovement}
                    canDeletePurchaseMovement={!shouldAddOnlyPurchaseMovement}
                    removeBlock={(_) => { }} />
            }
        </div>
    )
}

let styles = _ =>
    createStyles({
        TabTextColor: {
            color: defaultColors.red.main.color
        },
        TabsIndicatorColor: {
            backgroundColor: defaultColors.red.main.color
        },
        productItem: {
            ...defaultStyles.flexRow
        },
        ProductDataRow: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1.0em'
        },
        textField: {
            width: '15.3em',
            marginLeft: '0.5em',
            marginRight: '0.5em',
            backgroundColor: defaultColors.grey.light.color
        },
        noPadding: {
            padding: '0px',
        },
        checkboxChecked: {
            color: defaultColors.red.main.color,
        },
        closeButton: {
            ...defaultStyles.dialogCloseButton
        },
        submitButton: {
            ...defaultStyles.dialogPrimaryButton
        },
        secondaryButton: { ...defaultStyles.secondaryButton },
        tertiaryButton: { ...defaultStyles.tertiaryButton }
    })

export default withStyles(styles, muiOptions)(ProductsSection)