import React from 'react'
import moment from 'moment'
import { withStyles, createStyles, Typography, Divider, IconButton } from '@material-ui/core'
import { Button, TextField } from '../../common/customComponents'
import { defaultStyles, defaultColors, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { storageLanguageKey, t } from '../../../infrastructure/i18nextHelper'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasFeature } from '../../../infrastructure/feature'
import { Select, DatePicker, NumberField, AttachFileButton, DateRangePicker } from '../../common/customComponents'
import { FieldStatusStore } from '../../common/fieldsStatus'
import { DealContainer } from './dealEditStore'
import guid, { Guid } from '../../../infrastructure/guid'
import * as Icons from '@material-ui/icons'
import { priceIndexDetailsDialog } from '../../pricings/priceIndexDetailsDialog'
import {
    FieldState, ValidationProcessForm, Deal,
    PriceIndex, DealPriceIndex, DealPricingPeriod,
    Period, MultiplePricingBlockType,
    MapDealFieldGroup, DealFieldGroup
} from '../dealModels'
import { pricingDialog } from '../../../app/pricings/pricingDialog'
import { CommentButton } from '../../common/comment/commentButton'
import { CommentContent } from '../../common/comment/commentContent'

const tPrefix = 'deals.label.'

function _FirstLine({ classes }) {
    return (
        <div className={classes.row}>
            <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                {t('deals.popup.pricingTitle')}
            </Typography>
        </div>
    )
}

function _SecondLine({ classes }) {
    let store = DealContainer.useContainer()
    if (!store || !store.deal) return <></>

    let priceIndexes = store.priceIndexes

    function isFinalValidationEngaged() {
        if (!store.deal) return false
        let process = store.deal.finalPricesValidation
        return !!process && (process.steps.findIndex(x => x === process?.status) > 0)
    }

    let updatePremium = (newValue: number | null) => {
        if (deal.pricingType == 'Trigger')
            store.setDeal({ ...deal, fixedPrice: { ...deal.fixedPrice, premium: { ...deal.fixedPrice.premium, value: newValue, isOverwritten: isAValue(newValue) } } })
        else
            store.setDeal({ ...deal, quotationPeriod: { ...deal.quotationPeriod, premium: { ...deal.quotationPeriod.premium, value: newValue, isOverwritten: isAValue(newValue) } } })
    }

    let deal = store.deal
    let premium = deal.pricingType == 'Trigger' ? deal.fixedPrice.premium : deal.quotationPeriod.premium
    let bookingExchangeRate = store.deal.fields.find(x => x.code === "booking_exchange_rate")
    let bookingExchangeRateBorderCountry = deal?.borderCountryCurrency !== null
        ? deal.fields.find(x => x.code === "booking_exchange_rate_" + deal.borderCountryCurrency?.toLowerCase())
        : null

    let bookingExchangeRateUsdEur = deal.fields.find(x => x.code === "booking_exchange_rate_usd_eur")

    let contractualDensity = store.deal.fields.find(x => x.code === "contractual_density")
    let densityBblTo = store.deal.fields.find(x => x.code === "density_bbl_to")
    let densityToM15 = store.deal.fields.find(x => x.code === "density_to_m15")
    let exchangeRate = store.deal.fields.find(x => x.code === "exchange_rate")

    let isGenericPricing = deal.pricingType == 'Formula' && hasFeature('GenericPricing')

    let priceIndexCurrency = priceIndexes.find(x => x.medecoCode === deal.quotationPeriod.quotation.value)?.currency
    let shouldDisplayExchangeRate = exchangeRate && deal.pricingType === 'Formula' && !hasFeature('GenericPricing')
        && !!priceIndexCurrency && !!deal.currency && priceIndexCurrency !== deal.currency

    let exchangeRateUnit = !!exchangeRate ?
        store.resolveExchangeRateUnit(exchangeRate?.currencyPerQuantityUnit!, priceIndexCurrency) : null

    return (
        <>
            <div className={(isGenericPricing ? classes.column : classes.row) + ' ' + classes.marginStartLine}>
                {isGenericPricing
                    ? <MultipleFormulaPricing classes={classes} priceIndexes={priceIndexes} isFinalValidationEngaged={isFinalValidationEngaged()} />
                    : <QuotationPeriod classes={classes}
                        quotations={priceIndexes.filter(x => x.type === 'Quotation').map(x => ({ value: x.medecoCode, text: x.name }))}
                        isFinalValidationEngaged={isFinalValidationEngaged()}
                        exchangeRate={exchangeRate} />}
                {bookingExchangeRate ?
                    <NumberField
                        status={store.costStatus.getStatus(bookingExchangeRate.code)}
                        label={[t(tPrefix + 'bookingExchangeRate'), bookingExchangeRate.currencyPerQuantityUnit].join(' ')}
                        text={bookingExchangeRate.price}
                        disabled={bookingExchangeRate.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        onChange={newValue => store.changeFieldValue("booking_exchange_rate", newValue)} />
                    : undefined}
                {bookingExchangeRateBorderCountry ?
                    <NumberField
                        status={store.costStatus.getStatus(bookingExchangeRateBorderCountry.code)}
                        label={[t(tPrefix + 'bookingExchangeRate'), bookingExchangeRateBorderCountry.currencyPerQuantityUnit].join(' ')}
                        text={bookingExchangeRateBorderCountry.price}
                        disabled={bookingExchangeRateBorderCountry.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        onChange={newValue => bookingExchangeRateBorderCountry && store.changeFieldValue(bookingExchangeRateBorderCountry.code, newValue)} />
                    : undefined}
                {bookingExchangeRateUsdEur ?
                    <NumberField
                        status={store.costStatus.getStatus(bookingExchangeRateUsdEur.code)}
                        label={[t(tPrefix + 'bookingExchangeRate'), bookingExchangeRateUsdEur.currencyPerQuantityUnit].join(' ')}
                        text={bookingExchangeRateUsdEur.price}
                        disabled={bookingExchangeRateUsdEur.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        onChange={newValue => bookingExchangeRateUsdEur && store.changeFieldValue(bookingExchangeRateUsdEur.code, newValue)} />
                    : undefined}
                {contractualDensity ?
                    <NumberField
                        status={store.costStatus.getStatus(contractualDensity.code)}
                        label={[t(tPrefix + 'contractualDensity'), contractualDensity.currencyPerQuantityUnit].join(' ')}
                        text={contractualDensity.price}
                        disabled={contractualDensity.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        onChange={newValue => store.changeFieldValue("contractual_density", newValue)} />
                    : undefined}
                {densityBblTo ?
                    <NumberField
                        status={store.costStatus.getStatus(densityBblTo.code)}
                        label={[t(tPrefix + 'costs.density'), densityBblTo.currencyPerQuantityUnit].join(' ')}
                        text={densityBblTo.price}
                        disabled={densityBblTo.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        onChange={newValue => store.changeFieldValue("density_bbl_to", newValue)} />
                    : undefined}
                {densityToM15 ?
                    <NumberField
                        status={store.costStatus.getStatus(densityToM15.code)}
                        label={[t(tPrefix + 'costs.density'), densityToM15.currencyPerQuantityUnit].join(' ')}
                        text={densityToM15.price}
                        disabled={densityToM15.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        onChange={newValue => store.changeFieldValue("density_to_m15", newValue)} />
                    : undefined}
                {(deal.pricingType === 'Trigger' || (deal.pricingType === 'Formula' && !hasFeature('GenericPricing')))
                    ? <NumberField
                        status={store.costStatus.getStatus('premium')}
                        label={[t(tPrefix + 'premium'), store.resolveDealFieldUnit(premium.unit as string, deal.pricingType, isGenericPricing, 'default')].join(' ')}
                        text={premium.value}
                        disabled={premium.readOnly || isFinalValidationEngaged()}
                        precision={5}
                        allowNegative
                        onChange={newValue => updatePremium(newValue)} />
                    : undefined}
                {shouldDisplayExchangeRate
                    ? <NumberField
                        status={store.costStatus.getStatus('')}
                        label={[t(tPrefix + 'exchangeRate'), exchangeRateUnit].join(' ')}
                        text={exchangeRate?.price}
                        disabled={exchangeRate!.readOnly}
                        precision={5}
                        allowNegative
                        onChange={newValue => store.changeFieldValue('exchange_rate', newValue)} />
                    : undefined}
            </div>
        </>
    )
}

type QuotationPeriodProps = {
    classes?: any
    quotations: { value: string, text: string }[]
    isFinalValidationEngaged: boolean
    exchangeRate: FieldState | undefined
}

function QuotationPeriod({ classes, quotations, isFinalValidationEngaged, exchangeRate }: QuotationPeriodProps) {
    let store = DealContainer.useContainer()
    if (!store || !store.deal) return <></>

    let { deal, priceIndexes, currency } = store

    let quotationOverwritten = { readOnly: false }
    let priceIndexCurrency = priceIndexes.find(x => x.medecoCode === deal.quotationPeriod.quotation.value)?.currency
    let fixedPriceOverwritten = { isOverwritten: true, readOnly: false }

    let shouldDisplayExchangeRate = (pricingType) => {
        if (!exchangeRate) return false;
        if (pricingType === 'Fixed' && currency && deal.currency && deal.currency !== 'Invalid' && deal.currency !== currency) return true;
        if (pricingType === 'Trigger' && priceIndexCurrency && deal.currency && deal.currency !== 'Invalid' && priceIndexCurrency !== deal.currency) return true;
        return false;
    }

    let exchangeRateUnit = !!exchangeRate ?
        store.resolveExchangeRateUnit(exchangeRate?.currencyPerQuantityUnit!, priceIndexCurrency)
        : null

    let handleQuotationChange = (val) => {
        store.setDeal({
            ...deal,
            quotationPeriod: {
                ...deal.quotationPeriod,
                quotation: { readOnly: false, value: val, isOverwritten: true, unit: '' }
            }
        })
    }

    return (
        <div className={classes.row + ' ' + classes.marginStartLine}>
            {deal.pricingType !== 'Fixed' && (
                <Select
                    choices={quotations}
                    label={t(tPrefix + 'quotation')}
                    value={deal.quotationPeriod.quotation.value}
                    disabled={deal.quotationPeriod.quotation.readOnly || isFinalValidationEngaged}
                    onChange={handleQuotationChange}
                />
            )}
            {shouldDisplayExchangeRate(deal.pricingType) && (
                <NumberField
                    status={store.costStatus.getStatus('')}
                    label={[t(tPrefix + 'exchangeRate'), exchangeRateUnit].join(' ')}
                    text={exchangeRate?.price}
                    disabled={exchangeRate!.readOnly}
                    precision={5}
                    allowNegative
                    onChange={(newValue) => store.changeFieldValue('exchange_rate', newValue)}
                />
            )}
            {
                deal.pricingType === 'Formula'
                    ? [<DatePicker key={'pricingStartDate'} label={t(tPrefix + 'pricingStartDate')} date={deal.quotationPeriod.start.value} auto={!deal.quotationPeriod.start.isOverwritten}
                        disabled={deal.quotationPeriod.start.readOnly || isFinalValidationEngaged}
                        setDate={newDate => {
                            let hasValue = !!newDate && moment(newDate).isValid()
                            store.setDeal({ ...deal, quotationPeriod: { ...deal.quotationPeriod, start: { ...quotationOverwritten, value: hasValue ? newDate : null, isOverwritten: hasValue } } })
                        }} />,
                    <DatePicker key={'pricingEndDate'} label={t(tPrefix + 'pricingEndDate')} date={deal.quotationPeriod.end.value} auto={!deal.quotationPeriod.end.isOverwritten}
                        disabled={deal.quotationPeriod.end.readOnly || isFinalValidationEngaged}
                        setDate={newDate => {
                            let hasValue = !!newDate && moment(newDate).isValid()
                            store.setDeal({ ...deal, quotationPeriod: { ...deal.quotationPeriod, end: { ...quotationOverwritten, value: hasValue ? newDate : null, isOverwritten: hasValue } } })
                        }} />]
                    : [<DatePicker key={'triggerDate'}
                        label={deal.pricingType === 'Trigger' ? t(tPrefix + 'triggerDate') : t(tPrefix + 'fixedPriceDate')}
                        date={deal.fixedPrice.date.value}
                        disabled={deal.fixedPrice.date.readOnly || isFinalValidationEngaged}
                        setDate={newDate => { if (newDate) store.setDeal({ ...deal, fixedPrice: { ...deal.fixedPrice, date: { ...fixedPriceOverwritten, value: newDate } } }) }} />,
                    <NumberField key={'triggerPrice'}
                        label={[deal.pricingType === 'Trigger'
                            ? t(tPrefix + 'triggerPrice')
                            : t(tPrefix + 'fixedPricePrice')
                            , store.resolveDealFieldUnit(deal.fixedPrice.price.unit as string, deal.pricingType, hasFeature('GenericPricing'), 'default')].join(' ')}
                        text={deal.fixedPrice.price.value}
                        disabled={deal.fixedPrice.price.readOnly || isFinalValidationEngaged}
                        precision={5}
                        onChange={newValue => store.setDeal({ ...deal, fixedPrice: { ...deal.fixedPrice, price: { ...fixedPriceOverwritten, value: newValue, unit: deal.fixedPrice.price.unit } } })} />
                    ]
            }
        </div>)
}

type MultipleFormulaPricing = {
    classes?: any
    isFinalValidationEngaged: boolean,
    priceIndexes: PriceIndex[]
}

function MultipleFormulaPricing({ classes, isFinalValidationEngaged, priceIndexes }: MultipleFormulaPricing) {
    let store = DealContainer.useContainer()
    if (!store || !store.deal) return <></>
    let deal = store.deal

    return (<>
        <MultiplePriceIndexPricingPeriod
            classes={classes}
            isFinalValidationEngaged={isFinalValidationEngaged}
            priceIndexes={priceIndexes}
            type='Final'
            dealPriceIndexes={deal.finalPricing.priceIndexes}
            dealPricingPeriods={deal.finalPricing.pricingPeriods}
            quantityPriceUnit={deal.quantityPriceUnit}
            dealCurrency={deal.currency}
            exchangeRate={store.deal.fields.find(x => x.code === "exchange_rate")} />
        {hasFeature('ProvisionalPricing')
            ? <>
                <Divider className={classes.divider} />
                <MultiplePriceIndexPricingPeriod
                    classes={classes}
                    isFinalValidationEngaged={isFinalValidationEngaged}
                    priceIndexes={priceIndexes}
                    type='Provisional'
                    dealPriceIndexes={deal.provisionalPricing.priceIndexes}
                    dealPricingPeriods={deal.provisionalPricing.pricingPeriods}
                    quantityPriceUnit={deal.quantityPriceUnit}
                    dealCurrency={deal.currency}
                    exchangeRate={store.deal.fields.find(x => x.code === 'provisional_exchange_rate')} />
            </>
            : null}
    </>
    );
}

type MultiplePriceIndexPricingPeriodProps = {
    classes?: any
    isFinalValidationEngaged: boolean,
    priceIndexes: PriceIndex[],
    type: MultiplePricingBlockType,
    dealPriceIndexes: DealPriceIndex[],
    dealPricingPeriods: DealPricingPeriod[],
    quantityPriceUnit: string | null,
    dealCurrency: string | null,
    exchangeRate: FieldState | undefined,
    openPricing?: () => void | undefined
}

function MultiplePriceIndexPricingPeriod(
    { classes, isFinalValidationEngaged, priceIndexes, type, dealPriceIndexes, dealPricingPeriods, quantityPriceUnit, dealCurrency, exchangeRate }:
        MultiplePriceIndexPricingPeriodProps) {
    let store = DealContainer.useContainer()

    let onChangePriceIndexPercentage = (id: Guid, newValue: number) => {
        let existingPriceIndex = dealPriceIndexes.find(x => x.id === id)
        if (!existingPriceIndex) return
        let updatedPriceIndex = { ...existingPriceIndex, percentage: newValue }
        store.addOrUpdateDealPriceIndex(updatedPriceIndex, type)
    }

    let onChangePriceIndexCode = (id: Guid, newValue: string) => {
        let existingPriceIndex = dealPriceIndexes.find(x => x.id === id)
        if (!existingPriceIndex) return
        let updatedPriceIndex = { ...existingPriceIndex, priceIndexCode: newValue }
        store.addOrUpdateDealPriceIndex(updatedPriceIndex, type)
    }

    let onChangePricingPeriod = (id: Guid, applyChange: (x: DealPricingPeriod) => void) => {
        let existingPricingPeriod = dealPricingPeriods.find(x => x.id === id)
        if (!existingPricingPeriod) return
        let updatedPricingPeriod = { ...existingPricingPeriod }
        applyChange(updatedPricingPeriod)
        store.addOrUpdateDealPricingPeriod(updatedPricingPeriod, type)
    }

    let createDealPriceIndex = () => {
        store.addOrUpdateDealPriceIndex({ id: guid.createNew(), percentage: 0, priceIndexCode: '' }, type)
    }

    let createDealPricingPeriod = () => {
        store.addOrUpdateDealPricingPeriod({
            id: guid.createNew(),
            percentage: 0,
            start: null,
            end: null,
            premium: 0,
            baseUnitPrice: null,
            premiumUnit: store.getPricingPeriodPremiumUnit(type)
        }, type)
    }

    let mappedDealFieldGroup = MapDealFieldGroup(type)

    let openPricing = async () => {
        if (!store.dealId) return
        let costGroupUsedInPricingFieldNames: DealFieldGroup = mappedDealFieldGroup === 'default' ? 'final' : 'provisional'
        if (hasClaim(Claims.DealManager)) {
            if (await store.saveIfNoFieldErrors())
                pricingDialog.open({ id: store.dealId, pricingType: store.deal?.pricingType ?? null, costGroup: costGroupUsedInPricingFieldNames })
        }
        else
            pricingDialog.open({ id: store.dealId, pricingType: store.deal?.pricingType ?? null, costGroup: costGroupUsedInPricingFieldNames })
    }

    let onDeletePriceIndex = (id: Guid) => { store.removeDealPriceIndex(id, type) }
    let onDeletePricingPeriod = (id: string) => { store.removeDealPricingPeriod(id, type) }
    let allowPriceIndexDelete = dealPriceIndexes.length > 1 && !isFinalValidationEngaged
    let allowPricingPeriodDelete = dealPricingPeriods.length > 1 && !isFinalValidationEngaged
    let allowPriceIndexCreation = hasFeature('DealFormulaMultipleQuotationIndex') && !isFinalValidationEngaged
    let allowPricingPeriodCreation = hasFeature('DealFormulaMultiplePricingPeriod') && !isFinalValidationEngaged

    let principalPriceIndexCurrency = priceIndexes.find(x => x.medecoCode === dealPriceIndexes.first()?.priceIndexCode)?.currency
    let shouldDisplayExchangeRate = exchangeRate && !!principalPriceIndexCurrency && !!dealCurrency && principalPriceIndexCurrency !== dealCurrency
    let exchangeRateUnit = !!exchangeRate ?
        store.resolveExchangeRateUnit(exchangeRate?.currencyPerQuantityUnit!, principalPriceIndexCurrency, type) : null

    let getAssociationKey = () => type === 'Final' ? `deal-final-pricing-${store.dealId}` : `deal-provisional-pricing-${store.dealId}`

    return (
        <div className={[classes.column, classes.marginStartLine, classes.marginBottom].join(' ')}>
            <Typography className={classes.subtitle} variant='overline'>{t('deals.label.costGroups.'.concat(mappedDealFieldGroup!))}</Typography>
            {shouldDisplayExchangeRate && <NumberField
                status={store.costStatus.getStatus('')}
                label={[t(tPrefix + 'exchangeRate'), exchangeRateUnit].join(' ')}
                text={exchangeRate?.price}
                disabled={exchangeRate!.readOnly}
                precision={5}
                allowNegative
                onChange={newValue => store.changeFieldValue(type === 'Final' ? 'exchange_rate' : 'provisional_exchange_rate', newValue)} />}
            <div className={classes.marginBottom}>{dealPriceIndexes.map((x, index) =>
                <PriceIndexLine classes
                    key={x.id}
                    dealPriceIndex={x}
                    priceIndexes={priceIndexes}
                    onChangePriceIndexPercentage={val => onChangePriceIndexPercentage(x.id, val)}
                    onChangePriceIndexCode={val => onChangePriceIndexCode(x.id, val)}
                    onDelete={allowPriceIndexDelete ? (() => onDeletePriceIndex(x.id)) : undefined}
                    onCreate={allowPriceIndexCreation && index === 0 ? createDealPriceIndex : undefined}
                    globalPricingPeriod={store.getGlobalPricingPeriod(type)}
                    productId={store.deal?.productId}
                    readonly={isFinalValidationEngaged} />)}
            </div>
            <div>
                {
                    dealPricingPeriods.map((x, index) =>
                        <PricingPeriodLine
                            classes={classes}
                            key={x.id}
                            pricingPeriod={x}
                            onChangePricingPeriod={(f) => onChangePricingPeriod(x.id, f)}
                            priceUnit={quantityPriceUnit ?? '?'}
                            onCreate={allowPricingPeriodCreation && index === 0 ? createDealPricingPeriod : undefined}
                            onDelete={allowPricingPeriodDelete ? (() => onDeletePricingPeriod(x.id)) : undefined}
                            readonly={isFinalValidationEngaged}
                        />)
                }
                <div className={classes.commentSection}>
                    <CommentContent associationKey={getAssociationKey()} />
                    <CommentButton withPadding associationKey={getAssociationKey()} />
                </div>
            </div>
            {mappedDealFieldGroup && <CostGroup displayTitle={false} costGroup={mappedDealFieldGroup} classes={classes} />}
            <div>
                <Button onClick={openPricing}
                    label={t('deals.popup.goToPricing')}
                    img={<Icons.GavelOutlined className={classes.buttonPictogram} />}
                    className={classes.buttonToPricing} />
            </div>
        </div>
    )
}

type PriceIndexLineProps = {
    classes?: any
    dealPriceIndex: DealPriceIndex
    priceIndexes: PriceIndex[]
    onChangePriceIndexPercentage: (newValue: number) => void
    onChangePriceIndexCode: (newValue: string) => void
    onDelete?: () => void | undefined
    onCreate?: () => void | undefined
    globalPricingPeriod: Period
    productId?: string | null
    readonly: boolean
}
function PriceIndexLine({ classes, dealPriceIndex, priceIndexes,
    onChangePriceIndexPercentage, onChangePriceIndexCode, onDelete, onCreate,
    globalPricingPeriod, productId, readonly }: PriceIndexLineProps) {
    let priceIndexChoices = priceIndexes.map(x => ({ value: x.medecoCode, text: x.name }))
    let priceIndex = priceIndexes.find(x => x.medecoCode == dealPriceIndex.priceIndexCode)
    let priceIndexQuantityPriceUnit = [priceIndex?.currency ?? '?', priceIndex?.unitOfMeasurement ?? '?'].join('/')

    let openPriceIndexDialog = () => {
        if (!globalPricingPeriod.from || !globalPricingPeriod.to || !dealPriceIndex.priceIndexCode) return
        priceIndexDetailsDialog.open({ index: dealPriceIndex.priceIndexCode, from: globalPricingPeriod.from, to: globalPricingPeriod.to, productId: productId! })
    }

    let enabalePriceIndexPopupButton = !!globalPricingPeriod.from && !!globalPricingPeriod.to && !!dealPriceIndex.priceIndexCode

    return (<div className={classes.row} >
        <IconButton disabled={!enabalePriceIndexPopupButton} onClick={openPriceIndexDialog}>
            <Icons.OpenInNewOutlined />
        </IconButton>
        <NumberField
            size={'small'}
            label={t(tPrefix + 'percentage')}
            text={dealPriceIndex.percentage * 100}
            precision={5}
            disabled={readonly}
            onChange={val => { if (val !== null) onChangePriceIndexPercentage(val / 100) }} />
        <Select
            label={t(tPrefix + 'quotation')}
            choices={priceIndexChoices}
            value={dealPriceIndex.priceIndexCode}
            disabled={readonly}
            onChange={val => { if (val) onChangePriceIndexCode(val) }} />
        <TextField
            label={t(tPrefix + 'quantityPriceUnit')}
            text={priceIndexQuantityPriceUnit}
            disabled={true} />
        {onDelete && <IconButton onClick={onDelete}>
            <Icons.DeleteOutlined />
        </IconButton>}
        {onCreate && <IconButton onClick={onCreate}>
            <Icons.Add />
        </IconButton>}
    </div>)
}

type DealPricingPeriodLineProps = {
    classes?: any
    pricingPeriod: DealPricingPeriod
    priceUnit: string
    onChangePricingPeriod: (applyChange: (pp: DealPricingPeriod) => void) => void
    onDelete?: () => void | undefined
    onCreate?: () => void | undefined
    readonly: boolean
}
function PricingPeriodLine({ classes, pricingPeriod, priceUnit, onChangePricingPeriod, onDelete, onCreate, readonly }: DealPricingPeriodLineProps) {
    return (<div className={classes.row} >
        <NumberField
            size={'small'}
            label={t(tPrefix + 'percentage')}
            text={pricingPeriod.percentage * 100}
            precision={5}
            disabled={readonly}
            onChange={val => { if (val !== null) onChangePricingPeriod((x: DealPricingPeriod) => x.percentage = val / 100) }} />
        <DateRangePicker
            startLabel={t(tPrefix + 'pricingStartDate')} startDate={pricingPeriod.start} disabled={readonly}
            endLabel={t(tPrefix + 'pricingEndDate')} endDate={pricingPeriod.end} autoReplace={true}
            onPeriodChange={period =>
                onChangePricingPeriod((x: DealPricingPeriod) => {
                    x.start = !!period.startDate && moment(period.startDate).isValid() ? period.startDate : null
                    x.end = !!period.endDate && moment(period.endDate).isValid() ? period.endDate : null
                })
            } />
        <NumberField
            label={[t(tPrefix + 'premium'), pricingPeriod.premiumUnit].join(' ')}
            text={pricingPeriod.premium}
            precision={5}
            disabled={readonly}
            allowNegative
            onChange={val => { onChangePricingPeriod((x: DealPricingPeriod) => x.premium = val ?? 0) }} />
        <TextField
            label={[t(tPrefix + 'baseUnitPrice'), priceUnit].join(' ')}
            text={pricingPeriod.baseUnitPrice}
            disabled={true}
            auto={true} />
        {onDelete && <IconButton onClick={onDelete}>
            <Icons.DeleteOutlined />
        </IconButton>}
        {onCreate && <IconButton onClick={onCreate}>
            <Icons.Add />
        </IconButton>}
    </div>)
}

function isAValue(str: any) { return str != null && str !== '' }

function _ThirdLine({ classes }) {
    let store = DealContainer.useContainer()

    let elements = React.useMemo(() => {
        if (!store?.deal?.fields?.length) return []

        let costsPerGroup = store.deal.fields.filter(x => x.group !== "custom").indexMultipleValueByProp('group')
        return Object.keys(costsPerGroup).sort(compareCostsGroup)

        function compareCostsGroup(groupA: string, groupB: string): number {
            let indexA = costsPerGroup[groupA][0].groupOrder
            let indexB = costsPerGroup[groupB][0].groupOrder
            return indexA - indexB
        }
    }, [store?.deal?.fields])

    return (
        <div>
            {elements.map((costGroup: DealFieldGroup, index) =>
                <CostGroup key={index} displayTitle={true} costGroup={costGroup} classes={classes} />)}
        </div>
    )
}

type CostGroupProps = {
    displayTitle?: boolean
    costGroup: DealFieldGroup
    classes?: any
}

function CostGroup({ displayTitle, costGroup, classes }: CostGroupProps) {
    let store = DealContainer.useContainer()
    let deal = store.deal as Deal
    let costsPerGroup = deal.fields.indexMultipleValueByProp('group')
    let isGenericPricingActive = hasFeature('GenericPricing')
    let changeValue = (group: string, index: number, newValue: number | null, unit: string) => {
        let costs = deal.fields
        let globalIndex = costs.findIndex(x => x.code == costsPerGroup[group][index].code)
        costs[globalIndex].price = newValue
        costs[globalIndex].currencyPerQuantityUnit = unit
        store.setDeal({ ...deal, fields: costs })
    }

    let isProvisional = (group: string) => group !== 'default'
    let isFinalPrice = (code: string) => ['provisional_total_amount', 'total_amount'].includes(code)
    let getGroupName = (group: string) => isProvisional(group) ? 'provisional' : 'final'

    function validationProcessEngaged(validationProcess: ValidationProcessForm | null): boolean {
        return !!validationProcess && validationProcess!.steps.findIndex(x => x === validationProcess!.status) > 0
    }

    let anyValidationProcessEngaged = (): boolean =>
        validationProcessEngaged(store?.deal?.provisionalPricesValidation ?? null)
        || validationProcessEngaged(store?.deal?.finalPricesValidation ?? null)

    function costGroupReadonly(costGroup: string) { return costGroupValidating(costGroup) }

    function costGroupValidating(costGroup: string) {
        return costGroup === 'default' && validationProcessEngaged(store?.deal?.finalPricesValidation ?? null)
            || costGroup === 'provisional' && anyValidationProcessEngaged()
    }
    return (
        <>
            <Divider className={classes.divider} />
            <div className={displayTitle ? classes.costGroupHeaderLineWithTitle : classes.costGroupHeaderLineWithoutTitle}>
                {displayTitle && <Typography className={classes.subtitle} variant='overline'>{t('deals.label.costGroups.' + costGroup)}</Typography>}
                <AttachFileButton
                    id={`upload-${getGroupName(costGroup)}-doc-button`}
                    className={classes.documentButton}
                    title={t(`deals.label.${getGroupName(costGroup)}DocumentsDialogTitle`)}
                    disableDelOrAdd={!hasClaim(Claims.DealManager) || costGroupValidating(costGroup)}
                    context={deal.company}
                    keyTemplate={`pricing-{dealId}-${costGroup}`}
                    keyParameters={{ dealId: store.dealId ? store.dealId.replace(/-/g, '') : '' }} />
            </div>
            <CostIncludedInTotal classes={classes}
                costGroup={costGroup}
                costs={costsPerGroup[costGroup]}
                changeValue={changeValue}
                isFinalPrice={isFinalPrice}
                hasAssociatedVessel={store.locked()}
                allDisabled={costGroupReadonly(costGroup)}
                dutyStatus={deal.dutyStatus ?? ''}
                costStatus={store.costStatus}
                getDealFieldUnit={(unit: string) => store.resolveDealFieldUnit(unit, deal.pricingType, isGenericPricingActive, costGroup)} />
            <CostNonIncludedInTotal classes={classes}
                costGroup={costGroup}
                costs={costsPerGroup[costGroup]}
                changeValue={changeValue}
                isFinalPrice={isFinalPrice}
                hasAssociatedVessel={store.locked()}
                allDisabled={costGroupReadonly(costGroup)}
                dutyStatus={deal.dutyStatus ?? ''}
                costStatus={store.costStatus}
                getDealFieldUnit={(unit: string) => store.resolveDealFieldUnit(unit, deal.pricingType, isGenericPricingActive, costGroup)} />
            <TotalPriceCosts
                classes={classes}
                costGroup={costGroup}
                costs={costsPerGroup[costGroup]}
                changeValue={changeValue}
                isFinalPrice={isFinalPrice}
                validationLocked={costGroupReadonly(costGroup)}
                dutyStatus={deal.dutyStatus ?? ''}
                getDealFieldUnit={(unit: string) => store.resolveDealFieldUnit(unit, deal.pricingType, isGenericPricingActive, costGroup)} />
        </>
    )
}

type CostBlockProps = {
    costGroup: string,
    costs: FieldState[],
    dutyStatus: string,
    changeValue: (group: string, index: number, newValue: number | null, unit: string) => void,
    isFinalPrice: (code: string) => boolean
    hasAssociatedVessel: boolean
    allDisabled: boolean
    costStatus: FieldStatusStore<{ [P in string]: string }>
    getDealFieldUnit: (unit: string) => string
}

let costPrefix = tPrefix + 'costs.'

function CostIncludedInTotal({ classes, costGroup, costs, changeValue, isFinalPrice, dutyStatus, allDisabled, costStatus, getDealFieldUnit }: CostBlockProps & MuiProps) {
    if (!(costs || []).some(cost => cost.includedInTotal && !isFinalPrice(cost.code))) return (<></>)
    return (
        <div className={classes.wrapRow}>
            {costs.map((cost, index) =>
                cost.includedInTotal && !isFinalPrice(cost.code)
                && <NumberField key={costGroup + index}
                    status={costStatus.getStatus(cost.code)}
                    label={createCostLabel(costPrefix + cost.code, dutyStatus, getDealFieldUnit(cost.currencyPerQuantityUnit))}
                    text={cost.price}
                    disabled={cost.readOnly || allDisabled}
                    precision={cost.precision ?? 5}
                    allowNegative
                    onChange={newValue => changeValue(costGroup, index, newValue, cost.currencyPerQuantityUnit)} />
            )}
            {
                costs.filter(x => x.includedInTotal).length > 0
                && <NumberField
                    label={createCostLabel(tPrefix + 'totalCost', dutyStatus, getDealFieldUnit(costs.filter(x => x.includedInTotal).first().currencyPerQuantityUnit))}
                    disabled={true} precision={5}
                    text={costs.filter(x => x.includedInTotal).reduce((x, cur) => x + (cur.price ?? 0), 0)} />
            }
        </div>
    )
}

function CostNonIncludedInTotal({ classes, costGroup, costs, changeValue, isFinalPrice, allDisabled: validationLocked, dutyStatus, getDealFieldUnit }: CostBlockProps & MuiProps) {
    if ((costs || []).every(cost => cost.includedInTotal || isFinalPrice(cost.code))) return (<></>)

    return (
        <div className={classes.wrapRow}>
            {costs.map((cost, index) =>
                cost.includedInTotal || isFinalPrice(cost.code)
                    ? null
                    : <NumberField key={index}
                        label={createCostLabel(costPrefix + cost.code, dutyStatus, getDealFieldUnit(cost.currencyPerQuantityUnit))}
                        text={cost.price}
                        disabled={cost.readOnly || validationLocked}
                        precision={cost.precision ?? 5}
                        allowNegative
                        onChange={(newValue) => {
                            changeValue(costGroup, index, newValue, cost.currencyPerQuantityUnit)
                        }} />
            )}
        </div>
    )
}

type FinalePriceCostBlockProps = {
    costGroup: string,
    costs: FieldState[],
    dutyStatus: string,
    isFinalPrice: (code: string) => boolean,
    changeValue: (group: string, index: number, newValue: number, unit: string) => void,
    validationLocked: boolean,
    getDealFieldUnit: (unit: string) => string
}

function TotalPriceCosts({ classes, costGroup, costs, changeValue, isFinalPrice, validationLocked, dutyStatus, getDealFieldUnit }: FinalePriceCostBlockProps & MuiProps) {
    let cost = (costs || []).filter(x => isFinalPrice(x.code)).first()

    if (!cost) return null

    let index = costs.findIndex(x => x.code === cost?.code)
    let localeLang = localStorage.getItem(storageLanguageKey) ?? 'en'

    let formatCurrency = (val: number | null, unit: string): string =>
        val ? Intl.NumberFormat(localeLang, { style: 'currency', currency: unit, maximumFractionDigits: 5 }).format(val) : ''

    return (
        <div className={classes.wrapRow}>
            {cost.readOnly || validationLocked
                ? <div>
                    <Typography>
                        <span className={classes.finalePricePolice}>{createCostLabel(costPrefix + cost.code, dutyStatus, null) + ': '}</span>
                        <span className={classes.finalPriceValue}>{formatCurrency(cost.price, cost.currencyPerQuantityUnit!)}</span>
                    </Typography>
                </div>
                : <NumberField
                    label={createCostLabel(costPrefix + cost.code, dutyStatus, getDealFieldUnit(cost.currencyPerQuantityUnit))}
                    text={cost.price}
                    disabled={false}
                    precision={cost.precision ?? 5}
                    allowNegative
                    onChange={(newValue) => {
                        if (newValue)
                            changeValue(costGroup, index, newValue, cost.currencyPerQuantityUnit)
                    }} />}
        </div>
    )
}

let createCostLabel = (translationCode: string, dutyStatus: string, unit: string | null): string => {
    return dutyStatus === 'Export'
        ? [t(tPrefix + 'costs.export'), t(translationCode), unit].join(' ')
        : [t(translationCode), unit].join(' ')
}

let styles = _ =>
    createStyles({
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        column: {
            ...defaultStyles.flexColumn,
            justifyContent: 'flex-start',
            alignItems: 'normal',
            rowGap: '1em'
        },
        wrapRow: {
            ...defaultStyles.flexRow,
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginLeft: '1em',
            '& > div': {
                margin: '0.5em'
            }
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            marginLeft: '-0.5em'
        },
        subtitle: {
            alignSelf: 'flex-start',
            color: defaultColors.red.main.color,
            margin: '1em 0 0.5em 1em'
        },
        divider: {
            width: '100%',
            marginTop: '1em'
        },
        documentButton: {
            marginRight: '0.5em',
            marginTop: '0.5em',
            alignSelf: 'flex-end'
        },
        costGroupHeaderLineWithTitle: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
        },
        costGroupHeaderLineWithoutTitle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        },
        finalPriceValue: { marginLeft: '0.5em' },
        finalePricePolice: {
            color: defaultColors.grey.main.color,
            fontSize: '1.1em',
            fontWeight: 'bold',
            marginTop: '0.5em',
            marginLeft: '0.3em'
        },
        marginStartLine: {
            marginLeft: '0.5em'
        },
        marginBottom: {
            marginBottom: '1em'
        },
        headerRight: {
            ...defaultStyles.flexRow
        },
        buttonPictogram: {
            marginRight: '0.5em'
        },
        buttonToPricing: {
            ...defaultStyles.primaryButton,
            marginTop: '2em',
            marginRight: '1em'
        },
        commentSection: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '0.5em'
        },
    })

export let PricingFirstLine = withStyles(styles, muiOptions)(_FirstLine)
export let PricingSecondLine = withStyles(styles, muiOptions)(_SecondLine)
export let PricingThirdLine = withStyles(styles, muiOptions)(_ThirdLine)