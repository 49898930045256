import React from 'react'
import { DealStatus } from '../dealModels'
import { t } from '../../../infrastructure/i18nextHelper'
import { StatusChip } from '../../common/customComponents'

type Props = { status: DealStatus, fontSize?: 'small' | 'regular', disabled?: boolean, hoverable?: boolean }

export function DealStatusChip({ status, fontSize, disabled, hoverable }: Props) {
    let option = options.find(x => x.status === status)
    return (<StatusChip color={disabled ? 'grey' : option!.color} fontSize={fontSize} hoverable={hoverable}>{t(option!.text)}</StatusChip >)
}

const options = [
    { status: DealStatus.Mandate, color: 'purple', text: 'deals.status.mandate' },
    { status: DealStatus.Negociated, color: 'blue', text: 'deals.status.negociated' },
    { status: DealStatus.Cancelled, color: 'red', text: 'deals.status.cancelled' },
    { status: DealStatus.Closed, color: 'green', text: 'deals.status.closed' },
] as { status: DealStatus, color: 'purple' | 'green' | 'blue' | 'red', text: string }[]