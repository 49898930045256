import React, { useState } from 'react'
import moment from 'moment'
import { withStyles, createStyles, Typography, Paper } from '@material-ui/core'
import DateRangeOutlined from '@material-ui/icons/DateRangeOutlined'
import { ChartData, ChartOptions } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { muiOptions, MuiProps, defaultColors, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { LoaderStatusContainer } from '../../../infrastructure/loader'
import { FeatureContainer } from '../../../infrastructure/feature'
import { StockBoardContainer } from '../stockBoardStore'
import { options } from '../../common/components/chart'
import { Switch, Menu } from '../../common/customComponents'
import { StockProjectionMode } from '../stockModels'
import { createChart } from './createChart'

function _StockChart({ classes, className }: MuiProps & { className?: string }) {
    let store = StockBoardContainer.useContainer()
    let { hasFeature } = FeatureContainer.useContainer()
    let [displayByDays, setDisplayByDays] = useState<boolean>(false)
    let loader = LoaderStatusContainer.useContainer()

    let toggleChartMode = () => {
        let nextStockMode = store.stockMode === StockProjectionMode.Sum
            ? StockProjectionMode.Each : StockProjectionMode.Sum
        store.setStockMode(nextStockMode)
    }

    let monthRangeChoices: { value: any, text: string }[] = [
        { value: 1, text: '1 ' + t('stock.label.chart.month') },
        { value: 2, text: '2 ' + t('stock.label.chart.months') },
        { value: 3, text: '3 ' + t('stock.label.chart.months') },
        { value: 4, text: '4 ' + t('stock.label.chart.months') },
        { value: 5, text: '5 ' + t('stock.label.chart.months') },
        { value: 6, text: '6 ' + t('stock.label.chart.months') },
    ]

    let chart = React.useMemo(() => createChart({
        ...store.stockProjectionResult,
        displayByDays,
        stockMode: store.stockMode,
        showTransitVolume: hasFeature('TransitVolume'),
        showLowHighs: true,
        withSimulation: store.simulations.length > 0
    }), [store.stockProjectionResult, displayByDays])

    let chartOptions: ChartOptions<'line'> = React.useMemo(() => createChartOptions(chart), [chart.datasets])

    return (
        <Paper className={classes.container + (className ? ' ' + className : '')}>
            <div className={classes.paperHeader}>
                <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                    {t('stock.chart.title', { unit: store.unit })}
                </Typography>
                <div className={classes.modeSelectors}>
                    <Switch form changeCallback={() => setDisplayByDays(!displayByDays)} isChecked={displayByDays}
                        disabled={loader.isActive}
                        offText={t('stock.label.chart.volume')} onText={t('stock.label.chart.days')} />
                    <Switch form changeCallback={toggleChartMode} isChecked={store.stockMode === StockProjectionMode.Each}
                        disabled={loader.isActive}
                        offText={t('stock.label.chart.sum')} onText={t('stock.label.chart.each')} />
                    <Menu icon={<DateRangeOutlined />} items={monthRangeChoices} classesOverride={{ button: classes.menuButton }}
                        selectedItemValue={store.stockFilters.monthRange} tooltip={t('stock.label.chart.monthRangeTooltip')}
                        onChange={newValue => store.setFilters({ ...store.stockFilters, monthRange: parseInt(newValue.toString()) })} />
                </div>
            </div>
            <div className={classes.graph}>
                <Line data={chart} options={chartOptions} />
            </div>
        </Paper>
    )
}

function createChartOptions(chart: ChartData<'line', Array<number | null>, string>): ChartOptions<'line'> {
    let today = moment().startOf('day').format('YYYY-MM-DD')
    let isTodayIsInChartLabels = chart.labels?.some(x => x.contains(today))
    let hasStack = chart.datasets.some(x => x.stack === 'stack')

    return {
        ...options,
        plugins: {
            ...options.plugins,
            annotation: {
                annotations: isTodayIsInChartLabels ? [
                    {
                        type: 'line',
                        borderColor: defaultColors.grey.main.color,
                        borderWidth: 3,
                        drawTime: 'beforeDatasetsDraw',
                        xMin: moment().startOf('day').toString(),
                        xMax: moment().startOf('day').toString(),
                    }
                ] : []
            }
        },
        scales: {
            x: { type: 'time', time: { unit: 'week', displayFormats: { week: 'MMM DD' } } },
            y: { type: 'linear', stacked: hasStack, },
        }
    }
}

let styles = _ =>
    createStyles({
        container: {
            height: '100%',
            width: '100%',
            paddingBottom: '0.2em',
            paddingLeft: '0.2em'
        },
        graph: {
            height: '88%',
            paddingBottom: '0.5em'
        },
        paperHeader: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between'
        },
        modeSelectors: {
            display: 'flex',
            alignItems: 'center'
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            marginLeft: '1em',
            marginBottom: '0',
            marginTop: '1em'
        },
        menuButton: {
            padding: '5px'
        }
    })

export let StockChart = withStyles(styles, muiOptions)(_StockChart)