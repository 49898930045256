import React from 'react'
import { VesselStatus } from './vesselModels'
import { StatusChip } from '../common/customComponents'
import { t } from '../../infrastructure/i18nextHelper'

type Props = { status: VesselStatus, fontSize?: 'small' | 'regular', disabled?: boolean, hoverable?: boolean }

export function VesselStatusChip({ status, fontSize, disabled, hoverable }: Props) {
    let option = options.find(x => x.status === status)
    return (<StatusChip color={disabled ? 'grey' : option!.color} fontSize={fontSize} hoverable={hoverable}>{t(option!.text)}</StatusChip >)
}

const options = [
    { status: VesselStatus.Forecast, color: 'purple', text: 'vessels.label.status.forecast' },
    { status: VesselStatus.Actual, color: 'green', text: 'vessels.label.status.actual' },
    { status: VesselStatus.Planned, color: 'blue', text: 'vessels.label.status.planned' },
] as { status: VesselStatus, color: 'purple' | 'green' | 'blue', text: string }[]