interface Array<T> {
    distinct(): T[]
    distinctBy(comparer: (a: T, b: T) => boolean): T[]
    first(callbackfn: (value: T) => any): T
    first(): T
    take(count: number): T[]
    indexBy(keySelector: (value: T) => string): { [key: string]: T }
    indexValueBy<TValue>(keySelector: (value: T) => string, valueSelector: (value: T) => TValue): { [key: string]: TValue }
    indexMultipleValueBy<TValue>(keySelector: (value: T) => string, valueSelector: (value: T) => TValue): { [key: string]: TValue[] }
    indexMultipleValueByProp(prop: keyof T): { [key: string]: T[] }
    groupBy<TValue>(keySelector: (value: T) => string, valueSelector: (value: T) => TValue): [key: string, value: TValue[]][]
}

interface String {
    contains(value: string): boolean
    startsWith(value: string): boolean
}

if (!Array.prototype.distinct) {
    Array.prototype.distinct = function distinct<T>(): T[] {
        return this.reduce((acc: T[], cur: T) => {
            if (acc.indexOf(cur) === -1) acc.push(cur)
            return acc
        }, [])
    }
}

if (!Array.prototype.distinctBy) {
    Array.prototype.distinctBy = function distinct<T>(comparer: (a: T, b: T) => boolean): T[] {
        return this.reduce((acc: T[], cur: T) => {
            if (!acc.some(x => {
                return comparer(cur, x as T)
            }

            )) acc.push(cur)
            return acc
        }, [])
    }
}

if (!Array.prototype.indexBy) {
    Array.prototype.indexBy = function indexBy<T>(keySelector: (value: T) => string): { [key: string]: T } {
        let indexed: { [key: string]: T } = {}
        this.forEach(x => {
            indexed[keySelector(x)] = x
        })
        return indexed
    }
}

if (!Array.prototype.indexValueBy) {
    Array.prototype.indexValueBy = function indexValueBy<T, TValue>(keySelector: (value: T) => string, valueSelector: (value: T) => TValue): { [key: string]: TValue } {
        let indexed: { [key: string]: TValue } = {}
        this.forEach(x => {
            indexed[keySelector(x)] = valueSelector(x)
        })
        return indexed
    }
}

if (!Array.prototype.indexMultipleValueBy) {
    Array.prototype.indexMultipleValueBy = function indexValueBy<T, TValue>(keySelector: (value: T) => string, valueSelector?: (value: T) => TValue): { [key: string]: TValue[] } {
        return this.reduce(function (groups, item) {
            const val = keySelector(item)
            groups[val] = groups[val] || []
            groups[val].push(valueSelector ? valueSelector(item) : item)
            return groups
        }, {});
    }
}

if (!Array.prototype.indexMultipleValueByProp) {
    Array.prototype.indexMultipleValueByProp = function (prop: string) {
        return this.reduce(function (groups, item) {
            const val = item[prop]
            groups[val] = groups[val] || []
            groups[val].push(item)
            return groups
        }, {});
    }
}

if (!Array.prototype.groupBy) {
    Array.prototype.groupBy = function groupBy<T, TValue>(keySelector: (value: T) => string, valueSelector: (value: T) => TValue): [key: string, value: TValue[]][] {
        let indexed = this.indexMultipleValueBy(keySelector, valueSelector)
        let flat: [key: string, value: TValue[]][] = []
        for (let prop in indexed) {
            flat.push([prop, indexed[prop]])
        }
        return flat
    }
}

if (!Array.prototype.first) {
    Array.prototype.first = function first<T>(callbackfn?: (value: T) => any): T[] | null {
        let result = callbackfn ? this.filter(callbackfn) : this
        return result.length ? result[0] : null
    }
}

if (!Array.prototype.take) {
    Array.prototype.take = function take<T>(count: number): T[] {
        return this.reduce((acc: T[], x: T) => (acc.length < count ? acc.concat(x) : acc), [])
    }
}

if (!String.prototype.contains) {
    String.prototype.contains = function contains(value: string): boolean {
        return this.indexOf(value) !== -1
    }
}

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function startsWith(value: string): boolean {
        return this.indexOf(value) === 0
    }
}
