import React, { useState } from 'react'
import { defaultStyles, muiOptions, MuiProps } from '../../infrastructure/materialUiThemeProvider'
import { withStyles, createStyles, CardActionArea, Card, CardMedia, CardContent, Typography } from '@material-ui/core'
import { t } from '../../infrastructure/i18nextHelper'
import { navigateTo } from '../../infrastructure/navigation'
import { hasClaim } from '../../infrastructure/signIn/userContext'
import { SearchField } from '../common/customComponents'
import { AdminCardContent, AdminCategory } from './adminModels'
import { Categories } from './homeCategories'

function GetTranslatedCardName(categoryName: string, cardName: string): string {
    return t(`admin.home.${categoryName}.${cardName}.name`)
}

function AdminHome({ classes }: MuiProps) {
    let [searchTerm, setSearchTerm] = useState<string>('')

    function FilteredCategories(): AdminCategory[] {
        let lowerSearchTerm = searchTerm.toLowerCase()

        function shouldShowCard(card: AdminCardContent, categoryName: string): boolean {
            return GetTranslatedCardName(categoryName, card.name).toLowerCase().includes(lowerSearchTerm) && hasAllClaims(card)
        }

        function hasAllClaims(card: AdminCardContent): boolean {
            return card.claims.reduce((acc, curr) => acc || hasClaim(curr), false) //has at least one claim
        }

        return Categories()
            .map((x: AdminCategory) => { return { ...x, cards: x.cards.filter(card => shouldShowCard(card, x.name)) } })
            .filter(x => x.cards.length !== 0)
    }

    return (
        <div className={classes.pageContainer}>
            <div className={classes.firstRow}>
                <SearchField onChange={x => setSearchTerm(x.target.value)} text={searchTerm} />
            </div>
            <div className={classes.secondRow}>
                {FilteredCategories().map(category =>
                (<div key={category.name} className={classes.category}>
                    <Typography variant='h6'>{t(`admin.home.${category.name}.name`)}</Typography>
                    <div className={classes.cardContainer}>
                        {category.cards.map(card => <AdminCard key={card.name} classes={classes} content={card} categoryName={category.name} />)}
                    </div>
                </div>))}
            </div>
        </div>)
}

function AdminCard({ classes, content, categoryName }: MuiProps & { content: AdminCardContent, categoryName: string }) {
    let descriptionTrad = `admin.home.${categoryName}.${content.name}.description`
    let imagePath = `static/images/administration/${content.name}.png`
    let route = content.url
        ? `/admin/read?name=${content.name}&url=${encodeURIComponent(content.url)}`
        : `/admin/${content.name}`
    return (
        <Card className={classes.card} onClick={_ => navigateTo(route)}>
            <CardActionArea className={classes.cardActionArea}>
                <CardMedia className={classes.cardImage} image={imagePath} />
                <CardContent className={classes.cardContent}>
                    <Typography variant='h6'>{GetTranslatedCardName(categoryName, content.name)}</Typography>
                    {content.hasDescription && <Typography variant='caption'>{t(descriptionTrad)}</Typography>}
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

let styles = () => createStyles({
    pageContainer: {
        display: 'grid',
        height: '100%',
        paddingBottom: '0.5em',
        'grid-template-areas': "'firstRow' 'secondRow'",
        'grid-template-columns': 'auto',
        'grid-template-rows': '4em minmax(0,1fr)',
        'grid-gap': '0.8em',
        'overflow-y': 'hidden',
        'box-sizing': 'border-box',
        '&> div': {
            height: '100%',
            width: '100%'
        }
    },
    firstRow: {
        'box-sizing': 'border-box',
        'grid-area': 'firstRow',
        marginTop: '1em',
        marginBottom: '1em'
    },
    secondRow: {
        overflow: 'auto',
        'box-sizing': 'border-box',
        'grid-area': 'secondRow'
    },
    cardContainer: {
        ...defaultStyles.flexRow,
        marginBottom: '1em',
        paddingRight: '0.5em',
        gap: '1em',
        width: '100%',
        flexWrap: 'wrap'
    },
    category: {
        ...defaultStyles.flexColumn,
        gap: '1em',
        width: '100%',
        alignItems: 'start'
    },
    card: {
        width: '21em',
        height: '12em'
    },
    cardActionArea: {
        height: '100%'
    },
    cardImage: {
        minHeight: '6em'
    },
    cardContent: {
        minHeight: '6em'
    }
})

export default withStyles(styles, muiOptions)(AdminHome)