import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { api } from '../../../infrastructure/api'
import { t } from '../../../infrastructure/i18nextHelper'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import guid from '../../../infrastructure/guid'
import { ColumnDescriptor, TableItem } from '../../common/customComponents'
import { MasterDataShell, MasterDataItem, createExcelLines } from './masterDataShell'
import { applyFilters } from '../../common/filtersHelper'
import { getBooleanLabel } from '../../common/filtersHelper'

function ReadMasterDataShellProps({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let params = new URLSearchParams(location.search)
    var url = params.get('url')!
    var name = params.get('name')!
    let title = t(`admin.masterdata.${name}.${name}`)

    let [items, setItems] = useState<TableItem<any>[]>([])
    let [filters, setFilters] = useState<any>({})

    let toTableItem = item => {
        if ('id' in item) return item
        if ('code' in item) return { ...item, id: item.code }
        return { ...item, id: guid.createNew() }
    }

    let getItems = (limitTop500: boolean) => {
        var result = applyFilters(items.map(x => getValue(x)), filters).map(toTableItem)
        if (limitTop500)
            return result.slice(0, 500)

        return result
    }

    let load = async () => {
        setItems(await api.get<TableItem<any>[]>(url))
    }

    useEffect(() => { load() }, [url])

    let capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
    let improveHeader = (property) => capitalizeFirstLetter(property.replace(/([A-Z])/g, ' $1').trim())
    let getValue = (value) => {
        if (typeof value === 'boolean') return getBooleanLabel(value)
        if (value instanceof Array) return value.join(', ')
        return value
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<any>>>[] =
        items.length === 0 ? [] :
            Object.getOwnPropertyNames(items[0]).map(property =>
            ({
                name: improveHeader(property),
                value: x => getValue(x[property]),
                columnFilter: {
                    value: filters[property] ?? '',
                    onChange: (value: string) => {
                        filters[property] = value
                        setFilters({ ...filters })
                    }
                }
            }))

    let exportExcel = async () => {
        excelGenerator.generate({
            filename: name + '.xlsx',
            sheets: [{ name: name, lines: createExcelLines(getItems(false), columns) }]
        })
    }

    return (
        <div className={classes.container}>
            <MasterDataShell tableId={name + '-read-table'}
                headerLabel={title}
                items={getItems(true)}
                onExportExcel={exportExcel}
                columns={columns}>
            </MasterDataShell>
        </div >)
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
        isBooleanContainer: {
            ...defaultStyles.flexRow,
            width: '15.3em',
            justifyContent: 'space-between',
            "& >span": { padding: '0' }
        }
    })
export default withStyles(styles, muiOptions)(ReadMasterDataShellProps) 