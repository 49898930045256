import { useState, useRef } from 'react'

export function useActionDebounce(action: (param?: any) => Promise<any> | any, delayInMs?: Milliseconds) {
    let [isPending, setIsPending] = useState<boolean>(false)

    async function execute(event?: any) {

        if (isPending) return

        setIsPending(true)

        try {
            await action(event)
        }
        catch (e) {
            throw e
        }
        finally {
            if (delayInMs)
                setTimeout(() => {
                    setIsPending(false)
                }, delayInMs)
            else
                setIsPending(false)
        }
    }

    return { execute }
}

export function useActionDebounceWithArgs<T>(action: ((arg: T) => Promise<void>) | ((arg: T) => void), delayInMs?: Milliseconds) {
    let [isPending, setIsPending] = useState<boolean>(false)

    async function execute(arg: T) {
        if (isPending) return

        setIsPending(true)

        try {
            await action(arg)
        }
        catch (e) {
            throw e
        }
        finally {
            setTimeout(() => {
                setIsPending(false)
            }, delayInMs ?? defaultDelay)
        }
    }

    return { execute }
}

export function useInputDebounce<T>(val: T | null, action: (val: T | null) => void, delay?: Milliseconds) {
    let lastChangeDate = useRef<Date | null>(null)
    let [value, setValue] = useState<T | null>(val)

    function onChange(newValue: T) {
        lastChangeDate.current = new Date()
        value = newValue
        setValue(newValue)

        setTimeout(() => {
            let now = new Date()
            if (lastChangeDate.current && now.getTime() - lastChangeDate.current.getTime() >= (delay ?? defaultDelay)) {
                action(value)
                lastChangeDate.current = null
            }
        }, delay ?? defaultDelay)
    }

    return { value, setValue, onChange }
}

type Milliseconds = number

const defaultDelay: Milliseconds = 1000