import React, { useImperativeHandle, useState, useEffect, useRef, RefObject, Fragment } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, createStyles, withStyles } from '@material-ui/core'
import { muiOptions, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { Guid } from '../../../infrastructure/guid'
import * as api from '../../../infrastructure/api'
import { t } from '../../../infrastructure/i18nextHelper'
import { HistoryTable, TableItem } from './table'
import { Button } from '../customComponents'
import moment from 'moment'

function _historyDialog(props) {
    const [open, setOpen] = useState<boolean>(false)
    const [entity, setEntity] = useState<HistorizedEntity | null>(null)
    const [history, setHistory] = useState<TableItem[]>([])

    dialogRef = useRef<{ open: (entity: HistorizedEntity) => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: (entity: HistorizedEntity) => {
            setOpen(true)
            setEntity(entity)
        }
    }))

    async function setEntityHistory() {
        if (entity == null) return
        let result = await fetchHistory(entity as HistorizedEntity)
        setHistory(result)
    } useEffect(() => { setEntityHistory() }, [entity])

    let title = entity
        ? t('history.title') + ` - ${entity.name}`
        : t('history.title')

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                scroll={'paper'}
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'>
                <DialogTitle id='scroll-dialog-title'>{title}</DialogTitle>
                <DialogContent dividers={true} className={props.classes.noPadding + ' ' + props.classes.minSize}>
                    <HistoryTable items={history} />
                </DialogContent>
                <DialogActions>
                    <Button className={props.classes.closeButton}
                        label={t('history.close')}
                        onClick={() => setOpen(false)}
                        color='primary' />
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

async function fetchHistory(entity: HistorizedEntity): Promise<TableItem[]> {
    let path = entity.type === 'movement' ? 'stock/movement' : entity.type
    let history = await api.get<ApiHistory[]>(`${path}/${entity.id}/history`)
    if (history == null) return []

    let historyTableItems = history.map(x => {
        return {
            username: x.username,
            date: x.dateTime,
            field: toTitle(x.field),
            oldValue: x.oldValue,
            newValue: x.newValue,
            where: toTitle(x.path)
        }
    })

    if (entity.type === 'movement' && entity.sapFlowId) {
        let sapHistory = await api.get<ApiHistory[]>(`${path}/${entity.sapFlowId}/history`)
        let sapHistoryTableItems = sapHistory.map(x => {
            return {
                username: x.username,
                date: x.dateTime,
                field: toTitle(x.field),
                oldValue: x.oldValue,
                newValue: x.newValue,
                where: toTitle(x.path)
            }
        })

        return ([...historyTableItems, ...sapHistoryTableItems]).sort(historyComparer)
    }

    return historyTableItems
}

let historyComparer = (a: TableItem, b: TableItem) => {
    if (moment(a.date).isBefore(moment(b.date)))
        return 1
    if (moment(a.date).isAfter(moment(b.date)))
        return -1
    return 0
}

let upperCaseFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)
let replacePoints = (text: string) => text.replace(/\./g, ' > ')
let addSpaceBetweenWords = (text: string) => text
    .replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')
    .replace(/([a-z])([A-Z])([A-Z])/g, '$1 $2$3')
    .replace(/([A-Z])([a-z])([A-Z])/g, '$1$2 $3')
let toTitle = (text: string) => text ? addSpaceBetweenWords(replacePoints(upperCaseFirstLetter(text))) : ''

export type HistorizedEntity = {
    id: Guid
    type: 'movement' | 'vessel' | 'deal' | 'truck'
    name: string
    sapFlowId?: string
}

type ApiHistory = {
    id: string
    username: string
    streamId: string
    dateTime: Date
    field: string
    path: string
    newValue: string
    oldValue: string
}

let dialogRef: RefObject<{ open: (entity: HistorizedEntity) => void }> | null = null
export let historyDialog = { open: (entity: HistorizedEntity) => dialogRef?.current?.open(entity) }

let styles = theme => createStyles({
    noPadding: {
        padding: '0'
    },
    minSize: {
        minWidth: '16em',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column'
    },
    closeButton: {
        ...defaultStyles.dialogCloseButton
    }
})

export let HistoryDialog = withStyles(styles, muiOptions)(_historyDialog)