import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { defaultStyles, muiOptions } from '../../../../infrastructure/materialUiThemeProvider'
import { StockMovementContainer } from '../stockMovementStore'
import * as Component from './_movementComponents'
import { LinkVesselMovementContainer } from './linkVesselContainer'
import { hasFeature } from '../../../../infrastructure/feature'

function _untriggeredForm({ classes }) {
    let linkStore = LinkVesselMovementContainer.useContainer()

    return (
        <div>
            <div className={classes.row}>
                <Component.Date isIn={true} />
                <Component.Product isIn={true} />
                <Component.DutyStatus isIn={true} />
                <Component.Company />
                <Component.Volume />
                {hasFeature('ObservedQuantityTemperature')
                    ? <>
                        <Component.ObservedTemperature />
                        <Component.ObservedVolume />
                    </>
                    : null}
            </div>
            <div className={classes.row}>
                <Component.Site isIn={true} />
                <Component.Counterparty />
                <Component.Mot />
                <Component.Status />
            </div>
            <div className={classes.row}>
                <Component.Reference />
                <Component.ExternalReference />
                {hasFeature('linkVesselMovement') && !!linkStore.associatedVessel &&
                    <Component.AssociatedVessel text={`${linkStore.associatedVessel?.name} ${linkStore.associatedVessel?.reference}`} />}
            </div>
        </div>
    )
}

let styles = () =>
    createStyles({
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1em'
        },
        alignedToVolume: {
            padding: '0px',
            margin: '0px',
            paddingLeft: '48.9em'
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            margin: "1em"
        }
    })

export default withStyles(styles, muiOptions)(_untriggeredForm)
