export function getEnvironment(): 'Local' | 'Production' | 'Staging' | 'Dev' | 'Qualification' | 'Integration' | 'Test' | 'Other' {
    if (document.location.hostname.indexOf('localhost') != -1) {
        return 'Local'
    } else {
        switch (document.location.hostname) {
            case 'lynks.total.com':
            case 'lynks.totalenergies.com':
            case 'cf.lynks.alzp.tgscloud.net':
                return 'Production'

            case 'cf.lynkss.alzp.tgscloud.net':
                return 'Staging'
            case 'cf.q.lynkss.alzp.tgscloud.net':
                return 'Qualification'

            case 'cf.lynksd.alzp.tgscloud.net':
                return 'Dev'
            case 'cf.i.lynksd.alzp.tgscloud.net':
                return 'Integration'
            case 'cf.t.lynksd.alzp.tgscloud.net':
                return 'Test'

            default:
                return 'Other'
        }
    }
}