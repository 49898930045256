import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, createStyles, withStyles } from '@material-ui/core'
import { Theme, ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { MuiProps, muiOptions, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import MUIRichTextEditor from 'mui-rte'
import { api } from '../../../infrastructure/api'
import { useContainer } from 'unstated-next'
import { CommentContainer } from './commentStore'

export type CommentContentProps = {
    associationKey: string
}

let theme: Theme = createMuiTheme()

Object.assign(theme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.05) !important',
                color: defaultColors.inputText,
                width: '100%',
                marginLeft: '0.5em'
            },
            container: {
                display: "flex",
                flexDirection: "column-reverse"
            },
            editor: {
                padding: '12px 12px 0px',
                minHeight: '47px',
                lineHeight: '1.1876em',
                wordBreak: 'break-all'
            },
            toolbar: {
                borderTop: "1px solid gray",
            },
            placeHolder: {
                paddingLeft: 20,
                width: "inherit",
            }
        }
    }
})

function _CommentContent(props: MuiProps & CommentContentProps) {
    let [commentContent, setCommentContent] = useState<string | null>(null)
    let store = useContainer(CommentContainer)

    useEffect(() => {
        if (!props.associationKey) return
        loadComment()
    }, [store.existingKeys, props.associationKey])

    let loadComment = async () => {
        let comment = await api.get<CommentItem | null>(`comment?associationKey=${props.associationKey}`)
        setComment(comment?.content)
    }

    let setComment = (content?: string | null) => {
        if (!content) {
            setCommentContent(null)
            return
        }

        let commentContent = store.convertContentTextToJSONString(content)
        setCommentContent(commentContent)
    }

    return (
        <div className={props.classes.container}>
            <ThemeProvider theme={theme}>
                <FormControl variant="filled" fullWidth>
                    <InputLabel shrink>{t('comment.comment')}</InputLabel>
                    <MUIRichTextEditor
                        defaultValue={commentContent}
                        readOnly
                        inheritFontSize={true}
                        controls={[]}
                    />
                </FormControl>
            </ThemeProvider>
        </div>)
}

let styles = _ =>
    createStyles({
        container: {
            width: '100%',
            paddingRight: '0.5em',
            '& label': {
                color: 'rgba(0, 0, 0, 0.54) !important',
                fontSize: '0.75em !important',
                transform: 'translate(20px,10px) !important'
            }
        }
    })

export let CommentContent = withStyles(styles, muiOptions)(_CommentContent)

type CommentItem = {
    associationKey: string
    content: string
    user: string
    createdAt: Date
}