import { createStyles, Tooltip, Typography, withStyles } from '@material-ui/core'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import React, { useEffect, useState } from 'react'
import { ColumnDescriptor, Select, TableItem, TextField } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import * as api from '../../../infrastructure/api'
import guid, { Guid } from '../../../infrastructure/guid'
import { formatFilename } from '../../../infrastructure/excelExport'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { MasterDataItem, MasterDataShell } from './masterDataShell'

type Counterparty = {
    id: Guid
    name: string
}

type Company = {
    id: Guid
    code: string
    name: string
}

type SapCounterparty = {
    id: Guid
    counterpartyId: Guid
    vendorCode: string | null
    vendorName: string | null
    shipToCode: string | null
    shipToName: string | null
    companyCode: string
    customerSegment: string | null
}

type CompanySupplyCustomerSegments = {
    company: string
    customerSegments: string[]
}

let emptySapCounterparty = () => {
    return {
        id: guid.empty,
        counterpartyId: guid.empty,
        vendorCode: null,
        vendorName: null,
        shipToCode: null,
        shipToName: null,
        companyCode: null,
        customerSegement: null,
    }
}

let toTableItem = (sapCounterparty: SapCounterparty): TableItem<MasterDataItem<SapCounterparty>> => {
    return {
        id: sapCounterparty.id,
        vendorName: sapCounterparty.vendorName,
        vendorCode: sapCounterparty.vendorCode,
        shipToName: sapCounterparty.shipToName,
        shipToCode: sapCounterparty.shipToCode,
        customerSegment: sapCounterparty.customerSegment,
        companyCode: sapCounterparty.companyCode,
        counterpartyId: sapCounterparty.counterpartyId,
        isModified: false
    }
}

type SapCounterpartyFilter = {
    vendorName: string | null
    vendorCode: string | null
    shipToName: string | null
    shipToCode: string | null
    customerSegment: string | null
    companyCode: string | null
    counterpartyName: string | null
}

let noFilters: SapCounterpartyFilter = {
    vendorName: null,
    vendorCode: null,
    shipToName: null,
    shipToCode: null,
    customerSegment: null,
    companyCode: null,
    counterpartyName: null,
}

let applyFilters = (sapCounterpartys: SapCounterparty[], filters: SapCounterpartyFilter, counterpartys: Counterparty[]): SapCounterparty[] => {
    if (filters.vendorName)
        sapCounterpartys = sapCounterpartys.filter(x => x.vendorName?.toLowerCase().contains(filters.vendorName!.toLowerCase()))

    if (filters.vendorCode)
        sapCounterpartys = sapCounterpartys.filter(x => x.vendorCode?.toLowerCase().contains(filters.vendorCode!.toLowerCase()))

    if (filters.shipToName)
        sapCounterpartys = sapCounterpartys.filter(x => x.shipToName?.toLowerCase().contains(filters.shipToName!.toLowerCase()))

    if (filters.shipToCode)
        sapCounterpartys = sapCounterpartys.filter(x => x.shipToCode?.toLowerCase().contains(filters.shipToCode!.toLowerCase()))

    if (filters.companyCode)
        sapCounterpartys = sapCounterpartys.filter(x => x.companyCode.toLowerCase().contains(filters.companyCode!.toLowerCase()))

    if (filters.counterpartyName)
        sapCounterpartys = sapCounterpartys.filter(x => counterpartys.find(c => c.id === x.counterpartyId)?.name?.toLowerCase().contains(filters.counterpartyName!.toLowerCase()))

    if (filters.customerSegment)
        sapCounterpartys = sapCounterpartys.filter(x => x.customerSegment?.toLowerCase().contains(filters.customerSegment!.toLowerCase()))

    return sapCounterpartys
}

function SapCounterpartyMasterData({ classes }: MuiProps) {
    let [counterpartys, setCounterpartys] = useState<Counterparty[]>([])
    let [companys, setCompanys] = useState<Company[]>([])
    let [sapCounterpartys, setSapCounterpartys] = useState<SapCounterparty[]>([])
    let [filters, setFilters] = useState<SapCounterpartyFilter>(noFilters)
    let [customerSegments, setCustomerSegments] = useState<CompanySupplyCustomerSegments[]>()
    let [customerSegmentsChoices, setCustomerSegmentsChoices] = useState<string[]>([])

    let load = async () => {
        let counterpartys = api.get<Counterparty[]>('masterdata/sapCounterparty/counterparty')
        let companys = api.get<Company[]>('masterdata/sapCounterparty/company')
        let sapCounterpartys = api.get<SapCounterparty[]>('masterdata/sapCounterparty')
        let customerSegments = api.get<CompanySupplyCustomerSegments[]>(
            'masterdata/sapCounterparty/companySupplyCustomerSegments')

        setCounterpartys(await counterpartys)
        setCompanys(await companys)
        setSapCounterpartys(await sapCounterpartys)
        setCustomerSegments(await customerSegments)
    }

    useEffect(() => { load() }, [])

    let onExportExcel = async () => {
        let filename = formatFilename('SapCounterparty.xlsx')
        await api.post('masterdata/sapCounterparty/export', { filename: filename })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')
    }

    let onImportExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/sapCounterparty/import'
        api.upload(uploadUrl, file, 'import').then(_ => load())
    }

    let onSave = async (item: SapCounterparty) => {
        await api.post('masterdata/sapCounterparty', item)
        await load()
        return true
    }

    let onDelete = async (ids: string[]) => {
        await api.del('masterdata/sapCounterparty', { ids: ids })
        await load()
        return true
    }

    let filterCustomerSegmentsChoices = (selected: SapCounterparty | null) => {
        setCustomerSegmentsChoices(customerSegments?.find(x => x.company == selected?.companyCode)?.customerSegments ?? [])
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<SapCounterparty>>>[] = [
        {
            name: t('admin.masterdata.sapCounterparty.companyCode'), value: x => x.companyCode,
            columnFilter: { value: filters.companyCode ?? '', onChange: (companyCode: string) => setFilters({ ...filters, companyCode }) }
        },
        {
            name: t('admin.masterdata.sapCounterparty.counterparty'), value: x => counterpartys.find(c => c.id === x.counterpartyId)?.name ?? '-',
            columnFilter: { value: filters.counterpartyName ?? '', onChange: (counterpartyName: string) => setFilters({ ...filters, counterpartyName }) }
        },
        {
            name: t('admin.masterdata.sapCounterparty.vendorName'), value: x => x.vendorName,
            columnFilter: { value: filters.vendorName ?? '', onChange: (name: string) => setFilters({ ...filters, vendorName: name }) }
        },
        {
            name: t('admin.masterdata.sapCounterparty.vendorCode'), value: x => x.vendorCode,
            columnFilter: { value: filters.vendorCode ?? '', onChange: (code: string) => setFilters({ ...filters, vendorCode: code }) }
        },
        {
            name: t('admin.masterdata.sapCounterparty.shipToName'), value: x => x.shipToName,
            columnFilter: { value: filters.shipToName ?? '', onChange: (name) => setFilters({ ...filters, shipToName: name }) }
        },
        {
            name: t('admin.masterdata.sapCounterparty.shipToCode'), value: x => x.shipToCode,
            columnFilter: { value: filters.shipToCode ?? '', onChange: (code) => setFilters({ ...filters, shipToCode: code }) }
        },
        {
            name: t('admin.masterdata.sapCounterparty.customerSegment'), value: x => x.customerSegment,
            columnFilter: { value: filters.customerSegment ?? '', onChange: (val) => setFilters({ ...filters, customerSegment: val }) }
        }
    ]

    let isManager = hasClaim(Claims.MasterdataSapCounterpartyManager)

    return (
        <div className={classes.container}>
            <MasterDataShell
                headerLabel={t('admin.masterdata.sapCounterparty.sapCounterpartys')}
                itemLabel={t('admin.masterdata.sapCounterparty.sapCounterparty')}
                isManager={isManager}
                onImportExcel={onImportExcel}
                onExportExcel={onExportExcel}
                onNew={emptySapCounterparty}
                onDelete={onDelete}
                onSave={onSave}
                onSelectedItem={filterCustomerSegmentsChoices}
                items={applyFilters(sapCounterpartys, filters, counterpartys).map(toTableItem)}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <Select label={t('admin.masterdata.sapCounterparty.company')}
                                disabled={!isManager}
                                value={selectedItem.companyCode}
                                choices={companys.map(x => ({ value: x.code, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, companyCode: val }) }} />
                            <Select label={t('admin.masterdata.sapCounterparty.counterparty')}
                                disabled={!isManager}
                                value={selectedItem.counterpartyId}
                                choices={counterpartys.map(x => ({ value: x.id, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, counterpartyId: val }) }} />
                            <TextField label={t('admin.masterdata.sapCounterparty.vendorName')}
                                disabled={!isManager}
                                text={selectedItem.vendorName}
                                onChange={event => setSelectedItem({ ...selectedItem, vendorName: event.target.value })} />
                            <TextField label={t('admin.masterdata.sapCounterparty.vendorCode')}
                                disabled={!isManager}
                                text={selectedItem.vendorCode}
                                onChange={event => setSelectedItem({ ...selectedItem, vendorCode: event.target.value })} />
                            <TextField label={t('admin.masterdata.sapCounterparty.shipToName')}
                                disabled={!isManager}
                                text={selectedItem.shipToName}
                                onChange={event => setSelectedItem({ ...selectedItem, shipToName: event.target.value })} />
                            <TextField label={t('admin.masterdata.sapCounterparty.shipToCode')}
                                disabled={!isManager}
                                text={selectedItem.shipToCode}
                                onChange={event => setSelectedItem({ ...selectedItem, shipToCode: event.target.value })} />
                            <Tooltip
                                title={<Typography variant='body1'>{t('admin.masterdata.sapCounterparty.customerSegmentTooltip')}</Typography>}
                                placement='top'>

                                <div><Select label={t('admin.masterdata.sapCounterparty.customerSegment')}
                                    disabled={!isManager || !customerSegmentsChoices.length}
                                    value={selectedItem.customerSegment}
                                    choices={customerSegmentsChoices.map(x => ({ value: x, text: x }))}
                                    isClearable={true} clearableLabel={' '}
                                    onChange={val => setSelectedItem({ ...selectedItem, customerSegment: val })} /></div>
                            </Tooltip>
                        </>
                    )}
            </MasterDataShell>
        </div>)
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
    })

export default withStyles(styles, muiOptions)(SapCounterpartyMasterData)