import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { api } from '../../../infrastructure/api'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, Select, TableItem, TextField } from '../../common/customComponents'
import { Plant } from './models'
import { MasterDataShell, MasterDataItem, createExcelLines } from './masterDataShell'
import { applyFilters } from '../../common/filtersHelper'

type StorageLocation = {
    plant: string,
    storageLocationCode: string,
    storageLocationName: string,
}

let zero = (): StorageLocation => {
    return {
        plant: '',
        storageLocationCode: '',
        storageLocationName: '',
    }
}

type PlantStorageLocationFilters = {
    plant: string | null,
    storageLocationCode: string | null,
    storageLocationName: string | null,
}

let nullFilters: PlantStorageLocationFilters = {
    plant: null,
    storageLocationCode: null,
    storageLocationName: null
}

let generateIdString = (item: StorageLocation | TableItem<StorageLocation>): string => item.plant + ' ' + item.storageLocationCode

let toTableItem = (item: StorageLocation): TableItem<MasterDataItem<StorageLocation>> => {
    return {
        id: generateIdString(item),
        plant: item.plant,
        storageLocationCode: item.storageLocationCode,
        storageLocationName: item.storageLocationName,
        isModified: false
    }
};

function StorageLocationMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [displayedStorageLocations, setDisplayedStorageLocations] = useState<TableItem<StorageLocation>[]>([])
    let [existingPlants, setExistingPlants] = useState<Plant[]>([])
    let [filters, setFilters] = useState<PlantStorageLocationFilters>(nullFilters)

    let isManager = hasClaim(Claims.MasterdataPlantStorageLocationManager)
    let storageLocationLabel = t('admin.masterdata.plantStorageLocation.storageLocation')

    let load = async () => {
        let existingPlants = await api.get<Plant[]>('masterdata/plant/all')

        let tmpStorageLocations: TableItem<StorageLocation>[] = []

        existingPlants.forEach(x => {
            x.storageLocations.forEach(s => {
                tmpStorageLocations.push({
                    storageLocationName: s.name,
                    storageLocationCode: s.code,
                    plant: x.code,
                    isModified: false
                })
            })
        })

        setDisplayedStorageLocations(tmpStorageLocations)
        setExistingPlants(existingPlants)
    }

    useEffect(() => { let effect = async () => { await load() }; effect() }, [])
    useEffect(() => { applyFilters(displayedStorageLocations, filters) }, [filters])

    let onDelete = async (ids: string[]) => {
        let toDelete = displayedStorageLocations.filter(x => ids.find(id => id == generateIdString(x)))
        if (!toDelete) return false
        await api.post('masterdata/plant/storageLocation/delete', { plantStorageLocations: toDelete })
        await load()
        return true
    }

    let onSave = async (item: StorageLocation) => {
        await api.post('masterdata/plant/storageLocation', item)
        await load()
        return true
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<StorageLocation>>>[] = [
        {
            name: t('admin.masterdata.plantStorageLocation.plant'), value: x => x.plant,
            columnFilter: { value: filters.plant ?? '', onChange: (plant: string) => setFilters({ ...filters, plant: plant }) }
        },
        {
            name: t('admin.masterdata.plantStorageLocation.storageLocation'), value: x => x.storageLocationCode,
            columnFilter: { value: filters.storageLocationCode ?? '', onChange: (storageLocation: string) => setFilters({ ...filters, storageLocationCode: storageLocation }) }
        },
        {
            name: t('admin.masterdata.plantStorageLocation.name'), value: x => x.storageLocationName,
            columnFilter: { value: filters.storageLocationName ?? '', onChange: (name: string) => setFilters({ ...filters, storageLocationName: name }) }
        }
    ]

    let getItems = () => applyFilters(displayedStorageLocations, filters).map(toTableItem)

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'StorageLocation.xlsx',
            sheets: [{ name: 'StorageLocation', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let importExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/plant/storageLocation/import'
        api.upload(uploadUrl, file, 'import').then(_ => load())
    }

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={'storage-location-table'}
                headerLabel={storageLocationLabel}
                itemLabel={storageLocationLabel}
                isManager={isManager}
                onExportExcel={exportExcel}
                onImportExcel={importExcel}
                onNew={zero}
                onDelete={onDelete}
                onSave={onSave}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <Select label={t('admin.masterdata.plantStorageLocation.plant')}
                                disabled={!isManager}
                                value={selectedItem.plant}
                                choices={existingPlants.map(x => ({ value: x.code, text: `${x.code} - ${x.name}` }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, plant: val }) }} />
                            <TextField label={t('admin.masterdata.plantStorageLocation.storageLocation')}
                                disabled={!isManager}
                                text={selectedItem.storageLocationCode}
                                onChange={event => setSelectedItem({ ...selectedItem, storageLocationCode: event.target.value })} />
                            <TextField label={t('admin.masterdata.plantStorageLocation.name')}
                                text={selectedItem.storageLocationName}
                                onChange={event => setSelectedItem({ ...selectedItem, storageLocationName: event.target.value })} />
                        </>
                    )}
            </MasterDataShell>
        </div >)
}

let styles = (_) => createStyles({ container: { height: '100%' } })

export default withStyles(styles, muiOptions)(StorageLocationMasterData)