import React from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { SupplyBalanceStore } from '../supplyBalanceStore'
import { MultipleSelect, GroupedMultiSelect } from '../../common/customComponents'

function _SupplyBalanceFilters({ classes }) {
    let store = SupplyBalanceStore.useContainer()

    let hasOneCompany = store.companies.map(x => x.code).length === 1

    return (
        <div className="supplyBalanceFilters">
            <GroupedMultiSelect
                label={t('supplyBalance.filters.sites')}
                classesOverride={{ form: classes.filterField }}
                values={store.filters.sites}
                choices={store.sites.map(x => ({ value: x.code, text: x.name, group: x.siteGroup }))}
                allWhenEmpty disableNewStyle
                onChange={x => store.setFilters({ ...store.filters, sites: x })} />
            {store.products.every(x => !x.productGroup)
                ? <MultipleSelect label={t('supplyBalance.filters.products')} classesOverride={{ form: classes.filterField }}
                    values={store.products.filter(x => !!store.filters.products && store.filters.products.indexOf(x.id) > -1).map(x => x.id)}
                    choices={store.products.map(x => ({ value: x.id, text: x.code }))} disableNewStyle
                    allWhenEmpty hideOnSingleChoice
                    onChange={x => store.setFilters({ ...store.filters, products: x })} />
                : <GroupedMultiSelect label={t('supplyBalance.filters.products')} classesOverride={{ form: classes.filterField }}
                    values={store.filters.products} hideOnSingleChoice allWhenEmpty
                    choices={store.products.map(x => { return { value: x.id, text: x.code, group: x.productGroup! } })} disableNewStyle
                    onChange={val => store.setFilters({ ...store.filters, products: val })} />}
            <MultipleSelect label={t('supplyBalance.filters.dutyStatus')} classesOverride={{ form: classes.filterField }}
                values={store.filters.dutyStatuses}
                choices={store.dutyStatuses.map(x => ({ value: x, text: x }))}
                allWhenEmpty disableNewStyle
                onChange={x => store.setFilters({ ...store.filters, dutyStatuses: x })} />
            <MultipleSelect label={t('supplyBalance.filters.companies')} classesOverride={{ form: classes.filterField }}
                values={hasOneCompany ? store.companies.map(x => x.code) : store.companies.filter(x => !!store.filters.companys && store.filters.companys.indexOf(x.code) > -1).map(x => x.code)}
                choices={store.companies.map(x => ({ value: x.code, text: x.name }))}
                allWhenEmpty={!hasOneCompany} disableNewStyle
                onChange={x => store.setFilters({ ...store.filters, companys: x })} />
        </div>
    )
}

let styles = _ =>
    createStyles({
        filterField: {
            width: '8.3em',
            margin: '0em 0.5em'
        }
    })

export let SupplyBalanceFilters = withStyles(styles, muiOptions)(_SupplyBalanceFilters)