import { createMuiTheme } from '@material-ui/core/styles'
import { defaultColors } from '../../../../infrastructure/materialUiThemeProvider'

export let customDatePickerTheme = createMuiTheme({
    palette: {},
    overrides: {
        //@ts-ignore
        MuiPickerToolbar: {
            toolbar: {
                backgroundColor: defaultColors.darkBlue.main.color,
            },
        },
        MuiInput: {
            root: {
                '& .Mui-disabled': {
                    color: 'black',
                    '& svg': {
                        visibility: 'hidden'
                    },
                },
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                "& .Mui-disabled": {
                    backgroundColor: 'white',
                },
            },
            input: {
                color: 'black'
            }
        },
        root: {
            width: '75%',
        },
    }
})