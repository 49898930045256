import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export function navigateTo(path: string) {
    history.push(path)
}

export function navigateToThenBack(path: string) {
    if (!history.location.pathname.contains(path)) {
        history.push(path, { shouldNavigateBack: true })
    }
}

export function navigateBack() {
    history.goBack()
}
