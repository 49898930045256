import { useEffect, useState } from 'react'
import { StockMovementContainer } from '../stockMovementStore'
import { createContainer } from 'unstated-next'
import { get, post } from '../../../../infrastructure/api'
import { Guid } from '../../../../infrastructure/guid'
import { hasVesselClaim } from '../../../../infrastructure/signIn/userContext'
import moment from 'moment'

export type VesselForMovement = {
    id: Guid
    counterparty: string
    name: string,
    reference: string,
    jetty: string
    laycanStart: string
    laycanEnd: string,
}

export type LinkVesselMovement = {
    movementId: Guid,
    vesselId: Guid
}

function useLinkVesselMovement() {
    let [associatedVessel, setAssociatedVessel] = useState<VesselForMovement | null>()
    let [vesselChoices, setVesselChoices] = useState<VesselForMovement[]>([])
    let [isConfirmVesselOpen, setIsConfirmVesselOpen] = useState<boolean>(false)
    let [selectedVessel, setSelectedVessel] = useState<VesselForMovement | null>(null)

    let store = StockMovementContainer.useContainer()

    let shouldDisableLinkVessel = () => {
        return !store.stockMovement.destinationProductId
            || !store.stockMovement.destinationSite
            || !store.stockMovement.stockInputDate
            || store.stockMovement.meanOfTransportation != "Ship"
    }

    useEffect(() => {
        let loadAssociatedVessel = async () => {
            setAssociatedVessel(await get<VesselForMovement>(`vessel/forMovement/${store.stockMovement.id}`))
        }
        if (!!store.associatedVesselId && hasVesselClaim())
            loadAssociatedVessel()
    }, [store.associatedVesselId])

    let openAssignableVessels = async () => {
        if (shouldDisableLinkVessel()) return

        let parameters = {
            site: store.stockMovement.destinationSite,
            date: store.stockMovement.stockInputDate,
            productId: store.stockMovement.destinationProductId
        }

        let assignableVessels = hasVesselClaim()
            ? await get<VesselForMovement[]>(`vessel/linkableMovement`, parameters)
            : []

        setVesselChoices(assignableVessels)
    }

    let formatDate = (date) => !!date ? moment(date).format('MM/DD') : ''

    let laycanFormated = (vessel: VesselForMovement) => formatDate(vessel.laycanStart) + ' - ' + formatDate(vessel.laycanEnd)

    let assignableVesselLabel = () => selectedVessel
        ? `${selectedVessel.name} - ${selectedVessel.reference} - ${selectedVessel.jetty} - ${laycanFormated(selectedVessel)} `
        : ''

    let assignVessel = async () => {
        let parameters = {
            movementId: store.stockMovement.id,
            dealId: store.stockMovement.associatedDealId,
            vesselId: selectedVessel?.id,
            pricingType: store.dealPricingType
        }

        await post('stock/movement/vessel', parameters)
    }

    return {
        associatedVessel, vesselChoices, setVesselChoices, shouldDisableLinkVessel,
        openAssignableVessels, isConfirmVesselOpen, setIsConfirmVesselOpen, selectedVessel,
        setSelectedVessel, laycanFormated, assignableVesselLabel, assignVessel
    }
}

export let LinkVesselMovementContainer = createContainer(useLinkVesselMovement)