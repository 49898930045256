import React from 'react'
import { withStyles } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import { createStyles } from '@material-ui/styles'
import { muiOptions, defaultColors, MuiProps, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'

type FactorFieldProps = {
    value: number,
    delta?: number | null,
    align: 'right' | 'left',
}

function _VariationNumberLabel({ value, align, classes }: FactorFieldProps & MuiProps) {
    let isNegative = value < 0
    let isPositive = value > 0
    let labelClass = isNegative ? classes.negative : isPositive ? classes.positive : ''
    let variationLabelClass = align == 'right' ? classes.variationOnRight : classes.variationOnLeft

    return (
        <div className={variationLabelClass}>
            <span className={labelClass}>{value}</span>
            <div className={classes.arrows}>
                {isPositive
                    ? <ArrowDropUp className={classes.arrowUp} />
                    : isNegative
                        ? <ArrowDropDown className={classes.arrowDown} />
                        : <ArrowDropUp className={classes.invisibleArrow} />}
            </div>
        </div>
    )
}

function _DeltaNumberLabel({ value, delta, align, classes }: FactorFieldProps & MuiProps) {
    let isNegative = delta && Math.abs(value - delta) > Math.abs(value)
    let isPositive = delta && Math.abs(value - delta) < Math.abs(value)
    let valueToDisplay = value - (delta ?? 0)
    let labelClass = isNegative ? classes.negative : isPositive ? classes.positive : ''
    let variationLabelClass = align == 'right' ? classes.variationOnRight : classes.variationOnLeft
    
    return (
        <div className={variationLabelClass}>
            <span className={labelClass}>{valueToDisplay.toFixed(2)}</span>
            <div className={classes.arrows}>
                {isPositive
                    ? <ArrowDropDown className={classes.arrowUp} />
                    : isNegative
                        ? <ArrowDropUp className={classes.arrowDown} />
                        : <ArrowDropUp className={classes.invisibleArrow} />}
            </div>
        </div>
    )
}

let styles = _ => createStyles({
    variationOnRight: {
        ...defaultStyles.flexRow,
        justifyContent: 'flex-end'
    },
    variationOnLeft: {
        ...defaultStyles.flexRow,
        justifyContent: 'flex-start'
    },
    arrows: { ...defaultStyles.flexRow },
    arrowUp: {
        paddingBottom: '15%',
        color: defaultColors.green.main.color
    },
    arrowDown: {
        paddingTop: '15%',
        color: defaultColors.red.main.color
    },
    invisibleArrow: { color: 'transparent' },
    negative: { color: defaultColors.red.main.color },
    positive: { color: defaultColors.green.main.color },
})

export let VariationNumberLabel = withStyles(styles, muiOptions)(_VariationNumberLabel)
export let DeltaNumberLabel = withStyles(styles, muiOptions)(_DeltaNumberLabel)