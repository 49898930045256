import React, { } from 'react'
import { withStyles, createStyles, Typography, Paper } from '@material-ui/core'
import { defaultStyles, muiOptions, defaultColors } from './../../infrastructure/materialUiThemeProvider'
import { t } from '../../infrastructure/i18nextHelper'
import ForecastEdit from './forecastEdit/_forecastEdit'
import { MktSales } from './models'
import { MktSalesContainer } from './mktSalesStore'
import { MktSalesFilters } from './_mktSalesFilters'
import { MktSalesTable } from './_mktSalesTable'
import { MktSalesChart } from './_mktSalesChart'
import { MktSalesBoardHeader } from './_mktSalesBoardHeader'

function MktSales({ classes }) {
    let store = MktSalesContainer.useContainer()

    let displayClass = () => {
        switch (store.displayMode) {
            case 'split':
                return ' ' + classes.splitDisplayMode
            case 'graph':
                return ' ' + classes.graphDisplayMode
            case 'table':
                return ' ' + classes.tableDisplayMode
            default :
                return ''
        }
    }

    return (
        <div className={classes.pageContainer}>
            <div className={classes.firstRow}>
                <Typography className={classes.pageTitle} variant='h5' display='block' gutterBottom>{t('mktSales.name')}</Typography>
                <div>
                    <MktSalesFilters />
                </div>
            </div>
            <Paper className={classes.secondRow + displayClass()}>
                <div className={classes.boardHeader}>
                    <MktSalesBoardHeader />
                </div>
                <div className={classes.chartContainer}>
                    <MktSalesChart chart={store.chart} hidden={store.displayMode === 'table'} />
                </div>
                <div className={classes.tableContainer}>
                    <MktSalesTable />
                </div>
            </Paper>
            <ForecastEdit closePopup={store.closeForecastPopup} isOpen={store.showForecastPopup} />
        </div>
    )
}

let styles = theme =>
    createStyles({
        firstRow: {
            ...defaultStyles.flexRow,
            alignItems: 'stretch',
            justifyContent: 'space-between',
            'box-sizing': 'border-box',
            'grid-area': 'firstRow'
        },
        chartContainer: {
            ...defaultStyles.flexRow,
            alignItems: 'stretch',
            justifyContent: 'space-between',
            flexGrow: 1,
            'box-sizing': 'border-box',
            transition: 'height 0.5s',
            zIndex: 1,
            'grid-area': 'chartContainer',
            paddingTop: '0.8em'
        },
        tableContainer: {
            ...defaultStyles.flexRow,
            alignItems: 'stretch',
            justifyContent: 'space-between',
            flexGrow: 1,
            transition: 'all 0.5s',
            'box-sizing': 'border-box',
            'grid-area': 'tableContainer'
        },
        secondRow: {
            display: 'grid',
            paddingBottom: '0.5em',
            transition: 'all 0.5s',
            'grid-template-areas': "'boardHeader' 'chartContainer' 'tableContainer'",
            'grid-template-columns': 'auto',
            'grid-gap': '0.8em',
            'overflow-y': 'hidden',
            'box-sizing': 'border-box',
            '&> div': {
                height: '100%',
                width: '100%'
            },
            'grid-area': 'secondRow'
        },
        splitDisplayMode: {
            'grid-template-rows': '5% 45% 50%',
        },
        graphDisplayMode: {
            'grid-template-rows': '5% 95% 0%',
        },
        tableDisplayMode: {
            'grid-template-rows': '5% 0% 95%',
        },
        pageTitle: {
            color: defaultColors.grey.dark.color,
        },
        pageContainer: {
            display: 'grid',
            height: '100%',
            paddingBottom: '0.5em',
            transition: 'all 0.5s',
            'grid-template-areas': "'firstRow' 'secondRow'",
            'grid-template-columns': 'auto',
            'grid-template-rows': '4em 1fr',
            'grid-gap': '0.8em',
            'overflow-y': 'hidden',
            'box-sizing': 'border-box',
            '&> div': {
                height: '100%',
                width: '100%'
            }
        },
    })

export default withStyles(styles, muiOptions)(MktSales)