import React, { useState, useEffect } from 'react'
import { defaultStyles, muiOptions, MuiProps, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import { withStyles, createStyles, Paper, Typography } from '@material-ui/core'
import { post } from '../../infrastructure/api'
import { t } from '../../infrastructure/i18nextHelper'
import { Button, DatePicker } from '../common/customComponents'
import { HelpButton } from '../common/components/helpButton'
import { snackbars } from '../../infrastructure/snackbars'

function ClearDataAdmin({ classes }: MuiProps) {
    return (
        <div className={classes.container}>
            <ClearTactixOutput classes={classes} />
        </div>)
}

function ClearTactixOutput({ classes }: MuiProps) {
    let [date, setDate] = useState<string | null>(null)
    let [canSave, setCanSave] = useState(false)

    useEffect(() => {
        !!date ? setCanSave(true) : setCanSave(false)
    }, [date])

    async function ClearTactixOutputs() {
        await post("stock/movement/mktsale/clear/tactixOutput", { dateTo: date })
        snackbars.success(t('admin.clearData.tactixOutput.success'))
    }

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                {t('admin.clearData.tactixOutput.name')}
            </Typography>
            <div className={classes.fieldsContainer}>
                <div className={classes.inputsContainer}>
                    <Button
                        className={classes.clearButton}
                        disabled={!canSave}
                        onClick={ClearTactixOutputs}
                        label={t('admin.clearData.tactixOutput.button')} />
                    <HelpButton title={t('admin.clearData.tactixOutput.helpTitle')} onClick={() => { }} />
                </div>

                <div className={classes.inputsContainer}>
                    <DatePicker date={date} label={t('admin.clearData.tactixOutput.date')}
                        setDate={newDate => setDate(newDate)} />
                </div>
            </div>
        </Paper>
    )
}

let styles = theme =>
    createStyles({
        paper: {
            width: '100%',
            padding: '1em',
            marginBottom: '1em'
        },
        container: {
            ...defaultStyles.flexColumn,
            marginTop: '2em'
        },
        fieldsContainer: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between'
        },
        inputsContainer: {
            ...defaultStyles.flexRow,
        },
        clearButton: {
            ...defaultStyles.secondaryButton,
            marginRight: '1em'
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            marginLeft: "1em"
        },
    })

export default withStyles(styles, muiOptions)(ClearDataAdmin)