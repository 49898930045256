import moment from 'moment'

const minDate = moment.utc('01/01/2000', 'MM/DD/YYYY')
const maxDate = moment.utc('01/01/2099', 'MM/DD/YYYY')

export function isWithinAcceptableRange(date: moment.Moment) {
    return minDate <= date && date <= maxDate
}

export function asDate(date: string) {
    return moment(date)
}

export function compareAndFixPeriod(
    oldStart: string | null, newStart: string|null,
    oldEnd: string|null, newEnd: string|null) {
    let datesError = moment(newStart).isAfter(newEnd)
    let startChanged = oldStart != newStart
    let endChanged = oldEnd != newEnd
    let lastDayOfMonthOfStart = moment(newStart).endOf('month').format('MM/DD/YYYY')
    let firtDayOfMonthOfEnd = moment(newEnd).startOf('month').format('MM/DD/YYYY')

    let start = !startChanged && datesError ? firtDayOfMonthOfEnd : newStart
    let end = !endChanged && datesError ? lastDayOfMonthOfStart : newEnd
    
    return { start, end }
}