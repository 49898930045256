import React, { useEffect } from 'react'
import { api } from './api'
import { popupNavigator, PopupType, OpenOptions } from './popupNavigator'

export function Startup() {
    useEffect(() => { startup() }, [])

    async function startup() {
        api.checkHttps()
        OpenPopup(popupNavigator.open)
    }

    return (<></>)
}

export function OpenPopup(open: (popup: PopupType, id: string, options?: OpenOptions) => void) {
    let dealId = findQueryParameter('openDeal')
    if (dealId) {
        open('deal', dealId)
        return
    }

    let vesselId = findQueryParameter('openVessel')
    if (vesselId) {
        let productId = findQueryParameter('product')
        open('vessel', vesselId, productId ? { productId: productId } : undefined)
        return
    }

    let movementId = findQueryParameter('openMovement')
    if (movementId) {
        open('movement', movementId)
        return
    }

    let truckId = findQueryParameter('openTruck')
    if (truckId) {
        open('truck', truckId)
        return
    }

    let railCarId = findQueryParameter('openRailCar')
    if (railCarId) {
        open('railCar', railCarId)
        return
    }
}

function findQueryParameter(parameterName: string) {
    if (window.location.search) {
        let query = window.location.search.substring(1)
        let vars = query.split('&')
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=')
            if (decodeURIComponent(pair[0]) === parameterName) {
                return decodeURIComponent(pair[1])
            }
        }
    }
    return null
}