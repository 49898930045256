import { createStyles, withStyles, Checkbox, FormControlLabel } from '@material-ui/core'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import React, { useState, useEffect } from 'react'
import { ColumnDescriptor, MultipleSelect, TableItem, TextField } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import * as api from '../../../infrastructure/api'
import guid, { Guid } from '../../../infrastructure/guid'
import moment from 'moment'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { Company } from './models'
import { MasterDataShell, MasterDataItem } from './masterDataShell'
import { SnackbarContainer } from '../../../infrastructure/snackbars'
import { applyFilters } from '../../common/filtersHelper'

type Counterparty = {
    id: Guid,
    name: string,
    companys: string[],
    isInternal: boolean
}

let zero = (): Counterparty => {
    return {
        id: guid.empty,
        name: '',
        companys: [],
        isInternal: false
    }
}

let toTableItem = (counterparty: Counterparty): TableItem<MasterDataItem<Counterparty>> => {
    return {
        id: counterparty.id,
        name: counterparty.name,
        companys: counterparty.companys,
        isModified: false,
        isInternal: counterparty.isInternal
    }
};

type CounterpartyFilter = {
    companys: string | null,
    name: string | null,
    isInternal: string | null
}

let noFilters: CounterpartyFilter = {
    companys: null,
    name: null,
    isInternal: null
}

function CounterpartyMasterData({ classes }: MuiProps) {
    let [displayedCounterpartys, setDiplayedCounterpartys] = useState<TableItem<Counterparty>[]>([])
    let [companys, setCompanys] = useState<Company[]>([])
    let [filters, setFilters] = useState<CounterpartyFilter>(noFilters)

    let isManager = hasClaim(Claims.MasterdataCounterpartyManager)
    let counterpartyLabel = t('admin.masterdata.counterparty.counterparty')
    let snackbar = SnackbarContainer.useContainer()

    let load = async () => {
        let companys = await api.get<Company[]>('masterdata/counterparty/company')
        let counterpartys = await api.get<Counterparty[]>('masterdata/counterparty')

        setCompanys(companys)
        setDiplayedCounterpartys(counterpartys.map(toTableItem))
    }

    useEffect(() => { let effect = async () => { await load() }; effect() }, [])
    useEffect(() => { applyFilters(displayedCounterpartys, filters) }, [filters])

    let onDeleteCounterparty = async (ids: string[]) => {
        if (ids.length === 0) return false
        await api.del('masterdata/counterparty', { ids: ids })
        await load()
        return true
    }

    let onSaveCounterParty = async (item: Counterparty) => {
        await api.post('masterdata/counterparty', item)
        await load()
        return true
    }

    let getBooleanLabel = (value: boolean): string => value
        ? t('admin.masterdata.company.isYes')
        : t('admin.masterdata.company.isNo')

    let columns: ColumnDescriptor<TableItem<MasterDataItem<Counterparty>>>[] = [
        {
            name: t('admin.masterdata.counterparty.name'), value: x => x.name,
            columnFilter: { value: filters.name ?? '', onChange: (name: string) => setFilters({ ...filters, name }) }
        },
        {
            name: t('admin.masterdata.counterparty.companys'), value: x => x.companys.join(', '),
            columnFilter: { value: filters.companys ?? '', onChange: (companys: string) => setFilters({ ...filters, companys }) }
        },
        {
            name: t('admin.masterdata.counterparty.isInternal'),
            value: x => getBooleanLabel(x.isInternal),
            columnFilter: { value: filters.isInternal ?? '', onChange: (isInternal: string) => setFilters({ ...filters, isInternal }) }
        }
    ]

    let exportExcel = async () => {
        let filename = `COUNTERPARTY_${moment().format('YYYY-MM-DD-HH-mm-ss')}_${guid.createNew().substring(0, 4)}.xlsx`
        await api.post('masterdata/counterparty/export', { filename: filename })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')
    }

    let importExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/counterparty/import'
        api.upload(uploadUrl, file, 'import').then(_ => {
            load();
            snackbar.success(t('httpSuccess.importSuccessful'))
        })
    }

    return (
        <div className={classes.container}>
            <MasterDataShell
                headerLabel={counterpartyLabel}
                itemLabel={counterpartyLabel}
                isManager={isManager}
                onExportExcel={exportExcel}
                onImportExcel={importExcel}
                onNew={zero}
                onDelete={onDeleteCounterparty}
                onSave={onSaveCounterParty}
                items={applyFilters(displayedCounterpartys, filters).map(toTableItem)}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <TextField label={t('admin.masterdata.counterparty.name')}
                                disabled={!isManager}
                                text={selectedItem.name}
                                onChange={event => setSelectedItem({ ...selectedItem, name: event.target.value })} />
                            <MultipleSelect label={t('admin.masterdata.counterparty.companys')}
                                chips={true}
                                allWhenEmpty={false}
                                disabled={!isManager}
                                values={selectedItem.companys}
                                choices={companys.map(x => ({ value: x.code, text: x.name }))}
                                onChange={companiesCode => setSelectedItem({ ...selectedItem, companys: companiesCode })} />
                            <div>
                                <FormControlLabel
                                    control={<Checkbox checked={selectedItem.isInternal}
                                        onChange={x => setSelectedItem({ ...selectedItem, isInternal: x.target.checked })} />}
                                    label={t('admin.masterdata.counterparty.isInternal')}
                                    labelPlacement="start"
                                />
                            </div>
                        </>
                    )}
            </MasterDataShell>
        </div>)
}


let styles = (theme) => createStyles({ container: { height: '100%' } })

export default withStyles(styles, muiOptions)(CounterpartyMasterData)