import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import './infrastructure/i18nextHelper'
import './infrastructure/extensions'
import 'chartjs-adapter-moment'
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart, CategoryScale, PointElement, LineElement, LinearScale, TimeSeriesScale, Legend, Filler, Tooltip } from 'chart.js'
import { GuidedTour } from './infrastructure/guidedTour/guidedTour'
Chart.register(TimeSeriesScale, CategoryScale, LinearScale, PointElement, LineElement, Legend, Filler, Tooltip, annotationPlugin)

ReactDOM.render(
    <Suspense fallback="loading">
        <GuidedTour >
            <App />
        </GuidedTour>
    </Suspense>,
    document.getElementById('appContainer'),
)
