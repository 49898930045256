import { createContainer } from 'unstated-next'
import { useState } from 'react'

function useMovementMessage() {
    let [searchText, setSearchText] = useState<string>('')
    let [selectedMovementIds, setSelectedMovementIds] = useState<string[]>([])

    return {
        searchText, setSearchText,
        selectedMovementIds, setSelectedMovementIds
    }
}

export let MovementMessageContainer = createContainer(useMovementMessage)