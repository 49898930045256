import { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import moment from 'moment'
import * as api from '../../infrastructure/api'
import { queryStringBuilder } from '../../infrastructure/queryStringBuilder'
import {
    Site, Product, DutyStatus, Company, SupplyBalanceFilter, SearchSupplyBalances,
    SearchSupplyBalancesResult, SupplyBalanceTableData, SupplyBalance, SupplyDemand, SupplyResource,
    defaultFilters, defaultSupplyBalanceTable, SupplyBalanceRxFilters, SupplyBalancePeriod, defaultSupplyBalancePeriod
} from './supplyBalanceModels'
import { Subject, interval } from 'rxjs'
import { compareAndFixPeriod } from '../common/components/datePicker/tools'
import { UserContextContainer } from '../../infrastructure/signIn/userContext'
import { StockBoardContainer } from '../stock/stockBoardStore'
import { switchMap } from 'rxjs/operators'
import { debounce } from 'rxjs/operators'

function useSupplyBalanceStore() {
    let stockBoardStore = StockBoardContainer.useContainer()
    let userContext = UserContextContainer.useContainer()

    let [sites, setSites] = useState<Site[]>([])
    let [products, setProducts] = useState<Product[]>([])
    let [dutyStatuses, setDutyStatuses] = useState<DutyStatus[]>([])
    let [companies, setCompanies] = useState<Company[]>([])
    let [displayBody, setDisplayBody] = useState<boolean>(false)
    let [filters, setFilters] = useState<SupplyBalanceFilter>(defaultFilters())
    let [supplyBalanceQuerySubject] = useState<Subject<SupplyBalanceRxFilters>>(new Subject<SupplyBalanceRxFilters>())
    let [supplyBalanceTables, setSupplyBalanceTables] = useState<SupplyBalanceTableData[]>([defaultSupplyBalanceTable(null)])

    let [demandDetails, setDemandDetails] = useState(false)
    let [ressourcesDetails, setRessourcesDetails] = useState(false)
    let [kUnite, setKUnite] = useState(false)

    let [supplyBalanceDefaultPeriod, setPeriodDates] = useState<SupplyBalancePeriod>(defaultSupplyBalancePeriod)
    let [disabledStockTarget, setDisabledStockTarget] = useState<boolean>(false)
    let [disabledStockTargetDays, setDisabledStockTargetDays] = useState<boolean>(false)

    useEffect(() => {
        if (!userContext.isLoggedIn) return

        loadFilters()

        let subscription = supplyBalanceQuerySubject.asObservable()
            .pipe(
                debounce(() => interval(500)),
                switchMap(async ({ latestFilters, lastestSupplyBalanceTables }) => {
                    let supplyBalanceTables = await processSupplyBalanceTables(latestFilters, lastestSupplyBalanceTables)
                    return { latestFilters, supplyBalanceTables }
                })
            )
            .subscribe((x) => {
                stockBoardStore.impactFilters(x.latestFilters)
                setSupplyBalanceTables(x.supplyBalanceTables)
                setDisabledStockTarget(false)
                setDisabledStockTargetDays(false)
            })

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    let processSupplyBalanceTables =
        async (latestFilters: SupplyBalanceFilter, latestSupplyBalanceTable: SupplyBalanceTableData[]) => {
            let updatedSupplyBalanceTables: SupplyBalanceTableData[] = []

            for (let supplyBalance of latestSupplyBalanceTable) {
                if (!supplyBalance.period.start || !supplyBalance.period.end) {
                    updatedSupplyBalanceTables.push({ ...supplyBalance })
                } else {
                    let supplyBalanceResult = await loadSupplyBalanceTableByDates(supplyBalance, latestFilters)
                    updatedSupplyBalanceTables.push({ ...supplyBalance, supplyBalanceResult })
                }
            }

            return updatedSupplyBalanceTables
        }

    let loadSupplyBalanceTableByDates = async (supplyBalance: SupplyBalanceTableData, latestFilters: SupplyBalanceFilter) => {
        let { period } = supplyBalance
        if (!period.start || !period.end) {
            return supplyBalanceFromPeriod(period)?.supplyBalanceResult ?? defaultSupplyBalanceTable(period.start).supplyBalanceResult
        }

        let filtersToUse = latestFilters ?? filters
        let args: SearchSupplyBalances = {
            startDate: moment(period.start).format('MM-DD-YYYY'),
            endDate: moment(period.end).format('MM-DD-YYYY'),
            sites: filtersToUse.sites.length !== 0 ? filtersToUse.sites : filtersToUse.allSites,
            products: filtersToUse.products.length !== 0 ? filtersToUse.products : filtersToUse.allProducts,
            dutyStatuses: filtersToUse.dutyStatuses.length !== 0 ? filtersToUse.dutyStatuses : filtersToUse.allDutyStatuses,
            companys: filtersToUse.companys.length !== 0 ? filtersToUse.companys : filtersToUse.allCompanys,
            usefulStock: filtersToUse.usefulStock
        }

        let newSupplyBalanceResult = await api.get<SearchSupplyBalancesResult>(`stock/supplyBalance/searchSupplyBalance${queryStringBuilder(args)}`)
        let supplyBalanceResult: SearchSupplyBalancesResult = supplyBalance.supplyBalanceResult
        newSupplyBalanceResult.balances.forEach((newBalance, i) => {
            let supplyBalance = supplyBalanceResult.balances[i]
            if (supplyBalance && supplyBalance.productCode === newBalance.productCode && supplyBalance.site === newBalance.site && supplyBalance.siteGroup === newBalance.siteGroup) {
                newBalance.need.ullageEnd = supplyBalance.need.ullageEnd
            }
        })

        return newSupplyBalanceResult
    }

    let loadFilters = async () => {
        let productsPromise = api.get<Product[]>('stock/supplyBalance/product')
        let companiesPromise = api.get<Company[]>('stock/supplyBalance/company')
        let sitesPromise = api.get<Site[]>('stock/supplyBalance/site')

        let products = await productsPromise
        let companies = await companiesPromise
        let sites = await sitesPromise
        let dutyStatuses = companies.length > 0 ? companies.map(x => x.dutyStatuses)?.reduce((a, b) => a.concat(b)).distinct() : []

        setFilters({
            ...filters,
            allSites: sites.map(x => x.code),
            allProducts: products.map(x => x.id),
            allDutyStatuses: dutyStatuses,
            allCompanys: companies.map(x => x.code)
        })
        setLocalStoredFiltersValues()

        setSites(sites)
        setProducts(products)
        setCompanies(companies)
        setDutyStatuses(dutyStatuses)

        changePeriod(supplyBalanceTables[0].period, supplyBalanceDefaultPeriod.start, supplyBalanceDefaultPeriod.end)
    }

    let loadSupplyBalance = () =>
        supplyBalanceQuerySubject.next({
            latestFilters: filters,
            lastestSupplyBalanceTables: supplyBalanceTables
        })

    let formatDatesToTriggerChanges = () => {
        return supplyBalanceTables.map(x => `${x.period.start}${x.period.end}`).join()
    }

    let applyPreviousStockTargetToCurrentOpening =
        (period: SupplyBalancePeriod, newSupplyBalanceResult: SearchSupplyBalancesResult): SearchSupplyBalancesResult => {
            let currentSupplyBalanceTableIndex = supplyBalanceTables.findIndex(x => x.period.start === period.start && x.period.end === period.end)

            if (currentSupplyBalanceTableIndex === 0) return newSupplyBalanceResult

            let previousSupplyBalanceIndex = currentSupplyBalanceTableIndex - 1
            let stockTargetsToApply = supplyBalanceTables[previousSupplyBalanceIndex].supplyBalanceResult.balances

            stockTargetsToApply.forEach(s => {
                if (s.need.stockTarget != null) {
                    let itemToChange = newSupplyBalanceResult.balances.find(x => x.productId === s.productId && x.site === s.site)
                    if (itemToChange) itemToChange.resource.stockProjOpening = s.need.stockTarget
                }
            })

            newSupplyBalanceResult.balances.filter(x => x.isSubTotal).forEach(s => {
                s.resource.stockProjOpening = newSupplyBalanceResult.balances
                    .filter(x => x.siteGroup === s.siteGroup && !x.isSubTotal)
                    .reduce((acc, curr) => acc += curr.resource.stockProjOpening ?? 0, 0)
            })

            newSupplyBalanceResult.total.resource.stockProjOpening = newSupplyBalanceResult.balances
                .filter(x => x.isSubTotal)
                .reduce((acc, curr) => acc += curr.resource.stockProjOpening ?? 0, 0)

            return newSupplyBalanceResult
        }

    let getIndexSupplyBalanceFromPeriod = (period: SupplyBalancePeriod) =>
        supplyBalanceTables.findIndex(x => x.period.start === period.start && x.period.end === period.end)

    let supplyBalanceFromPeriod = (period: SupplyBalancePeriod) => {
        let index = getIndexSupplyBalanceFromPeriod(period)
        return index >= 0 ? supplyBalanceTables[index] : null
    }

    let changeSupplyBalancesResults = (period: SupplyBalancePeriod, balances: SupplyBalance[]) => {
        let supplyBalance = supplyBalanceFromPeriod(period)
        if (!supplyBalance) return

        replaceSupplyBalanceTableAtPeriod(period, {
            ...supplyBalance,
            supplyBalanceResult:
            {
                ...supplyBalance.supplyBalanceResult,
                balances: balances
            }
        })

        let index = supplyBalanceTables.findIndex(x => x.period.start === period.start && x.period.end === period.end)
        let shouldUpdateNextPeriods = index < supplyBalanceTables.length - 1

        if (shouldUpdateNextPeriods) {
            let nextPeriod = supplyBalanceTables[index + 1]
            let updatedSupplyBalanceResult = applyPreviousStockTargetToCurrentOpening(nextPeriod.period, nextPeriod.supplyBalanceResult)
            replaceSupplyBalanceTableAtPeriod(nextPeriod.period, { ...nextPeriod, supplyBalanceResult: updatedSupplyBalanceResult })
        }
    }

    let addPeriod = () => {
        let previousSupplyBalanceTableIndex = supplyBalanceTables.length - 1

        if (previousSupplyBalanceTableIndex >= 0) {
            let previousEndDate = supplyBalanceTables[previousSupplyBalanceTableIndex].period.end
            let newStartDate = moment(previousEndDate).add(1, 'd').format('MM/DD/YYYY')

            setSupplyBalanceTables([...supplyBalanceTables, defaultSupplyBalanceTable(newStartDate)])
        }
    }

    function parseLocalStorage(): SupplyBalanceFilter {
        let localStorageFilters = localStorage.getItem('filters')

        if (localStorageFilters) {
            let filterObj = JSON.parse(localStorageFilters)
            let filtersLength = Object.keys(filterObj).length

            if (filtersLength === 0) return { ...filters }

            let parseArray = x => Array.isArray(x) ? x : null

            return {
                ...filters,
                dutyStatuses: parseArray(filterObj.dutyStatuses) ?? filters.dutyStatuses,
                products: parseArray(filterObj.productIds) ?? filters.products,
                companys: parseArray(filterObj.companies) ?? filters.companys,
                sites: parseArray(filterObj.sites) ?? filters.sites,
                usefulStock: filterObj.usefulStock ?? filters.usefulStock,
                allSites: parseArray(filterObj.allSites) ?? filters.allSites,
                allProducts: parseArray(filterObj.allProductIds) ?? filters.allProducts,
                allDutyStatuses: parseArray(filterObj.allDutyStatuses) ?? filters.allDutyStatuses,
                allCompanys: parseArray(filterObj.allCompanies) ?? filters.allCompanys
            }
        }

        return { ...filters }
    }

    let setLocalStoredFiltersValues = () => setFilters(parseLocalStorage())

    let changePeriod = async (period: SupplyBalancePeriod, start: string | null, end: string | null) => {
        let index = getIndexSupplyBalanceFromPeriod(period)
        let supplyBalanceTable = supplyBalanceTables[index];
        if (!supplyBalanceTable) return

        if (index === 0)
            setFirstSupplyBalancePeriodDates(start, end)

        let newPeriod = compareAndFixPeriod(supplyBalanceTable.period.start, start, supplyBalanceTable.period.end, end)
        supplyBalanceTable.period.start = newPeriod.start
        supplyBalanceTable.period.end = newPeriod.end

        if ((index + 1) > 0 && (index + 1) < supplyBalanceTables.length) {
            changePeriod(
                supplyBalanceTables[(index + 1)].period,
                moment(supplyBalanceTable.period.end).add(1, 'd').format('MM/DD/YYYY'),
                supplyBalanceTables[(index + 1)].period.end)
        }

        replaceSupplyBalanceTableAtPeriod(supplyBalanceTables[index].period, supplyBalanceTable)
    }

    let setFirstSupplyBalancePeriodDates = (start: string | null, end: string | null) => {
        let firstPeriod = supplyBalanceDefaultPeriod;
        firstPeriod['start'] = start;
        firstPeriod['end'] = end;
        setPeriodDates(firstPeriod);
    }

    let replaceSupplyBalanceTableAtPeriod = (period: SupplyBalancePeriod, newItem: SupplyBalanceTableData) => {
        let newTable = supplyBalanceTables.map(original => original.period.start === period.start && original.period.end === period.end ? newItem : original)
        setSupplyBalanceTables([...newTable])
    }

    let demandTotal = (demands: SupplyDemand) => {
        return Object.keys(demands).reduce((acc, curr) => acc += demands[curr] ?? 0, 0)
    }

    let resourceTotal = (resources: SupplyResource) => {
        return Object.keys(resources).reduce((acc, curr) => acc += resources[curr] ?? 0, 0)
    }

    let stockTargetSupplyNeedTotal = (data: SupplyBalanceTableData, field: string) => {
        return data.supplyBalanceResult.balances
            .filter(item => item.isSubTotal)
            .map(item => item.need[field])
            .reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0)
    }

    return {
        sites, products, dutyStatuses, companies,
        filters, setFilters,
        displayBody, setDisplayBody,
        supplyBalanceTables,
        supplyBalanceQuerySubject,
        loadFilters,
        setSupplyBalanceTables,
        changeSupplyBalancesResults,
        changePeriod,
        addPeriod,
        formatDatesToTriggerChanges,
        replaceSupplyBalanceTableAtPeriod,
        loadSupplyBalanceTableByDates,
        supplyBalanceFromPeriod,
        demandDetails, setDemandDetails,
        ressourcesDetails, setRessourcesDetails,
        kUnite, setKUnite,
        demandTotal, resourceTotal,
        getIndexSupplyBalanceFromPeriod,
        stockTargetSupplyNeedTotal,
        loadSupplyBalance,
        disabledStockTarget,
        disabledStockTargetDays,
        setDisabledStockTarget,
        setDisabledStockTargetDays
    }
}

export let SupplyBalanceStore = createContainer(useSupplyBalanceStore)