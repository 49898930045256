import React from 'react'
import { MovementStatus } from './stockModels'
import { StatusChip } from '../common/customComponents'
import { t } from '../../infrastructure/i18nextHelper'

type Props = { status: MovementStatus, fontSize?: 'small' | 'regular', disabled?: boolean, hoverable?: boolean }

export function MovementStatusChip({ status, fontSize, disabled, hoverable }: Props) {
    let option = options.find(x => x.status === status)
    return (<StatusChip color={disabled ? 'grey' : option!.color} fontSize={fontSize} hoverable={hoverable}>{t(option!.text)}</StatusChip >)
}

const options = [
    { status: MovementStatus.Forecast, color: 'purple', text: 'vessels.label.status.forecast' },
    { status: MovementStatus.Actual, color: 'green', text: 'vessels.label.status.actual' },
    { status: MovementStatus.Planned, color: 'blue', text: 'vessels.label.status.planned' },
] as { status: MovementStatus, color: 'purple' | 'green' | 'blue', text: string }[]