import React from 'react'
import { withStyles, createStyles, Typography, Switch as MuiSwitch, FormControlLabel, FormControl, Tooltip } from '@material-ui/core'
import { muiOptions, defaultColors, defaultStyles, MuiProps } from '../../../infrastructure/materialUiThemeProvider'

let ColoredSwitch = withStyles({
    switchBase: {
        color: defaultColors.darkBlue.light.color,
        '&$checked': {
            color: defaultColors.darkBlue.main.color,
        },
        '&$checked + $track': {
            backgroundColor: defaultColors.lightBlue.main.color,
        },
    },
    checked: {},
    track: {},
})(MuiSwitch)

type SwitchProps = {
    isChecked: boolean,
    changeCallback: () => void,
    offText?: string,
    onText?: string,
    label?: string,
    disabled?: boolean
    className?: string
    form?: boolean
    title?: string
}

function TwoTextSwitch({ classes, isChecked, changeCallback, offText, onText, label, disabled, id, className, form, title }: SwitchProps & MuiProps) {
    return (
        <Tooltip title={title ? <Typography variant='body1'>{title}</Typography> : ''}>
            <div className={className ?? `${classes.container} ${form ? classes.containerWithForm : ''}`}>
                <Typography variant='subtitle1' className={!!label ? classes.offText : classes.offTextNoLabel}>{offText}</Typography>
                <FormControl>
                    <FormControlLabel
                        className={'switchRoot'}
                        value={isChecked}
                        control={<ColoredSwitch id={id} disabled={disabled ?? false} checked={isChecked} onChange={changeCallback} />}
                        label={!!label ? <Typography variant='caption' className={classes.label}>{label}</Typography> : null}
                        labelPlacement='top'
                    />
                </FormControl>
                <Typography variant='subtitle1' className={!!label ? classes.onText : classes.onTextNoLabel}>{onText}</Typography>
            </div>
        </Tooltip>
    )
}

let styles = _ =>
    createStyles({
        container: {
            ...defaultStyles.flexRow,
            margin: '0em 0.5em',
            justifyContent: 'center'
        },
        containerWithForm: {
            minWidth: '15.3em',
        },
        offText: {
            paddingTop: '1.2em',
            marginRight: '-1.5em',
            alignText: 'right'
        },
        onText: {
            paddingTop: '1.2em',
            marginLeft: '-1.5em'
        },
        offTextNoLabel: {
            alignText: 'right',
            marginRight: '-1em',
        },
        onTextNoLabel: {
            marginLeft: '-1em'
        },
        label: {
            color: defaultColors.grey.dark.color,
            lineHeight: 1,
            marginLeft: '-1em'
        }
    })

export let Switch = withStyles(styles, muiOptions)(TwoTextSwitch)