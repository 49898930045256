import { t } from "../../infrastructure/i18nextHelper"

export const getBooleanLabel = (value: boolean): string => value
    ? t('admin.masterdata.isYes')
    : t('admin.masterdata.isNo')

const filterByInput = (prop: string, input: string) => (filter: any) => {
    if (typeof filter[prop] === 'boolean') {
        var value = getBooleanLabel(filter[prop])
        return value.toLowerCase().contains(input.toLowerCase())
    }
    return (filter[prop] + '').toLowerCase().contains(input.toLowerCase())
}

export function applyFilters<T>(data: T[], filters: any): T[] {
    for (const input in filters) {
        if (filters[input])
            data = data.filter(filterByInput(input, filters[input]));
    }
    return data
}