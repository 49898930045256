import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import createStyles from '@material-ui/styles/createStyles'
import { red } from '@material-ui/core/colors/'
import { createMuiTheme, Theme } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

export type MuiProps = {
    id?: string
    classes: any
    theme?: Theme
}

function createMaterialUiTheme(options: any) {
    return createMuiTheme({
        ...options,
    })
}

export let theme = createMaterialUiTheme({
    palette: {
        primary: {
            light: '#4965b4',
            main: '#013b84',
            dark: '#0011756',
            contrastText: '#FFF',
        },
        secondary: red,
        error: red,
    },
    breakpoints: {
        values: {
            lg: 1500,
            xl: 1920
        }
    },
    overrides: {
        MuiListItemIcon: {
            root: {
                color: 'inherit',
            },
        },
        MuiTypography: {
            subheading: {
                color: 'inherit',
            },
            title: {
                color: 'inherit',
            },
        },
        MuiTableCell: {
            head: {
                color: 'inherit',
            },
            footer: {
                left: 0,
                bottom: 0,
                zIndex: 2,
                position: 'sticky',
                height: '1rem',
                paddingTop: '3px !important',
                paddingBottom: '3px !important',
                backgroundColor: '#fafafa',
                '&::before': {
                    content: '""',
                    display: 'inline-block',
                    position: 'absolute',
                    width: '100%',
                    height: '1px',
                    top: '-1px',
                    left: 0,
                    backgroundColor: 'rgba(224, 224, 224, 1)'
                }
            }
        }
    },
})

export let muiOptions = { withTheme: true }

export const defaultColors = {
    primary: {
        light: {
            color: '#4965b4',
            text: '#ffffff',
        },
        main: {
            color: '#013b84',
            text: '#ffffff',
        },
        dark: {
            color: '#0011756',
            text: '#ffffff',
        },
    },
    secondary: {
        light: {
            color: theme.palette.secondary.light,
            text: theme.palette.getContrastText(theme.palette.secondary.light),
        },
        main: {
            color: theme.palette.secondary.main,
            text: theme.palette.getContrastText(theme.palette.secondary.main),
        },
        dark: {
            color: theme.palette.secondary.dark,
            text: theme.palette.getContrastText(theme.palette.secondary.dark),
        },
    },
    orange: {
        veryLight: {
            color: '#fbf0e2',
            text: '#000000',
        },
        light: {
            color: '#ffae42',
            text: '#000000',
        },
        main: {
            color: '#db7e04',
            text: '#000000',
        },
        dark: {
            color: '#a35100',
            text: '#ffffff',
        },
    },
    red: {
        light: {
            color: '#e94b52',
            text: '#000000',
        },
        main: {
            color: '#b00429',
            text: '#ffffff',
        },
        dark: {
            color: '#790000',
            text: '#ffffff',
        },
    },
    darkBlue: {
        light: {
            color: '#4965b4',
            text: '#ffffff',
        },
        main: {
            color: '#013b84',
            text: '#ffffff',
        },
        dark: {
            color: '#011756',
            text: '#ffffff',
        },
    },
    lightBlue: {
        light: {
            color: '#4e6bc7',
            text: '#ffffff',
        },
        main: {
            color: '#004196',
            text: '#ffffff',
        },
        dark: {
            color: '#001c67',
            text: '#ffffff',
        },
    },
    grey: {
        veryLight: {
            color: 'rgb(250, 250, 250, 0.7)',
            text: '#000000',
        },
        light: {
            color: '#b3b3b3',
            text: '#000000',
        },
        main: {
            color: '#838383',
            text: '#ffffff',
        },
        dark: {
            color: '#565656',
            text: '#ffffff',
        },
    },
    green: {
        light: {
            color: '#5fba3e',
            text: '#000000',
        },
        main: {
            color: '#278903',
            text: '#ffffff',
        },
        dark: {
            color: '#005b00',
            text: '#ffffff',
        },
    },
    focus: {
        main: {
            color: 'rgb(116, 185, 255, 0.1)'
        },
        dark: {
            color: 'rgb(116, 185, 255, 0.2)',
        },
    },
    inputText: 'rgba(0, 0, 0, 0.87)'
}

export let dialogCell: CSSProperties = {
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
}

export let alignRight: CSSProperties = { textAlign: 'right' }

export const borderStyle = '1px solid ' + defaultColors.grey.main.color

export const defaultStyles = createStyles({
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    input: {
        width: '50px',
        textAlign: 'center',
    },
    inputError: {
        width: '50px',
        textAlign: 'center',
        backgroundColor: 'red',
        color: 'white',
    },
    primaryButton: {
        height: '3em',
        color: defaultColors.red.main.text,
        backgroundColor: defaultColors.red.main.color,
        '&:hover': {
            backgroundColor: defaultColors.red.dark.color,
        },
        '&:active': {
            backgroundColor: defaultColors.red.light.color,
        },
    },
    secondaryButton: {
        height: '3em',
        color: defaultColors.red.main.color,
        border: '1px solid',
        borderColor: 'silver',
        backgroundColor: 'white',
        '&:hover': {
            color: defaultColors.red.dark.color,
            backgroundColor: 'transparent',
        },
        '&:active': {
            color: defaultColors.red.light.color,
            backgroundColor: 'transparent',
        },
    },
    tertiaryButton: {
        color: defaultColors.red.main.color,
    },
    dialogPrimaryButton: {
        color: defaultColors.primary.main.color,
        marginTop: '1em',
    },
    dialogCloseButton: {
        marginTop: '1em',
        color: defaultColors.grey.dark.color
    },
    hidden: {
        display: 'none',
    }
})

export function customStyledComponent(Component) {
    return (style, options) => {
        function StyledComponent(props: { [x: string]: any; classes: any; className: string }) {
            const { classes, className, ...other } = props
            return <Component className={classNames(classes.root, className)} {...other} />
        }
        const styles = typeof style === 'function' ? theme => ({ root: style(theme) }) : { root: style }
        return withStyles(styles, options)(StyledComponent)
    }
}
