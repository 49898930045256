import moment from 'moment'

export function updateDateTime(newDate, newTime) {
    let dateMoment = moment(newDate)
    let timeMoment = moment(newTime)

    let combinedDateTime = dateMoment.set({
        hour: timeMoment.hour(),
        minute: timeMoment.minute(),
        second: timeMoment.second(),
        millisecond: timeMoment.millisecond()
    })

    return moment(combinedDateTime).format('YYYY-MM-DDTHH:mm:ss')
}