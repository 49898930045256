import { Guid } from './guid'
import { vesselDialog } from '../app/vessels/vesselEdit/vesselEditStore'
import { movementDialog } from '../app/stock/movementEdit/stockMovementStore'
import { dealDialog } from '../app/deals/dealEdit/dealEditStore'
import { truckDialog } from '../app/truck/form/truckFormStore'
import { pricingDialog } from '../app/pricings/pricingDialog'
import { DealPricingType } from '../app/deals/dealModels'

export type PopupType = 'vessel' | 'deal' | 'movement' | 'truck' | 'railCar' | 'pricing' | ''

export type OpenOptions = {
    productId?: Guid,
    pricingType?: DealPricingType
}

let open = (popupType: PopupType, id: Guid | null, options?: OpenOptions) => {
    closeAll()

    if (popupType === 'vessel') vesselDialog.open(id, options?.productId)
    if (popupType === 'deal') dealDialog.open(id, options?.pricingType)
    if (popupType === 'movement') movementDialog.open(id)
    if (popupType === 'truck') truckDialog.open(id, 'Road')
    if (popupType === 'railCar') truckDialog.open(id, 'Train')
}

let closeAll = () => {
    vesselDialog.close()
    movementDialog.close()
    dealDialog.close()
    truckDialog.close()
    pricingDialog.close()
}

export let popupNavigator = { open }

export type PopupNavigatorType = typeof popupNavigator