import { useState, useEffect, RefObject, useRef, useImperativeHandle } from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import { createContainer } from 'unstated-next'
import * as api from '../../../infrastructure/api'
import guid, { Guid } from '../../../infrastructure/guid'
import { hasAutoCreateDealAndHasNotSelectPricingTypeFeature, hasFeature } from '../../../infrastructure/feature'
import { hasDealClaim, hasVesselClaim, UserContextContainer, hasScopeOnClaim, hasTruckClaim, hasClaim } from '../../../infrastructure/signIn/userContext'
import { snackbars } from '../../../infrastructure/snackbars'
import { t } from '../../../infrastructure/i18nextHelper'
import { Claims } from '../../../infrastructure/signIn/models'
import {
    MovementForm, defaultStockMovement, Counterparty, DutyStatus, Company, Site, StockProduct,
    MeanOfTransportation, BatchMovement, BatchMode, DealLabelId, PricingType, inMovementTypes, defaultAssociatedDeal, SapFlow
} from '../stockModels'
import { useStockFormErrors } from './formErrors'
import React from 'react'
import { Deal } from 'src/app/deals/dealModels'

type OpenCloseDialogRef = { open: (id?: Guid | null, half?: boolean) => void, close: () => void }
let dialogRef: RefObject<OpenCloseDialogRef> | null = null
export let movementDialog = {
    open: (id?: Guid | null, half?: boolean) => dialogRef?.current?.open(id, half),
    close: () => dialogRef?.current?.close(),
}

function useStockMovement() {
    let [stockMovement, setStockMovement] = useState<MovementForm>(defaultStockMovement())
    let [isConfirmDealOpen, setIsConfirmDealOpen] = useState<boolean>(false)
    let [batchMovement, setBatchMovement] = useState<BatchMovement | null>(null)
    let [isOpen, setIsOpen] = useState<boolean>(false)
    let [isHalfBottom, setIsHalfBottom] = useState<boolean>(false)
    let [associatedDeal, setAssociatedDeal] = useState<DealLabelId | null>(null)
    let [associatedVesselId, setAssociatedVesselId] = useState<Guid | null>(null)
    let [associatedTruckId, setAssociatedTruckId] = useState<Guid | null>(null)
    let [counterpartys, setCounterpartys] = useState<Counterparty[]>([])
    let [companys, setCompanys] = useState<Company[]>([])
    let [sites, setSites] = useState<Site[]>([])
    let [products, setProducts] = useState<StockProduct[]>([])
    let [dutyStatuses, setDutyStatuses] = useState<DutyStatus[]>([])
    let [meanOfTransportations, setMeanOfTransportations] = useState<MeanOfTransportation[]>([])
    let [dealCandidates, setDealCandidates] = useState<DealLabelId[]>([])
    let [unit, setUnit] = useState<string | undefined>()
    let [selectPricingTypeFor, setSelectPricingTypeFor] = useState<'vessel' | 'deal' | null>(null)
    let [openAssignDealPopup, setOpenAssignDealPopup] = useState<boolean>(false)
    let [dealPricingType, setDealPricingType] = useState<string | null>(null)
    let [dealPricingTypes, setDealPricingTypes] = useState<PricingType[]>([])
    let [assignableDealsFromMovement, setAssignableDealsFromMovement] = useState<Deal[] | null>(null)
    let [dealIdToAssign, setDealIdToAssign] = useState<Guid | null>(null)

    let userContext = UserContextContainer.useContainer()

    let formErrorsContext = React.createContext({ stockMovement, batchMovement, companys, counterpartys })
    let formErrors = useStockFormErrors(formErrorsContext)

    let setUnitByProductId = (productId) => setUnit(products.find(x => x.id === productId)?.unit)
    useEffect(() => { setUnitByProductId(stockMovement.originProductId) }, [stockMovement.originProductId])
    useEffect(() => { setUnitByProductId(stockMovement.destinationProductId) }, [stockMovement.destinationProductId])

    useEffect(() => {
        if (userContext.isLoggedIn) return

        ReactDOM.unstable_batchedUpdates(() => {
            setCompanys([])
            setSites([])
            setDutyStatuses([])
            setProducts([])
        })
    }, [userContext.isLoggedIn])

    let load = async (id: Guid | null) => {
        let refsPromise = products.length == 0 ? getRefs() : null
        let movement = id
            ? await api.get<MovementForm>(`stock/movement/${id}`)
            : { ...defaultStockMovement(), id: guid.createNew() }

        if (!movement.mainSapFlow.id)
            movement.mainSapFlow.id = guid.createNew()

        if (movement.secondSapFlow && !movement.secondSapFlow.id)
            movement.secondSapFlow.id = guid.createNew()

        let dealPricingTypes = hasClaim(Claims.StockManager)
            ? await api.get<PricingType[]>('stock/movement/deal/pricingTypes')
            : []

        let refs = refsPromise ? await refsPromise : null
        let associatedVesselId = hasVesselClaim() ? movement.associatedVesselId : null
        let associatedDeal = hasDealClaim() && !!movement.associatedDealId
            ? { ...defaultAssociatedDeal(), id: movement.associatedDealId }
            : null

        let associatedTruckId = hasTruckClaim() ? movement.associatedTruckId : null

        ReactDOM.unstable_batchedUpdates(() => {
            setAssociatedVesselId(associatedVesselId)
            setAssociatedTruckId(associatedTruckId)
            setAssociatedDeal(associatedDeal)
            setDealPricingTypes(dealPricingTypes)

            if (refs) {
                setMeanOfTransportations(refs.meanOftransportations)
                setDutyStatuses(refs.dutyStatuses)
                setCounterpartys(refs.counterpartys)
                setSites(refs.sites)
                setProducts(refs.products)
                setCompanys(refs.companys)
            }
            setStockMovement(movement)
        })

        if (hasDealClaim())
            loadDealCandidates()
    }

    let trySave = async () => {
        formErrors.highlightErrors()
        if (formErrors.hasMovementError()) {
            if (isOpen) snackbars.warning(t('warnings.mandatoryFieldsBeforeSaving'))
            throw new Error("Error while saving")
        }

        let isBatchMovement = batchMovement != null
        if (isBatchMovement) {
            let batchMvt = { ...batchMovement, movementTemplate: stockMovement }
            await api.post('stock/movement/batch', batchMvt)
            snackbars.success(t('httpSuccess.batchSaved'))
        }
        else {
            try {
                await api.post('stock/movement', stockMovement)
                snackbars.success(t('httpSuccess.movementSaved'))
            }
            catch (err) {
                if (err.status !== 409) throw err
            }
            load(stockMovement.id)
        }
    }

    let loadDealCandidates = async () => {
        if (!stockMovement || !stockMovement.company || !stockMovement.movementType) return

        let isIn = inMovementTypes.indexOf(stockMovement.movementType) >= 0
        let productId = isIn
            ? stockMovement.destinationProductId
            : stockMovement.originProductId
        let date = isIn
            ? stockMovement.stockInputDate
            : stockMovement.stockOutputDate

        if (!date || !productId) return

        let dealCandidates = await api.get<DealLabelId[]>(`deal/formovement?company=${stockMovement.company}&date=${date}&productId=${productId}`)
        setDealCandidates(dealCandidates)
    }

    let open = (id?: Guid | null, isHalf?: boolean) => {
        setIsOpen(true)
        load(id || null)
        if (id) window.history.pushState({}, '', '?openMovement=' + id)
        setIsHalfBottom(isHalf ?? false)
    }

    let close = () => {
        setIsOpen(false)
        formErrors.clear()
        setBatchMovement(null)
        setStockMovement(defaultStockMovement())
        setAssociatedVesselId(null)
        setAssociatedDeal(null)
        setIsConfirmDealOpen(false)
        setOpenAssignDealPopup(false)
        setDealIdToAssign(null)
        window.history.pushState({}, document.title, window.location.pathname)
    }

    let getRefs = async () => {
        let productsPromise = api.get<StockProduct[]>('stock/movement/product')
        let companysPromise = api.get<Company[]>('stock/movement/company')
        let counterpartysPromise = api.get<Counterparty[]>('stock/movement/counterparty')
        let sitesPromise = api.get<Site[]>('stock/movement/site')
        let meanOftransportationsPromise = api.get<MeanOfTransportation[]>('stock/movement/meanOfTransportation')
        return {
            companys: await companysPromise,
            dutyStatuses: (await companysPromise).map(x => x.dutyStatuses).reduce((a, b) => a.concat(b)).distinct(),
            counterpartys: await counterpartysPromise,
            sites: await sitesPromise,
            products: await productsPromise,
            meanOftransportations: await meanOftransportationsPromise
        }
    }

    let locked = () => !!associatedVesselId || !!associatedTruckId

    let canCreateDeal = () => !associatedDeal
        && !associatedVesselId
        && !!stockMovement.company
        && hasScopeOnClaim(Claims.Companies, stockMovement.company)
        && hasClaim(Claims.DealManager)

    let canAssignDeal = () => canCreateDeal() && hasFeature('AssignDealFromMovement')

    let canAssignVessel = () => hasVesselClaim()
        && !associatedVesselId
        && stockMovement.destinationProductId
        && stockMovement.destinationSite
        && stockMovement.stockInputDate
        && stockMovement.meanOfTransportation == "Ship"

    let canCreateDealManually = () => canCreateDeal()
        && !hasAutoCreateDealAndHasNotSelectPricingTypeFeature()

    let linkDeal = (id: Guid) => {
        let deal = dealCandidates.find(x => x.id === id)
        if (deal) setAssociatedDeal(deal)
        else setAssociatedDeal(null)
    }

    let toggleBatchMovement = () => {
        let isBatchMovement = batchMovement != null
        let willBeBatchMovement = !isBatchMovement
        if (willBeBatchMovement)
            setBatchMovement({
                fromDate: moment().utc().startOf('day').format(),
                toDate: moment().utc().startOf('day').format(),
                every: 1,
                mode: BatchMode.Day,
                days: [],
                movementTemplate: null
            })
        else
            setBatchMovement(null)
    }

    let assignDealFromMovement = async () => {
        await api.post('deal/linkMovement', { dealId: dealIdToAssign, movementId: stockMovement.id })
        await trySave().then(_ => snackbars.success(t('httpSuccess.dealAssigned')))
    }

    let createDealFromMovement = async () => {
        await api.post('stock/movement/deal', { movementId: stockMovement.id, pricingType: dealPricingType })
        snackbars.success(t('httpSuccess.dealSaved'))
    }

    let canEditMovementMetadata = () => !locked() && !stockMovement.corePropertiesFrozen && !stockMovement.readOnly

    let dealToString = (label: DealLabelId): string => {
        let getStringOfDate = (date: string | null, formatTemplate: string): string => {
            return date ? moment(date).format(formatTemplate) : t(tBase + 'noDate')
        }
        let tBase = 'stock.label.movement.'
        let reference = label.reference ?? t(tBase + 'noReference')
        let dutyStatus = label.dutyStatus ?? t(tBase + 'noDutyStatus')
        let date = getStringOfDate(label.validFrom, 'YY/MM/DD') + '->' + getStringOfDate(label.validTo, 'YY/MM/DD')
        let site = label.site ?? t(tBase + 'noSite')

        return `${reference} - ${dutyStatus} - ${date} - ${site}`
    }

    let openAssignableDeals = async () => {
        let assignableDeals = hasDealClaim()
            ? await api.get<Deal[]>(`stock/movement/${stockMovement.id}/deal/assignable`)
            : []

        setAssignableDealsFromMovement(assignableDeals)
        if (assignableDeals.length === 0)
            snackbars.info(t('stock.label.movement.noAssignableDealFound'))
        else
            setOpenAssignDealPopup(true)
    }

    dialogRef = useRef<OpenCloseDialogRef>(null)
    useImperativeHandle(dialogRef, () => ({ open, close }))

    let getSapFlow = (isMainSapFlow: boolean) => isMainSapFlow ? stockMovement.mainSapFlow : stockMovement.secondSapFlow!

    let setSapFlow = (isMainSapFlow: boolean, sapFlow: SapFlow) => {
        let movement = isMainSapFlow ? { ...stockMovement, mainSapFlow: sapFlow } : { ...stockMovement, secondSapFlow: sapFlow }
        setStockMovement(movement)
    }

    let getSapFlowFieldStatus = (isMainSapFlow: boolean) => isMainSapFlow ? formErrors.mainSapFlowFieldStatus : formErrors.secondSapFlowFieldStatus

    return {
        dutyStatuses, setDutyStatuses,
        isOpen, locked, load, trySave,
        stockMovement, setStockMovement,
        getSapFlow, setSapFlow, getSapFlowFieldStatus,
        toggleBatchMovement, formErrors, batchMovement,
        setBatchMovement, counterpartys, setCounterpartys,
        companys, setCompanys, sites, setSites,
        products, setProducts, meanOfTransportations,
        setMeanOfTransportations, associatedVesselId, associatedTruckId,
        isConfirmDealOpen, setIsConfirmDealOpen,
        associatedDeal, setAssociatedDeal,
        canEditMovementMetadata, canCreateDeal,
        canCreateDealManually, dealCandidates,
        linkDeal, unit, dealPricingType,
        setDealPricingType, selectPricingTypeFor, setSelectPricingTypeFor,
        dealPricingTypes, canAssignDeal, setOpenAssignDealPopup,
        openAssignDealPopup, assignableDealsFromMovement,
        assignDealFromMovement, createDealFromMovement, dealIdToAssign,
        setDealIdToAssign, dealToString, openAssignableDeals,
        isHalfBottom, setIsHalfBottom, canAssignVessel
    }
}

export let StockMovementContainer = createContainer(useStockMovement)

export type StockMovementStore = ReturnType<typeof useStockMovement>
