import React from 'react'
import moment from 'moment'
import { t } from '../../../../infrastructure/i18nextHelper'
import { TruckTransportContainer } from '../../truckStore'
import { TruckStatus } from '../../truckModels'

export function _TruckEventStepSubtitle({ currentStep, classes }: { currentStep: TruckStatus, classes: any }) {
    let store = TruckTransportContainer.useContainer()
    let truck = store.form.truck

    let truckStatusIndex = store.form.indexOf(truck.truckStatus)
    let currentStepIndex = store.form.indexOf(currentStep) - 1

    if (!currentStep || truckStatusIndex < currentStepIndex) return (<></>)

    let getTrad = (code: string, param?: any) => t('trucks.form.steps.subtitle.' + code, param)

    function getFormat(step: TruckStatus): SubtitleFormat {
        switch (step) {
            case 'loaded': return {
                quantity: truck.loadedQuantity,
                location: store.referential.sites.find(x => x.code === truck.originSite)?.name ?? truck.originSite,
                locationPrefix: getTrad('from'),
                date: truck.loadingDate,
                unit: truck.quantityUnit,
                density: truck.density,
                sealNumber: truck.loadingSealNumber
            }
            case 'arrivedBorder': return {
                location: truck.border,
                locationPrefix: getTrad('of'),
                date: truck.arrivedBorderDate
            }
            case 'passedBorder': return {
                location: truck.border,
                locationPrefix: getTrad('of'),
                date: truck.crossingBorderDate
            }
            case 'arrivedBorder2': return {
                location: truck.border2,
                locationPrefix: getTrad('of'),
                date: truck.arrivedBorder2Date
            }
            case 'passedBorder2': return {
                location: truck.border2,
                locationPrefix: getTrad('of'),
                date: truck.crossingBorder2Date
            }
            case 'arrived': return {
                location: store.referential.sites.find(x => x.code === truck.destinationSite)?.name ?? truck.destinationSite,
                locationPrefix: getTrad('at'),
                date: truck.arrivalDate
            }
            case 'offloaded': return {
                quantity: truck.offloadedQuantity,
                location: store.referential.sites.find(x => x.code === truck.destinationSite)?.name ?? truck.destinationSite,
                locationPrefix: getTrad('at'),
                date: truck.offloadingDate,
                unit: truck.quantityUnit,
                sealNumber: truck.offloadingSealNumber
            }
            default: throw 'unknown step ' + step
        }
    }

    function getText({ quantity, location, locationPrefix, date, unit, sealNumber, density }: SubtitleFormat) {
        let label = ''

        if (quantity)
            label += quantity + ` (${unit}) `

        if (location)
            label += locationPrefix + ' ' + location

        if (date)
            label += (truckStatusIndex !== currentStepIndex
                ? (label ? ' ' : '') + getTrad('on') + ' '
                : ', ' + getTrad('expected') + ' ' + getTrad('on') + ' ')
                + moment(date).format('MM/DD/YYYY')

        if (label.startsWith(','))
            label = label.substr(1, label.length - 1)

        if (label)
            label = ' ' + label

        if (density)
            label += `, ${getTrad('density', { density: density })}`

        if (sealNumber)
            label += `, ${getTrad('sealNumber', { sealNumber: sealNumber })}`

        return label
    }

    return (
        <span className={classes.stepSubtitle}>
            {getText(getFormat(currentStep))}
        </span>
    )
}

type SubtitleFormat = {
    quantity?: number | null
    location?: string | null
    locationPrefix: string
    date?: string | null
    unit?: string | null
    sealNumber?: string | null
    density?: number | null
}