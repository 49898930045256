import React, { useState, Dispatch, useEffect } from 'react'
import { createContainer } from 'unstated-next';
import guid, { Guid } from '../infrastructure/guid'

function useLoaderStatus() {
    let [isActive, setIsActive] = useState(false)
    let [isBackgroundActive, setIsBackgroundActive] = useState(false)

    useEffect(() => {
        activateLoader = setIsActive
        activateBackgroundLoader = setIsBackgroundActive
    }, []);

    return { isActive, isBackgroundActive }
}

export let LoaderStatusContainer = createContainer(useLoaderStatus)

let activateLoader: Dispatch<React.SetStateAction<boolean>> = () => { }
let activateBackgroundLoader: Dispatch<React.SetStateAction<boolean>> = () => { }

export let delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export let loader = (() => {
    let loads: string[] = []
    let last: Date | null = null
    let start = (id) => {
        loads = loads.concat([id])
        activateBackgroundLoader(true)
    }

    let running = () => loads.length > 0
    let end = (id) => { 
        loads = loads.filter(x => x !== id)
        last = new Date()
        if(!running()) activateBackgroundLoader(false)
    }
    let lastEndedMoreThan1Second = () => last && (new Date() as any - (last as any)) > 1000

    let displayProgressFor = (workPromise) => {
        let loadId = guid.createNew()
        let show = () => activateLoader(true)
        let hide = () => activateLoader(false) 
        let delayHide = () => delay(1000).then(() => !running() && lastEndedMoreThan1Second() ? hide() : null)

        start(loadId)
        workPromise.then(_ => end(loadId)).catch(_ => end(loadId))

        delay(1000).then(() => (running() ? show() : null))
        Promise.all([workPromise, delay(1000)])
            .then(delayHide)
            .catch(delayHide)
    }

    return { displayProgressFor: displayProgressFor }
})()