import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { api } from '../../../infrastructure/api'
import { MasterDataShell, MasterDataItem, createExcelLines } from './masterDataShell'
import { ColumnDescriptor, Select, TableItem, NumberField } from '../../common/customComponents'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { t } from '../../../infrastructure/i18nextHelper'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import guid from '../../../infrastructure/guid'

type VesselDelay = {
    id: string,
    field: string,
    referenceField: string,
    offset: number,
    mode: string
}

let emptyVesselDelay = (): VesselDelay => {
    return {
        id: guid.createNew(),
        field: '',
        referenceField: '',
        offset: 0,
        mode: ''
    }
}

let toTableItem = (delay: VesselDelay): TableItem<MasterDataItem<VesselDelay>> => {
    return {
        id: delay.id,
        field: delay.field,
        referenceField: delay.referenceField,
        offset: delay.offset,
        mode: delay.mode,
        isModified: false
    }
}

function VesselDelayMasterData() {
    let [countryDelays, setCountryDelays] = useState<VesselDelay[]>([])
    let [fields, setFields] = useState<string[]>([])
    let [modes, setModes] = useState<string[]>([])
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let load = async () => {
        let countryDelays = api.get<VesselDelay[]>('masterdata/vesselDelay')
        let fields = api.get<string[]>('masterdata/vesselDelay/fields')
        let modes = api.get<string[]>('masterdata/vesselDelay/modes')

        setCountryDelays(await countryDelays)
        setFields((await fields).sort())
        setModes(await modes)
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<VesselDelay>>>[] = [
        { name: t('admin.masterdata.vesselDelay.date'), value: x => x.field },
        { name: t('admin.masterdata.vesselDelay.referenceDate'), value: x => x.referenceField },
        { name: t('admin.masterdata.vesselDelay.offset'), value: x => x.offset },
        { name: t('admin.masterdata.vesselDelay.mode'), value: x => x.mode }
    ]

    useEffect(() => { let effect = async () => { await load() }; effect() }, [])

    let vesselDelayLabel = t('admin.masterdata.vesselDelay.vesselDelay')

    let isManager = hasClaim(Claims.MasterDataVesselDelayManager)

    let onSave = async (item: VesselDelay) => {
        let vesselDelay = { field: item.field, referenceField: item.referenceField, offset: item.offset, mode: item.mode }

        await api.post('masterdata/vesselDelay', vesselDelay)
        await load()
        return true
    }

    let getItems = () => countryDelays.map(x => toTableItem(x))

    let exportExcel = async () => {
        excelGenerator.generate({
            filename: 'VesselDelays.xlsx',
            sheets: [{ name: 'VesselDelays', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let importExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/vesselDelay/import'
        api.upload(uploadUrl, file, 'import').then(_ => load())
    }

    let onDelete = async (ids: string[]) => {
        if (ids.length === 0) return false
        await api.del('masterdata/vesselDelay', { ids: ids })
        await load()
        return true
    }

    return (
        <MasterDataShell
            headerLabel={vesselDelayLabel}
            itemLabel={vesselDelayLabel}
            isManager={isManager}
            onExportExcel={exportExcel}
            onImportExcel={importExcel}
            onNew={emptyVesselDelay}
            onDelete={onDelete}
            onSave={onSave}
            items={getItems()}
            columns={columns}>{
                (selectedItem, setSelectedItem) => (
                    <>
                        <Select label={t('admin.masterdata.vesselDelay.date')}
                            value={selectedItem.field}
                            choices={fields.map(x => ({ value: x, text: x }))}
                            onChange={val => { if (val) setSelectedItem({ ...selectedItem, field: val }) }} />

                        <Select label={t('admin.masterdata.vesselDelay.referenceDate')}
                            value={selectedItem.referenceField}
                            choices={fields.map(x => ({ value: x, text: x }))}
                            onChange={val => { if (val) setSelectedItem({ ...selectedItem, referenceField: val }) }} />

                        <NumberField label={t('admin.masterdata.vesselDelay.offset')}
                            text={selectedItem.offset === null ? null : selectedItem.offset}
                            onChange={x => setSelectedItem({ ...selectedItem, offset: x })} />

                        <Select label={t('admin.masterdata.vesselDelay.mode')}
                            value={selectedItem.mode}
                            choices={modes.map(x => ({ value: x, text: x }))}
                            onChange={val => { if (val) setSelectedItem({ ...selectedItem, mode: val }) }} />
                    </>
                )}
        </MasterDataShell>)
}

let styles = () => createStyles({})

export default withStyles(styles, muiOptions)(VesselDelayMasterData)