import React from 'react'
import { Select } from '../../../app/common/customComponents'
import { UserSettingsChoice, UserSettingsContextContainer } from '../userSettings/userSettingsStore'
import { UserSettings } from '../../../infrastructure/signIn/models'

type UserSettingsSelectorProps = {
    userSettingsKey: keyof UserSettings
    choices: UserSettingsChoice[]
}

export function UserSettingsSelector(props: UserSettingsSelectorProps) {
    let userSettingsStore = UserSettingsContextContainer.useContainer()

    return (<Select value={userSettingsStore.userSettings[props.userSettingsKey]}
        choices={props.choices.map(x => { return { value: x.value, text: x.label } })}
        onChange={val => userSettingsStore.changeUserSettings({ ...userSettingsStore.userSettings, [props.userSettingsKey]: val })} />)
}