import React from 'react'
import { MyLocation, PanTool, LocalShipping, VerifiedUser, CheckCircle, Train } from '@material-ui/icons'
import { Text, Date, Number, EntryLines, SingleEntryLine } from '../_truckFields'
import { TruckStatus, TruckTransportFormModel } from '../../truckModels'
import { TruckTransportContainer } from '../../truckStore'
import guid from '../../../../infrastructure/guid'
import { FeatureContainer } from '../../../../infrastructure/feature'

export function _TruckEventLoadedStep() {
    let store = TruckTransportContainer.useContainer()
    let truck = store.form.truck
    let features = FeatureContainer.useContainer()
    let hasBorder = store.isRailCar ? features.hasFeature('RailBorder') : features.hasFeature('TruckBorder')

    if (!truck.entrys.length)
        truck.entrys.push({ id: guid.createNew() })

    return (
        <div>
            <div>
                <Date name='loadingDate' />
                <Date name='arrivalDate' customTraductionKey='expectedArrivalDate' />
                {
                    truck.entrys.length > 1 || !hasBorder
                        ? <Number name='loadedQuantity' tradParam={{ unit: truck.quantityUnit }} />
                        : null
                }
                {
                    features.hasFeature('ObservedQuantityTemperature')
                        ? <>
                            <Number name='loadedObservedQuantity' />
                            <Number name='loadedObservedTemperature' />
                        </>
                        : null
                }
                {
                    store.isRailCar
                        ? <>
                            <Number name='density' />
                            <Text name='loadingSealNumber' />
                        </>
                        : null
                }
            </div>
            {
                !hasBorder
                    ? null
                    : truck.entrys.length > 1
                        ? <EntryLines />
                        : <SingleEntryLine />
            }
        </div >)
}

export function _TruckEventArrivedAtBorderStep({ ArrivedBorderfield, borderField }:
    { ArrivedBorderfield: keyof TruckTransportFormModel, borderField: keyof TruckTransportFormModel }) {
    return (
        <div>
            <div style={{ marginBottom: '0.5em' }}>
                <Date name={ArrivedBorderfield} />
                <Text name={borderField} />
            </div>
            <Date name='arrivalDate' customTraductionKey='expectedArrivalDate' />
        </div>

    )
}

export function _TruckEventPassedTheBorderStep({ crossingBorderField }:
    { crossingBorderField: keyof TruckTransportFormModel }) {

    return (
        <div style={{ marginBottom: '0.5em' }}>
            <Date name={crossingBorderField} />
            <Date name='arrivalDate' customTraductionKey='expectedArrivalDate' />
        </div>
    )
}

export function _TruckEventArrivedStep() {
    let features = FeatureContainer.useContainer()

    return (
        <div>
            <div>
                <Date name='arrivalDate' />
                <Date name='offloadingDate' customTraductionKey='expectedOffloadingDate' />
                {
                    features.hasFeature('TruckOffloadingAdditionalFieldsZambia') &&
                    <>
                        <div style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                            <Text name='erbNumber' />
                            <Text name='customsReference' />
                            <Number name='fuelLevy' />
                        </div>
                        <div>
                            <Number name='customsDuty' />
                            <Number name='cedFees' />
                            <Number name='importVAT' />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export function _TruckEventOffloadedStep() {
    let store = TruckTransportContainer.useContainer()
    let features = FeatureContainer.useContainer()
    return (
        <div>
            <Date name='offloadingDate' />
            <Number name='offloadedQuantity' tradParam={{ unit: store.form.truck.quantityUnit }} />
            {
                features.hasFeature('ObservedQuantityTemperature')
                    ? <>
                        <Number name='offloadedObservedQuantity' />
                        <Number name='offloadedObservedTemperature' />
                    </>
                    : null
            }
            {
                store.isRailCar
                    ? <Text name='offloadingSealNumber' />
                    : null
            }
        </div>
    )
}

export function _TruckEventStep({ truckStatus }: { truckStatus: TruckStatus }) {
    switch (truckStatus) {
        case 'loaded': return <_TruckEventLoadedStep />
        case 'arrivedBorder': return <_TruckEventArrivedAtBorderStep ArrivedBorderfield='arrivedBorderDate' borderField='border' />
        case 'passedBorder': return <_TruckEventPassedTheBorderStep crossingBorderField='crossingBorderDate' />
        case 'arrivedBorder2': return <_TruckEventArrivedAtBorderStep ArrivedBorderfield='arrivedBorder2Date' borderField='border2' />
        case 'passedBorder2': return <_TruckEventPassedTheBorderStep crossingBorderField='crossingBorder2Date' />
        case 'arrived': return <_TruckEventArrivedStep />
        case 'offloaded': return <_TruckEventOffloadedStep />
        default: throw 'unknown step'
    }
}

export function _TruckEventStepIcon({ truckStatus, state, isTrain, classes }
    : { truckStatus: TruckStatus, state: 'active' | 'current' | 'disabled', isTrain: boolean, classes: any }) {
    let className = classes.stepIcon +
        (state === 'active' ? ' ' + classes.stepIconActive : '') +
        (state === 'current' ? ' ' + classes.stepIconCurrent : '')

    switch (truckStatus) {
        case 'loaded': return isTrain ? <Train className={className} /> : <LocalShipping className={className} />
        case 'arrivedBorder': return <PanTool className={className} />
        case 'passedBorder': return <VerifiedUser className={className} />
        case 'arrivedBorder2': return <PanTool className={className} />
        case 'passedBorder2': return <VerifiedUser className={className} />
        case 'arrived': return <MyLocation className={className} />
        case 'offloaded': return <CheckCircle className={className} />
        default: throw 'unknown step'
    }
}