export let mergeLanguageFiles = (language: string, country: string | null) => {
    let mainLanguage = require(`../../static/lang/${language}.json`)

    if (!country) return mainLanguage

    try {
        let englishCountryLanguage = require(`../../static/lang/en-${country}.json`)

        if (language === 'en')
            return mergeObjects(mainLanguage, englishCountryLanguage)
        else {
            let countryLanguage = require(`../../static/lang/${language}-${country}.json`)
            let mainLanguageOverrided = mergeObjects(mainLanguage, englishCountryLanguage)
            return mergeObjects(mainLanguageOverrided, countryLanguage)
        }
    } catch (e) {
        console.warn("Following language not found:", e?.message)
        return mainLanguage
    }
}

export function mergeObjects(mainJson, specificJson) {
    for (let prop in specificJson) {
        let value = specificJson[prop]
        if ((typeof value) == 'object') {
            if (!mainJson[prop]) mainJson[prop] = {}
            mergeObjects(mainJson[prop], specificJson[prop])
        } else {
            mainJson[prop] = value;
        }
    }
    return mainJson
}
