import React, { useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions, MuiProps, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import * as api from '../../../infrastructure/api'
import { StockFilters, StockProduct, Company, Site } from '../stockModels'
import { MultipleSelect, GroupedMultiSelect, DateRangePicker } from '../../common/customComponents'
import { StockBoardContainer } from '../stockBoardStore'
import { UserContextContainer } from '../../../infrastructure/signIn/userContext'
import { setInLocalStorage } from '../../../infrastructure/localStorage'

function _StockBoardFilters({ classes }: MuiProps) {
    let store = StockBoardContainer.useContainer()
    let userContext = UserContextContainer.useContainer()

    let loadFiltersElements = async () => {
        if (!userContext.isLoggedIn) return
        if (store.dutyStatuses.length > 0) return

        let productsPromise = api.get<StockProduct[]>('stock/product')
        let companiesPromise = api.get<Company[]>('stock/company')
        let sitesPromise = api.get<Site[]>('stock/site')

        let products = await productsPromise
        let companies = await companiesPromise
        let sites = await sitesPromise
        let dutyStatuses = companies.map(x => x.dutyStatuses).reduce((a, b) => a.concat(b)).distinct()

        setDefaultFiltersValues(products, sites, companies, dutyStatuses)

        store.setSites(sites)
        store.setProducts(products)
        store.setCompanies(companies)
        store.setDutyStatuses(dutyStatuses)
    }

    let saveFiltersInLocalStorage = () => {
        let filtersLength = Object.keys(store.stockFilters).length
        if (filtersLength > 0)
            setInLocalStorage('filters', store.stockFilters)
    }

    let setDefaultFiltersValues = (products: StockProduct[], sites: Site[], companies: Company[], dutyStatuses: string[]) => {
        let changes: StockFilters = { ...store.stockFilters }

        if (dutyStatuses.length > 0)
            changes = { ...changes, allDutyStatuses: dutyStatuses }

        if (companies.length > 0)
            changes = { ...changes, allCompanies: companies.map(x => x.code) }

        if (products.length > 0)
            changes = { ...changes, allProductIds: products.map(x => x.id) }

        if (sites.length > 0)
            changes = { ...changes, allSites: sites.map(x => x.code) }

        store.setFilters({ ...changes })
    }

    useEffect(() => { loadFiltersElements() }, [])

    useEffect(() => { saveFiltersInLocalStorage() }, [store.stockFilters])

    let onChangeCompanies = (selectedCompaniesCode: string[]) => {
        let companiesCode = store.companies.filter(x => selectedCompaniesCode.indexOf(x.code) > -1).map(x => x.code)
        store.setFilters({ ...store.stockFilters, companies: companiesCode })
    }

    let hasOneCompany = store.companies.map(x => x.code).length === 1

    return (
        <div className={'stockFilters'}>
            <DateRangePicker startDate={store.stockFilters.start || null} startLabel={t('stock.filters.startDate')}
                endDate={store.stockFilters.end || null} endLabel={t('stock.filters.endDate')} disableNewStyle
                onPeriodChange={period => store.changeDates(period.startDate, period.endDate)}
                onError={store.filterDatesOnError} classesOverride={{ datepicker: classes.filterFieldDate }} />
            <GroupedMultiSelect
                label={t('stock.filters.sites')}
                classesOverride={{ form: classes.filterField }}
                values={store.stockFilters.sites}
                choices={store.sites.map(x => { return { value: x.code, text: x.name, group: x.siteGroup } })}
                allWhenEmpty disableNewStyle
                onChange={x => store.setFilters({ ...store.stockFilters, sites: x })} />
            {store.products.every(x => !x.productGroup)
                ? <MultipleSelect label={t('stock.filters.product')} classesOverride={{ form: classes.filterField }}
                    values={store.products.filter(x => !!store.stockFilters.productIds && store.stockFilters.productIds.indexOf(x.id) > -1).map(x => x.id)}
                    choices={store.products.map(x => ({ value: x.id, text: x.code }))} disableNewStyle
                    allWhenEmpty hideOnSingleChoice
                    onChange={x => store.setFilters({ ...store.stockFilters, productIds: x })} />
                : <GroupedMultiSelect label={t('stock.filters.product')} classesOverride={{ form: classes.filterField }}
                    values={store.stockFilters.productIds} hideOnSingleChoice allWhenEmpty
                    choices={store.products.map(x => { return { value: x.id, text: x.code, group: x.productGroup! } })} disableNewStyle
                    onChange={val => store.setFilters({ ...store.stockFilters, productIds: val })} />}
            <MultipleSelect label={t('stock.filters.dutyStatus')} classesOverride={{ form: classes.filterField }}
                values={store.dutyStatuses.filter(x => !!store.stockFilters.dutyStatuses && store.stockFilters.dutyStatuses.indexOf(x) > -1)}
                choices={store.dutyStatuses.map(x => ({ value: x, text: x }))} disableNewStyle
                allWhenEmpty hideOnSingleChoice
                onChange={x => store.setFilters({ ...store.stockFilters, dutyStatuses: x })} />
            <MultipleSelect label={t('stock.filters.company')} classesOverride={{ form: classes.filterField }}
                values={hasOneCompany ? [store.companies[0].code] : store.companies.filter(x => !!store.stockFilters.companies && store.stockFilters.companies.indexOf(x.code) > -1).map(x => x.code)}
                choices={store.companies.map(x => ({ value: x.code, text: x.name }))} disableNewStyle
                allWhenEmpty={!hasOneCompany}
                onChange={onChangeCompanies} />
        </div>
    )
}

let styles = theme =>
    createStyles({
        filterField: {
            width: '8.3em',
            margin: '0em 0.5em'
        },
        filterFieldDate: {
            width: '10.3em',
            margin: '0em 0.5em'
        },
        searchFilter: {
            ...defaultStyles.secondaryButton
        }
    })

export let StockBoardFilters = withStyles(styles, muiOptions)(_StockBoardFilters)