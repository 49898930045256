import React from 'react'
import { makeStyles } from '@material-ui/core'


export function StatusChip({ fontSize, color, hoverable, className, children }: React.PropsWithChildren<Props>) {
    const classes = useStyles()
    return (
        <div>
            <span className={[
                classes.chip, 'chip-' + (color ?? 'grey'),
                fontSize === 'small' ? classes.fontSmall : classes.fontRegular,
                hoverable ? 'hoverable' : '',
                className].join(' ')}>
                {children}
            </span>
        </div>
    )
}

type Props = {
    className?: string
    fontSize?: 'regular' | 'small'
    color?: 'green' | 'blue' | 'purple' | 'grey' | 'orange' | 'red'
    hoverable?: boolean
}

const useStyles = makeStyles({
    chip: {
        position: 'relative',
        padding: '0.2em 0.5em',
        borderRadius: '1em',
        color: 'white',
        transition: 'background-color 0.2s',
        '&.chip-green': {
            backgroundColor: '#65BC45'
        },
        '&.chip-blue': {
            backgroundColor: '#4E6BC7'
        },
        '&.chip-purple': {
            backgroundColor: '#9B51E0'
        },
        '&.chip-grey': {
            backgroundColor: '#C0C0C0'
        },
        '&.chip-orange': {
            backgroundColor: '#FFAE42'
        },
        '&.chip-red': {
            backgroundColor: '#E63C3C'
        },
        '&.hoverable:hover': {
            filter: 'brightness(85%)'
        }
    },
    fontRegular: {
        fontSize: '1em'
    },
    fontSmall: {
        fontSize: '0.875em'
    }
})