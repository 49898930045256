import React from 'react'
import moment from 'moment'
import ReactDOM from 'react-dom'
import { isWithinAcceptableRange } from './tools'

export function useDatePickerState(date, setDate, onError) {
    let [isOnError, setIsOnError] = React.useState<boolean>(!!onError)

    function onChange(newDate: moment.Moment | null, newText: string | null) {
        let newState = {
            date: null as string | null,
            isOnError: false
        }

        if (newDate !== null) {
            if (!newDate.isValid())
                newState.isOnError = true
            else {
                newState.isOnError = !isWithinAcceptableRange(newDate)
                newState.date = newDate.startOf('day').utc(true).toISOString()
            }
        }

        ReactDOM.unstable_batchedUpdates(() => {
            setDate(!newText ? null : newState.date ?? date)
            setIsOnError(newState.isOnError)
        })
    }

    return { isOnError, setIsOnError, onChange }
}