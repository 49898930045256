import React, { RefObject, useImperativeHandle, useRef } from "react"
import moment from "moment"
import { useState } from "react"
import { Dialog, DialogContent, DialogTitle, DialogActions, createStyles, withStyles, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core'
import { CSSProperties } from "@material-ui/styles"
import { t } from './../../infrastructure/i18nextHelper'
import { defaultStyles, dialogCell, alignRight } from '../../infrastructure/materialUiThemeProvider'
import { api } from '../../infrastructure/api'
import { Button } from "../common/customComponents"
import { TransitStockDetail, TransitStockDetailSite } from "./stockModels"
import { Line, roundedSum } from "./stockProjectionDetailDialog"

export type TransitStockDetailArgs = {
    date: string
    productIds: string[]
    sites: string[]
    dutyStatuss: string[]
    companys: string[]
}

let getStockDetailTrad = (text: string) => t('stock.stockProjectionDetailsDialog.' + text)
let getTransitDetailTrad = (text: string) => t('stock.transitStockDetailDialog.' + text)

function TransitDetailDialog(props) {
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [transitStockDetail, setTransitStockDetail] = useState<TransitStockDetail | null>(null)

    dialogRef = useRef<{ open: (args: TransitStockDetailArgs) => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: async (args: TransitStockDetailArgs) => {
            await fetchDetails(args)
            setOpenDialog(true)
        }
    }))

    let fetchDetails = async (args: TransitStockDetailArgs) => {
        let result = await api.get<TransitStockDetail>(`stock/movement/searchTransitStockDetails?${prepareQuery()}`)
        setTransitStockDetail(result)

        function prepareQuery() {
            let query = `date=${args.date.toString()}&`
            args.companys?.map(x => query = query + `companys=${x}&`)
            args.dutyStatuss?.map(x => query = query + `dutystatuss=${x}&`)
            args.productIds?.map(x => query = query + `productIds=${x}&`)
            args.sites.map(x => query = query + `sites=${x}&`)
            return query
        }
    }

    let close = () => {
        setTransitStockDetail(null)
        setOpenDialog(false)
    }

    return (
        <Dialog open={openDialog}
            onClose={close}
            scroll={'paper'}>
            <DialogTitle>{getTransitDetailTrad('title')}</DialogTitle>
            <DialogContent dividers={true}>
                <div>
                    <Line classes={props.classes} title={getStockDetailTrad('date')} value={moment(transitStockDetail?.date).format('MM/DD/YYYY')} />
                    <Line classes={props.classes} title={getStockDetailTrad('products')} value={transitStockDetail?.productCodes} />
                    <Line classes={props.classes} title={getStockDetailTrad('dutyStatuss')} value={transitStockDetail?.dutyStatuss} />
                    <Line classes={props.classes} title={getStockDetailTrad('companys')} value={transitStockDetail?.companys} />
                    <Line classes={props.classes} title={getTransitDetailTrad('destinations')} value={transitStockDetail?.destinationSites} />
                    <DetailTable classes={props.classes} transitStockDetail={transitStockDetail} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button className={props.classes.closeButton}
                    label={t('history.close')}
                    onClick={() => setOpenDialog(false)}
                    color='primary' />
            </DialogActions>
        </Dialog>
    )
}

type DetailTableProps = {
    transitStockDetail: TransitStockDetail | null
    classes: any
}

function DetailTable(props: DetailTableProps) {

    if (!props.transitStockDetail) return (<></>)

    let totalForecasted = roundedSum<TransitStockDetailSite>(props.transitStockDetail?.values, x => x.forecastQuantity)
    let totalPlanned = roundedSum<TransitStockDetailSite>(props.transitStockDetail?.values, x => x.plannedQuantity)
    let totalActual = roundedSum<TransitStockDetailSite>(props.transitStockDetail?.values, x => x.actualQuantity)

    let totalQuantityCellClass = props.classes.cell + ' ' + props.classes.totalCell

    return (
        <Table className={props.classes?.table}>
            <TableHead>
                <TableRow>
                    <TableCell className={props.classes.cell}>{getTransitDetailTrad('origin')}</TableCell>
                    <TableCell className={props.classes.cell}>{getTransitDetailTrad('forecast')}</TableCell>
                    <TableCell className={props.classes.cell}>
                        <Tooltip title={<Typography variant='body1'>{getTransitDetailTrad('plannedTooltip')}</Typography>}
                            placement='top'>
                            <span>{getTransitDetailTrad('planned')}</span>
                        </Tooltip>
                    </TableCell>
                    <TableCell className={props.classes.cell}>
                        <Tooltip title={<Typography variant='body1'>{getTransitDetailTrad('actualTooltip')}</Typography>}
                            placement='top'>
                            <span>{getTransitDetailTrad('actual')}</span>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.transitStockDetail.values.map((x, index) => (
                    <TableRow key={index}>
                        <TableCell className={props.classes.cell}>{x.originSite}</TableCell>
                        <TableCell className={props.classes.cell}>{x.forecastQuantity == 0 ? '' : x.forecastQuantity}</TableCell>
                        <TableCell className={props.classes.cell}>{x.plannedQuantity == 0 ? '' : x.plannedQuantity}</TableCell>
                        <TableCell className={props.classes.cell}>{x.actualQuantity == 0 ? '' : x.actualQuantity}</TableCell>
                    </TableRow>
                ))}
                <TableRow >
                    <TableCell className={props.classes.cell + ' ' + props.classes.totalCell}>{getStockDetailTrad('total')}</TableCell>
                    <TableCell className={totalQuantityCellClass}>{totalForecasted}</TableCell>
                    <TableCell className={totalQuantityCellClass}>{totalPlanned}</TableCell>
                    <TableCell className={totalQuantityCellClass}>{totalActual}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

let cell: CSSProperties = dialogCell

let styles = () => createStyles({
    cell,
    title: { marginRight: '0.5em' },
    value: { fontWeight: 'lighter' },
    line: { ...defaultStyles.flexRow },
    alignRight: { textAlign: 'right' },
    totalCell: {
        fontWeight: 'bold'
    }
})

let dialogRef: RefObject<{ open: (args: TransitStockDetailArgs) => void }> | null = null

export let transitStockDetailDialog = {
    open: (args: TransitStockDetailArgs) => dialogRef?.current?.open(args)
}

export let TransitStockDetailDialog = withStyles(styles)(TransitDetailDialog)