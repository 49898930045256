import { defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { ChartDataset, ChartOptions } from 'chart.js'
import moment from 'moment'

export const options: ChartOptions<'line'> =
{
    maintainAspectRatio: false,
    plugins: {
        legend: { position: 'top' },
        tooltip: { callbacks: { title: (tooltipItem) => moment(tooltipItem[0].label).format('MM/DD/YYYY') } },
    },
    elements: { point: { radius: 0, hitRadius: 10 } }
}

type DataSetInput = ChartDataset<'line', Array<number | null>> & { color: ColorPresetType, dotted?: boolean, dottedSmall?: boolean }
export function prepareDataSet(dataSet: DataSetInput): ChartDataset<'line', Array<number | null>> {
    let colors = getColorPreset(dataSet.color)
    return {
        label: dataSet.label,
        stack: dataSet.stack,
        data: dataSet.data.map(x => x != null ? Math.round(x) : null),
        backgroundColor: dataSet.backgroundColor ?? colors?.primary,
        borderColor: colors.primary,
        pointHoverBackgroundColor: colors.secondary,
        pointHoverBorderColor: colors.secondary,
        pointBorderColor: colors.primary,
        pointBackgroundColor: colors.primary,
        borderDash: dataSet.dotted ? [10, 5] : dataSet.dottedSmall ? [5, 4] : [],
        borderWidth: dataSet.dottedSmall ? 2 : undefined,
        fill: dataSet.fill ?? false,
    }
}

function getColorPreset(presetType: ColorPresetType): ColorPreset {
    return colorPresetList.find(x => x.presetType === presetType) ??
    {
        presetType: presetType,
        primary: presetType,
        secondary: presetType
    }
}

const colorPresetList: ColorPreset[] = [
    {
        presetType: 'min',
        primary: defaultColors.orange.light.color,
        secondary: defaultColors.orange.light.color
    },
    {
        presetType: 'low',
        primary: '#D4AC0D',
        secondary: '#D4AC0D',
    },
    {
        presetType: 'high',
        primary: '#d35400',
        secondary: '#d35400'
    },
    {
        presetType: 'max',
        primary: defaultColors.red.light.color,
        secondary: defaultColors.red.light.color
    },
    {
        presetType: 'default',
        primary: defaultColors.darkBlue.light.color,
        secondary: defaultColors.darkBlue.light.color
    },
    {
        presetType: 'red',
        primary: defaultColors.red.light.color,
        secondary: defaultColors.red.light.color
    },
    {
        presetType: 'blue',
        primary: defaultColors.darkBlue.light.color,
        secondary: defaultColors.darkBlue.light.color
    },
    {
        presetType: 'yellow',
        primary: defaultColors.orange.light.color,
        secondary: defaultColors.orange.light.color
    }
]

export type ColorPresetType = 'min' | 'max' | 'default' | CustomColorHex

type CustomColorHex = string

type ColorPreset = {
    presetType: ColorPresetType
    primary: string
    secondary: string
}