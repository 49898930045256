import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import { TrendingFlatOutlined } from '@material-ui/icons'
import { AttachFileButton, Button } from '../../common/customComponents'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { FeatureContainer } from '../../../infrastructure/feature'
import { popupNavigator } from '../../../infrastructure/popupNavigator'
import { TruckTransportContainer } from '../truckStore'
import { getTradCode } from './truckForm'
import { Text, Select, Switch } from './_truckFields'
import { CommentButton } from '../../common/comment/commentButton'
import { CommentContent } from '../../common/comment/commentContent'

export function _TruckGeneralSection({ classes }: Props) {
    let store = TruckTransportContainer.useContainer()
    let sites = store.referential.sites
    let features = FeatureContainer.useContainer()
    let hasPurchase = features.hasFeature('TruckPurchase')
    let t = store.t

    let getTrad = (label: string, params?: any) => { return t(getTradCode(label), params) }

    let getAssociationKey = () => store.form.truck.id ? `transport-${store.form.truck.id}` : ''

    if (store.form.defaultDestinationSite)
        sites = [{
            code: store.form.defaultDestinationSite,
            name: store.form.defaultDestinationSite,
            siteGroup: null
        }, ...sites]

    return (
        <Paper className={classes.paper}>
            <div className={classes.titleRow}>
                <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                    {getTrad('generalTitle')}
                </Typography>
                <AttachFileButton
                    id='upload-doc-button'
                    className={classes.documentButton}
                    title={t('trucks.label.documentDialogTitle')}
                    disableDelOrAdd={!hasClaim(Claims.TruckManager)}
                    context={store.form.truck.company ?? ''}
                    keyTemplate='truck-{truckId}'
                    keyParameters={{ truckId: store.form.truck.id?.replace(/-/g, '') ?? '' }} />
            </div>
            <div>
                <Text name='truckNumber' />
                <Text name='meanOfTransportation' disabled />
                <Select name='productId' choices={store.referential.products.map(x => ({ text: x.code, value: x.id }))} />
                <Select name='company' choices={store.referential.companies.map(x => ({ text: x.name, value: x.code }))} />
                <Select name='customerSegment' choices={store.referential.customerSegments} />
                <Text name='dutyStatus' disabled />
            </div>
            <div>
                <Text name='transporter' />
                <Text name='driver' />
                <Text name='reference' />
                {store.isRailCar && features.hasFeature('RailDeliveryFields') ? <>
                    <Text name='billOfLadingNumber' />
                    <Text name='carrierDeliveryNote' customTraductionKey='consignmentNumber' />
                </>
                    : features.hasFeature('TruckDeliveryFields') ? <>
                        <Text name='billOfLadingNumber' />
                        <Text name='carrierDeliveryNote' />
                    </> : undefined}
                {features.hasFeature('ImportTruckWithExitEntry') ? <Text name='exitNumber' /> : null}
            </div>
            <div>
                <Select name='originSite' choices={store.referential.sites.map(x => ({ value: x.code, text: x.name }))} />
                <Select name='destinationSite' choices={sites.map(x => ({ value: x.code, text: x.name }))} />
                <Select name='counterpartyId' choices={store.referential.counterparties.map(x => ({ value: x.id, text: x.name }))} />
                {hasPurchase ? <Text name='purchaseNumber' /> : null}
                <Switch name='isOwnCollection' disabled={store.form.shouldDisableOwnCollection()} />
            </div>
            <div>
                {(store.isRailCar && features.hasFeature('RailDeliveryFields')) || features.hasFeature('TruckDeliveryFields') ?
                    <div className={classes.truckCommentRow}>
                        <CommentContent associationKey={getAssociationKey()} />
                        <CommentButton withPadding associationKey={getAssociationKey()} />
                    </div>
                    : <></>}
                {store.form.truck.movementId
                    ? <Button
                        label={t('trucks.label.goToMovement')}
                        img={<TrendingFlatOutlined className={classes.buttonPictogram} />}
                        onClick={() => { popupNavigator.open('movement', store.form.truck.movementId!) }} className={classes.buttonLink} />
                    : null}
            </div>
        </Paper>
    )
}

type Props = {
    classes?: any
}