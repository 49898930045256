import React from 'react'
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { t } from '../../../../infrastructure/i18nextHelper'
import { customSelectTheme, ClassNames } from './select'
import { FieldStatus } from '../../fieldsStatus'

export function CustomSelectWrapper(props: React.PropsWithChildren<CustomSelectProps & { classNames: ClassNames }>) {
    return (
        <ThemeProvider theme={customSelectTheme}>
            <FormControl
                className={props.classNames.form} error={props.status === 'alert' || !!props.onError}>
                <InputLabel shrink className={props.classNames.input}>{props.label}</InputLabel>
                {props.children}
                {props.status === 'alert' || props.onError ? <FormHelperText>{t('components.error')}</FormHelperText> : null}
            </FormControl>
        </ThemeProvider>
    )
}

export type CustomSelectProps = {
    id?: string
    label: string
    onChange?: (selectedValues: string[]) => void
    onError?: boolean
    status?: FieldStatus
}