import { Chip, makeStyles } from "@material-ui/core"
import React from "react"
import { defaultColors, MuiProps } from "../../../infrastructure/materialUiThemeProvider"

type SapChipProps = {
    label: string | null
    isFromSap: boolean | null
    isFilled: boolean | null
    sapChipStyle: React.CSSProperties
    key?: number
    disabled?: boolean
    clickable?: boolean
    onClick?: (event: any) => any
}

export function SapChip({ key, label, isFromSap, isFilled, disabled, clickable, sapChipStyle, onClick }: SapChipProps): JSX.Element {
    const classes = useStyles(sapChipStyle)()
    return <Chip key={key}
        className={isFilled
            ? (isFromSap ? classes.sapChipIsFromSap : classes.sapChipWithValue)
            : classes.sapChip}
        classes={{ label: classes.padding }} label={label} disabled={disabled} clickable={clickable} onClick={onClick} />
}

const useStyles = sapChipStyle => makeStyles({
    padding: {
        padding: sapChipStyle.padding
    },
    sapChip: {
        ...sapChipStyle
    },
    sapChipIsFromSap: {
        ...sapChipStyle,
        backgroundColor: '#ddefdd',
        color: defaultColors.green.main.color,
    },
    sapChipWithValue: {
        ...sapChipStyle,
        backgroundColor: '#daecf9',
        color: defaultColors.darkBlue.main.color,
    },
})