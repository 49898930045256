import React, { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { api } from '../../infrastructure/api'
import { hasClaim } from '../../infrastructure/signIn/userContext'
import { Claims } from '../../infrastructure/signIn/models'
import { Product, Site, ChannelCustomerSegment, Company, Counterparty, TruckTransportItem } from './truckModels'
import { useFormState } from './form/truckFormStore'
import { useTruckTransportList } from './board/truckListStore'
import { useFilters } from './board/filters/truckFiltersStore'
import { history } from '../../infrastructure/navigation'
import { t } from '../../infrastructure/i18nextHelper'

function useStore() {
    let referential = useReferential()
    let referentialContext = React.createContext(referential)
    let transportationType = useTransportationType()
    let transportationTypeContext = React.createContext(transportationType)
    let form = useFormState(referentialContext, transportationTypeContext)
    let filters = useFilters(transportationTypeContext)
    let list = useTruckTransportList()

    useEffect(loadTable, [transportationType.meanOfTransportation])

    function loadTable() {
        transportationType.updateTransportationFromUrl()
        let hasAtLeastOneClaim = hasClaim(Claims.TruckManager) || hasClaim(Claims.TruckReader) || hasClaim(Claims.RailCarsManager) || hasClaim(Claims.RailCarsReader)
        if (form.isOpen || !transportationType.meanOfTransportation || !hasAtLeastOneClaim) return

        list.search(filters.fields, transportationType.meanOfTransportation)
    }

    async function batchDelete(trucks: TruckTransportItem[]) {
        if (trucks.length === 0 || !transportationType.meanOfTransportation) return

        await api.post('truck/batchDelete', { truckTransportIds: trucks.map(x => x.id) })
        await list.search(filters.fields, transportationType.meanOfTransportation)
    }

    return { referential, form, filters, list, loadTable, batchDelete, t: transportationType.label, isRailCar: transportationType.transportationType == 'Rail Car' }
}

function useReferential() {
    let [products, setProducts] = React.useState<Product[]>([])
    let [sites, setSites] = React.useState<Site[]>([])
    let [companies, setCompanies] = React.useState<Company[]>([])
    let [customerSegments, setCustomerSegments] = React.useState<string[]>([])
    let [counterparties, setCounterparties] = React.useState<Counterparty[]>([])

    async function init() {
        let shouldLoad = products.length === 0 &&
            sites.length === 0 &&
            customerSegments.length === 0

        if (shouldLoad) await load()
    }

    async function load() {
        setProducts((await api.get<Product[]>('truck/product')).sort((a, b) => a.order - b.order))
        setSites(await api.get<Site[]>('truck/site'))
        setCompanies((await api.get<Company[]>('truck/company')).sort((a, b) => a.order - b.order))
        setCounterparties((await api.get<Counterparty[]>('truck/counterparty')))
        setCustomerSegments((await api.get<ChannelCustomerSegment[]>('truck/channelCustomerSegment'))
            .map(x => x.customerSegment).distinct())
    }

    return { products, sites, companies, customerSegments, counterparties, init, load }
}

function useTransportationType() {
    let [transportationType, setTransportationType] = useState<'Truck' | 'Rail Car' | null>(null)
    let [meanOfTransportation, setMeanOfTransportation] = useState<MeanOfTransportation | null>(null)

    useEffect(() => {
        updateTransportationFromUrl()
    }, [history.location.pathname])

    function urlContains(subStr: string): boolean {
        return (history.location.pathname + history.location.search).toLowerCase().includes(subStr.toLowerCase())
    }

    function label(label: string, params?: any) {
        let mot = transportationType == 'Rail Car'
            ? t('trucks.motRailCar')
            : t('trucks.motTruck')
        return t(label, { __mot__: mot, ...params })
    }

    function updateTransportationFromUrl() {
        if (!urlContains('railCar') && !urlContains('trucks'))
            return
        setTransportationType(urlContains('railCar')
            ? 'Rail Car'
            : urlContains('trucks')
                ? 'Truck'
                : null)
        setMeanOfTransportation(urlContains('railCar')
            ? 'Train'
            : urlContains('trucks')
                ? 'Road'
                : null)
    }

    function updateTransportationFromMeanOfTransportation(mot: MeanOfTransportation) {
        setTransportationType(mot == 'Train'
            ? 'Rail Car'
            : mot == 'Road'
                ? 'Truck'
                : null)
        setMeanOfTransportation(mot)
    }

    return { transportationType, meanOfTransportation, updateTransportationFromUrl, updateTransportationFromMeanOfTransportation, label }
}

export type MeanOfTransportation = 'Road' | 'Train'

export let TruckTransportContainer = createContainer(useStore)
export type TruckTransportStore = ReturnType<typeof useStore>
export type TruckTransportFormState = ReturnType<typeof useFormState>
export type TruckTransportReferential = ReturnType<typeof useReferential>
export type TransportationType = ReturnType<typeof useTransportationType>
export type TruckTransportFilters = ReturnType<typeof useFilters>