import React, { useState } from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { defaultStockInput } from '../stockModels'
import { Popup, ButtonPopupParams } from '../../common/components/popup'
import { ActualStockContainer } from './actualStockStore'
import ActualStockEditContent from './_actualStockEditContent'
import { useActionDebounce } from '../../common/debounce'
import { hasFeature } from '../../../infrastructure/feature'

function ActualStockEditPopup({ closePopup, isOpen }) {
    let store = ActualStockContainer.useContainer()

    let onSaveDebouncer = useActionDebounce(store.onSave)

    let onClosePopup = () => {
        store.setStockInput({ ...defaultStockInput })
        closePopup()
    }

    let buttons: ButtonPopupParams[] = [{
        buttonEffect: onSaveDebouncer.execute,
        buttonText: t('components.saveButton'),
    }]

    return (
        <Popup title={hasFeature('OpeningActualStock') ? t('stock.popup.titleOpeningStockInput') : t('stock.popup.titleClosingStockInput')}
            close={() => onClosePopup()}
            isOpen={isOpen}
            buttons={buttons}>
            <ActualStockEditContent isInPopup={true} />
        </Popup>
    )
}

let styles = theme => createStyles({})

export default withStyles(styles, muiOptions)(ActualStockEditPopup)