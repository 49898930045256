import { Profile, Claims, UserClaim, UserSettings } from "./models"
import JwtDecode from 'jwt-decode'
import { setAuthToken, getAuthToken, setProfile as setLocalProfile, getProfile, clearStorage } from "./authToken"
import { post, get } from "../api"
import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { FeatureContainer } from '../feature'

let userClaims: UserClaim[] = []

export function hasAdminClaim() {
    return hasClaim(Claims.FeatureToggle)
        || hasClaim(Claims.UserManager)
        || hasClaim(Claims.UserPersonaManager)
        || hasClaim(Claims.RefreshData)
        || hasMasterdataClaim()
}

export function hasVesselClaim() {
    return hasClaim(Claims.VesselManager)
        || hasClaim(Claims.VesselReader)
        || hasClaim(Claims.VesselWriter)
}

export function hasDealClaim() {
    return hasClaim(Claims.DealManager) || hasClaim(Claims.DealReader)
}

export function hasTruckClaim() {
    return hasClaim(Claims.TruckManager) || hasClaim(Claims.TruckReader)
}

export function hasActualStockClaim() {
    return hasClaim(Claims.ActualStockSiteReader) || hasClaim(Claims.ActualStockSiteWriter) || hasClaim(Claims.ActualStockSiteValidator)
}

export function hasClaim(...expectedClaims: Claims[]) {
    return userClaims.map(x => x.claim).some(x => expectedClaims.indexOf(x) >= 0)
}

function hasMasterdataClaim() {
    return userClaims.some(x => x.claim.toLowerCase().startsWith("masterdata"))
}

export function hasChannelSalesManagerClaimWithScope(expectedScope: string | null) {
    if (expectedScope) {
        let scope = getScope(Claims.ChannelSalesManager)
        if (scope && scope.length)
            return scope.includes(expectedScope)
        return hasClaim(Claims.ChannelSalesManager)
    }
    return false
}

export function hasMarketRiskClaim() {
    return hasClaim(Claims.MarketRiskSimulatorManager,
        Claims.MarketRiskSimulatorReader,
        Claims.SalesManager,
        Claims.SalesReader)
}

export function hasScopeOnClaim(claim: Claims, expectedScope: string) {
    let scope = getScope(claim)
    if (scope && expectedScope)
        return scope.includes(expectedScope)
    return false
}

export function currentAuthToken() {
    return getAuthToken()
}

export function changeAuthToken(authToken: string): void {
    if (getAuthToken() != authToken) {
        setAuthToken(authToken)
        setClaims()
    }
}

let getScope = (claim: Claims) => {
    return userClaims.first(x => x.claim === claim)?.scope
}

function setClaims() {
    let token = getAuthToken()

    if (!!token) {
        let decodedToken: object = JwtDecode(token)
        userClaims = Object.keys(decodedToken).filter(x => x in Claims).map(x => ({ claim: x as Claims, scope: decodedToken[x] }) as UserClaim)
    }
}

setClaims()

function useUserContext() {
    let feature = FeatureContainer.useContainer()

    let [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!getAuthToken())
    let [currentCountry, setCountry] = useState<string>('')
    let [profile, setProfile] = useState<Profile | null>(null)

    useEffect(() => { loadProfile() }, [])

    function disconnect(): void {
        userClaims = []
        setLocalProfile(null)
        setAuthToken(null)
        setIsLoggedIn(false)
        clearStorage()
    }

    async function impersonate(username: string) {
        await post('auth/impersonate', { username: username })
        await postAuthentication()
    }

    async function authenticateAutoDev(username: string) {
        await post('auth/dev', { username: username })
        await postAuthentication()
    }

    async function authenticateDigitalPass(authorizationCode: string) {
        await post('auth/digitalPass', { code: authorizationCode })
        await postAuthentication()
    }

    async function authenticateCognito(authorizationCode: string, state: string) {
        await post('auth/cognito', { code: authorizationCode, state })
        await postAuthentication()
    }

    async function loadProfile() {
        let profile = await get<Profile>("auth/profile")
        setLocalProfile(profile)
        setProfile(profile)
    }

    async function postAuthentication() {
        await loadProfile()
        setIsLoggedIn(true)
        await feature.reload()
    }

    return {
        disconnect, authenticateAutoDev, authenticateDigitalPass, authenticateCognito,
        isLoggedIn, currentCountry, setCountry, impersonate, profile
    }
}

export let UserContextContainer = createContainer(useUserContext)