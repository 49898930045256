import React, { useImperativeHandle, useState, useEffect, useRef, RefObject, Fragment } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, createStyles, withStyles, Paper, Table, TableBody, TableCell, Typography, TableContainer, TableHead, TableRow, Icon, SvgIcon } from '@material-ui/core'
import { muiOptions, defaultStyles, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import * as api from '../../infrastructure/api'
import { t } from '../../infrastructure/i18nextHelper'
import { NumberField, DotIcon, Button } from '../common/customComponents'
import { Guid } from '@guid'

function _PriceIndexDetailsDialog(props) {
    const [open, setOpen] = useState<boolean>(false)
    const [name, setName] = useState<string | null>(null)
    const [priceIndexDetails, setPriceIndexDetails] = useState<PriceIndexDetails | null>(null)

    dialogRef = useRef<{ open: (entity: OpenPriceIndexDetails) => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: async (parameter: OpenPriceIndexDetails) => {
            setPriceIndexDetails(null)
            setName(null)
            let result = await api.get<PriceIndexDetails>(`pricing/priceIndex/${parameter.index}/${parameter.from}/${parameter.to}/${parameter.productId}`)
            setPriceIndexDetails(result)
            setOpen(true)
            setName(result.name)
        }
    }))

    let getTrad = (text: string) => t('pricing.priceIndexDetails.label.' + text)
    let formatDate = (date) => new Intl.DateTimeFormat('en', { day: '2-digit', month: '2-digit', year: '2-digit' }).format(new Date(date))

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                scroll={'paper'}
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'>
                <DialogTitle>{name}</DialogTitle>
                <DialogContent dividers={true} className={props.classes.noPadding + ' ' + props.classes.minSize}>
                    <>
                        {priceIndexDetails &&
                            <>
                                <div className={props.classes.header}>
                                    <div className={props.classes.line}>
                                        <Typography className={props.classes.title}>{getTrad('pricingPeriod')}</Typography>
                                        <Typography className={props.classes.value}>{formatDate(priceIndexDetails.from)} - {formatDate(priceIndexDetails.to)}</Typography>
                                    </div>
                                    <div className={props.classes.line}>
                                        <Typography className={props.classes.title}>{getTrad('average')}</Typography>
                                        <Typography className={props.classes.boldValue}>{priceIndexDetails.average && parseFloat(priceIndexDetails.average.toFixed(2))}&nbsp;</Typography>
                                        <Typography className={props.classes.unit}>{priceIndexDetails.unit}</Typography>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <Table className={props.classes?.table} size='small' aria-label='history table'>
                                        <TableBody>
                                            {priceIndexDetails.values.map((x) => (
                                                <TableRow key={x.date}>
                                                    <TableCell component='th' scope='row'>
                                                        {formatDate(x.date)}
                                                    </TableCell>
                                                    <TableCell className={props.classes.valueCell}>
                                                        <NumberField
                                                            align={'right'} disabled
                                                            text={x.value}
                                                            precision={2} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <DotIcon className={props.classes.svgMiddle} color={getPriceIndexStatusColor(x.status)} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                    </>
                </DialogContent>
                <DialogActions>
                    <Button className={props.classes.closeButton}
                        label={t('components.dialogClose')}
                        onClick={() => setOpen(false)}
                        color='primary' />
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export type OpenPriceIndexDetails = {
    index: string
    from: string
    to: string
    productId: Guid
}

type PriceIndexDetails = {
    name: string
    from: string
    to: string
    average: number | null
    unit: string
    values: PriceIndexValue[]
}

type PriceIndexValue = {
    date: string
    value: number | null
    status: PriceIndexStatus
}

export type PriceIndexStatus = 'Missing' | 'Estimated' | 'OfficeClosed' | 'Actual'

let dialogRef: RefObject<{ open: (entity: OpenPriceIndexDetails) => void }> | null = null
export let priceIndexDetailsDialog = { open: (entity: OpenPriceIndexDetails) => dialogRef?.current?.open(entity) }

export let getPriceIndexStatusColor = (status: PriceIndexStatus | null) => {
    switch (status) {
        case 'Missing': return defaultColors.red.light.color
        case 'Estimated': return defaultColors.darkBlue.light.color
        case 'OfficeClosed': return defaultColors.orange.light.color
        case 'Actual': return defaultColors.green.light.color
    }
}

let styles = theme => createStyles({
    noPadding: {
        padding: '0'
    },
    minSize: {
        minwidth: 'calc(100% - 3em)',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column'
    },
    closeButton: {
        ...defaultStyles.dialogCloseButton
    },
    header: { width: '100%', padding: '16px 16px 8px 16px', fontSize: '0.875em' },
    line: { ...defaultStyles.flexRow, alignItems: 'baseline' },
    title: { marginRight: '0.5em' },
    value: { fontWeight: 'lighter' },
    boldValue: { fontWeight: 400 },
    unit: {
        fontSize: '0.875em'
    },
    italic: {
        fontStyle: 'italic'
    },
    svgMiddle: {
        verticalAlign: 'middle'
    },
    valueCell: {
        paddingRight: 0,
        "& input": {
            paddingTop: 0,
            paddingBottom: 0,
        }
    }
})

export let PriceIndexDetailsDialog = withStyles(styles, muiOptions)(_PriceIndexDetailsDialog)