import { createStyles } from "@material-ui/styles";

export let switcherStyle = createStyles({
    dateAdornment: {
        position: 'relative',
        right: '6.7em',
        top: '4px',
        width: '0px'
    },
    adornment: {
        position: 'relative',
        right: '5.5em',
        top: '4px',
        width: '0px'
    },
    switchModeButton: {
        position: 'relative',
        top: '0.5em',
    }
})