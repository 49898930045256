import { useEffect, useState } from "react";
import { api } from "../../../infrastructure/api";
import { createContainer } from "unstated-next";
import { convertToRaw, ContentState } from 'draft-js'
import guid, { Guid } from '../../../infrastructure/guid'


function useComment() {
    let [demandingKeys, setDemandingKeys] = useState<string[]>([])
    let [loadingKeys, setLoadingKeys] = useState<string[]>([])
    let [loadedKeys, setLoadedKeys] = useState<string[]>([])
    let [existingKeys, setExistingKeys] = useState<Set<string>>(new Set())

    useEffect(function startLoadNextBatchOnNewDemandOrPreviousBatchEnded() {
        if (demandingKeys.length) {
            load()
        }
    }, [demandingKeys, loadingKeys])

    useEffect(function exposeLoadedKeysWhenAllBatchsAreLoaded() {
        if (!demandingKeys.length && !loadingKeys.length)
            setExistingKeys(new Set(Object.values(loadedKeys)))
    }, [loadingKeys])

    let exists = async (associationKey: string) => {
        if (!associationKey || existingKeys.has(associationKey)) return
        setDemandingKeys(previous => [...previous, associationKey])
    }

    let load = async () => {
        if (loadingKeys.length) return

        let nextKeys = demandingKeys
        setLoadingKeys(nextKeys)
        setDemandingKeys([])

        try {
            var postResult = await api.post('comment/existence', { associationKeys: nextKeys }, { correlationId: guid.createNew() })
            let result = await api.get<string[]>(`comment/existence?correlationId= ${postResult.correlationId}`)
            setLoadedKeys(previous => [...previous, ...result])
        } catch (err) {
            throw err
        } finally {
            setLoadingKeys([])
        }
    }

    let addExistence = (associationKey: string) => setExistingKeys(new Set(existingKeys).add(associationKey))

    let convertContentTextToJSONString = (content: string) => {
        try {
            JSON.parse(content)
            return content
        } catch (err) {
            let parsedContent = ContentState.createFromText(content)
            let rawContent = convertToRaw(parsedContent)
            return JSON.stringify(rawContent)
        }
    }

    return { existingKeys, exists, addExistence, convertContentTextToJSONString }
}

export let CommentContainer = createContainer(useComment)