import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { api } from '../../../infrastructure/api'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, NumberField, Select, TableItem } from '../../common/customComponents'
import { Company, Product } from './models'
import { MasterDataShell, MasterDataItem, createExcelLines } from './masterDataShell'

type ProductCompany = {
    company: string,
    productCode: string,
    minimumStockDays: number | null,
}

let zero = (): ProductCompany => {
    return {
        company: '',
        productCode: '',
        minimumStockDays: null,
    }
}

type ProductCompanyFilters = {
    company: string | null,
    product: string | null,
}

let nullFilters: ProductCompanyFilters = {
    company: null,
    product: null,
}

let generateIdString = (item: ProductCompany | TableItem<ProductCompany>): string => item.company + ' ' + item.productCode

let toTableItem = (item: ProductCompany): TableItem<MasterDataItem<ProductCompany>> => {
    return {
        id: generateIdString(item),
        company: item.company,
        productCode: item.productCode,
        minimumStockDays: item.minimumStockDays,
        isModified: false
    }
};

function ProductCompanyMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [displayedProductCompanys, setDisplayedProductCompanys] = useState<TableItem<ProductCompany>[]>([])
    let [companys, setCompanys] = useState<Company[]>([])
    let [products, setProducts] = useState<Product[]>([])
    let [filters, setFilters] = useState<ProductCompanyFilters>(nullFilters)

    let productCompanyLabel = t('admin.masterdata.productCompany.productCompany')
    let isManager = hasClaim(Claims.MasterdataProductCompanyManager)

    let init = async () => {
        let companyPromise = api.get<Company[]>('masterdata/productCompany/company')
        let productPromise = api.get<Product[]>('masterdata/productCompany/product')
        setCompanys(await companyPromise)
        setProducts(await productPromise)
        load()
    }

    let load = async () => {
        let productCompanys = await api.get<TableItem<ProductCompany>[]>('masterdata/productCompany')
        setDisplayedProductCompanys(productCompanys.map(toTableItem))
    }

    let onSave = async (item: ProductCompany) => {
        await api.post('masterdata/productCompany', item)
        await load()
        return true
    }

    let onDelete = async (ids: string[]) => {
        let toDelete = displayedProductCompanys.filter(x => ids.find(id => id === generateIdString(x)))
        if (toDelete.length === 0) return false
        await api.post(`masterdata/productCompany/delete`, { toDelete: toDelete })
        await load()
        return true
    }

    let applyFilters = () => (item: ProductCompany) => {
        let unfiltered = true
        if (filters.company)
            unfiltered = unfiltered &&
                (item.company.toLowerCase().contains(filters.company!)
                    || getCompanyNameFromCode(item.company).toLowerCase().contains(filters.company!.toLowerCase()))
        if (filters.product)
            unfiltered = unfiltered && item.productCode.toLowerCase().contains(filters.product!.toLowerCase())

        return unfiltered
    }

    useEffect(() => { let effect = async () => { await init() }; effect() }, [])
    useEffect(() => { applyFilters() }, [filters])

    let getCompanyNameFromCode = (code: string): string => companys.find(x => x.code == code)?.name ?? ''

    let columns: ColumnDescriptor<TableItem<MasterDataItem<ProductCompany>>>[] = [
        {
            name: t('admin.masterdata.productCompany.company'), value: x => getCompanyNameFromCode(x.company),
            columnFilter: { value: filters.company ?? '', onChange: (company: string) => setFilters({ ...filters, company: company }) }
        },
        {
            name: t('admin.masterdata.productCompany.product'), value: x => x.productCode,
            columnFilter: { value: filters.product ?? '', onChange: (product: string) => setFilters({ ...filters, product: product }) }
        },
        { name: t('admin.masterdata.productCompany.minDays'), value: x => x.minimumStockDays },
    ]

    let getItems = () => displayedProductCompanys.filter(applyFilters()).map(toTableItem)

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'ProductCompany.xlsx',
            sheets: [{ name: 'ProductCompany', lines: createExcelLines(getItems(), columns) }]
        })
    }

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={'product-company-table'}
                headerLabel={productCompanyLabel}
                itemLabel={productCompanyLabel}
                isManager={isManager}
                onExportExcel={exportExcel}
                onNew={zero}
                onDelete={onDelete}
                onSave={onSave}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <Select label={t('admin.masterdata.productCompany.company')}
                                disabled={!isManager}
                                value={selectedItem.company}
                                choices={companys.map(x => ({ value: x.code, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, company: val }) }} />
                            <Select label={t('admin.masterdata.productCompany.product')}
                                disabled={!isManager}
                                value={selectedItem.productCode}
                                choices={products.map(x => ({ value: x.code, text: x.code }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, productCode: val }) }} />
                            <NumberField label={t('admin.masterdata.productCompany.minDays')}
                                text={selectedItem.minimumStockDays ? selectedItem.minimumStockDays : null}
                                onChange={x => setSelectedItem({ ...selectedItem, minimumStockDays: x })} />
                        </>
                    )}
            </MasterDataShell>
        </div >)
}

let styles = (_) => createStyles({ container: { height: '100%' } })
export default withStyles(styles, muiOptions)(ProductCompanyMasterData)