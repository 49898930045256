import React, { useImperativeHandle, useState, useRef, RefObject, Fragment } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, createStyles, withStyles } from '@material-ui/core'
import { muiOptions, defaultStyles } from '../../infrastructure/materialUiThemeProvider'
import * as api from '../../infrastructure/api'
import { t } from '../../infrastructure/i18nextHelper'
import { PricingEdit, Pricing, exportPricing } from './pricing'
import { createContainer } from 'unstated-next'
import { Button } from '../common/customComponents'
import { Claims } from '../../infrastructure/signIn/models'
import { hasClaim } from '../../infrastructure/signIn/userContext'
import { DealFieldGroup } from '../deals/dealModels'

function usePricingDialogContainer() {
    let [isOpen, setIsOpen] = useState<boolean>(false)
    return { isOpen, setIsOpen }
}

export let PricingDialogContainer = createContainer(usePricingDialogContainer)

function _PricingDialog(props) {
    let store = PricingDialogContainer.useContainer()
    const [name, setName] = useState<string | null>(null)
    const [openPricing, setOpenPricing] = useState<OpenPricing | null>(null)

    dialogRef = useRef<{ open: (entity: OpenPricing) => void, close: () => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: async (parameter: OpenPricing) => {
            setName(null)
            setOpenPricing(parameter)
            let result = await api.get<Pricing>(`pricing/${parameter.id}`)
            store.setIsOpen(true)
            setName(result.name)
        },
        close: () => store.setIsOpen(false)
    }))

    let exportExcel = () => exportPricing.export()

    return (
        <Fragment>
            <Dialog
                classes={{ root: props.classes.dialog }}
                open={store.isOpen}
                onClose={() => store.setIsOpen(false)}>
                <DialogTitle>
                    <div className={props.classes.dialogTitleContainer}>
                        {name}
                        <Button className={props.classes.exportButton}
                            label={t('admin.masterdata.export')}
                            img='/static/images/excel_red.svg'
                            onClick={exportExcel} />
                    </div>
                    {openPricing?.pricingType
                        ? <div className={props.classes.dialogTitleContainer}>
                            {t("pricing.label.pricingInformation", { type: openPricing?.pricingType })}
                        </div>
                        : undefined
                    }
                </DialogTitle>
                <DialogContent dividers={true} className={props.classes.noPadding + ' ' + props.classes.minSize}>
                    <PricingEdit id={openPricing?.id} readOnly={!hasClaim(Claims.DealManager)} costGroup={openPricing?.costGroup} />
                </DialogContent>
                <DialogActions>
                    <Button className={props.classes.closeButton}
                        label={t('components.dialogClose')}
                        onClick={() => store.setIsOpen(false)}
                        color='primary' />
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export type OpenPricing = {
    id: string,
    pricingType: string | null
    costGroup: DealFieldGroup | undefined
}

let dialogRef: RefObject<{ open: (entity: OpenPricing) => void, close: () => void }> | null = null
export let pricingDialog = {
    open: (entity: OpenPricing) => dialogRef?.current?.open(entity),
    close: () => dialogRef?.current?.close(),
}

let styles = theme => createStyles({
    dialog: {
        "& .MuiDialog-paper": {
            overflowY: 'hidden !important'
        }
    },
    noPadding: {
        padding: '0'
    },
    minSize: {
        minWidth: '18em',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column'
    },
    closeButton: defaultStyles.dialogCloseButton,
    exportButton: defaultStyles.secondaryButton,
    dialogTitleContainer: {
        ...defaultStyles.flexRow,
        justifyContent: 'space-between',
        width: '100%',
    },
})

export let PricingDialog = withStyles(styles, muiOptions)(_PricingDialog)