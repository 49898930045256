import React from 'react'
import { defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { Icon, SvgIcon } from '@material-ui/core'
import { Typography, Tooltip } from '@material-ui/core'

export function DotIcon({ tooltipText, className, height, color }: Props) {
    let dotIcon = <Icon>
                    <SvgIcon className={(className ? ' ' + className : '')}>
                        <circle cx={height ? height : 10} cy={height ? height : 10} r={height ? height : 10} fill={color ? color : defaultColors.lightBlue.light.color}/>
                    </SvgIcon>
                  </Icon>
    return (
        tooltipText ? 
            <Tooltip title={<Typography variant='body1'>{tooltipText}</Typography>} placement='top'>
                {dotIcon}
            </Tooltip> : dotIcon
    )
}

type Props = {
    height?: string
    color?: string
    tooltipText?: string
    className?: string
}