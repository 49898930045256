import React from 'react'
import { defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { makeStyles } from '@material-ui/core'
import { WithTooltip } from './withTooltip'


export function Link({ tooltipText, onClick, className, children }: React.PropsWithChildren<Props>) {
    const classes = useStyles()
    return (
        <WithTooltip text={tooltipText}>
            <span onClick={onClick ?? noop} className={classes.link + (className ? ' ' + className : '')}>
                {children}
            </span>
        </WithTooltip>
    )
}

type Props = {
    onClick?: () => void
    tooltipText?: string
    className?: string
}

const noop = () => { }

const useStyles = makeStyles({
    link: {
        color: defaultColors.lightBlue.light.color,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            color: '#BCD6FE'
        }
    }
})