import React, { RefObject, useRef, useImperativeHandle, useState, useEffect } from 'react'
import moment from 'moment'
import { createStyles, withStyles, Dialog, DialogContent, DialogActions, DialogTitle, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { defaultStyles, muiOptions, MuiProps } from '../../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../../infrastructure/i18nextHelper'
import { Button, CustomDialog } from '../../../common/customComponents'
import { useActionDebounce } from '../../../common/debounce'
import { StockMovementContainer } from '../stockMovementStore'
import { LinkVesselMovementContainer } from '../movementForms/linkVesselContainer'
import { TableHead, TableRow, TableCell, Table, TableBody } from '@material-ui/core'
import { VesselForMovement } from '../movementForms/linkVesselContainer'

let selectAssignableVesselDialogRef: RefObject<{ open: () => void }> | null = null

function _SelectAssignableVessels({ classes }: MuiProps) {
    let linkVesselStore = LinkVesselMovementContainer.useContainer()

    let [isOpen, setIsOpen] = useState<boolean>(false)

    selectAssignableVesselDialogRef = useRef<{ open: () => void }>(null)

    useImperativeHandle(selectAssignableVesselDialogRef, () => ({
        open: () => {
            setIsOpen(true)
            linkVesselStore.setSelectedVessel(null)
        }
    }))

    useEffect(() => {
        if (linkVesselStore.vesselChoices.length)
            selectAssignableVesselDialogRef?.current?.open()
    }, [linkVesselStore.vesselChoices])

    let label = (key: string) => t('stock.label.movement.assignVesselTable.' + key)

    let close = () => {
        linkVesselStore.setSelectedVessel(null)
        linkVesselStore.setVesselChoices([])
        setIsOpen(false)
    }

    let confirm = () => {
        linkVesselStore.setIsConfirmVesselOpen(true)
        setIsOpen(false)
    }

    let columns: { name: string, value: (vessel: VesselForMovement) => string }[] = [
        { name: label('name'), value: x => x.name },
        { name: label('reference'), value: x => x.reference },
        { name: label('jetty'), value: x => x.jetty },
        { name: label('counterparty'), value: x => x.counterparty },
        { name: label('laycan'), value: x => linkVesselStore.laycanFormated(x) }
    ]

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle>{t('stock.label.movement.selectVesselForAssignation')}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead className={classes.table}>
                        <TableRow>
                            <TableCell className={classes.headerPadding} align='center'></TableCell>
                            {columns.map(x =>
                                <TableCell className={classes.headerPadding} key={x.name}>{x.name}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {linkVesselStore.vesselChoices && linkVesselStore.vesselChoices.map((vessel, index) => (
                            <TableRow key={index}>
                                <TableCell align='center' scope='row' className={classes.cellPadding}>
                                    <RadioGroup
                                        aria-label="Vessels"
                                        name="Vessels"
                                        value={!linkVesselStore.selectedVessel ? '' : linkVesselStore.selectedVessel.id}
                                        onChange={() => linkVesselStore.setSelectedVessel(vessel)}>
                                        <FormControlLabel
                                            value={vessel.id}
                                            control={<Radio />}
                                            label={""}
                                        />
                                    </RadioGroup>
                                </TableCell>
                                {columns.map((x, i) =>
                                    <TableCell className={classes.cellPadding} key={i}>
                                        <span >
                                            {vessel ? x.value(vessel) : ''}
                                        </span>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions className={classes.marginTop1em}>
                <Button onClick={close}
                    label={t('components.dialogClose')}
                    color='primary'
                    className={classes.cancelButton} />
                <Button onClick={confirm}
                    label={t('components.dialogConfirm')}
                    color='primary'
                    className={classes.confirmButton}
                    disabled={!linkVesselStore.selectedVessel} />
            </DialogActions>
        </Dialog>
    )
}

function _ConfirmVesselAssignation() {
    let store = StockMovementContainer.useContainer()
    let linkVesselStore = LinkVesselMovementContainer.useContainer()

    let label = (key: string) => t('stock.label.movement.confirmAssignVessel.' + key)

    let closeVesselConfirmationPopup = () => {
        linkVesselStore.setIsConfirmVesselOpen(false)
        linkVesselStore.setSelectedVessel(null)
        linkVesselStore.setVesselChoices([])
    }

    let assignVesselFromMovementDebouncer = useActionDebounce(async () => {
        await linkVesselStore.assignVessel()
        await store.load(store.stockMovement.id)
        closeVesselConfirmationPopup()
    })

    return (
        <CustomDialog isOpen={linkVesselStore.isConfirmVesselOpen}
            title={label('assignVesselDialogTitle')}
            contentText={label('vesselToAssign') + ': ' + linkVesselStore.assignableVesselLabel()}
            confirmButtonText={label('assignVesselButton')}
            onConfirm={assignVesselFromMovementDebouncer.execute}
            onClose={closeVesselConfirmationPopup}
            onCancel={closeVesselConfirmationPopup}
        />)
}

let styles = () =>
    createStyles({
        confirmButton: { ...defaultStyles.dialogPrimaryButton },
        cancelButton: { ...defaultStyles.dialogCloseButton },
        dialogContent: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        cellPadding: {
            padding: '0px 15px 0px 15px !important'
        },
        headerPadding: {
            padding: '10px 15px 10px 15px !important'
        }
    })

export let SelectAssignableVessels = withStyles(styles, muiOptions)(_SelectAssignableVessels)
export let ConfirmVesselAssignation = withStyles(styles, muiOptions)(_ConfirmVesselAssignation)