import React from 'react'
import { t } from '../../i18nextHelper'
import { withStyles, Tooltip, ClickAwayListener } from '@material-ui/core'
import { defaultColors } from '../../materialUiThemeProvider'
import { NotificationItem } from './listItem'
import { NotificationView } from './models'

export let DropDown = React.memo((props: Props) => {
    return (
        <ClickAwayListener onClickAway={() => props.setIsOpen(false)}>
            <HtmlTooltip
                placement='bottom-end'
                onClose={() => props.setIsOpen(false)}
                open={props.isOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                    <div className={props.classes.dropdown +
                        (props.notifications.length > 8 ? ' ' + props.classes.dropdownWithScrollBar : '')}>
                        {props.notifications.length > 0
                            ? props.notifications.map((x, i) =>
                                <NotificationItem key={i} notification={x} classes={props.classes} />)
                            : <div className={props.classes.empty}>
                                <div><span>{t('notification.none')}</span></div>
                            </div>}
                    </div>
                }>
                <div>{props.children}</div>
            </HtmlTooltip>
        </ClickAwayListener>)
}, (prev, next) => prev.notifications === next.notifications && prev.isOpen === next.isOpen)

type Props = {
    notifications: NotificationView[]
    isOpen: boolean
    classes: any
    children: any
    setIsOpen: (value: boolean) => void
}

const HtmlTooltip = withStyles(_ => ({
    tooltip: {
        backgroundColor: '#FFFFFF',
        color: defaultColors.inputText,
        maxWidth: '30rem',
        pointerEvents: 'auto',
        padding: 0,
        margin: 0
    }
}))(Tooltip)

