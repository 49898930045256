import React from 'react'
import { muiOptions, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { createStyles, withStyles, Tooltip, Typography, ClickAwayListener } from '@material-ui/core'

function _HelpButton({ title, text, onClick, classes, height, width, placement, fullWidth, children }: React.PropsWithChildren<Props>) {
    let [open, setOpen] = React.useState(false)

    let onIconClick = () => {
        setOpen(true)
        if (onClick) onClick()
    }

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Tooltip
                classes={{ tooltip: fullWidth ? classes.fullWidthTooltip : classes.tooltip }}
                placement={placement ?? 'bottom-end'}
                onClose={() => setOpen(false)}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
                title={
                    <Typography variant='body1' color='inherit'>
                        {title ? (<><strong>{title}</strong><br /></>) : null}
                        {text ? (<div>{text.split('\r\n').map((t, i) => <p key={i}>{t}</p>)}</div>) : null}
                    </Typography>
                }>
                <div className={classes.container}>
                    {children}
                    <a>
                        <div className={classes.icon} onClick={onIconClick}>
                            <img src='/static/images/info_outline.svg' style={{ width: width ?? '1em', height: height ?? '1em' }} />
                        </div>
                    </a>
                </div >
            </Tooltip>
        </ClickAwayListener>
    )
}

type Props = {
    title?: string
    text?: string
    height?: string
    width?: string
    placement?: 'bottom-end' | 'top-end'
    fullWidth?: boolean
    onClick?: () => void
    classes?: any
}

const tooltipClass = {
    backgroundColor: '#f5f5f9',
    color: defaultColors.inputText,
    border: '1px solid #dadde9'
}

let styles = _ =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center'
        },
        icon: {
            color: defaultColors.grey.main.color,
            opacity: 0.5,
            transition: 'opacity 0.2s',
            cursor: 'pointer',
            '&:hover': {
                opacity: 0.75
            }
        },
        tooltip: { ...tooltipClass },
        fullWidthTooltip: { ...tooltipClass, maxWidth: 'none' }
    })

export let HelpButton = withStyles(styles, muiOptions)(_HelpButton)