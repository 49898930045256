import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Input, createStyles, withStyles } from '@material-ui/core'
import AttachFileOutlined from '@material-ui/icons/AttachFileOutlined'
import { t } from '../../../infrastructure/i18nextHelper'
import { muiOptions, defaultStyles, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { Button, ColumnDescriptor, DataTable, TextField } from '../customComponents'
import { Guid } from '@guid'
import moment from 'moment'
import * as Icons from '@material-ui/icons'
import { snackbars } from '../../../infrastructure/snackbars'
import * as api from '../../../infrastructure/api'
import { allowedExtensions } from '../fieldProps'

type AttachFileButtonProps = {
    id: Guid
    context: string | null
    keyTemplate: string
    keyParameters: { [key: string]: string }
    secondaryAssociationKeys?: string[]
    disableDelOrAdd?: boolean
    title?: string
    className?: string
    classes?: any
}

function _AttachFileButton({ classes, ...props }: AttachFileButtonProps) {
    let [documents, setDocuments] = useState<AttachedDocument[]>([])
    let [isDialogOpened, setIsDialogOpened] = useState<boolean>(false)
    let [documentPath, setDocumentPath] = useState<string>('')
    let [linkId, setLinkId] = useState<string>('')

    function getAssociationKey(attachFile: AttachFileButtonProps): string {
        let associationKey = attachFile.keyTemplate
        for (let key in attachFile.keyParameters) {
            associationKey = associationKey.replace(`{${key}}`, attachFile.keyParameters[key])
        }

        return associationKey
    }

    let associationKey = getAssociationKey(props)

    useEffect(() => { load() }, [props.context])

    let load = async () => {
        if (props.context) {
            let path = await api.get<{ path: string }>(`document/path/${props.context}/${props.keyTemplate}`)
            setDocumentPath(path.path)
        }
    }

    useEffect(() => { loadDocuments() }, [isDialogOpened, associationKey])

    let columns: ColumnDescriptor<AttachedDocument>[] = [
        { name: '', value: x => x.kind == 'File' ? <Icons.Link></Icons.Link> : <Icons.Link></Icons.Link> },
        { name: t('components.documentDialog.fileNameColumn'), value: x => x.fileName },
        { name: t('components.documentDialog.addedOnColumn'), value: x => !!x.createdAt ? moment(x.createdAt).format('MM/DD/YYYY h:mm a') : '' }
    ]

    return (
        <div className={props.className ?? ''}>
            <Button
                className={classes.button}
                label={<span className={classes.documentNumber}>({documents.length})</span>}
                img={<AttachFileOutlined />}
                id={props.id}
                onClick={() => setIsDialogOpened(true)} />
            <Dialog
                open={isDialogOpened}
                onClose={() => setIsDialogOpened(false)}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <DataTable isSelectable={false}
                        idSelector={(x: AttachedDocument) => x.id}
                        items={documents}
                        onClick={(x: AttachedDocument) => downloadFile(x.downloadUrl)}
                        columns={columns}
                        actions={!props.disableDelOrAdd
                            ? [
                                {
                                    name: 'deleteDocument', action: (x: AttachedDocument) => deleteDocument(x.id, x.associationKey),
                                    isBodyAction: true, noMarginOnIcon: true,
                                    icon: <Icons.DeleteOutlined />
                                },
                            ]
                            : []} />
                </DialogContent>
                <DialogActions>
                    <Input
                        className={classes.fileInput}
                        id='attach-document-button'
                        type='file'
                        onChange={e => uploadFile(e.target)}
                        inputProps={allowedExtensions} />
                    <Button className={classes.closeButton}
                        label={t('components.dialogClose')}
                        onClick={() => setIsDialogOpened(false)}
                        color='primary' />
                    {
                        !props.disableDelOrAdd &&
                        <Button
                            label={<label htmlFor='attach-document-button'>
                                {t('components.documentDialog.addFile')}
                            </label>}
                            className={classes.addFileButton} />
                    }
                    {
                        !props.disableDelOrAdd && documentPath &&
                        <TextField inputProps={{ classes: { root: classes.smallTextFieldInput } }}
                            overrideStyle={{ root: classes.smallTextFieldOverrideStyle }}
                            text={linkId}
                            onChange={e => setLinkId(e.target.value)} />
                    }
                    {
                        !props.disableDelOrAdd && documentPath &&
                        <Button
                            label={t('components.documentDialog.addLink')}
                            disabled={!linkId}
                            className={classes.addFileButton}
                            onClick={() => addLink()} />
                    }
                </DialogActions>
            </Dialog>
        </div>)

    async function loadDocuments() {
        let documents = await api.get<AttachedDocument[]>('document', { associationKey: associationKey })
        if (props.secondaryAssociationKeys) {
            let promises = props.secondaryAssociationKeys.map(x => api.get<AttachedDocument[]>('document', { associationKey: x }))
            await Promise.all(promises).then(values => values.forEach(value => documents = documents.concat(value)))
        }
        setDocuments(documents)
    }

    async function downloadFile(url: string) {
        window.open(url, '_blank')
    }

    async function uploadFile(e) {
        await api.upload('document', e.files[0], associationKey)
        snackbars.success(t('httpSuccess.stockFileUploaded'))
        loadDocuments()
    }

    async function deleteDocument(id: Guid, associationKey: string) {
        await api.del(`document/${associationKey}/${id}`)
        loadDocuments()
    }

    async function addLink() {
        await api.post('document/link', { associationKey: associationKey, path: documentPath.replace('{id}', linkId) })
        loadDocuments()
    }
}

let styles = _ =>
    createStyles({
        fileInput: {
            display: 'none'
        },
        addFileButton: {
            ...defaultStyles.dialogPrimaryButton,
            '& *': {
                cursor: 'pointer'
            }
        },
        closeButton: {
            ...defaultStyles.dialogCloseButton
        },
        button: {
            ...defaultStyles.secondaryButton
        },
        documentNumber: {
            color: defaultColors.grey.dark.color
        },
        smallTextFieldInput: {
            height: '2em',
            fontSize: 'small',
            padding: '0em',
            margin: '1em -1em 0em 1em'
        },
        smallTextFieldOverrideStyle: {
            width: '3.5em',
            padding: '0em',
            margin: '0em 0.5em'
        }
    })

export let AttachFileButton = withStyles(styles, muiOptions)(_AttachFileButton)

type StringDate = string

type AttachmentKind = 'File' | 'Link'

export type AttachedDocument = {
    id: Guid
    fileName: string
    downloadUrl: string
    kind: AttachmentKind
    createdAt: StringDate
    associationKey: string
}