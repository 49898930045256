import React from 'react'
import { useFieldStatus } from '../../common/fieldsStatus'
import { MovementType, MovementForm, Company, BatchMovement, Counterparty, SapFlow } from '../stockModels'
import moment from 'moment'
import { hasFeature } from '../../../infrastructure/feature'

export function useStockFormErrors(context: StoreContext) {
    let store = React.useContext(context)
    let movementFieldStatus = useFieldStatus<MovementForm>()

    let mainSapFlowFieldStatus = useFieldStatus<SapFlow>()
    let secondSapFlowFieldStatus = useFieldStatus<SapFlow>()

    let [specialRules, setSpecialRules] = React.useState<SpecialRules>(createDefaultSpecialRules())

    React.useEffect(() => {
        movementFieldStatus.initPropertyTracking(store.stockMovement)
        mainSapFlowFieldStatus.initPropertyTracking(store.stockMovement.mainSapFlow)
    }, [])

    React.useEffect(() => {
        movementFieldStatus.clearStatuses()
        mainSapFlowFieldStatus.clearStatuses()
    }, [store?.stockMovement.id])

    React.useEffect(() => secondSapFlowFieldStatus.initPropertyTracking(store.stockMovement.secondSapFlow), [store.stockMovement.secondSapFlow])
    React.useEffect(() => secondSapFlowFieldStatus.clearStatuses(), [store.stockMovement.secondSapFlow, store?.stockMovement.id])

    function highlightErrors() {
        movementFieldStatus.clearStatuses()
        mainSapFlowFieldStatus.clearStatuses()
        secondSapFlowFieldStatus.clearStatuses()

        let mvt = store.stockMovement
        if (mvt.movementType === MovementType.Unknown)
            return

        let isCompanyDutyStatusValid = isCompanyDutyStatusCombinationValid()
        let isCompanyCounterpartyValid = isCompanyCounterpartyCombinationValid()
        let batchMvtDateValid = isBatchMovementDateValid()

        let fieldsOnError = findTrackedErrors()

        movementFieldStatus.setStatus(fieldsOnError, 'alert')
        setSpecialRules(specialRules)

        function findTrackedErrors(): string[] {
            let errors: { [P in keyof MovementForm]?: boolean } = {
                company: !mvt.company || !isCompanyDutyStatusValid,
                volume: mvt.volume == null && !mvt.nomination,
                originDutyStatus: !isCompanyDutyStatusValid ||
                    !is([MT.Purchase, MT.Borrow]) && (!mvt.originDutyStatus
                        || is([MT.Rebranding]) && mvt.originDutyStatus !== mvt.destinationDutyStatus),
                destinationDutyStatus: !isCompanyDutyStatusValid ||
                    !is([MT.Sale, MT.Loan]) && (!mvt.destinationDutyStatus
                        || is([MT.Rebranding]) && mvt.destinationDutyStatus !== mvt.originDutyStatus),
                stockOutputDate: stockOutputDateHasErrors(),
                stockInputDate: stockInputDateHasErrors(),
                originProductId: !is([MT.Purchase, MT.Borrow]) && (!mvt.originProductId
                    || is([MT.Transfer, MT.StatusChange]) && mvt.originProductId !== mvt.destinationProductId
                    || is(MT.Rebranding) && mvt.originProductId === mvt.destinationProductId),
                destinationProductId: !is([MT.Sale, MT.Loan]) && (!mvt.destinationProductId
                    || is([MT.Transfer, MT.StatusChange]) && mvt.destinationProductId !== mvt.originProductId
                    || is(MT.Rebranding) && mvt.destinationProductId === mvt.originProductId),
                originSite: !is([MT.Purchase, MT.Borrow]) && (!mvt.originSite
                    || is([MT.Rebranding, MT.StatusChange]) && mvt.originSite !== mvt.destinationSite
                    || is(MT.Transfer) && mvt.originSite === mvt.destinationSite),
                destinationSite: !is([MT.Sale, MT.Loan]) && (!mvt.destinationSite
                    || is([MT.Rebranding, MT.StatusChange]) && mvt.destinationSite !== mvt.originSite
                    || is(MT.Transfer) && mvt.destinationSite === mvt.originSite),
                counterpartyId: !isCompanyCounterpartyValid
            }
            return Object.keys(errors).filter(x => errors[x])

            function stockOutputDateHasErrors() {
                let hasNoSwapMirrorMandatory = is(MT.Borrow) && !hasFeature('MirrorSwapMandatory')
                let needValidation = !is([MT.Purchase, MT.Gains]) && !hasNoSwapMirrorMandatory

                let datesShouldNotEqual = () => !is([MT.Sale, MT.Loan, MT.Transfer]) && hasNoSwapMirrorMandatory
                    && mvt.stockInputDate !== mvt.stockOutputDate

                let transferDatesShouldBeOrdered = () => is(MT.Transfer) && (!mvt.stockInputDate || moment(mvt.stockInputDate).isBefore(moment(mvt.stockOutputDate!)))

                let hasErrors = !mvt.stockOutputDate || datesShouldNotEqual() || transferDatesShouldBeOrdered() || !batchMvtDateValid

                return needValidation && hasErrors
            }

            function stockInputDateHasErrors() {
                let hasNoSwapMirrorMandatory = is(MT.Loan) && !hasFeature('MirrorSwapMandatory')
                let needValidation = !is([MT.Sale, MT.Losses]) && !hasNoSwapMirrorMandatory

                let datesShouldNotEqual = () => !is([MT.Purchase, MT.Borrow, MT.Transfer]) && hasNoSwapMirrorMandatory
                    && mvt.stockInputDate !== mvt.stockOutputDate

                let transferDatesShouldBeOrdered = () => is(MT.Transfer) && (!mvt.stockOutputDate || moment(mvt.stockInputDate!).isBefore(moment(mvt.stockOutputDate)))

                let hasErrors = !mvt.stockInputDate || datesShouldNotEqual() || transferDatesShouldBeOrdered() || !batchMvtDateValid

                return needValidation && hasErrors
            }
        }

        function isCompanyDutyStatusCombinationValid() {
            let selectedDutyStatus = store.stockMovement.originDutyStatus ?? store.stockMovement.destinationDutyStatus
            let isValid = !selectedDutyStatus || !mvt.company || !(store.companys.first(x => x.code === mvt.company)?.dutyStatuses.indexOf(selectedDutyStatus) === -1)

            specialRules.isCompanyDutyStatusValid = isValid

            return isValid
        }

        function isCompanyCounterpartyCombinationValid() {
            let isValid = store.companys.first(x => x.code === mvt.company)?.name !== store.counterpartys.first(x => x.id === mvt.counterpartyId)?.name

            specialRules.isCompanyCounterpartyValid = isValid

            return isValid
        }

        function isBatchMovementDateValid() {
            let batch = store.batchMovement
            let dateDiff = batch?.fromDate && batch.toDate ? moment(batch.toDate).diff(moment(batch.fromDate), 'days') : null

            specialRules.isBatchMovementSameDateValid = !dateDiff || dateDiff !== 0
            specialRules.isBatchMovementDateStartsAfterEndValid = !dateDiff || dateDiff >= 0

            return batch === null
                || batch.fromDate && batch.toDate && batch.fromDate !== batch.toDate
                || !dateDiff
                || dateDiff > 0 && dateDiff < 62
        }

        function is(movementType: MovementType | MovementType[]) {
            return Array.isArray(movementType) ? movementType.some(x => mvt.movementType === x) : movementType === mvt.movementType
        }
    }

    function hasMovementError(fieldName?: keyof MovementForm): boolean {
        return !fieldName ?
            movementFieldStatus.getStatuses().some(x => x === 'alert')
            || mainSapFlowFieldStatus.getStatuses().some(x => x === 'alert')
            || secondSapFlowFieldStatus.getStatuses().some(x => x === 'alert')

            : movementFieldStatus.getStatus(fieldName) === 'alert'
    }

    function clear() {
        movementFieldStatus.clearStatuses()
        mainSapFlowFieldStatus.clearStatuses()
        secondSapFlowFieldStatus.clearStatuses()
        setSpecialRules(createDefaultSpecialRules)
    }

    function createDefaultSpecialRules() {
        return {
            isCompanyDutyStatusValid: true,
            isBatchMovementDateStartsAfterEndValid: true,
            isBatchMovementSameDateValid: true,
            isCompanyCounterpartyValid: true
        }
    }

    return { highlightErrors, hasMovementError, clear, specialRules, movementFieldStatus, mainSapFlowFieldStatus, secondSapFlowFieldStatus }
}

let MT = MovementType

type SpecialRules = {
    isCompanyDutyStatusValid: boolean
    isBatchMovementSameDateValid: boolean
    isBatchMovementDateStartsAfterEndValid: boolean,
    isCompanyCounterpartyValid: boolean
}

type StoreContext = React.Context<StoreDependencies>

type StoreDependencies = {
    stockMovement: MovementForm
    batchMovement: BatchMovement | null
    companys: Company[]
    counterpartys: Counterparty[]
}