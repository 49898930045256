import { defaultColors, defaultStyles } from '../../../../infrastructure/materialUiThemeProvider'
import { tableStyles } from '../../../common/components/table/table'

export const style = {
    statusIcon: {
        color: defaultColors.grey.dark.color
    },
    warning: {
        display: 'flex',
    },
    filterField: {
        width: '8.3em',
        margin: '0em 0.5em'
    },
    warningIcon: {
        marginLeft: '0.1em',
        marginTop: '-0.1em',
        color: defaultColors.orange.main.color
    },
    statusIconsContainer: {
        display: 'flex',
        '& > *': {
            width: '2.5em'
        },
        '& svg': {
            width: '1.3em',
            height: '1.3em'
        }
    },
    statusIconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '4.5em'
    },
    statusIconDateContainer: {
        ...defaultStyles.flexRow,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '.7rem !important',
        marginTop: '-.4em',
        color: defaultColors.grey.main.color,
        '& > span': {
            margin: '0 .3em .3em .3em'
        },
        '&:hover': {
            '& $statusIconDateButton': {
                opacity: '1'
            }
        },
        '& svg': {
            width: '0.5em',
            height: '0.5em'
        }
    },
    activeStatusIcon: {
        color: '#748bd6'
    },
    disabledStatusIcon: {
        color: defaultColors.grey.light.color
    },
    currentStatusIcon: {
        color: defaultColors.darkBlue.main.color,
    },
    hidden: {
        opacity: '0 !important',
        padding: '0',
        '& svg': {
            width: '0.7em',
            height: '0.7em',
        }
    },
    tableStatusIcon: {
        margin: '0.2em',
        cursor: 'default',
    },
    checkbox: {
        color: defaultColors.red.main.color,
    },
    rowFlex: {
        ...defaultStyles.flexRow
    },
    separatorArrow: {
        margin: '0 0.5em 0 0.5em'
    },
    statusImg: {
        height: '3em',
        width: '3em',
    },
    ...tableStyles
} as any