import React from 'react'
import { createStyles, Stepper as MuiStepper, Step, StepButton } from '@material-ui/core'
import { muiOptions, defaultColors, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { withStyles } from '@material-ui/styles'

type BaseStepperProps = { steps: string[], activeStep: number, icons?: JSX.Element[] }
type StepperClassesOverride = { root?: string }
type StepperProps = {
    onStepChange?: (index: number) => void
    linearStepping?: boolean
    classesOverride?: StepperClassesOverride
    ignoreStepStyles?: boolean
} & BaseStepperProps

function _Stepper({ classes, activeStep, steps, onStepChange, icons, linearStepping, classesOverride, ignoreStepStyles }: StepperProps & MuiProps) {
    let handleStepTo = (stepIndex: number) => {
        if ((!linearStepping || stepIndex == activeStep + 1) && onStepChange != undefined)
            onStepChange(stepIndex)
    }

    return (
        <div>
            <MuiStepper activeStep={activeStep} classes={classesOverride}>
                {steps.map((label, index) =>
                    <Step key={index}>
                        <StepButton
                            id={`stepper-${label}`}
                            className={ignoreStepStyles ? '' : getStepClassName(index, activeStep, classes)}
                            onClick={() => handleStepTo(index)}
                            disabled={!!linearStepping && index != activeStep + 1}
                            icon={icons?.[index]}>
                            {label}
                        </StepButton>
                    </Step>
                )}
            </MuiStepper>
        </div>)
}

function _ValidationStepper({ classes, activeStep, steps, icons }: BaseStepperProps & MuiProps) {
    let getClassName = (index: number) => {
        if (index <= activeStep) return classes.completed
        if (index == activeStep + 1) return classes.currentValidationStep
        return classes.disabled
    }

    return (
        <div>
            <MuiStepper activeStep={activeStep}>
                {steps.map((label, index) =>
                    <Step key={index}>
                        <StepButton id={`stepper-${label}`} className={getClassName(index)} disabled={true} icon={icons?.[index]}>
                            {label}
                        </StepButton>
                    </Step>
                )}
            </MuiStepper>
        </div>)
}

export function getStepClassName(index: number, activeStepIndex: number, classes: any) {
    if (index < activeStepIndex) return classes.completed
    if (index == activeStepIndex) return classes.lastCompleted
    if (index == activeStepIndex + 1) return classes.active
    return classes.disabled
}

let stepStyle = { padding: 0, margin: 0, }

let stepUnderlinedStyle = {
    ...stepStyle,
    color: defaultColors.darkBlue.main.color,
    borderColor: defaultColors.darkBlue.main.color,
    margin: '0 0 -2px 0',
    borderBottom: 'solid 2px',
}

export let stepperStyle = {
    active: { ...stepStyle, color: defaultColors.lightBlue.light.color, },
    completed: { ...stepStyle, color: defaultColors.darkBlue.dark.color, },
    disabled: { ...stepStyle, color: defaultColors.grey.light.color, },
    lastCompleted: stepUnderlinedStyle,
    currentValidationStep: stepUnderlinedStyle,
}

let styles = _ => createStyles({ ...stepperStyle })

export let Stepper = withStyles(styles, muiOptions)(_Stepper)
export let ValidationStepper = withStyles(styles, muiOptions)(_ValidationStepper)