import React, { useState } from 'react'
import { api } from '../../../infrastructure/api'
import { Guid } from '../../../infrastructure/guid'
import { Filters, Site, TruckTransportItem as TruckTransportItemModel } from '../truckModels'
import { MeanOfTransportation } from '../truckStore'

export function useTruckTransportList() {
    let [listItems, setListItems] = React.useState<TruckTransportItem[]>([])
    let [selectedIds, setSelectedIds] = useState<Guid[]>([])

    let constructFiltersQuery = (filters: Filters) => {
        let filterProperties = Object.keys(filters)

        let initializedFilters = filterProperties
            .filter(x => !Array.isArray(filters[x]))
            .filter(x => (filters[x] !== undefined && filters[x] !== null))
            .map(x => `${x}=${filters[x]}`)

        let collectionFilters = filterProperties
            .filter(x => Array.isArray(filters[x]))
            .filter(x => filters[x] && filters[x].length > 0)
            .flatMap(x => filters[x].map(value => `${x}=${value}`))

        return initializedFilters.concat(collectionFilters).join('&')
    }

    async function search(filters: Filters, mot: MeanOfTransportation) {
        filters.meanOfTransportation = mot
        let filtersQuery = constructFiltersQuery(filters)
        let route = filtersQuery ? `truck?${filtersQuery}` : 'truck'
        let searchResult = await api.get<TruckTransportItem[]>(route)
        setListItems([])
        setListItems(searchResult)
    }

    function isChecked(id: Guid): boolean { return selectedIds.findIndex(x => x === id) >= 0 }

    function toggleCheckLine(id: Guid) {
        let selected = [...selectedIds];
        if (selectedIds.findIndex(x => x === id) >= 0)
            selected = selectedIds.filter(x => x !== id)
        else
            selected.push(id)
        setSelectedIds(selected)
    }

    function toggleCheckAllLines() {
        if (selectedIds.length === listItems.length)
            setSelectedIds([])
        else
            setSelectedIds(listItems.map(x => x.id))
    }

    return { listItems, setListItems, search, toggleCheckAllLines, selectedIds, toggleCheckLine, isChecked }
}

type TruckTransportItem = TruckTransportItemModel & { destinationSites: Site[] }