import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import ActualStockEditContent from './_actualStockEditContent'

function ActualStockEditPage() {
    return (
        <div> 
            <ActualStockEditContent isInPopup={false} />
        </div>
    )
}

let styles = theme => createStyles({})

export default withStyles(styles, muiOptions)(ActualStockEditPage)