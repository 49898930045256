import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

export function ImportLoader() {
    return (
        <div>
            <CircularProgress />
            <span>Reading file, please wait...</span>
        </div>
    )
}