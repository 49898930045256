import React, { useState, useEffect } from 'react'
import { getAuthToken, setAuthToken, clearStorage } from '../../../infrastructure/signIn/authToken'
import { MuiProps, muiOptions } from '../../materialUiThemeProvider'
import { t, changeCountryLanguage } from './../../../infrastructure/i18nextHelper'
import { UserContextContainer } from '../../signIn/userContext'
import { HeaderSelect } from '../../../app/common/customComponents'
import { get } from '../../api'
import withStyles from '@material-ui/core/styles/withStyles'

function CountrySelector({ classes }: MuiProps) {
    let [countrys, setCountrys] = useState<string[]>([])
    let userStore = UserContextContainer.useContainer()

    useEffect(() => {
        let init = async () => {
            if (userStore.isLoggedIn) {
                let userCountrys = await get<string[]>('auth/country')
                setCountrys(userCountrys)
                if (userCountrys.length == 1)
                    changeUserCountry(userCountrys[0])
                else {
                    let loadedCountryIndex = userCountrys.indexOf(localStorage.getItem('userCountry') ?? '')
                    if (loadedCountryIndex == -1)
                        changeUserCountry(userCountrys[0])
                    else
                        changeUserCountry(userCountrys[loadedCountryIndex])
                }
            }
        }
        init()
    }, [userStore.isLoggedIn])

    let setCountry = (country: string) => {
        localStorage.setItem('userCountry', country)
        userStore.setCountry(country)
        changeCountryLanguage()
    }

    let changeUserCountry = (country: string) => {
        if (userStore.currentCountry !== '' && userStore.currentCountry !== country) {
            let token = getAuthToken()
            clearStorage()
            token && setAuthToken(token)
            setCountry(country)
            window.location.reload()
        } else {
            setCountry(country)
        }
    }

    return (
        <HeaderSelect
            choices={countrys.map(x => { return { value: x, text: t(`country.${x}`) } })}
            value={userStore.currentCountry} size='standard'
            onChange={val => changeUserCountry(val)} />)
}

export default withStyles({}, muiOptions)(CountrySelector)