import React, { useContext, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { SignIn, AuthCallback } from './signIn/signIn'
import { ShellContext } from './shell/shell'
import vesselList from '../app/vessels/vesselList'
import vesselSimulate from '../app/vessels/vesselSimulate/vesselSimulate'
import dealList from '../app/deals/dealList'
import stockBoard from '../app/stock/stockBoard'
import mktSales from '../app/mktSales/mktSales'
import truckTransportList from '../app/truck/board/truckBoard'
import stockInput from '../app/stock/actualStockEdit/actualStockEditPage'
import stockCapacity from '../app/administration/masterData/stockCapacity'
import physicalStock from '../app/administration/masterData/physicalStock'
import productCompany from '../app/administration/masterData/productCompany'
import company from '../app/administration/masterData/company'
import storageLocation from '../app/administration/masterData/storageLocation'
import plant from '../app/administration/masterData/plant'
import counterparty from '../app/administration/masterData/counterparty'
import productgroup from '../app/administration/masterData/productGroup'
import localProduct from '../app/administration/masterData/localProduct'
import product from '../app/administration/masterData/product'
import sapCounterparty from '../app/administration/masterData/sapCounterparty'
import sapCounterpartyDealType from '../app/administration/masterData/sapCounterpartyDealType'
import supplyOrigin from '../app/administration/masterData/supplyOrigin'
import supplyDestination from '../app/administration/masterData/supplyDestination'
import securityStock from '../app/administration/masterData/securityStock'
import pricingPeriod from '../app/administration/masterData/pricingPeriod'
import persona from '../app/administration/masterData/persona'
import profile from '../app/administration/masterData/profile'
import vesselDelay from '../app/administration/masterData/vesselDelay'
import calendar from '../app/administration/masterData/calendar'
import regulatedPrice from '../app/administration/masterData/regulatedPrice'
import site from '../app/administration/masterData/site'
import companyDutyStatus from '../app/administration/masterData/companyDutyStatus'
import jetty from '../app/administration/masterData/jetty'
import report from '../app/report/report'
import featureToggle from '../app/administration/featureToggle'
import impersonate from '../app/administration/impersonate'
import adminHome from '../app/administration/home'
import refreshData from '../app/administration/refreshData'
import clearData from '../app/administration/clearData'
import mktRisksExposure from '../app/marketRisks/mktRisksExposure'
import supplyBalance from '../app/supplyBalance/supplyBalance'
import dealType from '../app/administration/masterData/dealType'
import sapShipTo from '../app/administration/masterData/sapShipTo'
import readMasterDataShell from '../app/administration/masterData/readMasterDataShell'
import quantityUnitPrice from '../app/administration/masterData/quantityUnitPrice'
import supplyPlantAssignment from '../app/administration/masterData/supplyPlantAssignment'
import { navigateTo } from './navigation'
import { hasActualStockClaim, hasClaim, UserContextContainer } from './signIn/userContext'
import { Claims } from './signIn/models'

function route(path: string, component: any, exact: boolean = true) {
    return ({
        path: path,
        component: component,
        exact: exact,
    })
}

export const routes = [
    route('/', RootRedirect),
    route('/signin', SignIn),
    route('/auth_callback', AuthCallback),
    route('/vessels', vesselList),
    route('/vessel/simulate', vesselSimulate),
    route('/deals', dealList),
    route('/stock', stockBoard),
    route('/mktSales', mktSales),
    route('/mktRiskExposure', mktRisksExposure),
    route('/stock/input', stockInput),
    route('/trucks', truckTransportList),
    route('/railCars', truckTransportList),
    route('/report', report),
    route('/supplyBalance', supplyBalance),
    route('/admin', adminHome),
    route('/admin/featuretoggle', featureToggle),
    route('/admin/impersonate', impersonate),
    route('/admin/refreshData', refreshData),
    route('/admin/clearData', clearData),
    route('/admin/stockCapacity', stockCapacity),
    route('/admin/productCompany', productCompany),
    route('/admin/storageLocation', storageLocation),
    route('/admin/plant', plant),
    route('/admin/counterparty', counterparty),
    route('/admin/productgroup', productgroup),
    route('/admin/localproduct', localProduct),
    route('/admin/product', product),
    route('/admin/sapCounterparty', sapCounterparty),
    route('/admin/sapCounterpartyDealType', sapCounterpartyDealType),
    route('/admin/securityStock', securityStock),
    route('/admin/supplyOrigin', supplyOrigin),
    route('/admin/supplyDestination', supplyDestination),
    route('/admin/persona', persona),
    route('/admin/profile', profile),
    route('/admin/physicalStock', physicalStock),
    route('/admin/vesselDelay', vesselDelay),
    route('/admin/calendar', calendar),
    route('/admin/pricingPeriod', pricingPeriod),
    route('/admin/company', company),
    route('/admin/regulatedPrice', regulatedPrice),
    route('/admin/site', site),
    route('/admin/companyDutyStatus', companyDutyStatus),
    route('/admin/jetty', jetty),
    route('/admin/dealType', dealType),
    route('/admin/read', readMasterDataShell),
    route('/admin/sapShipTo', sapShipTo),
    route('/admin/quantityUnitPrice', quantityUnitPrice),
    route('/admin/supplyPlantAssignment', supplyPlantAssignment)
]

function RootRedirect() {
    let user = UserContextContainer.useContainer()
    useEffect(() => {
        if (!user.isLoggedIn) {
            navigateTo('/signin')
        }
        else if (hasActualStockClaim() && !hasClaim(Claims.StockManager))
            navigateTo('/stock/input')
        else
            navigateTo('/stock')
    }, [])
    return (<div />)
}

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
export const RouteWithSubRoutes = route => {
    let setShellVisible: (newState: boolean) => void = useContext(ShellContext)

    useEffect(() => {
        if (route.path.includes('signin') || route.path.includes('auth_callback'))
            setShellVisible(false)
        else
            setShellVisible(true)
    }, [])

    return (
        <Route
            path={route.path}
            render={props => <route.component {...props} routes={route.routes} />}
        />
    )
}
