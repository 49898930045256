import { defaultColors } from '../../infrastructure/materialUiThemeProvider'

export let fieldStatuses = {
    fieldStatusInfo: {
        '& label': {
            color: defaultColors.lightBlue.main.color
        },
        '& input': {
            borderBottomWidth: '0'
        },
        '& div[class*=MuiInput-underline]:before': {
            borderBottom: '1px solid ' + defaultColors.lightBlue.main.color
        },
        '& .Mui-focused:not(.Mui-disabled):before': {
            borderBottom: '2px solid ' + defaultColors.lightBlue.main.color
        }
    },
    fieldStatusWarning: {
        '& label': {
            color: defaultColors.orange.light.color
        },
        '& input': {
            borderBottomWidth: '0'
        },
        '& div[class*=MuiInput-underline]:before': {
            borderBottom: '1px solid ' + defaultColors.orange.light.color
        },
        '& .Mui-focused:not(.Mui-disabled):before': {
            borderBottom: '2px solid ' + defaultColors.orange.light.color
        }
    }
}