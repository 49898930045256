import React from 'react'

export function useFieldStatus<T>() {
    let [fields, setFields] = React.useState<Field[]>([])

    function initPropertyTracking(item: T | (keyof T)[] | null) {
        if (item == null) return
        let properties = Array.isArray(item) ? item : Object.keys(item) as (keyof T)[]
        fields = properties.map(x => ({
            name: x as FieldName,
            status: fields.length === 0 ? 'none' : fields.find(f => f.name === x)?.status ?? 'none'
        }))
        setFields(fields)
    }

    function setStatus(target: FieldName | FieldName[] | 'all' | any, status: FieldStatus, filter?: (field: Field) => boolean) {
        addUnknownTargets()
        let targets = getTargetedFields()
        targets.forEach(x => x.status = status)
        setFields(fields)

        function addUnknownTargets() {
            let unknownTargets = target === 'all' ? []
                : Array.isArray(target) ? target.filter(t => !fields.find(f => f.name === t))
                    : typeof target === 'string' ? (!fields.find(f => f.name === target) ? [target] : [])
                        : Object.keys(target).filter(t => !fields.find(f => f.name === t))

            fields = fields.concat(unknownTargets.map(x => ({ name: x, status: 'none' })))
        }

        function getTargetedFields() {
            let result = target === 'all' ? fields
                : Array.isArray(target) ? fields.filter(x => target.indexOf(x.name) !== -1)
                    : typeof target === 'string' ? [fields.find(x => x.name === target)!]
                        : Object.keys(target).map(x => fields.find(f => f.name === x)!)
            if (filter)
                result = result.filter(filter)
            return result
        }
    }

    function getStatus(target: FieldName) {
        return fields.find(x => x.name === target)?.status ?? 'none'
    }

    function getStatuses(targets?: FieldName[]) {
        return targets ? fields.filter(x => targets.indexOf(x.name) !== -1).map(x => x.status) : fields.map(x => x.status)
    }

    function getFieldsWithStatus(status: FieldStatus) {
        return fields.filter(x => x.status === status).map(x => x.name)
    }

    function clearStatuses() {
        setStatus('all', 'none')
    }

    return { initPropertyTracking, getStatus, getStatuses, setStatus, clearStatuses, getFieldsWithStatus }


    type FieldName = keyof T

    type Field = {
        name: FieldName
        status: FieldStatus
    }
}

export type FieldStatus = 'alert' | 'warning' | 'info' | 'none'

class ReturnTypeHelper<T> {
    Return = useFieldStatus<T>()
}

export type FieldStatusStore<T> = ReturnTypeHelper<T>['Return']