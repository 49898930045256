import React, { useState, useEffect } from 'react'
import { withStyles, createStyles, Typography, Checkbox } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { api } from '../../../infrastructure/api'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, TableItem, TextField, Select, Switch } from '../../common/customComponents'
import { MasterDataItem, MasterDataShell, createExcelLines } from './masterDataShell'
import { applyFilters } from '../../common/filtersHelper'

function SiteMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [sites, setSites] = useState<TableItem<Site>[]>([])
    let [filters, setFilters] = useState<SiteFilters>(noFilters)

    let [countrys, setCountrys] = useState<Country[]>([])

    const tBase = 'admin.masterdata.site.'

    let load = async () => {
        let sites = api.get<TableItem<Site>[]>('masterdata/site')
        setSites(await sites)
        let countrys = api.get<Country[]>('masterdata/site/country')
        setCountrys(await countrys)
    }

    let getItems = () => applyFilters(sites, filters).map(toTableItem)

    useEffect(() => {
        load()
    }, [])

    let onSave = async (item: Site & IsNew) => {
        await api.post('masterdata/site', { site: item, isNew: item.isNew })
        await load()
        return true
    }

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'Sites.xlsx',
            sheets: [{ name: 'Sites', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let onImportExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/site/import'
        api.upload(uploadUrl, file, 'site', { withReport: 'dialog' }).then(_ => load())
    }

    let getBooleanLabel = (value: boolean): string => value
        ? t(tBase + 'isYes')
        : t(tBase + 'isNo')

    let columns: ColumnDescriptor<TableItem<MasterDataItem<Site>>>[] = [
        {
            name: t(tBase + 'code'),
            value: x => x.code,
            columnFilter: { value: filters.code ?? '', onChange: (code: string) => setFilters({ ...filters, code }) }
        },
        {
            name: t(tBase + 'name'),
            value: x => x.name,
            columnFilter: { value: filters.name ?? '', onChange: (name: string) => setFilters({ ...filters, name }) }
        },
        {
            name: t(tBase + 'siteGroup'),
            value: x => x.siteGroup,
            columnFilter: { value: filters.siteGroup ?? '', onChange: (siteGroup: string) => setFilters({ ...filters, siteGroup }) }
        },
        {
            name: t(tBase + 'country'),
            value: x => x.country,
            columnFilter: { value: filters.country ?? '', onChange: (country: string) => setFilters({ ...filters, country }) }
        },
        {
            name: t(tBase + 'isSellingPoint'),
            value: x => getBooleanLabel(x.isSellingPoint),
        },
        {
            name: t(tBase + 'allocatedQuantity'),
            value: x => x.allocatedQuantity ?? '',
        },
        {
            name: t(tBase + 'unusableQuantity'),
            value: x => x.unusableQuantity ?? '',
        },
        {
            name: t(tBase + 'mktSalesPriority'),
            value: x => x.hasSiteQuantitySource ? 'Site' : 'SAP',
        },
    ]

    let isManager = hasClaim(Claims.MasterdataSiteManager);

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={'site-table'}
                headerLabel={t(tBase + 'sites')}
                itemLabel={t(tBase + 'site')}
                isManager={isManager}
                onExportExcel={exportExcel}
                onImportExcel={onImportExcel}
                onNew={emptySite}
                onSave={onSave}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <TextField label={t(tBase + 'code')}
                                disabled={true}
                                text={selectedItem.code} />
                            <TextField label={t(tBase + 'name')}
                                disabled={!isManager}
                                text={selectedItem.name}
                                onChange={event => setSelectedItem({ ...selectedItem, name: event.target.value, code: selectedItem.isNew ? event.target.value : selectedItem.code })} />
                            <TextField label={t(tBase + 'siteGroup')}
                                disabled={!isManager}
                                text={selectedItem.siteGroup}
                                onChange={event => setSelectedItem({ ...selectedItem, siteGroup: event.target.value })} />
                            <Select label={t(tBase + 'country')}
                                disabled={!isManager || !selectedItem.isNew}
                                value={selectedItem.country}
                                choices={countrys.map(x => ({ value: x.name, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, country: val }) }} />
                            <div className={classes.isBooleanContainer}>
                                <Typography variant='subtitle1'>{t(tBase + 'isSellingPoint')}</Typography>
                                <Checkbox checked={selectedItem.isSellingPoint} onChange={x => setSelectedItem({ ...selectedItem, isSellingPoint: x.target.checked })} />
                            </div>
                            <TextField label={t(tBase + 'allocatedQuantity')}
                                disabled={!isManager}
                                text={selectedItem.allocatedQuantity}
                                onChange={event => setSelectedItem({ ...selectedItem, allocatedQuantity: event.target.value })} />
                            <TextField label={t(tBase + 'unusableQuantity')}
                                disabled={!isManager}
                                text={selectedItem.unusableQuantity}
                                onChange={event => setSelectedItem({ ...selectedItem, unusableQuantity: event.target.value })} />
                            <Switch form isChecked={selectedItem.hasSiteQuantitySource}
                                changeCallback={() => setSelectedItem({ ...selectedItem, hasSiteQuantitySource: !selectedItem.hasSiteQuantitySource })}
                                offText="SAP"
                                onText="Site" />
                        </>
                    )}
            </MasterDataShell>
        </div >)
}

type Site = {
    code: string
    name: string
    siteGroup: string
    country: string
    isSellingPoint: boolean
    allocatedQuantity: string
    unusableQuantity: string
    hasSiteQuantitySource: boolean
}

type Country = {
    name: string
}

type IsNew = { isNew: boolean }

let emptySite = (): Site & IsNew => ({
    code: '',
    name: '',
    siteGroup: '',
    country: '',
    isSellingPoint: false,
    allocatedQuantity: '',
    unusableQuantity: '',
    isNew: true,
    hasSiteQuantitySource: false
})

let toTableItem = (site: Site): TableItem<MasterDataItem<Site>> => {
    return {
        id: site.code,
        code: site.code,
        name: site.name,
        siteGroup: site.siteGroup,
        country: site.country,
        isSellingPoint: site.isSellingPoint,
        allocatedQuantity: site.allocatedQuantity,
        unusableQuantity: site.unusableQuantity,
        isModified: false,
        hasSiteQuantitySource: site.hasSiteQuantitySource
    }
};

type SiteFilters = {
    code: string | null,
    name: string | null,
    siteGroup: string | null,
    country: string | null,
    allocatedQuantity: string | null,
    unusableQuantity: string | null,
}

let noFilters: SiteFilters = {
    code: null,
    name: null,
    siteGroup: null,
    country: null,
    allocatedQuantity: null,
    unusableQuantity: null,
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
        isBooleanContainer: {
            ...defaultStyles.flexRow,
            width: '15.3em',
            justifyContent: 'space-between',
            "& >span": { padding: '0' }
        }
    })
export default withStyles(styles, muiOptions)(SiteMasterData)