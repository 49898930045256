import React, { useState, useEffect } from 'react'
import { defaultStyles, muiOptions, MuiProps, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import { withStyles, createStyles, Paper, Typography } from '@material-ui/core'
import { t } from '../../infrastructure/i18nextHelper'
import { get } from '../../infrastructure/api'
import { Button, Select } from '../common/customComponents'
import { UserContextContainer } from '../../infrastructure/signIn/userContext'

function Impersonate({ classes }: MuiProps) {
    let userStore = UserContextContainer.useContainer()
    let [users, setUsers] = useState<{ username: string, name: string }[]>([])
    let [selectedUser, setSelectedUser] = useState<string | null>(null)

    useEffect(() => { load() }, [])

    let load = async () =>
        setUsers(await get<{ username: string, name: string }[]>('auth/user'))

    let impersonate = async () => {
        if (!selectedUser) return
        await userStore.impersonate(selectedUser)
        window.location.href = '/'
    }

    return (
        <div className={classes.container}>
            <Paper className={classes.paper}>
                <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                    {t('admin.impersonate.name')}
                </Typography>
                <div className={classes.fieldsContainer}>
                    <div className={classes.inputsContainer}>
                        <Button
                            className={classes.button}
                            disabled={!selectedUser}
                            label={t('admin.impersonate.button')}
                            onClick={impersonate} />
                    </div>
                    <div className={classes.inputsContainer}>
                        <Select label={t('admin.impersonate.users')}
                            value={selectedUser}
                            choices={users.map(x => ({ value: x.username, text: x.name }))}
                            onChange={x => setSelectedUser(x)} />
                    </div>
                </div>
            </Paper>
        </div>)
}

let styles = theme =>
    createStyles({
        paper: {
            width: '100%',
            padding: '1em',
            marginBottom: '1em'
        },
        container: {
            ...defaultStyles.flexColumn,
            marginTop: '2em'
        },
        field: {
            marginTop: "0.5em",
            marginBottom: "0.5em"
        },
        fieldsContainer: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between'
        },
        inputsContainer: {
            ...defaultStyles.flexRow,
        },
        button: {
            ...defaultStyles.secondaryButton,
            marginRight: '1em'
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            marginLeft: "1em"
        },
    })

export default withStyles(styles, muiOptions)(Impersonate)