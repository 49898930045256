import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { api } from '../../../infrastructure/api'
import { MasterDataShell, MasterDataItem, createExcelLines } from './masterDataShell'
import { ColumnDescriptor, TableItem, DatePicker, TextField } from '../../common/customComponents'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { t } from '../../../infrastructure/i18nextHelper'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import guid, { Guid } from '../../../infrastructure/guid'

type CalendarDay = {
    id: Guid,
    date: Date,
    description: string
}

let emptyCalendarDay = (): CalendarDay => {
    return {
        id: guid.createNew(),
        date: new Date(),
        description: ''
    }
}

let toTableItem = (day: CalendarDay): TableItem<MasterDataItem<CalendarDay>> => {
    return {
        id: day.id,
        date: day.date,
        description: day.description,
        isModified: false
    }
}

function CalendarMasterData() {
    let [calendarDays, setCalendarDays] = useState<CalendarDay[]>([])
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let load = async () => {
        setCalendarDays(await api.get<CalendarDay[]>(`masterdata/calendar`))
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<CalendarDay>>>[] = [
        { name: t('admin.masterdata.calendar.date'), value: x => x.date == null ? '' : moment(x.date).format('MM/DD/YYYY') },
        { name: t('admin.masterdata.calendar.description'), value: x => x.description }
    ]

    useEffect(() => { let effect = async () => { await load() }; effect() }, [])

    let calendarLabel = t('admin.masterdata.calendar.calendar')

    let isManager = hasClaim(Claims.MasterdataCalendarManager)

    let onSave = async (item: CalendarDay) => {
        await api.post('masterdata/calendar', item)
        await load()
        return true
    }

    let getItems = () => calendarDays.map(x => toTableItem(x))

    let exportExcel = async () => {
        excelGenerator.generate({
            filename: 'calendar.xlsx',
            sheets: [{ name: 'calendar', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let importExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/calendar/import'
        api.upload(uploadUrl, file, 'import').then(_ => load())
    }

    let onDelete = async (ids: string[]) => {
        if (ids.length === 0) return false
        await api.del('masterdata/calendar', { ids: ids })
        await load()
        return true
    }

    return (
        <MasterDataShell
            headerLabel={calendarLabel}
            itemLabel={calendarLabel}
            isManager={isManager}
            onExportExcel={exportExcel}
            onImportExcel={importExcel}
            onNew={() => emptyCalendarDay()}
            onDelete={onDelete}
            onSave={onSave}
            items={getItems()}
            columns={columns}>
            {
                (selectedItem, setSelectedItem) => (
                    <>
                        <DatePicker date={selectedItem.date}
                            label={t('admin.masterdata.calendar.date')}
                            setDate={date => setSelectedItem({ ...selectedItem, date })} />

                        <TextField label={t('admin.masterdata.calendar.description')}
                            disabled={!isManager}
                            text={selectedItem.description}
                            onChange={event => setSelectedItem({ ...selectedItem, description: event.target.value })} />
                    </>
                )}
        </MasterDataShell>)
}

let styles = () => createStyles({})

export default withStyles(styles, muiOptions)(CalendarMasterData)