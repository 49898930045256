import React from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, createStyles, withStyles } from '@material-ui/core'
import { dialogCell, muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import moment from 'moment'

type Props = {
    items: TableItem[]
    classes?: any
}

export type TableItem = {
    username: string
    date: Date
    field: string
    oldValue: string
    newValue: string
    where?: string
}

let tBase = 'history.table.'

function _historyTable(props: Props) {
    if (props.items.length === 0)
        return (<span className={props.classes?.italic + ' ' + props.classes?.comment}>{t(tBase + 'empty')}</span>)

    let hasWhere = props.items.some(x => x.where)
    let hasValues = props.items.some(x => x.oldValue) || props.items.some(x => x.newValue)

    return (
        <TableContainer component={Paper}>
            <Table className={props.classes?.table} aria-label='history table'>
                <TableHead>
                    <TableRow>
                        <TableCell className={props.classes?.cell}>{t(tBase + 'user')}</TableCell>
                        <TableCell className={props.classes?.cell}>{t(tBase + 'date')}</TableCell>
                        <TableCell className={props.classes?.cell}>{t(tBase + 'field')}</TableCell>
                        {hasValues ? <TableCell className={props.classes?.cell}>{t(tBase + 'old')}</TableCell> : null}
                        {hasValues ? <TableCell className={props.classes?.cell}>{t(tBase + 'new')}</TableCell> : null}
                        {hasWhere ? <TableCell className={props.classes?.cell}>{t(tBase + 'where')}</TableCell> : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.items.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell className={props.classes?.cell} component='th' scope='row'>
                                {item.username}
                            </TableCell>
                            <TableCell className={props.classes?.cell}>{moment(item.date).format('MM/DD/YYYY HH:mm')}</TableCell>
                            <TableCell className={props.classes?.cell}>{item.field !== '*' ? item.field : 'Creation'}</TableCell>
                            {hasValues ? <TableCell className={props.classes?.cell + ' ' + props.classes?.italic}>{item.oldValue}</TableCell> : null}
                            {hasValues ? <TableCell className={props.classes?.cell + ' ' + props.classes?.italic}>{item.newValue}</TableCell> : null}
                            {hasWhere ? (<TableCell className={props.classes?.cell}>{item.where ?? ''}</TableCell>) : null}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

let styles = theme => createStyles({
    cell: dialogCell,
    italic: {
        fontStyle: 'italic'
    },
    comment: {
        color: 'silver',
        fontSize: '0.8em',
        fontFamily: 'Roboto',
        margin: '4em'
    }
})

export let HistoryTable = withStyles(styles, muiOptions)(_historyTable)