import React, { ReactElement } from 'react'
import { InfoOutlined } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { useTour, StepType, } from '@reactour/tour'
import { ContentProps } from '@reactour/tour/dist/types'

export type GuidedTourStep = {
    key: string,
    isActive?: boolean
    content: ReactElement | string | ((props: ContentProps) => void)
    watchKeysForHighlight?: string[]
    byPassElem?: boolean
}

function _GuidedTourButton({ steps }: { steps: () => GuidedTourStep[] }) {
    const { setIsOpen, setSteps } = useTour();

    let onClick = () => {
        let mappedSteps: StepType[] = steps().filter(x => x.isActive || x.isActive === undefined)
            .map(x => {
                let highlightKeys = x.watchKeysForHighlight?.map(x => `[data-tour="${x}"]`)
                return ({
                    selector: `[data-tour="${x.key}"] `,
                    content: x.content,
                    highlightedSelectors: highlightKeys,
                    mutationObservables: highlightKeys,
                    resizeObservables: highlightKeys,
                    bypassElem: x.byPassElem
                })
            })

        setSteps(mappedSteps)
        setIsOpen(true)
    }

    return (
        <IconButton onClick={onClick} size={'small'}>
            <InfoOutlined style={{ width: '0.95em', height: '0.95em' }} />
        </IconButton>
    )
}

export let GuidedTourButton = _GuidedTourButton
