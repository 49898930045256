import React from 'react'
import { createContainer } from 'unstated-next'
import { popupNavigator } from '../../popupNavigator'
import { api } from '../../api'
import { Notification, NotificationView } from './models'
import { useActionDebounce } from '../../../app/common/debounce'
import { UserContextContainer } from '../../signIn/userContext'
import { Guid } from '../../guid'
import { t } from '../../i18nextHelper'

const fifteenSeconds = 15 * 1000

function mapToView(notification: Notification): NotificationView {
    return {
        subtitle: notification.description,
        daysAgo: getDaysAgo(notification.date),
        open: getOpen(notification.relatedId)
    }
}

function getDaysAgo(date: Date) {
    let alertDate = new Date(date)
    let today = new Date()
    let daysDiff = (today.getTime() - alertDate.getTime()) / oneDay

    return daysDiff < 1 ? t('notification.today') :
        daysDiff > 1 && daysDiff < 2
            ? Math.round(daysDiff).toString() + ' ' + t('notification.dayAgo')
            : Math.round(daysDiff).toString() + ' ' + t('notification.daysAgo')
}

function getOpen(relatedId: Guid) {
    return () => popupNavigator.open('movement', relatedId)
}

const oneDay = 24 * 60 * 60 * 1000

function useNotifications() {
    let [list, setList] = React.useState<NotificationView[]>([])
    let [isOpen, setIsOpen] = React.useState<boolean>(false)
    let userContext = UserContextContainer.useContainer()

    React.useEffect(() => { load() }, [userContext.isLoggedIn])

    let loadDebounced = useActionDebounce(load, fifteenSeconds)

    async function load() {
        if (!userContext.isLoggedIn) return
        let result = await api.get<Notification[]>('stock/movement/notification')
        let views = result.map(mapToView)
        setList(views)
    }

    return { list, isOpen, setIsOpen, load: loadDebounced.execute }
}

export let NotificationContainer = createContainer(useNotifications)
export type NotificationStore = ReturnType<typeof useNotifications>