import React, { RefObject, useRef, useState, useImperativeHandle, useEffect } from 'react'
import {
    createStyles, withStyles, Typography, Tooltip, Divider,
    Dialog, DialogTitle, DialogContent, DialogActions, Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core'
import { CheckCircle, CheckCircleOutline, ExpandMore } from '@material-ui/icons'
import { muiOptions, MuiProps, defaultStyles, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { ValidationStepper, TextField, Select, Button } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import * as api from '../../../infrastructure/api'
import { FeatureContainer } from '../../../infrastructure/feature'
import { ValidationProcessForm, Deal, CollapsableSection } from '../dealModels'
import { DealContainer } from './dealEditStore'

export function ValidationProcessContainer({ classes }: MuiProps) {
    let feature = FeatureContainer.useContainer()
    let store = DealContainer.useContainer()
    let deal = store.deal as Deal
    let dependencies = [deal?.provisionalPricesValidation, deal?.finalPricesValidation]

    let [processStates, setProcessStates] = useState<ValidationProcessState[]>(calculateAccordionStatuses(dependencies))

    useEffect(() => { refreshAccordionStatuses() }, [...dependencies, deal?.id])

    async function refreshAccordionStatuses() {
        let states = calculateAccordionStatuses(dependencies)
        for (let i = 0; i < states.length; i++) {
            let state = states[i]
            let isStarted = state.form.status !== 'New'
            let hasNextStarted = i < states.length - 1 && states[i + 1].form.status !== 'New'
            state.userCanRefuseStep = isStarted && !hasNextStarted
            state.userCanValidateStep = await api.get<boolean>(`deal/canValidate/${store.deal!.id}/${states[i].form.type}`)
        }

        setProcessStates(states)
    }

    let validateStep = async (type: string, comment: string | null) => {
        try {
            await api.post(`deal/validate`, { deal: store.deal, comment, validationProcessName: type })
        } catch (err) {
            if (err.status !== 409) throw err
        }
        await store.loadDeal(store.dealId)
    }

    let refuseStep = async (type: string, comment: string | null) => {
        try {
            await api.post(`deal/refuse`, { deal: store.deal, comment, validationProcessName: type })
        } catch (err) {
            if (err.status !== 409) throw err
        }
        await store.loadDeal(store.dealId)
    }

    let updateStep = async (validationProcess: ValidationProcessForm, validatorLogin: string, comment: string | null, stepName: string) => {
        let validationStepIndex = validationProcess.validationSteps.findIndex(x => x.name == stepName)
        validationProcess.validationSteps[validationStepIndex].comment = comment
        validationProcess.validationSteps[validationStepIndex].validatorLogin = validatorLogin
        store.setDeal({ ...deal })
    }

    return (
        <div>
            {processStates.map((x, i) =>
                <ValidationAccordion
                    key={i} state={x} classes={classes}
                    validateStep={validateStep} refuseStep={refuseStep} updateStep={updateStep}
                />)}
        </div>
    )
}

type ValidationAccordionProps = {
    state: ValidationProcessState
    validateStep: (type: string, comment: string | null) => Promise<void>
    refuseStep: (type: string, comment: string | null) => Promise<void>
    updateStep: (validationProcess: ValidationProcessForm, validatorLogin: string, comment: string | null, stepName: string) => Promise<void>
    classes?: any
}

function ValidationAccordion(props: ValidationAccordionProps) {
    let store = DealContainer.useContainer()
    let { state, validateStep, refuseStep, updateStep, classes } = props
    if (!state.form) return (<></>)

    function toggleAccordion() {
        store.toggleSection(state.form.type as CollapsableSection)
    }

    return (
        <Accordion TransitionProps={{ unmountOnExit: true }} expanded={store.openSections[state.form.type]}>
            <AccordionSummary expandIcon={<ExpandMore />}
                onClick={toggleAccordion}>
                <Typography className={classes.accordionTitle + ' MuiTypography-overline'}>
                    {state.title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ValidationInformations
                    classes={classes}
                    validationProcess={state.form}
                    validate={validateStep}
                    refuse={refuseStep}
                    canRefuse={state.userCanRefuseStep}
                    canValidate={state.userCanValidateStep}
                    updateStep={(validatorName, comment, stepName) =>
                        updateStep(state.form, validatorName, comment, stepName)} />
            </AccordionDetails>
        </Accordion>)
}

type ValidationProcessState = {
    form: ValidationProcessForm
    title: string
    userCanValidateStep: boolean
    userCanRefuseStep: boolean
}

function calculateAccordionStatuses(forms: (ValidationProcessForm | null)[]): ValidationProcessState[] {
    return forms.reduce(reducer, [])

    function reducer(accumulator: ValidationProcessState[], currentValue: ValidationProcessForm | null): ValidationProcessState[] {
        if (!currentValue) return accumulator
        accumulator.push({
            form: currentValue,
            title: t(`deals.label.validation.${currentValue.type.contains('rovisional') ? 'provisional' : 'final'}Title`),
            userCanValidateStep: false,
            userCanRefuseStep: false,
        })
        return accumulator
    }

    function isLastStep(process: ValidationProcessForm | null): boolean {
        if (!process?.steps.length) return false
        return process.steps.findIndex(x => x === process.status) === process.steps.length - 1
    }
}

let dialogRef: RefObject<{ open: () => void }> | null = null

type ValidationInformationsProps = {
    validationProcess: ValidationProcessForm
    validate: (type: string, comment: string | null) => void
    refuse: (type: string, comment: string | null) => void
    canValidate: boolean
    canRefuse: boolean
    updateStep: (validator: string, comment: string | null, stepName: string) => void
}

function ValidationInformations({ validationProcess, validate, refuse, canValidate, canRefuse, updateStep, classes }: ValidationInformationsProps & MuiProps) {
    let [dialogProps, setDialogProps] = useState<ValidationDialogProps | null>(null)

    let openConfirmWhenPropsAreSet = () => {
        if (dialogProps != null)
            dialogRef?.current?.open()
    }
    useEffect(() => openConfirmWhenPropsAreSet(), [dialogProps])

    let validatedStepPrefix = 'deals.label.validation.done.'
    let futureStepPrefix = 'deals.label.validation.waiting.'

    let currentStepIndex = validationProcess.steps.findIndex(x => x === validationProcess.status)

    let validationStepsForStepper = validationProcess.steps.slice(1).map((x, i) => {
        return i + 1 <= currentStepIndex ?
            {
                label: t(validatedStepPrefix + x),
                icon: <CheckCircle key={i} />
            }
            : {
                label: t(futureStepPrefix + x),
                icon: <CheckCircleOutline key={i} />
            }
    })

    let getComment = () => {
        let index = validationProcess.validationSteps.findIndex(x => x.name == validationProcess.steps[currentStepIndex + 1])
        return index === -1 ? null : validationProcess.validationSteps[index].comment
    }

    let validateStep = async () => {
        let nextStepIndex = currentStepIndex + 2
        let nextStep = nextStepIndex < validationProcess.steps.length - 1 ? validationProcess.steps[nextStepIndex] : null
        let nextValidationStep = validationProcess.validationSteps.first(x => x.name === nextStep)
        let validator = nextValidationStep?.allValidators.first(x => x.login === nextValidationStep?.validatorLogin)?.name

        let validateDialogProps: ValidationDialogProps = {
            title: t('deals.label.validation.validateTitle'),
            content: !!validator ? t('deals.label.validation.validateContent', { validator: validator }) : '',
            validationButtonText: t('deals.label.validation.validate'),
            onConfirm: () => validate(validationProcess.type, getComment()),
            onClose: () => setDialogProps(null),
            classes: classes
        }
        setDialogProps(validateDialogProps)
    }

    let refuseStep = async () => {
        let refuseDialogProps: ValidationDialogProps = {
            title: t('deals.label.validation.refuseTitle'),
            content: t('deals.label.validation.refuseContent'),
            validationButtonText: t('deals.label.validation.refuse'),
            onConfirm: () => refuse(validationProcess.type, getComment()),
            onClose: () => setDialogProps(null),
            classes: classes
        }
        setDialogProps(refuseDialogProps)
    }

    let isCommentDisabled = (stepName: string) => {
        let stepIndex = validationProcess.steps.findIndex(x => x == stepName)
        return stepIndex !== currentStepIndex + 1
    }

    let isValidatorDisabled = (stepName: string) => {
        let stepIndex = validationProcess.steps.findIndex(x => x == stepName)
        return stepIndex <= currentStepIndex
    }

    let stepValidatorOnError = (validatorLogin: string) =>
        validationProcess.validationSteps.filter(x => x.validatorLogin == validatorLogin).length > 1

    let allStepsHaveDifferentValidators = () =>
        !!validationProcess.validationSteps
        && validationProcess.validationSteps.length > 0
        && validationProcess.validationSteps.map(x => x.validatorLogin).distinct().length == validationProcess.validationSteps.length

    return (
        <div className={classes.validationStepperContainer}>
            <ValidationStepper steps={validationStepsForStepper.map(x => x.label)}
                activeStep={currentStepIndex - 1}
                icons={validationStepsForStepper.map(x => x.icon)} />
            <div className={classes.row}>
                <Divider orientation='vertical' flexItem className={classes.divider} />
                {validationProcess.validationSteps.map((x, i) =>
                    <div key={i}>
                        <div className={classes.validators}>
                            <Typography className={classes.stepName} variant='overline' display='block' gutterBottom>{t(`deals.label.validation.step.${x.name}`)}</Typography>
                            <Select label={t('deals.label.validation.validator')}
                                value={x.validatorLogin}
                                choices={x.allValidators.map(x => ({ value: x.login, text: x.name }))}
                                onChange={val => updateStep(val, x.comment, x.name)}
                                disabled={isValidatorDisabled(x.name)}
                                onError={stepValidatorOnError(x.validatorLogin)} />
                            <TextField label={t('deals.label.validation.comment')}
                                text={x.comment}
                                onChange={event => updateStep(x.validatorLogin, event.target.value, x.name)}
                                fullWidth={true}
                                disabled={isCommentDisabled(x.name)} />
                        </div>
                        <Divider orientation='vertical' flexItem className={classes.divider} />
                    </div>)
                }
            </div>
            <div className={classes.row}>
                <Button className={classes.button}
                    label={t('deals.label.validation.refuse')}
                    onClick={refuseStep}
                    disabled={!canRefuse} />
                {!allStepsHaveDifferentValidators()
                    ? <Tooltip title={<Typography variant='body1'>{t('deals.label.validation.sameValidatorBlocking')}</Typography>} placement='top'>
                        <span>
                            <Button className={classes.button}
                                label={t('deals.label.validation.validate')}
                                disabled={true} />
                        </span>
                    </Tooltip>
                    : <Button className={classes.button}
                        label={t('deals.label.validation.validate')}
                        onClick={validateStep}
                        disabled={!canValidate} />
                }
            </div>
            <ValidationDialog title={dialogProps?.title ?? ''}
                content={dialogProps?.content ?? ''}
                validationButtonText={dialogProps?.validationButtonText ?? ''}
                onConfirm={dialogProps?.onConfirm ?? (() => { })}
                onClose={dialogProps?.onClose ?? (() => { })}
                classes={dialogProps?.classes ?? classes} />
        </div>
    )
}

type ValidationDialogProps = {
    title: string,
    content: string,
    validationButtonText: string,
    onConfirm: () => void,
    onClose: () => void
} & MuiProps

function ValidationDialog(props: ValidationDialogProps) {
    let state = useValidationDialog()

    let confirm = () => {
        props.onConfirm();
        state.close();
    }

    return (
        <Dialog open={state.isOpen} onClose={close} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent className={props.classes.dialogContent}>
                <Typography>{props.content}</Typography>
            </DialogContent>
            <DialogActions className={props.classes.marginTop1em}>
                <Button onClick={state.close}
                    label={t('components.dialogClose')}
                    color='primary'
                    className={props.classes.cancelButton} />
                <Button onClick={confirm}
                    label={props.validationButtonText}
                    color='primary'
                    className={props.classes.confirmButton} />
            </DialogActions>
        </Dialog>
    )
}

function useValidationDialog() {
    let [isOpen, setIsOpen] = useState<boolean>(false)

    dialogRef = useRef<{ open: () => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: () => { setIsOpen(true) }
    }))

    function close() {
        setIsOpen(false)
    }

    return { isOpen, close }
}

let styles = () =>
    createStyles({
        paper: {
            padding: '0.5em',
            marginTop: '1em',
            marginBottom: '0.5em'
        },
        titleRow: {
            ...defaultStyles.flexRow
        },
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'center',
            marginTop: '2em'
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            margin: '1em'
        },
        accordionTitle: {
            color: defaultColors.red.main.color
        },
        validationStepperContainer: {
            width: '100%',
            marginTop: '-2em'
        },
        validators: {
            ...defaultStyles.flexColumn,
            width: '20em',
            alignItems: 'flex-start',
            '& > div': {
                marginTop: '0.5em',
                marginBottom: '0.5em'
            }
        },
        button: {
            ...defaultStyles.secondaryButton,
            marginRight: '3em'
        },
        divider: {
            margin: '0px 5px'
        }
    })

export let ValidationProcess = withStyles(styles, muiOptions)(ValidationProcessContainer)