export function copyHtmlElement(el) {
    const htmlTree = document.getElementById(el);
    const tempDiv = document.createElement('div');
    tempDiv.contentEditable = 'true';
    tempDiv.style.position = 'absolute';
    tempDiv.style.left = '-9999px';
    if (htmlTree?.outerHTML)
        tempDiv.innerHTML = htmlTree.outerHTML;

    document.body.appendChild(tempDiv);
    const range = document.createRange();
    range.selectNodeContents(tempDiv);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);

    document.execCommand('copy');
    document.body.removeChild(tempDiv);
}