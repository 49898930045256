import React from "react"
import { RefObject, useImperativeHandle, useRef, useState } from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core'
import { Button } from '../common/customComponents'
import { t } from '../../infrastructure/i18nextHelper'
import PrimoExport from './_primoExport'

export let exportPrimoDialogRef: RefObject<{ open: () => void }> | null = null

export function PrimoExportDialog({ startDate, pricingMonth }) {
    let [isOpen, setIsOpen] = useState<boolean>(false)
    let [doExport, setDoExport] = useState<boolean>(false)

    let close = () => {
        setDoExport(false)
        setIsOpen(false)
    }

    let confirm = () => {
        setDoExport(true)
    }

    exportPrimoDialogRef = useRef<{ open: () => void }>(null)

    useImperativeHandle(exportPrimoDialogRef, () => ({
        open: () => { setIsOpen(true) }
    }))

    let exportFinishedHandler = (closeDialog: boolean) => {
        setDoExport(false)
        if (closeDialog)
            setIsOpen(false)
    }

    return (
        <Dialog open={isOpen} onClose={close} fullWidth={false} maxWidth={'sm'}>
            <DialogTitle>{t('mktRiskExposure.exportDialog.title')}</DialogTitle>
            <DialogContent >
                <Typography variant='body1'>
                    <PrimoExport doExport={doExport} exportFinishedHandler={exportFinishedHandler} defaultForcastStartDate={startDate}
                        defaultFromMonth={pricingMonth} />
                </Typography>
            </DialogContent>
            <DialogActions >
                <Button onClick={close}
                    label={t('mktRiskExposure.exportDialog.cancel')}
                />
                <Button onClick={confirm}
                    label={t('mktRiskExposure.exportDialog.export')}
                    color='primary'
                />
            </DialogActions>
        </Dialog>
    )
}