import React, { useImperativeHandle, useState, useEffect, useRef, RefObject } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, createStyles, withStyles } from '@material-ui/core'
import { muiOptions, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import * as api from '../../../infrastructure/api'
import { t } from '../../../infrastructure/i18nextHelper'
import { Button, DataTable } from '../../common/customComponents'
import { getColumns } from '../_movementMessageTable'
import { MessageMovement } from '../stockModels'

type Reference = { reference: string }

function _movementMessageHistory({ classes }) {
    const [open, setOpen] = useState<boolean>(false)
    const [reference, setReference] = useState<string | null>()
    const [messageMovements, setMessageMovements] = useState<MessageMovement[]>([])

    dialogRef = useRef<{ open: (entity: Reference) => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: (entity: Reference) => {
            setReference(entity.reference)
            setOpen(true)
        }
    }))

    async function load() {
        if (!open) return
        setMessageMovements([])
        setMessageMovements(await api.get<MessageMovement[]>('stock/movementMessage/movement', { reference }))
    }

    useEffect(() => { load() }, [open])

    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogTitle>{t('stock.label.movementMessageHistoryTitle')}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant='body1'>
                    <DataTable
                        isSelectable={false}
                        items={messageMovements}
                        tableId={'message-movement-history'}
                        idSelector={(x: MessageMovement) => x.id}
                        columns={getColumns('All')}
                    />
                </Typography>
            </DialogContent>
            <DialogActions className={classes.marginTop1em}>
                <Button onClick={() => setOpen(false)}
                    label={t('components.dialogClose')}
                    color='primary'
                    className={classes.cancelButton} />
            </DialogActions>
        </Dialog>
    )
}

let dialogRef: RefObject<{ open: (entity: Reference) => void }> | null = null
export let movementMessageHistoryDialog = { open: (entity: Reference) => dialogRef?.current?.open(entity) }

let styles = theme => createStyles({})

export let MovementMessageHistory = withStyles(styles, muiOptions)(_movementMessageHistory)