import moment from 'moment'
import React, { useEffect } from 'react'
import { createStyles, withStyles, Paper, Typography, Tooltip, Input, LinearProgress } from '@material-ui/core'
import { muiOptions, defaultStyles, defaultColors, } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import * as api from '../../../infrastructure/api'
import { Claims } from '../../../infrastructure/signIn/models'
import { snackbars } from '../../../infrastructure/snackbars'
import { hasActualStockClaim, hasClaim, UserContextContainer } from '../../../infrastructure/signIn/userContext'
import { hasFeature } from '../../../infrastructure/feature'
import { DatePicker, AttachFileButton, Button, LabelButton, GroupedMultiSelect } from '../../common/customComponents'
import { ActualStockContainer } from './actualStockStore'
import ActualStockTable from './actualStockTable'
import { CommentButton } from '../../common/comment/commentButton'
import { accountingPeriodDialog, closedColor, frozenColor, openedColor } from './accountingPeriod/accountingPeriodDialog'
import { allowedExtensions } from '../../common/fieldProps'
import { LoaderStatusContainer } from '../../../infrastructure/loader'
import { AccountingPeriodStatus } from '../stockModels'
import { CommentContent } from '../../common/comment/commentContent'

function ActualStockEditContent({ classes, isInPopup }: { classes: any, isInPopup: boolean }) {
    let store = ActualStockContainer.useContainer()
    let user = UserContextContainer.useContainer()
    let loader = LoaderStatusContainer.useContainer()

    let filtersSet = store.stockInput.date && store.stockInput.site

    useEffect(() => { store.init() }, [])

    let onCalibrate = async () => {
        await api.post('stock/calibrate', store.stockInput)
        store.loadStock()
        snackbars.success(t('httpSuccess.calibrationDone'))
    }

    let cancelCalibration = async () => {
        await api.post('stock/calibrate/cancel', store.stockInput)
        store.loadStock()
    }

    let onSave = async () => {
        store.onSave()
    }

    let calibrationDisabled = (): boolean => !store.availableCompanies
        || !store.availableProducts
        || !store.stockInput

    let handleStockInputDate = (date: string | null) => {
        if (date) {
            store.setStockInput({ ...store.stockInput, date: date })
        }
    }

    let importStock = (e, isCalibratedStock: boolean) => {
        api.upload(`stock/import/${!isCalibratedStock ? 'actual' : 'calibrated'}`, e.target.files[0], 'import', { withReport: 'dialog' })
            .then(_ => {
                snackbars.success(t('httpSuccess.importSuccessful'))
                if (store.stockInput.date && store.stockInput.site)
                    store.loadStock()
            })
    }

    let formatCommentAssociationKey = (): string => {
        let site = store?.stockInput.site
        let formatedDate = moment(store?.stockInput.date).format('YYYY/MM/DD')
        if (!site || !formatedDate) return ''
        return `actualStock-${site}-${formatedDate}`
    }

    let openAccountingPeriodDialog = () => {
        if (!store.stockInput.site || !store.stockInput.date) return

        let accountingPeriodArgs = {
            country: user.currentCountry,
            site: store.stockInput.site,
            date: store.stockInput.date
        }

        accountingPeriodDialog.open(accountingPeriodArgs)
    }

    let accountingPeriodStatus = (status: AccountingPeriodStatus) => {
        let options = { text: '', className: '' }
        switch (status) {
            case 'Opened': options = { text: 'stock.label.openedStatus', className: classes.openedField }; break;
            case 'Frozen': options = { text: 'stock.label.frozenStatus', className: classes.frozenField }; break;
            case 'Closed': options = { text: 'stock.label.closedStatus', className: classes.closedField }; break;
            default: options = { text: '', className: '' };
        }

        return <Typography variant='h6' className={options.className}>{t(options.text)}</Typography>
    }

    return (
        <>
            {loader.isBackgroundActive
                && <LinearProgress classes={{ barColorPrimary: classes.progressBar }}
                    className={classes.loader} />}
            <div>
                <Paper className={classes.paperFilter}>
                    <div>
                        <div className={classes.filters}>
                            <GroupedMultiSelect label={t("stock.popup.site")} singleChoice
                                values={store?.stockInput?.site} disableNewStyle
                                choices={store.sites.map(x => ({ value: x.code, text: x.name, group: x.siteGroup }))}
                                onChange={val => store.setStockInput({ ...store.stockInput, site: val })}
                                disabled={loader.isBackgroundActive} />
                            <DatePicker date={store.stockInput.date || null} label={t("stock.popup.date")}
                                setDate={handleStockInputDate} disableNewStyle
                                disabled={loader.isBackgroundActive} />
                        </div >
                        <div className={classes.commentRow}>
                            {store?.stockInput?.site &&
                                <>
                                    <CommentContent associationKey={formatCommentAssociationKey()} />
                                    <CommentButton withPadding associationKey={formatCommentAssociationKey()} />
                                </>
                            }
                        </div>
                    </div>
                    {!!store.stockInput.accountingPeriodStatus && accountingPeriodStatus(store.stockInput.accountingPeriodStatus)}
                    {filtersSet && store.stockInput.isCalibration && hasClaim(Claims.ActualStockSiteValidator)
                        ? <Tooltip title={<Typography variant='body1'>{t('stock.label.calibrationTrackLabel',
                            {
                                calibrationDate: moment(store.stockInput.calibratedAt).format('MM/DD/YYYY'),
                                user: store.stockInput.calibratedBy
                            })}</Typography>} placement='top'>
                            <Typography variant='h6' className={classes.calibrationText}>{t('stock.label.calibrations')}</Typography>
                        </Tooltip>
                        : null}
                    <div className={classes.headButtons}>
                        {hasClaim(Claims.ActualStockSiteWriter) &&
                            <>
                                {hasFeature('ImportSam')
                                    ? <>
                                        <Button
                                            label={t('stock.accountingPeriodsDialog.title')}
                                            onClick={openAccountingPeriodDialog}
                                            disabled={!store.stockInput.date || !store.stockInput.site}
                                            className={classes.importButton} />
                                        <LabelButton className={classes.importButton}
                                            classes={{ label: classes.buttonFullHeightLabel }}
                                            img='/static/images/excel_red.svg'
                                            label={t('stock.label.stocks.import.actualStock')}>
                                            <Input style={{ display: 'none' }} type='file' onChange={e => { importStock(e, false); e.target.value = ""; }}
                                                inputProps={allowedExtensions} />
                                        </LabelButton>

                                    </>
                                    : !store.stockInput.isCalibration &&
                                    <>
                                        {
                                            hasFeature('ImportCalibratedStock') &&
                                            <LabelButton className={classes.importButton}
                                                classes={{ label: classes.buttonFullHeightLabel }}
                                                img='/static/images/excel_red.svg'
                                                label={t('stock.label.stocks.import.calibratedStock')}>
                                                <Input style={{ display: 'none' }} type='file' onChange={e => { importStock(e, true); e.target.value = ""; }}
                                                    inputProps={allowedExtensions} />
                                            </LabelButton>
                                        }
                                        {
                                            hasFeature('ImportActualStock') &&
                                            <LabelButton className={classes.importButton}
                                                classes={{ label: classes.buttonFullHeightLabel }}
                                                img='/static/images/excel_red.svg'
                                                label={t('stock.label.stocks.import.actualStock')}>
                                                <Input style={{ display: 'none' }} type='file' onChange={e => { importStock(e, false); e.target.value = ""; }}
                                                    inputProps={allowedExtensions} />
                                            </LabelButton>
                                        }
                                    </>

                                }
                            </>
                        }
                        {
                            filtersSet && hasActualStockClaim()
                                ? <AttachFileButton
                                    key={`${store.stockDocumentLoader}-${store.stockInput.site}-${moment(store.stockInput.date ?? '').format('YYYY-MM-DD')}`}
                                    id='upload-doc-button'
                                    className={classes.documentButton}
                                    title={hasFeature('OpeningActualStock') ? t('stock.label.documentDialogTitleOpening') : t('stock.label.documentDialogTitleClosing')}
                                    disableDelOrAdd={!hasClaim(Claims.ActualStockSiteWriter) && !hasClaim(Claims.ActualStockSiteValidator)}
                                    context={user.currentCountry}
                                    keyTemplate='stock-{stockInputSite}-{stockInputDate}'
                                    keyParameters={{ stockInputSite: store.stockInput.site ?? '', stockInputDate: moment(store.stockInput.date ?? '').format('YYYY-MM-DD') }}
                                    secondaryAssociationKeys={store.countrys()
                                        .map(company => `stock-${company}-${moment(store.stockInput.date ?? '').format('YYYY-MM-DD')}`)
                                    } />
                                : null
                        }
                        {
                            filtersSet && hasClaim(Claims.ActualStockSiteValidator) && (!store.stockInput.isCalibration)
                                ? calibrationDisabled()
                                    ? <Tooltip title={<Typography variant='body1'>{t('stock.label.calibrationProvisionalDisabled')}</Typography>} placement='top' >
                                        <span>
                                            <Button className={classes.calibrationButton}
                                                label={t('stock.label.calibrate')}
                                                disabled={true} />
                                        </span>
                                    </Tooltip>
                                    : <Button className={classes.calibrationButton}
                                        label={t('stock.label.calibrate')}
                                        onClick={onCalibrate} />
                                : null
                        }
                        {
                            filtersSet && hasClaim(Claims.ActualStockSiteValidator) && store.stockInput.isCalibration
                                ? <Button className={classes.calibrationButton}
                                    label={t('stock.label.cancelCalibration')}
                                    onClick={cancelCalibration} />
                                : null
                        }
                        {
                            filtersSet && hasClaim(Claims.ActualStockSiteWriter) && !isInPopup
                                ? <Button
                                    label={t('components.updateButton')}
                                    onClick={onSave}
                                    className={classes.updateButton} />
                                : null
                        }
                    </div>
                </Paper>
                {
                    filtersSet && store.availableCompanies && store.availableProducts
                    && <ActualStockTable />
                }
            </div>
        </>
    )
}

let styles = () =>
    createStyles({
        paperFilter: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            height: 'auto',
            padding: '0.5em',
            margin: '0.5em 0em 0.5em 0em',
            alignItems: 'start'
        },
        headButtons: {
            ...defaultStyles.flexRow
        },
        calibrationButton: {
            ...defaultStyles.secondaryButton
        },
        importButton: {
            ...defaultStyles.secondaryButton,
            marginRight: '0.5em'
        },
        documentButton: {
            marginRight: '0.5em'
        },
        calibrationText: {
            color: defaultColors.darkBlue.main.color,
            cursor: 'pointer'
        },
        updateButton: {
            ...defaultStyles.primaryButton,
            marginLeft: '1em'
        },
        closedField: {
            color: closedColor,
            fontWeight: 'bold'
        },
        openedField: {
            color: openedColor,
            fontWeight: 'bold'
        },
        frozenField: {
            color: frozenColor,
            fontWeight: 'bold'
        },
        filters: {
            display: 'flex',
            alignItems: 'baseline'
        },
        stockLoader: {
            marginLeft: '0.5em'
        },
        loader: {
            backgroundColor: 'white',
            height: '0.19em',
            left: '-1em',
            width: '110%'
        },
        progressBar: {
            backgroundColor: defaultColors.darkBlue.main.color
        },
        commentRow: {
            ...defaultStyles.flexRow,
            marginTop: '15px',
            justifyContent: 'flex-start',
            alignItems: 'center',
            maxWidth: '32em'
        }
    })

export default withStyles(styles, muiOptions)(ActualStockEditContent)