import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions, MuiProps, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { Button, MonthPicker } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import { formatFilename } from '../../../infrastructure/excelExport'
import * as api from '../../../infrastructure/api'
import { reportStyles, ReportShell } from './_common'

function _overstayAgeing({ classes }: MuiProps) {

    let generateIncrements = async () => {
        let filename = formatFilename('increments.xlsx')
        await api.post(`report/increment`, { filename: filename })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')
    }

    return (
        <ReportShell title={t('report.increments')} displayError={false}>
            <div className={classes.form}>
                <Button className={classes.generateButton}
                    label={t('report.export')}
                    img='/static/images/excel_red.svg'
                    onClick={() => generateIncrements()} />
            </div>
        </ReportShell>
    )
}

let styles = theme => createStyles({
    generateButton: defaultStyles.secondaryButton,
    datePicker: reportStyles.datePicker,
    form: reportStyles.form,
})

export default withStyles(styles, muiOptions)(_overstayAgeing)