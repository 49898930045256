import { useMemo } from "react"
import { SortStatus, TableItem } from "./table"

type UseSortTableProps<T> = {
    items: (T | TableItem<T>)[]
    sortStatus?: SortStatus
}

export function useSortTable<T>({ items, sortStatus }: UseSortTableProps<T>) {
    const sortedItems = useMemo(() => {
        if (!sortStatus || !sortStatus?.prop || sortStatus?.isDirectionAsc == null) {
            return items
        }
        else {
            const sortedItems = [...items].sort((a, b) =>
                sortStatus.isDirectionAsc
                    ? (a[sortStatus.prop] < b[sortStatus.prop] ? -1 : 1)
                    : (a[sortStatus.prop] < b[sortStatus.prop] ? 1 : -1))

            return sortedItems
        }
    }, [items, sortStatus])

    return { sortedItems }
}