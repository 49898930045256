import React from 'react'
import { TableContainer, Table, TableRow, TableCell, TableBody, Paper, createStyles, withStyles } from '@material-ui/core'
import { replaceUnicodeCharacters, t } from '../../../infrastructure/i18nextHelper'
import { muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { Line } from './dialog'

type Props = {
    lines: Line[]
    classes: any
}

export type TableItem = {
    username: string
    date: Date
    field: string
    oldValue: string
    newValue: string
    where?: string
}

function _ImportTable({ lines, classes }: Props) {
    if (lines.length === 0)
        return (<></>)

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='history table'>
                <TableBody>
                    {lines.map((line, index) => (
                        <TableRow key={index}>
                            <TableCell component='th' scope='row'>
                                <span className={classes.grey}>
                                    {line.number ? `${t('report.dialog.line')} ${line.number}` : t('report.dialog.file')}
                                </span>
                            </TableCell>
                            <TableCell>
                                {line.errors.map(error =>
                                (<div>
                                    {replaceUnicodeCharacters(error.text)}
                                    {error.value ? <> : <span className={classes.italic}>{error.value}</span></> : null}
                                </div>))}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

let styles = _ => createStyles({
    italic: {
        fontStyle: 'italic'
    },
    grey: { color: 'grey' },
    comment: {
        color: 'silver',
        fontSize: '0.8em',
        fontFamily: 'Roboto',
        margin: '4em'
    }
})

export let ImportTable = withStyles(styles, muiOptions)(_ImportTable)