import React from 'react'
import { Button, Switch } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import { createStyles, withStyles, Paper } from '@material-ui/core'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { MovementType, MovementTypeSelectionOptions } from '../stockModels'
import { StockBoardContainer } from '../stockBoardStore'
import { StockMovementContainer } from './stockMovementStore'
import { hasFeature } from '../../../infrastructure/feature'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'

function MovementTypeSelection(props: MuiProps & {
    onMovementTypeSelected: (type: MovementType, isIn: boolean) => void,
    options: MovementTypeSelectionOptions
}) {
    let classes = props.classes
    let selectIn = (type: MovementType) => props.onMovementTypeSelected(type, true)
    let selectOut = (type: MovementType) => props.onMovementTypeSelected(type, false)
    let store = StockBoardContainer.useContainer()
    let movementStore = StockMovementContainer.useContainer()

    let toggleInOutSwitch = () => store.changeSwitchState(store.switchFilter == store.outValue ? store.inValue : store.outValue)

    return (
        <Paper className={classes.movementTypeSelection}>
            <div className={classes.rowContainer}>
                {props.options.showInOutSwitch ?
                    <Switch form
                        isChecked={store.switchFilter == store.outValue}
                        changeCallback={() => toggleInOutSwitch()}
                        offText={t('stock.label.movement.in')}
                        onText={t('stock.label.movement.out')} /> : null}

                {props.options.showUniqueRecurrentSwitch ?
                    <Switch form
                        isChecked={movementStore.batchMovement != null}
                        changeCallback={() => movementStore.toggleBatchMovement()}
                        offText={t('stock.label.movement.unique')}
                        onText={t('stock.label.movement.batch')} /> : null}

            </div>
            {
                store.switchFilter === store.inValue
                    ? <div className={classes.rowCentered + ' movementTypeChoice'}>
                        {props.options.showPurchase ?
                            <Button
                                label={t('stock.label.purchase')}
                                className={classes.type}
                                onClick={() => selectIn(MovementType.Purchase)} /> : null}

                        {props.options.showTransfer ?
                            <Button
                                label={t('stock.label.transfer')}
                                className={classes.type}
                                onClick={() => selectIn(MovementType.Transfer)} /> : null}

                        {(props.options.showStatusChange && hasFeature('StatusChangeMovement')) ?
                            <Button className={classes.type}
                                label={t('stock.label.statusChange')}
                                onClick={() => selectIn(MovementType.StatusChange)} /> : null}

                        {props.options.showRebranding ?
                            <Button className={classes.type}
                                label={t('stock.label.rebranding')}
                                onClick={() => selectIn(MovementType.Rebranding)} /> : null}

                        {props.options.showBorrow ?
                            <Button className={classes.type}
                                label={t('stock.label.borrow')}
                                onClick={() => selectIn(MovementType.Borrow)} /> : null}

                        {(props.options.showGain && hasFeature('MovementGainsAndLosses') && hasClaim(Claims.StockManager)) ?
                            <Button className={classes.type}
                                label={t('stock.label.gains')}
                                onClick={() => selectIn(MovementType.Gains)} /> : null}
                    </div>
                    : <div className={classes.rowCentered + ' movementTypeChoice'}>
                        {props.options.showSale ?
                            <Button
                                className={classes.type}
                                label={t('stock.label.sale')}
                                onClick={() => selectOut(MovementType.Sale)} /> : null}

                        {props.options.showTransfer ?
                            <Button className={classes.type}
                                label={t('stock.label.transfer')}
                                onClick={() => selectOut(MovementType.Transfer)} /> : null}

                        {(props.options.showStatusChange && hasFeature('StatusChangeMovement')) ?
                            <Button className={classes.type}
                                label={t('stock.label.statusChange')}
                                onClick={() => selectOut(MovementType.StatusChange)} /> : null}

                        {props.options.showRebranding ?
                            <Button className={classes.type}
                                label={t('stock.label.rebranding')}
                                onClick={() => selectOut(MovementType.Rebranding)} /> : null}

                        {props.options.showLoan ?
                            <Button className={classes.type}
                                label={t('stock.label.loan')}
                                onClick={() => selectOut(MovementType.Loan)} /> : null}

                        {(props.options.showLosses && hasFeature('MovementGainsAndLosses') && hasClaim(Claims.StockManager)) ?
                            <Button className={classes.type}
                                label={t('stock.label.losses')}
                                onClick={() => selectIn(MovementType.Losses)} /> : null}
                    </div>
            }
        </Paper>
    )
}

let styles = () =>
    createStyles({
        rowContainer: {
            ...defaultStyles.flexRow
        },
        movementTypeSelection: {
            padding: '0.5em',
            marginTop: '1em',
            marginBottom: '0.5em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        type: {
            ...defaultStyles.secondaryButton,
            margin: '1em'
        },
        rowCentered: {
            ...defaultStyles.flexRow,
            justifyContent: 'center',
            alignItems: 'stretch',
            margin: '1em',
        },
    })

export default withStyles(styles, muiOptions)(MovementTypeSelection)