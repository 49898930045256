import React, { useState, useEffect } from 'react'
import * as Icons from '@material-ui/icons'
import { defaultStyles, muiOptions, defaultColors } from './../../infrastructure/materialUiThemeProvider'
import { withStyles, createStyles, Paper, Typography, Tooltip, Chip, Grid } from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import guid, { Guid } from '../../infrastructure/guid'
import { DataTable, ColumnDescriptor, CustomDialog, Button, SapChip } from '../common/customComponents'
import { api, get, post } from '../../infrastructure/api'
import {
    DealListItem,
    DealMovementType,
    SearchFields,
    DutyStatus,
    Deal,
    DuplicateDealCommand,
    DealPricingType,
    GetMovementType,
    DealStatus,
    PricingPeriodListItem,
    DealListItemBase
} from './dealModels'
import { t } from '../../infrastructure/i18nextHelper'
import { DealContainer, DealDialogContainer } from './dealEdit/dealEditStore'
import { SearchField, DatePicker, MultipleSelect, Switch, MonthRangePicker, GroupedMultiSelect } from '../common/customComponents'
import { historyDialog } from '../common/history/dialog'
import moment from 'moment'
import { popupNavigator } from '../../infrastructure/popupNavigator'
import { hasFeature } from '../../infrastructure/feature'
import { ActionDescriptor, GroupDescriptor } from '../common/components/table/table'
import { exportItems } from './dealListExporter'
import { hasClaim } from '../../infrastructure/signIn/userContext'
import { Claims } from '../../infrastructure/signIn/models'
import { ExcelGeneratorContainer } from '../../infrastructure/excelExport'
import { useActionDebounceWithArgs } from '../common/debounce'
import { getMeansOfTransportationName, MovementMotIcon, MovementTypeIcon } from '../stock/_movementIcon'
import { DealStatusChip } from './dealEdit/statusChip'
import { setInLocalStorage } from '../../infrastructure/localStorage'
import { MovementTypeNameFromMovementType } from '../stock/stockModels'
import { getBooleanLabel } from '../common/filtersHelper'
import { CommentButton } from '../common/comment/commentButton'

let emptySearchFields = (): SearchFields => ({
    sites: [],
    companies: [],
    counterparties: [],
    products: [],
    dealAssignees: [],
    dealTypes: [],
    statuss: [],
    moTs: []
})

let roundNumber = (value: number | null): number | null => value ? Math.round(value) : null
let isBetween = (value: number | null, min: number | null, max: number | null): boolean =>
    value != null && (value >= (min ?? 0)) && (value <= (max ?? 0))
let getToleranceOptionLabel = (value: string | null): string | null => value && t(`deals.label.${value}`)
let roundDecimal = (value: number | null): number | null => value ? Math.round(value * 100) / 100 : null

let toTableItemBase = (dealItem: DealListItemBase): DealListItemBase => {
    return {
        id: dealItem.id,
        type: dealItem.type,
        movementType: dealItem.movementType,
        referenceNumber: dealItem.referenceNumber,
        dutyStatus: dealItem.dutyStatus,
        company: dealItem.company,
        counterparty: dealItem.counterparty,
        validFrom: dealItem.validFrom,
        validTo: dealItem.validTo,
        productCode: dealItem.productCode,
        site: dealItem.site,
        contractReference: dealItem.contractReference,
        meanOfTransportation: dealItem.meanOfTransportation,
        signed: dealItem.signed,
        assigneeFullName: dealItem.assigneeFullName,
        das: dealItem.das,
        dealNumber: dealItem.dealNumber,
        exchangeNumber: dealItem.exchangeNumber,
        frontOfficerFullName: dealItem.frontOfficerFullName,
        pricingType: dealItem.pricingType,
        sapHandlingType: dealItem.sapHandlingType,
        sapPlant: dealItem.sapPlant,
        sapValuationType: dealItem.sapValuationType,
        sapVendor: dealItem.sapVendor,
        shipTo: dealItem.shipTo,
        status: dealItem.status,
        termSpot: dealItem.termSpot,
        generateMktSaleForecasts: dealItem.generateMktSaleForecasts,
        volume: roundNumber(dealItem.volume),
        underToleranceQuantity: roundNumber(dealItem.underToleranceQuantity),
        overToleranceQuantity: roundNumber(dealItem.overToleranceQuantity),
        isContractFromSap: dealItem.isContractFromSap
    }
}

let toDealListTableItem = (dealItem: DealListItem): DealListItem => {
    return {
        ...toTableItemBase(dealItem),
        nextValidator: dealItem.nextValidator,
        assignee: dealItem.assignee,
        currency: dealItem.currency,
        dealConsumption: dealItem.dealConsumption,
        frontOfficer: dealItem.frontOfficer,
        movementQuantity: roundNumber(dealItem.movementQuantity),
        overTolerance: dealItem.overTolerance,
        quantity: roundNumber(dealItem.quantity),
        quantityPriceUnit: dealItem.quantityPriceUnit,
        sapQuantity: roundNumber(dealItem.sapQuantity),
        toleranceOption: getToleranceOptionLabel(dealItem.toleranceOption),
        underTolerance: dealItem.underTolerance,
        unit: dealItem.unit,
        isQuantityInTolerance: isBetween(dealItem.movementQuantity, dealItem.underToleranceQuantity, dealItem.overToleranceQuantity),
        companyCurrency: dealItem.companyCurrency,
        indexPriceUnit: dealItem.indexPriceUnit,
        productQuantitytUnit: dealItem.productQuantitytUnit,
        provisionalIndexPriceUnit: dealItem.provisionalIndexPriceUnit,
        unitPrice: roundDecimal(dealItem.unitPrice),
        premium: dealItem.premium,
        unitPricePlusPremium: roundDecimal(dealItem.unitPricePlusPremium),
        quantityUnitPrice: roundDecimal(dealItem.quantityUnitPrice),
        transportFees: roundDecimal(dealItem.transportFees),
        storageFees: roundDecimal(dealItem.storageFees),
        dealUnitPrice: roundDecimal(dealItem.dealUnitPrice),
        exchangeRate: dealItem.exchangeRate,
        amount: roundNumber(dealItem.amount),
        amountSap: roundNumber(dealItem.amountSap),
        paymentTerms: dealItem.paymentTerms,
        paymentTermsDate: dealItem.paymentTermsDate,
        provisionalUnitPrice: roundDecimal(dealItem.provisionalUnitPrice),
        provisionalPremium: dealItem.provisionalPremium,
        provisionalUnitPricePlusPremium: roundDecimal(dealItem.provisionalUnitPricePlusPremium),
        provisionalQuantityUnitPrice: roundDecimal(dealItem.provisionalQuantityUnitPrice),
        provisionalTransportFees: roundDecimal(dealItem.provisionalTransportFees),
        provisionalStorageFees: roundDecimal(dealItem.provisionalStorageFees),
        provisionalDealUnitPrice: roundDecimal(dealItem.provisionalDealUnitPrice),
        provisionalExchangeRate: dealItem.provisionalExchangeRate,
        provisionalAmount: roundNumber(dealItem.provisionalAmount),
        provisionalAmountSap: roundNumber(dealItem.provisionalAmountSap)
    }
}

let toPricingPeriodTableItem = (pricingPeriodItem: PricingPeriodListItem): PricingPeriodListItem => {
    return {
        ...toTableItemBase(pricingPeriodItem),
        priceIndexOrder: pricingPeriodItem.priceIndexOrder,
        pricingPeriodOrder: pricingPeriodItem.pricingPeriodOrder,
        priceIndexName: pricingPeriodItem.priceIndexName,
        pricingStartDate: pricingPeriodItem.pricingStartDate,
        pricingEndDate: pricingPeriodItem.pricingEndDate,
        premium: pricingPeriodItem.premium,
        pricedDealVolume: roundNumber(pricingPeriodItem.pricedDealVolume),
        pricedMovementVolume: roundNumber(pricingPeriodItem.pricedMovementVolume),
        pricedSapQuantity: roundNumber(pricingPeriodItem.pricedSapQuantity),
        pricedDealQuantity: roundNumber(pricingPeriodItem.pricedDealQuantity),
        percentage: roundNumber(pricingPeriodItem.percentage * 100) ?? 0,
        key: pricingPeriodItem.id.toString() + pricingPeriodItem.priceIndexOrder.toString() + pricingPeriodItem.pricingPeriodOrder.toString(),
        isQuantityInTolerance: isBetween(pricingPeriodItem.pricedMovementVolume, pricingPeriodItem.underToleranceQuantity, pricingPeriodItem.overToleranceQuantity)
    }
}

type DealListFilters = {
    referenceNumber: string | null
    dealNumber: string | null
}

let noFilters: DealListFilters = {
    referenceNumber: null,
    dealNumber: null
}

function DealList({ classes }) {
    let [searchFields, setSearchFields] = useState<SearchFields>(emptySearchFields)
    let [dutyStatuses, setDutyStatuses] = useState<DutyStatus[]>([])
    let [deleteDealConfirmIsOpen, setDeleteDealConfirmIsOpen] = useState<boolean>(false)
    let [deleteDealSimpleConfirmIsOpen, setDeleteDealSimpleConfirmIsOpen] = useState<boolean>(false)
    let [toDeleteDealIds, setToDeleteDealIds] = useState<Guid[]>([])
    let [searchQuery, setSearchQuery] = useState<string>('')
    let [dealItems, setDealItems] = useState<DealListItem[]>([])
    let [pricingPeriodItems, setPricingPeriodItems] = useState<PricingPeriodListItem[]>([])
    let [selectedDeals, setSelectedDeals] = useState<DealListItem[]>([])
    let [selectedPricingPeriods, setSelectedPricingPeriods] = useState<PricingPeriodListItem[]>([])
    let [duplicateConfirmDialogOpen, setDuplicateConfirmDialogOpen] = useState<boolean>(false)
    let [currentDealId, setcurrentDealId] = useState<Guid | null>(null)
    let [dealListFilters, setDealListFilters] = useState<DealListFilters>(noFilters)

    let dealContainer = DealDialogContainer.useContainer()
    let dealStore = DealContainer.useContainer()
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    const termSpot = ['Term', 'Spot']
    const signedNotSigned = ['Signed', 'Not Signed']

    let filters = dealStore.filters

    let getDealListItems = () => applyFilters<DealListItem>(dealItems).map(toDealListTableItem)
    let getPricingPeriodListItems = () => applyFilters<PricingPeriodListItem>(pricingPeriodItems).map(toPricingPeriodTableItem)

    let openPopup = (targetId: Guid, pricingType?: DealPricingType) => {
        popupNavigator.open('deal', targetId, { pricingType: pricingType })
    }

    let loadDeals = async () => {
        let filtersQuery = constructFiltersQuery()
        let route = filtersQuery ? `deal?${filtersQuery}` : 'deal'
        setDealItems(await get<DealListItem[]>(route))
    }

    let loadPricingPeriods = async () => {
        let filtersQuery = constructFiltersQuery()
        let route = filtersQuery ? `deal/pricingPeriod?${filtersQuery}` : 'deal/pricingPeriod'
        setPricingPeriodItems(await get<PricingPeriodListItem[]>(route))
    }

    let loadDealSearchFields = async () => {
        let searchFields = await get<SearchFields>('deal/searchField')
        setSearchFields(searchFields)

        let dutyStatuses = searchFields.companies.map(x => x.dutyStatuses).reduce((a, b) => a.concat(b)).distinct()
        setDutyStatuses(dutyStatuses)
    }

    let saveFiltersInLocalStorage = () => {
        let filtersLength = Object.keys(filters).length

        if (filtersLength > 0)
            setInLocalStorage('dealFilters', filters)
    }

    let changeDate = (newDate: string | null, key) => {
        dealStore.setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: newDate
        }))
    }

    let constructFiltersQuery = () => {
        let filtersToApply = filters
        let query = ''
        let arrays = ['dealTypes', 'movementTypes', 'termSpot',
            'companys', 'productIds', 'sites', 'moTs',
            'dutyStatuss', 'counterpartyIds', 'statuss',
            'signedNotSigned', 'dealAssignees', 'frontOfficers']

        let filterProperties = Object.keys(filtersToApply).filter(x => arrays.indexOf(x) === -1)
        let initializedFilters = filterProperties.filter(x =>
            (filtersToApply[x] !== undefined && filtersToApply[x] !== null))

        initializedFilters.map(x => query = query + `${x}=${filtersToApply[x]}&`)

        arrays.forEach(prop => {
            if (filtersToApply[prop] && filtersToApply[prop].length > 0)
                filtersToApply[prop].map(value => query = query + `${prop}=${value}&`)
        })

        return query
    }

    let applyFilters = <T extends DealListItemBase>(deals: T[]): T[] => {
        if (dealListFilters.referenceNumber)
            deals = deals.filter(x => x.referenceNumber?.toLowerCase().contains(dealListFilters.referenceNumber!.toLowerCase()))
        if (dealListFilters.dealNumber)
            deals = deals.filter(x => x.dealNumber?.toString().contains(dealListFilters.dealNumber!.toString()))
        return deals
    }

    let onSelect = async (deal: DealListItemBase) => {
        openPopup(deal.id)
    }

    let onDeleteDeals = <T extends DealListItemBase>(selectedItems: T[]) => {
        setToDeleteDealIds(selectedItems.map(x => x.id).distinct())

        let shouldNotAllowMovementToStay = hasFeature('BatchDailyMovementCreationFromDeal') &&
            selectedItems.some(x => x.meanOfTransportation === 'Road' && x.movementType === DealMovementType.PURCHASE || x.generateMktSaleForecasts)

        if (shouldNotAllowMovementToStay)
            setDeleteDealSimpleConfirmIsOpen(true)
        else
            setDeleteDealConfirmIsOpen(true)
    }

    let deleteDeals = async (deleteMovements: boolean) => {
        setDeleteDealConfirmIsOpen(false)
        setDeleteDealSimpleConfirmIsOpen(false)
        await post('deal/batchDelete', { dealIds: toDeleteDealIds, deleteMovements: deleteMovements })
        filters.displayPricingPeriodList ? await loadPricingPeriods() : await loadDeals()
    }

    useEffect(() => {
        if (!dealContainer.isOpen && dealContainer.previousIsOpen)
            filters.displayPricingPeriodList ? loadPricingPeriods() : loadDeals()
    }, [dealContainer.isOpen])

    useEffect(() => {
        saveFiltersInLocalStorage()
        filters.displayPricingPeriodList ? loadPricingPeriods() : loadDeals()
        loadDealSearchFields()
        setSearchQuery(filters.query ?? '')
    }, [filters])

    let onValidatedQuery = () => dealStore.setFilters({ ...filters, query: searchQuery })

    let onQueryChanged = (e) => {
        if (e.key === 'Enter')
            return onValidatedQuery()
        else
            setSearchQuery(e.target.value)
    }

    let exportListItems = async () => {
        let items: DealListItemBase[] = filters.displayPricingPeriodList
            ? selectedPricingPeriods.length > 0 ? selectedPricingPeriods : pricingPeriodItems
            : selectedDeals.length > 0 ? selectedDeals : dealItems
        let columns = filters.displayPricingPeriodList
            ? pricingPeriodColumns
            : dealListColumns

        exportItems(columns, items, excelGenerator.generate, filters.displayPricingPeriodList)
    }

    let duplicateDeal = async (selectedDealId: Guid): Promise<void> => {
        let command: DuplicateDealCommand = {
            sourceId: selectedDealId,
            duplicateId: guid.createNew()
        }
        await dealStore.duplicateDeal(command);
        openPopup(command.duplicateId)
    }

    let handleDuplicateDebouncer = useActionDebounceWithArgs(duplicateDeal)

    let formatFromToDate = (date: Date | null): string => !!date ? moment(date).format("MM/DD/YY") : ''

    let hasOneCompany = searchFields.companies.map(x => x.code).distinct().length === 1

    let dealListItems = getDealListItems()
    let totalVolume = roundNumber(dealListItems.reduce((acc, curr) => acc + (curr.volume ?? 0), 0))
    let totalMvtQuantity = roundNumber(dealListItems.reduce((acc, curr) => acc + (curr.movementQuantity ?? 0), 0))
    let totalQuantity = roundNumber(dealListItems.reduce((acc, curr) => acc + (curr.quantity ?? 0), 0))
    let totalSapQuantity = roundNumber(dealListItems.reduce((acc, curr) => acc + (curr.sapQuantity ?? 0), 0))

    let pricingPeriodListItems = getPricingPeriodListItems()
    let totalPricedDealVolume = roundNumber(pricingPeriodListItems.reduce((acc, curr) => acc + (curr.pricedDealVolume ?? 0), 0))
    let totalPricedMovementVolume = roundNumber(pricingPeriodListItems.reduce((acc, curr) => acc + (curr.pricedMovementVolume ?? 0), 0))
    let totalPricedSapQuantity = roundNumber(pricingPeriodListItems.reduce((acc, curr) => acc + (curr.pricedSapQuantity ?? 0), 0))
    let totalPricedDealQuantity = roundNumber(pricingPeriodListItems.reduce((acc, curr) => acc + (curr.pricedDealQuantity ?? 0), 0))

    let formatCell = (isGreen: boolean, value: number | null) => (<span className={isGreen ? classes.positive : ''}>{value}</span>)
    let formatDate = (date: Date | null) => !!date ? moment(date).format('MM/DD/YY') : ''

    let hasFeatureUpdateSapVolume = hasFeature('DealsSapQuantityVolumeUpdate')
    let hasFeatureGenericPricing = hasFeature('GenericPricing')
    let hasFeatureProvitionalPricing = hasFeature('ProvisionalPricing')

    let groupedHeaderColumns: ColumnDescriptor<DealListItemBase>[] = [
        { name: t('deals.label.company'), value: x => x.company, hidden: hasOneCompany },
        {
            name: t('deals.label.dealNumberAbr'), defaultGroup: 'header',
            value: x => x.dealNumber,
            columnFilter:
            {
                value: dealListFilters.dealNumber ?? '',
                onChange: (dealNumber: string) => setDealListFilters({ ...dealListFilters, dealNumber })
            },
            wrapTextMax: true, sortProperty: 'dealNumber'
        },
        {
            name: t('deals.label.referenceNumberAbr'), defaultGroup: 'header',
            value: x => x.referenceNumber,
            columnFilter:
            {
                value: dealListFilters.referenceNumber ?? '',
                onChange: (referenceNumber: string) => setDealListFilters({ ...dealListFilters, referenceNumber })
            },
            wrapTextMax: true, valueSubText: x => (x.site ?? '') + ' - ' + (x.productCode ?? '')
        },
        {
            name: t('deals.label.mvtType'), groupedBy: 'header', htmlFor: x =>
                <span className={!!x.movementType && classes.iconsActive}>
                    <MovementTypeIcon movementType={GetMovementType(x.movementType ?? '')} />
                </span>
        },
        { name: t('deals.label.dealType'), groupedBy: 'header', value: x => x.type },
        {
            name: t('deals.label.termSpot'), groupedBy: 'header', value: _ => '', textAlign: 'right',
            htmlFor: x => (
                x.termSpot == 'Term'
                    ? <CheckBox className={classes.iconsActive} />
                    : <CheckBoxOutlineBlank />
            )
        },
        { name: t('deals.label.product'), groupedBy: 'header', value: x => x.productCode },
        { name: t('deals.label.site'), groupedBy: 'header', value: x => x.site, wrapTextMax: true },
        { name: t('deals.label.dutyStatusAbr'), groupedBy: 'header', value: x => x.dutyStatus },
        {
            name: t('deals.label.mot'), groupedBy: 'header', htmlFor: x =>
                <span className={!!x.meanOfTransportation && classes.iconsActive}>
                    <MovementMotIcon meanOfTransportation={x.meanOfTransportation ?? ''} />
                </span>
        },
        { name: t('deals.label.counterparty'), groupedBy: 'header', value: x => x.counterparty?.name ?? '', wrapTextMax: true },
        {
            name: t('deals.label.validityPeriodAbr'), defaultGroup: 'header',
            value: x => formatFromToDate(x.validFrom) + ' -> ' + formatFromToDate(x.validTo)
        }]

    let headerColumns: ColumnDescriptor<DealListItemBase>[] = [
        { name: t('deals.label.company'), value: x => x.company, hidden: hasOneCompany },
        {
            name: t('deals.label.dealNumberAbr'),
            value: x => x.dealNumber,
            columnFilter:
            {
                value: dealListFilters.dealNumber ?? '',
                onChange: (dealNumber: string) => setDealListFilters({ ...dealListFilters, dealNumber })
            },
            wrapTextMax: true, sortProperty: 'dealNumber'
        },
        {
            name: t('deals.label.referenceNumberAbr'),
            value: x => x.referenceNumber,
            columnFilter:
            {
                value: dealListFilters.referenceNumber ?? '',
                onChange: (referenceNumber: string) => setDealListFilters({ ...dealListFilters, referenceNumber })
            },
            wrapTextMax: true
        },
        {
            name: t('deals.label.mvtType'), value: x => MovementTypeNameFromMovementType(GetMovementType(x.movementType ?? '')),
            htmlFor: x =>
                <span className={!!x.movementType && classes.iconsActive}>
                    <MovementTypeIcon movementType={GetMovementType(x.movementType ?? '')} />
                </span>
        },
        { name: t('deals.label.dealType'), value: x => x.type },
        {
            name: t('deals.label.termSpot'), value: x => getBooleanLabel(x.termSpot == 'Term'), textAlign: 'right',
            htmlFor: x => (
                x.termSpot == 'Term'
                    ? <CheckBox className={classes.iconsActive} />
                    : <CheckBoxOutlineBlank />
            )
        },
        { name: t('deals.label.product'), value: x => x.productCode },
        { name: t('deals.label.site'), value: x => x.site, wrapTextMax: true },
        { name: t('deals.label.dutyStatusAbr'), value: x => x.dutyStatus },
        {
            name: t('deals.label.mot'), value: x => getMeansOfTransportationName(x.meanOfTransportation ?? ''),
            htmlFor: x =>
                <span className={!!x.meanOfTransportation && classes.iconsActive}>
                    <MovementMotIcon meanOfTransportation={x.meanOfTransportation ?? ''} />
                </span>
        },
        { name: t('deals.label.counterparty'), value: x => x.counterparty?.name ?? '', wrapTextMax: true },
        {
            name: t('deals.label.validityPeriodAbr'),
            value: x => formatFromToDate(x.validFrom) + ' -> ' + formatFromToDate(x.validTo)
        }]

    let sapColumns = <T extends DealListItemBase>(): ColumnDescriptor<T>[] => {
        return [
            {
                name: t('deals.label.sapText'), defaultGroup: 'sapDetails',
                value: x => x.contractReference ?? '',
                htmlFor: x =>
                (!dealStore.isPaperTransaction()
                    ? <div>
                        <SapChip
                            sapChipStyle={sapChipStyle}
                            label={x.contractReference ?? 'PC'}
                            isFilled={!!x.contractReference}
                            isFromSap={x.isContractFromSap}
                            disabled={!x.contractReference && hasFeatureUpdateSapVolume}
                            clickable={hasFeatureUpdateSapVolume}
                            onClick={async (event) => {
                                if (!hasFeatureUpdateSapVolume) return
                                event.stopPropagation();
                                await api.post('sapApi/updateDealSapQuantity ', { dealId: x.id })
                            }}
                        />
                    </div>
                    : <></>
                ),
                tooltipValue: (x) => x.contractReference && hasFeatureUpdateSapVolume ? t('deals.label.updateSapQuantity') : '',
            },
            { name: t('deals.label.exchangeNumberAbr'), groupedBy: 'sapDetails', value: x => x.exchangeNumber },
            { name: t('deals.label.sapPlant'), groupedBy: 'sapDetails', value: x => x.sapPlant },
            { name: t('deals.label.das'), groupedBy: 'sapDetails', value: x => x.das },
            { name: t('deals.label.sapHandlingType'), groupedBy: 'sapDetails', value: x => x.sapHandlingType },
            { name: t('deals.label.sapValuationType'), groupedBy: 'sapDetails', value: x => x.sapValuationType },
            { name: t('deals.label.sapVendor'), groupedBy: 'sapDetails', value: x => x.sapVendor },
            { name: t('deals.label.shipTo'), groupedBy: 'sapDetails', value: x => x.shipTo },
        ]
    }

    let organizationnalColumns = <T extends DealListItemBase>(): ColumnDescriptor<T>[] => {
        return [
            {
                name: t('deals.label.status'), defaultGroup: 'organizationnalDetails', value: x => x.status,
                htmlFor: x => <DealStatusChip key={x.id} status={x.status as DealStatus} />
            },
            {
                name: t('deals.label.signed'), groupedBy: 'organizationnalDetails',
                value: x => getBooleanLabel(x.signed), textAlign: 'right',
                htmlFor: x => (
                    x.signed
                        ? <CheckBox />
                        : <CheckBoxOutlineBlank />
                )
            },
            {
                name: t('deals.label.assigneeUserName'), groupedBy: 'organizationnalDetails',
                value: x => x.assigneeFullName, wrapTextMax: true, tooltipValue: x => x.assigneeFullName
            },
            {
                name: t('deals.label.frontOfficerAbr'), groupedBy: 'organizationnalDetails',
                value: x => x.frontOfficerFullName, wrapTextMax: true, tooltipValue: x => x.frontOfficerFullName
            }
        ]
    }

    let pricingColumns = <T extends DealListItem>(): ColumnDescriptor<T>[] => {
        return [
            {
                name: t('deals.label.pricing.unit_price'), groupedBy: 'pricingDetails',
                value: x => x.unitPrice, unit: x => x.indexPriceUnit, textAlign: 'right'
            },
            {
                name: t('deals.label.pricing.premium'), groupedBy: 'pricingDetails',
                value: x => x.premium, unit: x => x.indexPriceUnit, textAlign: 'right'
            },
            {
                name: t('deals.label.pricing.unit_price_plus_premium'), groupedBy: 'pricingDetails',
                value: x => x.unitPricePlusPremium, unit: x => x.indexPriceUnit, textAlign: 'right'
            },
            {
                name: t('deals.label.pricing.quantity_unit_price'), groupedBy: 'pricingDetails',
                value: x => x.quantityUnitPrice, unit: x => x.quantityPriceUnit, textAlign: 'right'
            },
            {
                name: t('deals.label.pricing.transport_fees'), groupedBy: 'pricingDetails',
                value: x => x.transportFees, unit: x => x.companyCurrency + '/' + x.productQuantitytUnit, textAlign: 'right'
            },
            {
                name: t('deals.label.pricing.storage_fees'), groupedBy: 'pricingDetails',
                value: x => x.storageFees, unit: x => x.companyCurrency + '/' + x.productQuantitytUnit, textAlign: 'right'
            },
            {
                name: t('deals.label.pricing.deal_unit_price'), defaultGroup: 'pricingDetails',
                value: x => x.dealUnitPrice, unit: x => x.quantityPriceUnit
            },
            {
                name: t('deals.label.pricing.exchange_rate'), groupedBy: 'pricingDetails',
                value: x => x.exchangeRate
            },
            {
                name: t('deals.label.pricing.amount'), groupedBy: 'pricingDetails',
                value: x => x.amount, unit: x => x.currency, textAlign: 'right',
                tooltipValue: x => t('deals.label.pricing.tooltip_amount')
            },
            {
                name: t('deals.label.pricing.amount_sap'), groupedBy: 'pricingDetails',
                value: x => x.amountSap, unit: x => x.currency, textAlign: 'right',
                tooltipValue: x => t('deals.label.pricing.tooltip_amount_sap')
            },
            {
                name: t('deals.label.pricing.payment_terms'), groupedBy: 'pricingDetails',
                value: x => x.paymentTerms
            },
            {
                name: t('deals.label.pricing.payment_terms_date'), groupedBy: 'pricingDetails',
                value: x => formatDate(x.paymentTermsDate)
            }
        ]
    }

    let provisionalPricingColumns = <T extends DealListItem>(): ColumnDescriptor<T>[] => {
        return [
            {
                name: t('deals.label.provisional_pricing.unit_price'), groupedBy: 'provisionalPricingDetails',
                value: x => x.provisionalUnitPrice, unit: x => x.indexPriceUnit, textAlign: 'right'
            },
            {
                name: t('deals.label.provisional_pricing.premium'), groupedBy: 'provisionalPricingDetails',
                value: x => x.provisionalPremium, unit: x => x.indexPriceUnit, textAlign: 'right'
            },
            {
                name: t('deals.label.provisional_pricing.unit_price_plus_premium'), groupedBy: 'provisionalPricingDetails',
                value: x => x.provisionalUnitPricePlusPremium, unit: x => x.indexPriceUnit, textAlign: 'right',
            },
            {
                name: t('deals.label.provisional_pricing.quantity_unit_price'), groupedBy: 'provisionalPricingDetails',
                value: x => x.provisionalQuantityUnitPrice, unit: x => x.quantityPriceUnit, textAlign: 'right'
            },
            {
                name: t('deals.label.provisional_pricing.transport_fees'), groupedBy: 'provisionalPricingDetails',
                value: x => x.provisionalTransportFees, unit: x => x.companyCurrency + '/' + x.productQuantitytUnit,
                textAlign: 'right'
            },
            {
                name: t('deals.label.provisional_pricing.storage_fees'), groupedBy: 'provisionalPricingDetails',
                value: x => x.provisionalStorageFees, unit: x => x.companyCurrency + '/' + x.productQuantitytUnit,
                textAlign: 'right'
            },
            {
                name: t('deals.label.provisional_pricing.deal_unit_price'), defaultGroup: 'provisionalPricingDetails',
                value: x => x.provisionalDealUnitPrice, unit: x => x.quantityPriceUnit
            },
            {
                name: t('deals.label.provisional_pricing.exchange_rate'), groupedBy: 'provisionalPricingDetails',
                value: x => x.provisionalExchangeRate
            },
            {
                name: t('deals.label.provisional_pricing.amount'), groupedBy: 'provisionalPricingDetails',
                value: x => x.provisionalAmount, unit: x => x.currency, textAlign: 'right',
                tooltipValue: x => t('deals.label.provisional_pricing.tooltip_amount')
            },
            {
                name: t('deals.label.provisional_pricing.amount_sap'), groupedBy: 'provisionalPricingDetails',
                value: x => x.provisionalAmountSap, unit: x => x.currency, textAlign: 'right',
                tooltipValue: x => t('deals.label.provisional_pricing.tooltip_amount_sap')
            }
        ]
    }

    let commentColumn: ColumnDescriptor<DealListItem>[] = hasFeature('CommentV2')
        ? [{
            preventRowClick: true,
            textAlign: 'center',
            name: t('deals.table.comments'),
            value: () => '',
            htmlFor: x => <CommentButton
                associationKey={`deal-${x.id}`}
                hideIfNoContent
                readOnly={dealStore.isInternal(x.type)} />
        }] : []

    let dealListColumns: ColumnDescriptor<DealListItem>[] = [
        ...groupedHeaderColumns,
        {
            name: t('deals.label.volumeAbr'), defaultGroup: 'quantityDetails',
            value: x => x.volume, textAlign: 'right',
            htmlFor: x => formatCell(x.isQuantityInTolerance, x.volume), total: totalVolume,
        },
        {
            name: t('deals.label.movementQuantity'), groupedBy: 'quantityDetails',
            value: x => x.movementQuantity, textAlign: 'right',
            htmlFor: x => formatCell(x.isQuantityInTolerance, x.movementQuantity), total: totalMvtQuantity,
        },
        {
            name: t('deals.label.sapQuantityAbr'), groupedBy: 'quantityDetails',
            value: x => x.sapQuantity, total: totalSapQuantity, textAlign: 'right'
        },
        { name: t('deals.label.unit'), groupedBy: 'quantityDetails', value: x => x.unit },
        {
            name: t('deals.label.quantityAbr'), groupedBy: 'quantityDetails',
            value: x => x.quantity, textAlign: 'right',
            htmlFor: x => formatCell(x.isQuantityInTolerance, x.quantity), total: totalQuantity,
        },
        {
            name: t('deals.label.underTolerancePercentage'), groupedBy: 'quantityDetails',
            value: x => x.underTolerance, textAlign: 'right'
        },
        {
            name: t('deals.label.UnderToleranceQuantity'), groupedBy: 'quantityDetails',
            value: x => x.underToleranceQuantity, textAlign: 'right'
        },
        {
            name: t('deals.label.overTolerancePercentage'), groupedBy: 'quantityDetails',
            value: x => x.overTolerance, textAlign: 'right'
        },
        {
            name: t('deals.label.overToleranceQuantity'), groupedBy: 'quantityDetails',
            value: x => x.overToleranceQuantity, textAlign: 'right'
        },
        { name: t('deals.label.toleranceOption'), groupedBy: 'quantityDetails', value: x => x.toleranceOption },
        {
            name: t('deals.label.dealConsumption'), groupedBy: 'quantityDetails',
            value: x => x.dealConsumption != null ? (x.dealConsumption * 100).toFixed(2) : null
        },
        ...sapColumns(),
        { name: t('deals.label.pricingType'), defaultGroup: 'priceDetails', value: x => x.pricingType },
        { name: t('deals.label.currency'), groupedBy: 'priceDetails', value: x => x.currency },
        { name: t('deals.label.quantityPriceUnitAbr'), groupedBy: 'priceDetails', value: x => x.quantityPriceUnit },
        ...organizationnalColumns(),
        ...commentColumn
    ]

    dealListColumns = [
        ...dealListColumns,
        ...(hasFeatureGenericPricing ? pricingColumns() : []),
        ...(hasFeatureGenericPricing && hasFeatureProvitionalPricing ? provisionalPricingColumns() : [])]

    let pricingPeriodColumns: ColumnDescriptor<PricingPeriodListItem>[] = [
        ...headerColumns,
        {
            name: t('deals.label.priceIndexName'),
            value: x => x.priceIndexName, wrapTextMax: true
        },
        {
            name: t('deals.label.pricingPeriod'),
            value: x => formatFromToDate(x.pricingStartDate) + ' -> ' + formatFromToDate(x.pricingEndDate)
        },
        {
            name: t('deals.label.premium'),
            value: x => x.premium, wrapTextMax: true
        },
        {
            name: t('deals.label.pricedDealVolume'), defaultGroup: 'quantityDetails',
            htmlFor: x => formatCell(x.isQuantityInTolerance, x.pricedDealVolume), wrapTextMax: true,
            value: x => x.pricedDealVolume,
            total: totalPricedDealVolume, textAlign: 'right'
        },
        {
            name: t('deals.label.pricedMovementVolume'), groupedBy: 'quantityDetails',
            htmlFor: x => formatCell(x.isQuantityInTolerance, x.pricedMovementVolume), wrapTextMax: true,
            value: x => x.pricedMovementVolume,
            total: totalPricedMovementVolume, textAlign: 'right'
        },
        {
            name: t('deals.label.pricedSapQuantity'), groupedBy: 'quantityDetails',
            value: x => x.pricedSapQuantity, wrapTextMax: true,
            total: totalPricedSapQuantity, textAlign: 'right'
        },
        {
            name: t('deals.label.pricedDealQuantity'), groupedBy: 'quantityDetails',
            htmlFor: x => formatCell(x.isQuantityInTolerance, x.pricedDealQuantity), wrapTextMax: true,
            value: x => x.pricedDealQuantity,
            total: totalPricedDealQuantity, textAlign: 'right'
        },
        {
            name: t('deals.label.pricingPercentage'), groupedBy: 'quantityDetails',
            value: x => x.percentage, wrapTextMax: true
        },
        ...sapColumns(),
        ...organizationnalColumns()]

    let dealsTableActions = <T extends DealListItemBase>(): ActionDescriptor<T>[] => {
        return [
            { name: 'Delete', action: onDeleteDeals, icon: <Icons.DeleteOutlined />, isHidden: !hasClaim(Claims.DealManager) },
            {
                name: 'History',
                action: (x: T) =>
                    historyDialog.open({
                        id: x.id, type: 'deal', name: `
                            ${x.referenceNumber ?? ''}
                            ${x.company ?? ''}
                            ${x.dutyStatus ?? ''}
                            ${x.productCode ?? ''}
                            ${x.volume ? x.volume + ' M20' : ''}`
                    }),
                isBodyAction: true, icon: <Icons.History />
            },
            {
                name: 'Duplicate', action: (x: T) => { setcurrentDealId(x.id); setDuplicateConfirmDialogOpen(true) },
                icon:
                    <Tooltip title={<Typography variant='body1'>{t('deals.label.duplicateDeal')}</Typography>} placement='top'>
                        <Icons.FileCopyOutlined />
                    </Tooltip>,
                isBodyAction: true,
                disabled: (x: T) => !hasFeature('CreateDeal') || !hasClaim(Claims.DealManager) || x.type === 'INTERNAL'
            }
        ]
    }

    let details = (span: number): GroupDescriptor => {
        return { name: 'details', title: '', colSpan: span }
    }
    let header = { name: 'header', title: t('deals.label.header'), withHiddenColumns: true }
    let quantityDetails = { name: 'quantityDetails', title: t('deals.label.quantityDetails'), withHiddenColumns: true }
    let sapDetails = { name: 'sapDetails', title: t('deals.label.sapDetails'), withHiddenColumns: true }
    let priceDetails = { name: 'priceDetails', title: t('deals.label.priceDetails'), withHiddenColumns: true }
    let organizationDetails = { name: 'organizationnalDetails', title: t('deals.label.organizationnalDetails'), withHiddenColumns: true }
    let pricingDetails = { name: 'pricingDetails', title: t('deals.label.pricingDetails'), withHiddenColumns: true }
    let provisionalPricingDetails = { name: 'provisionalPricingDetails', title: t('deals.label.provisionalPricingDetails'), withHiddenColumns: true }
    let options = { name: 'options', title: '' }

    let dealsTableGroups: GroupDescriptor[] =
        [
            ...[details(hasOneCompany ? 0 : 1), header, quantityDetails, sapDetails, priceDetails, organizationDetails],
            ...hasFeatureGenericPricing ? [pricingDetails] : [],
            ...hasFeatureGenericPricing && hasFeatureProvitionalPricing ? [provisionalPricingDetails] : [],
            ...[options]
        ]

    let pricingPeriodsTableGroups: GroupDescriptor[] = [
        details(hasOneCompany ? 15 : 16),
        quantityDetails,
        sapDetails,
        organizationDetails,
        options
    ]

    let canCreate = hasFeature('CreateDeal') && hasClaim(Claims.DealManager)

    return (
        <div className={classes.pageContainer}>
            <div className={classes.firstRow}>
                <Typography className={classes.pageTitle} variant='h5' display='block' gutterBottom>{t('deals.name')}</Typography>
                <div>
                    <Paper className={classes.paperFilter}>
                        <MultipleSelect label={t('deals.label.type')} classesOverride={{ form: classes.filterField }}
                            values={filters.dealTypes} disableNewStyle
                            choices={searchFields.dealTypes}
                            onChange={x => dealStore.setFilters({ ...filters, dealTypes: x })} />
                        <MultipleSelect label={t('deals.label.movementType')} classesOverride={{ form: classes.filterField }}
                            values={filters.movementTypes} disableNewStyle
                            choices={Object.keys(DealMovementType).map(x => { return { value: DealMovementType[x], text: dealStore.getTranslatedMovementType(DealMovementType[x]) } })}
                            onChange={x => dealStore.setFilters({ ...filters, movementTypes: x })} />
                        {dealStore.updatedDealFilters() && <MultipleSelect label={t('deals.label.termSpot')} classesOverride={{ form: classes.filterField }}
                            values={filters.termSpot} disableNewStyle
                            choices={termSpot}
                            onChange={x => dealStore.setFilters({ ...filters, termSpot: x })} />}
                        {!hasOneCompany && <MultipleSelect label={t('deals.label.company')} classesOverride={{ form: classes.filterField }}
                            values={filters.companys} disableNewStyle
                            choices={searchFields.companies.map(x => ({ value: x.code, text: x.name }))}
                            onChange={x => dealStore.setFilters({ ...filters, companys: x })} />}
                        <GroupedMultiSelect label={t('deals.label.product')} classesOverride={{ form: classes.filterField }}
                            values={filters.productIds} disableNewStyle
                            choices={searchFields.products.map(x => { return { value: x.id, text: x.code, group: x.productGroup ?? x.code } })}
                            onChange={x => dealStore.setFilters({ ...filters, productIds: x })} />
                        {dealStore.updatedDealFilters() && <GroupedMultiSelect
                            label={t('deals.label.site')}
                            classesOverride={{ form: classes.filterField }}
                            values={filters.sites} disableNewStyle
                            choices={searchFields.sites.map(x => { return { value: x.code, text: x.name, group: x.siteGroup } })}
                            allWhenEmpty={false}
                            onChange={x => dealStore.setFilters({ ...filters, sites: x })} />}
                        <MultipleSelect label={t('deals.label.dutyStatus')} hideOnSingleChoice
                            values={filters.dutyStatuss} classesOverride={{ form: classes.filterField }}
                            choices={dutyStatuses} disableNewStyle
                            onChange={x => dealStore.setFilters({ ...filters, dutyStatuss: x })} />
                        {dealStore.updatedDealFilters() && <MultipleSelect label={t('deals.label.mot')} hideOnSingleChoice
                            values={filters.moTs} classesOverride={{ form: classes.filterField }}
                            choices={searchFields.moTs} disableNewStyle
                            onChange={x => dealStore.setFilters({ ...filters, moTs: x })} />}
                        <MultipleSelect label={t('deals.label.counterparty')} classesOverride={{ form: classes.filterField }}
                            values={filters.counterpartyIds} disableNewStyle
                            choices={searchFields.counterparties.map(x => ({ value: x.id, text: x.name }))}
                            onChange={x => dealStore.setFilters({ ...filters, counterpartyIds: x })} />
                        {dealStore.updatedDealFilters() &&
                            <>
                                <MultipleSelect label={t('deals.label.status')} classesOverride={{ form: classes.filterField }}
                                    values={filters.statuss} disableNewStyle
                                    choices={searchFields.statuss}
                                    onChange={x => dealStore.setFilters({ ...filters, statuss: x })} />
                                <MultipleSelect label={t('deals.label.signed')} classesOverride={{ form: classes.filterField }}
                                    values={filters.signedNotSigned} disableNewStyle
                                    choices={signedNotSigned}
                                    onChange={x => dealStore.setFilters({ ...filters, signedNotSigned: x })} />
                                <MultipleSelect label={t('deals.label.assigneeUserName')} classesOverride={{ form: classes.filterField }}
                                    values={filters.dealAssignees} disableNewStyle
                                    choices={searchFields.dealAssignees.map(x => ({ value: x.userName, text: x.fullName }))}
                                    onChange={x => dealStore.setFilters({ ...filters, dealAssignees: x })} />
                                <MultipleSelect label={t('deals.label.frontOfficer')} classesOverride={{ form: classes.filterField }}
                                    values={filters.frontOfficers} disableNewStyle
                                    choices={searchFields.dealAssignees.map(x => ({ value: x.userName, text: x.fullName }))}
                                    onChange={x => dealStore.setFilters({ ...filters, frontOfficers: x })} />
                            </>}
                    </Paper>
                </div>
            </div>
            <Paper className={classes.root + ' ' + classes.secondRow}>
                <div className={classes.tableHead}>
                    {hasFeature('GenericPricing') && <div className={classes.titleSwitchRow}>
                        <Switch form
                            isChecked={filters.displayPricingPeriodList}
                            changeCallback={() => dealStore.setFilters({ ...filters, displayPricingPeriodList: !filters.displayPricingPeriodList })}
                            offText={t('deals.label.allDeals')}
                            onText={t('deals.label.pricingPeriodList')} />
                    </div>}
                    <div className={classes.datePickerContainer}>
                        <Switch
                            isChecked={filters.isDayPeriodMode}
                            changeCallback={() => dealStore.setFilters({ ...filters, isDayPeriodMode: !filters.isDayPeriodMode })}
                            offText={t('deals.label.monthFilter')}
                            onText={t('deals.label.dayFilter')}
                        />
                        <Grid>
                            {filters.isDayPeriodMode
                                ? <DatePicker
                                    date={filters.dateOn || null}
                                    label={t('deals.table.' + (filters.displayPricingPeriodList ? 'priceOn' : 'validOn'))}
                                    disableNewStyle
                                    setDate={newDate => changeDate(newDate, 'dateOn')}
                                    classesOverride={{ datepicker: classes.filterFieldDate }}
                                />
                                : <MonthRangePicker
                                    label={t('deals.table.' + (filters.displayPricingPeriodList ? 'priceIn' : 'validIn'))}
                                    startDate={filters.startDate || null}
                                    endDate={filters.endDate || null}
                                    onStartDateChange={(newDate) => changeDate(newDate, 'startDate')}
                                    onEndDateChange={(newDate) => changeDate(newDate, 'endDate')}
                                    disableNewStyle
                                    classesOverride={{ datepicker: classes.filterFieldDate }}
                                    status="info"
                                />
                            }
                        </Grid>
                    </div>
                    <div className={classes.buttonsRow}>
                        {hasFeature('ExportDealList') && (hasClaim(Claims.DealManager) || hasClaim(Claims.DealReader))
                            ? <Button className={classes.headerButton}
                                label={t('admin.masterdata.export')}
                                img={'/static/images/excel_red.svg'}
                                onClick={exportListItems} />
                            : null}
                        <SearchField label={t('deals.label.search')} onChange={onQueryChanged} onClick={onValidatedQuery} text={searchQuery} />
                        {
                            canCreate
                            && <Button className={classes.createButton}
                                label={t('deals.table.createNewFormula')}
                                img={<Icons.Add />}
                                onClick={() => openPopup(guid.createNew() as Guid, 'Formula')} />}
                        {
                            canCreate && hasFeature('PricingTrigger')
                            && <Button
                                label={t('deals.table.createNewTrigger')}
                                img={<Icons.Add />}
                                onClick={() => openPopup(guid.createNew() as Guid, 'Trigger')} />
                        }
                        {
                            canCreate && hasFeature('PricingFixedPrice')
                            && <Button
                                label={t('deals.table.createNewFixed')}
                                img={<Icons.Add />}
                                onClick={() => openPopup(guid.createNew() as Guid, 'Fixed')} />
                        }
                    </div>
                </div>
                {filters.displayPricingPeriodList
                    ?
                    <DataTable
                        tableId={'pricing-period-table'}
                        className={classes.stickyHeader}
                        items={getPricingPeriodListItems()}
                        idSelector={(x: PricingPeriodListItem) => x.key}
                        onClick={onSelect}
                        columns={pricingPeriodColumns}
                        actions={dealsTableActions<PricingPeriodListItem>()}
                        groups={pricingPeriodsTableGroups}
                        onSelectionChange={(x: PricingPeriodListItem[]) => setSelectedPricingPeriods(x)}
                    />
                    : <DataTable
                        tableId={'deal-table'}
                        className={classes.stickyHeader}
                        items={getDealListItems()}
                        idSelector={(x: DealListItem) => x.id}
                        onSelectionChange={(x: DealListItem[]) => setSelectedDeals(x)}
                        onClick={onSelect}
                        columns={dealListColumns}
                        actions={dealsTableActions<DealListItem>()}
                        groups={dealsTableGroups} />
                }
            </Paper>
            <CustomDialog isOpen={deleteDealConfirmIsOpen} yesNo
                title={t('deals.table.confirmDelete.title')}
                contentText={t('deals.table.confirmDelete.content')}
                content2Text={t('deals.table.confirmDelete.content2')}
                yesButtonText={t('deals.table.confirmDelete.yes')}
                noButtonText={t('deals.table.confirmDelete.no')}
                onYes={() => deleteDeals(true)}
                onNo={() => deleteDeals(false)}
                onCancel={() => setDeleteDealConfirmIsOpen(false)}
                onClose={() => setDeleteDealConfirmIsOpen(false)}
            />
            <CustomDialog isOpen={deleteDealSimpleConfirmIsOpen}
                title={t('deals.table.simpleConfirmDelete.title')}
                contentText={t('deals.table.simpleConfirmDelete.content')}
                confirmButtonText={t('deals.table.simpleConfirmDelete.confirm')}
                onConfirm={() => deleteDeals(true)}
                onCancel={() => setDeleteDealSimpleConfirmIsOpen(false)}
                onClose={() => setDeleteDealSimpleConfirmIsOpen(false)}
            />
            <CustomDialog isOpen={duplicateConfirmDialogOpen} title={t('deals.label.confirmTitle')}
                contentText={t('deals.label.confirmDuplicate')}
                confirmButtonText={t('deals.label.duplicate')}
                onConfirm={async () => {
                    setDuplicateConfirmDialogOpen(false)
                    if (currentDealId) await handleDuplicateDebouncer.execute(currentDealId)
                }}
                onClose={() => setDuplicateConfirmDialogOpen(false)}
                onCancel={() => setDuplicateConfirmDialogOpen(false)} />
        </div >
    )
}

const sapChipStyle = {
    minWidth: '2.5em',
    height: '2.5em',
    color: defaultColors.grey.main.color,
    marginLeft: '0.2em',
    marginRight: '0.2em',
    padding: '0.2em',
    borderRadius: '7px',
    '& span': {
        textOverflow: 'clip !important',
        padding: '0 !important'
    }
}

let styles = _ =>
    createStyles({
        firstRow: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'stretch',
            width: '100%',
            gridArea: 'firstRow',
            whiteSpace: 'nowrap'
        },
        secondRow: {
            gridArea: 'secondRow'
        },
        titleSwitchRow: {
            ...defaultStyles.flexRow,
        },
        pageTitle: {
            color: defaultColors.grey.dark.color,
            alignSelf: 'flex-start',
            marginRight: '2em'
        },
        tableHead: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'stretch',
            padding: '0.2em 1%',
            width: '100%'
        },
        paperTitle: {
            margin: '0',
            marginRight: '6em',
            color: defaultColors.red.main.color
        },
        filterField: {
            width: '8.3em',
            margin: '0em 0.5em'
        },
        filterFieldDate: {
            width: '10.3em',
            margin: '0em 0.5em'
        },
        paperFilter: {
            height: 'auto',
            padding: '0.5em',
        },
        root: {
            flexGrow: 2,
            width: '100%',
            overflow: 'auto'
        },
        headerButton: {
            ...defaultStyles.secondaryButton,
            marginRight: '2em',
        },
        pageContainer: {
            display: 'grid',
            height: '100%',
            paddingBottom: '0.5em',
            'grid-template-areas': "'firstRow' 'secondRow'",
            'grid-template-columns': 'auto',
            'grid-template-rows': '4em minmax(0, 1fr)',
            'grid-gap': '0.8em',
            'overflow-y': 'hidden',
            'box-sizing': 'border-box',
            '&> div': {
                height: '100%',
                width: '100%'
            }
        },
        buttonsRow: {
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:last-child)': {
                marginRight: '1em'
            }
        },
        sapButton: {
            '& button': {
                ...defaultStyles.secondaryButton,
                minWidth: '8em',
                height: '8em',
                width: '8em',
                padding: '0px',
            }
        },
        createButton: {
            ...defaultStyles.primaryButton
        },
        iconsActive: {
            color: defaultColors.lightBlue.main.color
        },
        datePickerContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        positive: {
            color: defaultColors.green.light.color
        },
        stickyHeader: {
            position: 'sticky',
            top: 0,
            zIndex: 2
        }
    })

export default withStyles(styles, muiOptions)(DealList)