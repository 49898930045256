import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { api } from '../../../infrastructure/api'
import { MasterDataShell, MasterDataItem, createExcelLines } from './masterDataShell'
import { ColumnDescriptor, TableItem, TextField, MultipleSelect, Select } from '../../common/customComponents'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { t } from '../../../infrastructure/i18nextHelper'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Site, Company } from './models'

type SupplyDestination = {
    code: string,
    company: string,
    sites: string[],
    name: string
}

let emptySupplyDestination = (): SupplyDestination => {
    return {
        code: '',
        company: '',
        sites: [],
        name: ''
    }
}

let toTableItem = (supplyDestination: SupplyDestination): TableItem<MasterDataItem<SupplyDestination>> => {
    return {
        ...supplyDestination,
        id: supplyDestination.code,
        isModified: false
    }
}

function SupplyDestinationMasterData() {
    let [supplyDestinations, setSupplyDestinations] = useState<SupplyDestination[]>([])
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [sites, setSites] = useState<Site[]>([])
    let [companys, setCompanys] = useState<Company[]>([])

    let load = async () => {
        setSites(await api.get<Site[]>(`masterdata/supplyDestination/site`))
        setCompanys(await api.get<Company[]>(`masterdata/supplyDestination/company`))
        setSupplyDestinations(await api.get<SupplyDestination[]>(`masterdata/supplyDestination`))
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<SupplyDestination>>>[] = [
        { name: t('admin.masterdata.supplyDestination.name'), value: x => x.name },
        { name: t('admin.masterdata.supplyDestination.company'), value: x => companys.find(c => c.code == x.company)!.name },
        { name: t('admin.masterdata.supplyDestination.sites'), value: x => x.sites.map(s => sites.find(ss => ss.code == s)!.name).join(', ') }
    ]

    useEffect(() => { let effect = async () => { await load() }; effect() }, [])

    let supplyDestinationLabel = t('admin.masterdata.supplyDestination.supplyDestination')

    let isManagerOnly = hasClaim(Claims.MasterdataSupplyDestinationManager)
    let isManagerOrWriter = isManagerOnly || hasClaim(Claims.MasterdataSupplyDestinationWriter)

    let onSave = async (item: SupplyDestination) => {
        await api.post('masterdata/supplyDestination', item)
        await load()
        return true
    }

    let getItems = () => supplyDestinations.map(x => toTableItem(x))

    let exportExcel = async () => {
        excelGenerator.generate({
            filename: 'SupplyDestination.xlsx',
            sheets: [{ name: 'SupplyDestination', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let onDelete = async (codes: string[]) => {
        if (codes.length === 0) return false
        await api.del('masterdata/supplyDestination', { codes: codes })
        await load()
        return true
    }

    let allowedSites = (selectedItem: SupplyDestination) => {
        let filteredSites = companys.find(x => x.code == selectedItem.company)?.sites ?? []
        return sites.filter(x => filteredSites.includes(x.code))
    }

    return (
        <MasterDataShell
            headerLabel={supplyDestinationLabel}
            itemLabel={supplyDestinationLabel}
            isManager={isManagerOnly}
            onExportExcel={exportExcel}
            onNew={() => emptySupplyDestination()}
            onDelete={onDelete}
            onSave={onSave}
            items={getItems()}
            columns={columns}>
            {
                (selectedItem, setSelectedItem) => (
                    <>
                        <TextField label={t('admin.masterdata.supplyDestination.name')}
                            disabled={!isManagerOrWriter}
                            text={selectedItem.name}
                            onChange={event => setSelectedItem({ ...selectedItem, name: event.target.value })} />

                        <Select label={t('admin.masterdata.supplyDestination.company')}
                            disabled={!isManagerOrWriter}
                            value={selectedItem.company}
                            choices={companys.map(x => ({ value: x.code, text: x.name }))}
                            onChange={val => { if (val) setSelectedItem({ ...selectedItem, company: val }) }} />

                        <MultipleSelect label={t('admin.masterdata.supplyDestination.sites')}
                            chips={true}
                            allWhenEmpty={false}
                            disabled={!isManagerOrWriter}
                            values={selectedItem.sites}
                            choices={allowedSites(selectedItem).map(x => ({ value: x.code, text: x.name }))}
                            onChange={event => setSelectedItem({ ...selectedItem, sites: event })} />
                    </>
                )}
        </MasterDataShell>)
}

let styles = () => createStyles({})

export default withStyles(styles, muiOptions)(SupplyDestinationMasterData)