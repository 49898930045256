import React from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { useDomStateTracker } from './infrastructure/domStateTracker'
import { Switch, Router } from 'react-router-dom'
import { routes, RouteWithSubRoutes } from './infrastructure/router'
import { history } from './infrastructure/navigation'
import Shell from './infrastructure/shell/shell'
import { MuiThemeProvider } from '@material-ui/core'
import { theme } from './infrastructure/materialUiThemeProvider'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { I18nextProvider, useTranslation } from 'react-i18next'
import i18nInstance from './infrastructure/i18nextHelper'
import { initTFunction } from './infrastructure/i18nextHelper'
import VesselEdit from './app/vessels/vesselEdit/vesselEdit'
import { CreateVesselTransportContainer } from './app/vessels/createVesselTransport'
import DealEdit from './app/deals/dealEdit/dealEdit'
import { DealContainer, DealDialogContainer } from './app/deals/dealEdit/dealEditStore'
import { VesselContainer } from './app/vessels/vesselStore'
import StockMovementEdit from './app/stock/movementEdit/stockMovementEdit'
import { StockMovementContainer } from './app/stock/movementEdit/stockMovementStore'
import { SupplyBalanceStore } from './app/supplyBalance/supplyBalanceStore'
import { StockBoardContainer } from './app/stock/stockBoardStore'
import { LoaderStatusContainer } from './infrastructure/loader'
import { UserContextContainer } from './infrastructure/signIn/userContext'
import { MktSalesContainer } from './app/mktSales/mktSalesStore'
import { SnackbarContainer } from './infrastructure/snackbars'
import { ActualStockContainer } from './app/stock/actualStockEdit/actualStockStore'
import { LinkVesselMovementContainer } from './app/stock/movementEdit/movementForms/linkVesselContainer'
import { PoReleaseDetailsDialog } from './app/stock/movementEdit/movementForms/sap/createInSap/releaseDialog'
import { Startup } from './infrastructure/startup'
import { HistoryDialog } from './app/common/history/dialog'
import { PricingDialog } from './app/pricings/pricingDialog'
import { PricingDialogContainer } from './app/pricings/pricingDialog'
import { StockProjectionDetailDialog } from './app/stock/stockProjectionDetailDialog'
import { FeatureContainer } from './infrastructure/feature'
import { ForecastContainer } from './app/mktSales/forecastEdit/forecastEditStore'
import { TruckTransportContainer } from './app/truck/truckStore'
import { TruckTransportForm } from './app/truck/form/truckForm'
import { NotificationContainer } from './infrastructure/shell/notifications/container'
import { VesselEditContainer, VesselEditDialogContainer } from './app/vessels/vesselEdit/vesselEditStore'
import { PriceIndexDetailsDialog } from './app/pricings/priceIndexDetailsDialog'
import { ImportDialog } from './app/common/import/dialog'
import { ExcelGeneratorContainer } from './infrastructure/excelExport'
import { UserSettingsDialog } from './infrastructure/shell/userSettings/userSettingsDialog'
import { UserSettingsContextContainer } from './infrastructure/shell/userSettings/userSettingsStore'
import { TransitStockDetailDialog } from './app/stock/transitStockDetailDialog'
import { AssignableMovementsDialog } from './app/deals/dealEdit/assignableMovements'
import { AccountingPeriodDialog } from './app/stock/actualStockEdit/accountingPeriod/accountingPeriodDialog'
import { MovementMessageContainer } from './app/stock/movementMessageStore'
import { MovementMessageHistory } from './app/stock/movementEdit/movementMessageHistory'
import { CommentContainer } from './app/common/comment/commentStore'
import { CommentButtonDialog } from './app/common/comment/commentButtonDialog'

function _App() {
    let domStateTracker = useDomStateTracker()
    const { t, i18n } = useTranslation()
    initTFunction(t)

    return (
        <I18nextProvider i18n={i18nInstance}>
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <CustomProviders>
                        <div id='app'>
                            <div id='page'>
                                <Shell>
                                    <Router history={history}>
                                        <Switch>{routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />)}</Switch>
                                    </Router>
                                </Shell>
                                <VesselEdit />
                                <StockMovementEdit />
                                <DealEdit />
                                <TruckTransportForm />
                                <HistoryDialog />
                                <MovementMessageHistory />
                                <UserSettingsDialog />
                                <ImportDialog />
                                <PricingDialog />
                                <PriceIndexDetailsDialog />
                                <StockProjectionDetailDialog />
                                <AccountingPeriodDialog />
                                <TransitStockDetailDialog />
                                <PoReleaseDetailsDialog />
                                <AssignableMovementsDialog />
                                <CommentButtonDialog />
                                <Startup />
                            </div>
                            <div id={'dom-status-' + domStateTracker.status} style={{ position: 'absolute', zIndex: -15 }}></div>
                        </div>
                    </CustomProviders>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </I18nextProvider>
    )
}

let containers = [
    FeatureContainer,
    UserContextContainer,
    LoaderStatusContainer,
    SnackbarContainer,
    DealDialogContainer,
    DealContainer,
    PricingDialogContainer,
    VesselEditDialogContainer,
    VesselEditContainer,
    VesselContainer,
    StockBoardContainer,
    ActualStockContainer,
    StockMovementContainer,
    MktSalesContainer,
    TruckTransportContainer,
    LinkVesselMovementContainer,
    ForecastContainer,
    SupplyBalanceStore,
    NotificationContainer,
    CreateVesselTransportContainer,
    ExcelGeneratorContainer,
    UserSettingsContextContainer,
    MovementMessageContainer,
    CommentContainer
]

function CustomProviders(props: React.PropsWithChildren<{}>) {
    return <>{containers.reduceRight((acc, cur) => <cur.Provider>{acc}</cur.Provider>, props.children)}</>
}

let styles = theme =>
    createStyles({
        '@global': {
            html: {
                fontSize: 16,
                [theme.breakpoints.up('sm')]: {
                    fontSize: 10
                },
                [theme.breakpoints.up(900)]: {
                    fontSize: 12
                },
                [theme.breakpoints.up(1400)]: {
                    fontSize: 16
                }
            },
            'html, body': {
                height: '100vh',
                width: '100vw',
                margin: 0,
                padding: 0,
                boxSizing: 'border-box'
            },
            '#appContainer, #app, #page, #page>div': {
                height: '100%'
            },
            '.MuiDialog-root, .MuiBackdrop-root': {
                marginTop: '2.5em !important'
            },
            '.MuiDialog-root .MuiAppBar-root': {
                position: 'sticky !important'
            },
            '*, *::before, *::after': {
                boxSizing: 'border-box'
            }
        }
    })

export default withStyles(styles)(_App)