import React from 'react'
import { defaultColors } from '../materialUiThemeProvider'
import { TourProvider } from '@reactour/tour'
import { StyleFn } from '@reactour/tour/dist/styles'

function _GuidedTour({ children }: { children: JSX.Element }) {
    return (
        <TourProvider disableFocusLock styles={tourStyle} steps={[]}>
            {children}
        </TourProvider>
    )
}

const badgeStyle: StyleFn = (provided, _) => ({
    ...provided,
    backgroundColor: defaultColors.red.main.color,
})

const closeStyle: StyleFn = (provided, _) => ({
    ...provided,
    right: '0.5em',
    top: '0.2em'
})

const dotStyle: StyleFn = (provided, _) => ({
    ...provided,
    backgroundColor: defaultColors.darkBlue.main.color,
    color: defaultColors.darkBlue.light.color,
})

const popoverStyle: StyleFn = (provided, _) => ({
    ...provided,
    fontFamily: 'Roboto'
})

const tourStyle = {
    badge: badgeStyle,
    dot: dotStyle,
    close: closeStyle,
    popover: popoverStyle
}

export let GuidedTour = _GuidedTour