import React from 'react'
import { TruckStatus } from '../../truckModels'

export function useScroller() {
    let scrollTo = (step: TruckStatus | 'start' | 'end') => scrollToRef(getRef(step))

    const start = React.useRef(null)
    const loadedStep = React.useRef(null)
    const arrivedBorderStep = React.useRef(null)
    const passedBorderStep = React.useRef(null)
    const arrivedStep = React.useRef(null)
    const offloadedStep = React.useRef(null)
    const end = React.useRef(null)

    function getRef(step: TruckStatus | 'start' | 'end') {
        switch (step) {
            case 'start': return start
            case 'loaded': return loadedStep
            case 'arrivedBorder': return arrivedBorderStep
            case 'passedBorder': return passedBorderStep
            case 'arrived': return arrivedStep
            case 'offloaded': return offloadedStep
            case 'end': return end
            default: return start
        }
    }

    function scrollToRef(ref) {
        if (!ref?.current) return
        let scroll = () => ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        scroll()
        if (ref.current === offloadedStep.current) { // todo: find a better way
            setTimeout(scroll, 100)
            setTimeout(scroll, 200)
            setTimeout(scroll, 300)
        }
    }

    return { scrollTo, getRef }
}