import React, { useEffect, useState } from 'react'
import { withStyles, createStyles, Typography, Paper } from '@material-ui/core'
import { debounce, distinctUntilChanged, withLatestFrom } from 'rxjs/operators'
import { interval, Subject } from 'rxjs'
import moment from 'moment'
import { muiOptions, defaultStyles, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import { replaceUnicodeCharacters, t } from '../../infrastructure/i18nextHelper'
import { get, post } from '../../infrastructure/api'
import { hasFeature } from '../../infrastructure/feature'
import { queryStringBuilder } from '../../infrastructure/queryStringBuilder'
import { Button, DatePicker, MonthPicker, NumberField, Select } from '../common/customComponents'
import { Company, Exposure, ExposureBalance, InputArgument, Product } from './models'
import { MarketRisksTable } from './_marketRisksTable'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { hasClaim } from '../../infrastructure/signIn/userContext'
import { Claims } from '../../infrastructure/signIn/models'
import { PrimoExportDialog, exportPrimoDialogRef } from './_primoExportDialog'
import { setInLocalStorage } from '../../infrastructure/localStorage'

let pricingEndDays = [
    { value: 'TwelveOfTheMonth', text: '12' },
    { value: 'TwentySevenOfTheMonth', text: '27' }
]

function _MktRisksExposure({ classes }) {
    let [exposureBalance, setExposureBalance] = useState<ExposureBalance | null>(null)
    let [products, setProducts] = useState<Product[]>([])
    let [companys, setCompanys] = useState<Company[]>([])
    let [changed] = useState<Subject<InputArgument>>(new Subject<InputArgument>())
    let [inputArguments, setInputArguments] = useState<InputArgument | null>(null)
    let [canSave, setCanSave] = useState<boolean>(false)
    let [showPurchaseDetails, setShowPurchaseDetails] = useState(false)

    let loadReferential = async () => {
        let loadProducts = await get<Product[]>('stock/marketRisk/product')
        let companys = await get<Company[]>('stock/marketRisk/company')
        setProducts(loadProducts)
        setCompanys(companys)
    }

    let loadFromLocalStorage = () => {
        let localStorageData = localStorage.getItem('marketRisksExposureArgs')
        if (localStorageData) {
            let localStorageItems: InputArgument = JSON.parse(localStorageData)
            setInputArguments({ ...inputArguments, company: localStorageItems.company, productId: localStorageItems.productId })
        }
    }

    let loadExposure = async () => {
        if (inputArguments?.company && inputArguments?.productId) {
            let query = `stock/marketRisk/exposure?company=${inputArguments?.company}&productId=${inputArguments?.productId}`
            let savedExposure = await get<Exposure>(query)
            if (savedExposure.exposure !== null && savedExposure.exposureDate) {
                let momentDate = moment(savedExposure.exposureDate)
                let day = momentDate.date().toString()
                let month = momentDate.set('date', 1).format('MM/DD/YYYY')
                let pricingEndDay = pricingEndDays.find(x => x.text === day)?.value
                setInputArguments({ ...inputArguments, exposure: savedExposure.exposure, pricingEndDay: pricingEndDay, pricingMonth: month, forecastStartDate: savedExposure.forecastStartDate })
            }
        }
    }

    let searchExposureBalance = async (args: InputArgument) => {
        let query = queryStringBuilder(args)
        let exposureBalance = await get<ExposureBalance>(`stock/marketRisk/exposureBalance${query}`)

        setExposureBalance(exposureBalance)
    }

    let saveExposure = async () => {
        if (canSave) {
            await post('stock/marketRisk/exposure', {
                productId: inputArguments?.productId,
                company: inputArguments?.company,
                exposure: inputArguments?.exposure,
                pricingEndDay: inputArguments?.pricingEndDay,
                pricingMonth: inputArguments?.pricingMonth,
                forecastStartDate: inputArguments?.forecastStartDate
            })

            setInLocalStorage('marketRisksExposureArgs',
                { productId: inputArguments?.productId, company: inputArguments?.company })
        }
    }

    useEffect(() => {
        let argsValid = !!(inputArguments?.company && inputArguments?.productId &&
            (inputArguments?.exposure || inputArguments?.exposure === 0) &&
            inputArguments?.pricingEndDay && inputArguments?.pricingMonth)

        if (argsValid) changed.next(inputArguments!)

        setCanSave(argsValid)
    }, [inputArguments])

    useEffect(() => {
        loadExposure()
    }, [inputArguments?.company, inputArguments?.productId])

    useEffect(() => {
        if (products.length && companys.length)
            loadFromLocalStorage()
    }, [products, companys])

    useEffect(() => {
        loadReferential()

        let subscription = changed
            .pipe(
                debounce(() => interval(500)),
                distinctUntilChanged(),
                withLatestFrom(changed))
            .subscribe(([x, _]) => searchExposureBalance(x))

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    return (
        <div className={classes.container}>
            <Paper className={classes.firstRow}>
                <div className={classes.inputArgumentContainer}>
                    <Select label={t('mktRiskExposure.filters.product')}
                        value={inputArguments?.productId} disableNewStyle
                        choices={products.map(x => ({ value: x.id, text: x.code }))}
                        classesOverride={{ form: classes.filterField }}
                        onChange={val => setInputArguments({ ...inputArguments, productId: val })} />
                    <Select label={t('mktRiskExposure.filters.company')}
                        value={inputArguments?.company} disableNewStyle
                        classesOverride={{ form: classes.filterField }}
                        choices={companys.map(x => ({ value: x.code, text: x.name }))}
                        onChange={val => setInputArguments({ ...inputArguments, company: val })} />
                </div>

                <div className={classes.inputArgumentContainer}>
                    {hasClaim(Claims.PxcReportExport) && hasFeature('PricingCalendarExport')
                        && <Button className={classes.exportReportButton}
                            label={t('report.export')}
                            img='/static/images/excel_red.svg'
                            onClick={() => exportPrimoDialogRef?.current?.open()}
                        />}
                    {hasFeature('MarketRiskCombinedPurchasesColumns') &&
                        <FormControlLabel control={<Checkbox onChange={() => setShowPurchaseDetails(!showPurchaseDetails)} checked={showPurchaseDetails} />}
                            label={t('mktRiskExposure.purchaseDetails')}
                        />}
                    {hasFeature('MarketRiskPastForecast') &&
                        <DatePicker date={inputArguments?.forecastStartDate || null} label={t('mktRiskExposure.filters.forecastStartDate')}
                            setDate={newDate => setInputArguments({ ...inputArguments, forecastStartDate: newDate })} size='standard' disableNewStyle />}
                    <NumberField label={t('mktRiskExposure.filters.exposure')} disableNewStyle
                        text={inputArguments?.exposure} size='small' allowNegative
                        onChange={x => setInputArguments({ ...inputArguments, exposure: x })} />
                    <Select label={t('mktRiskExposure.filters.pricingEndDay')}
                        value={inputArguments?.pricingEndDay} disableNewStyle
                        choices={pricingEndDays.map(x => ({ value: x.value, text: x.text }))}
                        onChange={val => setInputArguments({ ...inputArguments, pricingEndDay: val })} classesOverride={{ form: classes.filterField }} />
                    <MonthPicker date={inputArguments?.pricingMonth || null} label={t('mktRiskExposure.filters.pricingMonth')} disablePast={false}
                        setDate={newDate => setInputArguments({ ...inputArguments, pricingMonth: newDate })} size='small' disableNewStyle />
                    {hasClaim(Claims.MarketRiskSimulatorManager, Claims.SalesManager)
                        && <Button
                            className={classes.save}
                            disabled={!canSave}
                            label={t('mktRiskExposure.filters.save')}
                            onClick={saveExposure} />}
                </div>
            </Paper>
            {hasClaim(Claims.PxcReportExport) && hasFeature('PricingCalendarExport')
                && <PrimoExportDialog startDate={inputArguments?.forecastStartDate}
                    pricingMonth={inputArguments?.pricingMonth} />}
            {
                exposureBalance &&
                <div className={classes.secondRow}>
                    <Paper className={classes.table}>
                        <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                            {replaceUnicodeCharacters(t('mktRiskExposure.table.title', { date: moment().format('MM/DD/yyyy') }))}
                        </Typography>
                        <MarketRisksTable exposureBalance={exposureBalance} showPurchaseDetails={showPurchaseDetails} />
                    </Paper>
                </div>
            }
        </div>)
}

let styles = _ => createStyles({
    container: {
        display: 'grid',
        height: '100%',
        paddingBottom: '0.5em',
        'grid-template-areas': "'firstRow' 'secondRow'",
        'grid-template-columns': 'auto',
        'grid-template-rows': '4em minmax(0, 1fr)',
        'grid-gap': '0.8em',
        'overflow-y': 'hidden',
        'box-sizing': 'border-box',
        '&> div': {
            height: '100%',
            width: '100%'
        }
    },
    paperTitle: {
        color: defaultColors.red.main.color,
        margin: '0.5em'
    },
    secondRow: {
        ...defaultStyles.flexRow,
        alignItems: 'stretch',
        justifyContent: 'space-between',
        flexGrow: 1,
        gridArea: 'secondRow',
    },
    firstRow: {
        ...defaultStyles.flexRow,
        justifyContent: 'space-between',
        width: '100%',
        gridArea: 'firstRow',
        whiteSpace: 'nowrap'
    },
    table: {
        flexGrow: 2,
        width: '100%',
        overflow: 'auto'
    },
    inputArgumentContainer: {
        ...defaultStyles.flexRow,
        padding: '0.5em'
    },
    filterField: {
        width: '8.3em',
        margin: '0em 0.5em'
    },
    save: {
        ...defaultStyles.primaryButton,
        marginLeft: '0.5em'
    },
    exportReportButton: {
        ...defaultStyles.secondaryButton,
        marginRight: '1em'
    }
})

export default withStyles(styles, muiOptions)(_MktRisksExposure)