import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { defaultStyles, muiOptions } from '../../../../infrastructure/materialUiThemeProvider'
import { StockMovementContainer } from '../stockMovementStore'
import * as Component from './_movementComponents'
import { LinkVesselMovementContainer } from './linkVesselContainer'
import { hasFeature } from '../../../../infrastructure/feature'

function _purchaseForm({ classes }) {
    let store = StockMovementContainer.useContainer()
    let linkStore = LinkVesselMovementContainer.useContainer()

    return (
        <div>
            <div className={classes.row + ' firstPurchaseRow'}>
                <Component.Date isIn={true} />
                <Component.Product isIn={true} />
                <Component.DutyStatus isIn={true} />
                <Component.Company />
                <Component.Volume />
                {hasFeature('ObservedQuantityTemperature')
                    ? <>
                        <Component.ObservedTemperature />
                        <Component.ObservedVolume />
                    </>
                    : null}
            </div>
            {hasFeature('ShowPurchaseRequestedDate') || hasFeature('ShowPurchaseNominatedVolume') || (hasFeature('DailyNomination') || hasFeature('WeeklyNomination'))
                ? <div className={classes.row}>
                    {hasFeature('ShowPurchaseRequestedDate') && <Component.RequestedDate />}
                    {(hasFeature('DailyNomination') || hasFeature('WeeklyNomination') || hasFeature('ShowPurchaseNominatedVolume')) &&
                        <div className={classes.alignedToVolume}>
                            <Component.NominatedVolume />
                        </div>}
                    {(hasFeature('DailyNomination') || hasFeature('WeeklyNomination')) && <Component.Nomination />}
                </div>
                : undefined}
            {hasFeature('MessageMovements') && store.stockMovement.meanOfTransportation === 'Pipe' &&
                <div>
                    <Component.OperationStartAt />
                    <Component.OperationEndAt />
                </div>
            }
            <div className={classes.row + ' purchaseRow'}>
                <Component.Site isIn={true} />
                <Component.Counterparty />
                <Component.Mot />
                <Component.Status />
            </div>
            <div className={classes.row}>
                <Component.Reference />
                <Component.ExternalReference />
                {hasFeature('MovementExternalNumber') && <Component.DestinationExternalNumber />}
                {hasFeature('linkVesselMovement') && !!linkStore.associatedVessel &&
                    <Component.AssociatedVessel text={`${linkStore.associatedVessel?.name} ${linkStore.associatedVessel?.reference}`} />}
                {store.associatedVesselId && <Component.Transporter />}
                {store.canCreateDealManually()
                    ? <Component.CreateDealButton buttonClass={classes.secondaryButton} />
                    : null}
                {store.canAssignDeal()
                    ? <Component.AssignDealButton buttonClass={classes.secondaryButton} />
                    : null}
                {store.canAssignVessel()
                    ? <Component.AssignVesselButton buttonClass={classes.secondaryButton} />
                    : null}
                <Component.MirrorMovementButton buttonClass={classes.secondaryButton} />
            </div>
            <div className={classes.row}>
                <Component.Comment />
            </div>
        </div >
    )
}

let styles = () =>
    createStyles({
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1em'
        },
        alignedToVolume: {
            padding: '0px',
            margin: '0px',
            paddingLeft: '48.9em'
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            margin: "1em"
        }
    })

export default withStyles(styles, muiOptions)(_purchaseForm)
