import React, { RefObject, useRef, useImperativeHandle, useState, useEffect } from 'react'
import moment from 'moment'
import { createStyles, withStyles, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core'
import { defaultStyles, muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { Guid } from '../../../infrastructure/guid'
import * as api from '../../../infrastructure/api'
import { t } from '../../../infrastructure/i18nextHelper'
import { popupNavigator } from '../../../infrastructure/popupNavigator'
import { useActionDebounce } from '../debounce'
import { Button, CustomDialog, DataTable } from '../customComponents'
import { MovementStatus, defaultAssociatedDeal, DealLabelId, MovementForm } from '../../stock/stockModels'
import { Deal } from 'src/app/deals/dealModels'
import { MuiProps } from '../../../infrastructure/materialUiThemeProvider'

let selectAssignableDealDialogRef: RefObject<{ open: () => void }> | null = null

export type SelectAssignableDealsProps = {
    products: ({ code: string, id: string })[]
    counterpartys: ({ name: string, id: string })[]
    openAssignDealPopup: boolean
    assignableDealsFromMovement: Deal[] | null
    setOpenAssignDealPopup: (x: boolean) => void
    setDealId: (dealId: Guid | null) => void
    setIsConfirmDealOpen: (x: boolean) => void
}

function _SelectAssignableDeals(props: MuiProps & SelectAssignableDealsProps) {

    let { classes, setOpenAssignDealPopup, setDealId, setIsConfirmDealOpen,
        products, counterpartys, openAssignDealPopup, assignableDealsFromMovement
    } = props

    let [isOpen, setIsOpen] = useState<boolean>(false)
    let [selectedDealId, setSelectedDealId] = useState<Guid | null>(null)

    selectAssignableDealDialogRef = useRef<{ open: () => void }>(null)

    useEffect(() => {
        if (openAssignDealPopup && assignableDealsFromMovement) {
            if (assignableDealsFromMovement.length > 1)
                selectAssignableDealDialogRef?.current?.open()
            else if (assignableDealsFromMovement.length == 1) {
                setDealId(assignableDealsFromMovement[0].id)
                setIsConfirmDealOpen(true)
            }
        }
    }, [openAssignDealPopup])

    useImperativeHandle(selectAssignableDealDialogRef, () => ({
        open: () => {
            setIsOpen(true)
            setSelectedDealId(null)
        }
    }))

    let close = () => {
        setSelectedDealId(null)
        setOpenAssignDealPopup(false)
        setIsOpen(false)
    }

    let confirm = () => {
        setDealId(selectedDealId)
        setIsOpen(false)
        setIsConfirmDealOpen(true)
    }

    let getProductCode = (productId: Guid | null) => {
        return !!products && products.length > 0 ? products.find(x => x.id === productId)?.code ?? '' : ''
    }

    let getCounterPartyName = (counterpartyId: Guid | null) => {
        return !!counterpartys && counterpartys.length > 0 ? counterpartys.find(x => x.id === counterpartyId)?.name ?? '' : ''
    }

    let dealTableColumns = [
        { name: t('stock.label.movement.table.dealNumber'), value: x => x.dealNumber ?? '' },
        { name: t('stock.label.movement.table.reference'), value: x => x.referenceNumber ?? '' },
        { name: t('stock.label.movement.table.product'), value: x => x.productId ? getProductCode(x.productId) : '' },
        { name: t('stock.label.movement.table.counterparty'), value: x => x.counterpartyId ? getCounterPartyName(x.counterpartyId) : '' },
        { name: t('stock.label.movement.table.movementType'), value: x => x.movementType ?? '' },
        { name: t('stock.label.movement.table.dealType'), value: x => x.dealType ? x.dealType : '' },
        { name: t('stock.label.movement.table.validFrom'), value: x => x.validFrom ? moment(x.validFrom).format('MM/DD') : '' },
        { name: t('stock.label.movement.table.validTo'), value: x => x.validTo ? moment(x.validTo).format('MM/DD') : '' },
        { name: t('stock.label.movement.table.quantity'), value: x => x.quantity ?? '' },
        { name: t('stock.label.movement.table.volume'), value: x => x.volume ?? '' }
    ]

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle>{t('stock.label.movement.selectDealForAssignation')}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {!!assignableDealsFromMovement && !!assignableDealsFromMovement.length ?
                    <DataTable tableId={'movement-assignable-deals-table'}
                        isMultiSelectable={false}
                        items={assignableDealsFromMovement}
                        idSelector={(x: Deal) => x.id}
                        columns={dealTableColumns}
                        onSelectionChange={(x: Deal[]) => { if (x.length > 0) setSelectedDealId(x.first().id) }}
                    /> : null}
            </DialogContent>
            <DialogActions className={classes.marginTop1em}>
                <Button onClick={close}
                    label={t('components.dialogClose')}
                    color='primary'
                    className={classes.cancelButton} />
                <Button onClick={confirm}
                    label={t('components.dialogConfirm')}
                    color='primary'
                    className={classes.confirmButton}
                    disabled={!selectedDealId} />
            </DialogActions>
        </Dialog>
    )
}

export type ConfirmDealAssignation = {
    isConfirmDealOpen: boolean
    openAssignDealPopup: boolean
    assignableDealsFromMovement: Deal[] | null
    dealIdToAssign: Guid | null
    shouldChangeStatus?: boolean,
    stockMovement?: MovementForm,
    setIsConfirmDealOpen: (x: boolean) => void
    setOpenAssignDealPopup: (x: boolean) => void
    setDealIdToAssign: (dealId: string | null) => void
    trySave: () => void
    createDealFromMovement?: (() => void)
    assignDealFromMovement: () => void
}

function _ConfirmDealAssignation(props: ConfirmDealAssignation) {

    let { setIsConfirmDealOpen, setOpenAssignDealPopup, setDealIdToAssign,
        trySave, createDealFromMovement, openAssignDealPopup,
        isConfirmDealOpen, assignableDealsFromMovement, assignDealFromMovement,
        shouldChangeStatus, stockMovement, dealIdToAssign
    } = props

    let closeDealConfirmationPopup = () => {
        setIsConfirmDealOpen(false)
        setOpenAssignDealPopup(false)
        setDealIdToAssign(null)
    }

    let createDealFromMovementDebouncer = useActionDebounce(async () => {
        await trySave()
        await createDealFromMovement!()
        let linkedDealId = await api.get<Guid>(`deal/id/fromMovement/${stockMovement!.id}`)
        popupNavigator.open('deal', linkedDealId)
    })

    let assignDealFromMovementDebouncer = useActionDebounce(async () => {
        await assignDealFromMovement()
        closeDealConfirmationPopup()
    })

    let assignableDealLabelToLink = (): string => {
        let deal = assignableDealsFromMovement?.find(x => x.id === dealIdToAssign);
        if (!deal) return ''

        return dealToString({
            ...defaultAssociatedDeal(),
            id: deal.id,
            reference: deal.referenceNumber,
            dutyStatus: deal.dutyStatus,
            validFrom: deal.validFrom.toString(),
            validTo: deal.validTo.toString(),
            site: deal.site
        })
    }

    let dealToString = (label: DealLabelId): string => {
        let getStringOfDate = (date: string | null, formatTemplate: string): string => {
            return date ? moment(date).format(formatTemplate) : t(tBase + 'noDate')
        }
        let tBase = 'stock.label.movement.'
        let reference = label.reference ?? t(tBase + 'noReference')
        let dutyStatus = label.dutyStatus ?? t(tBase + 'noDutyStatus')
        let date = getStringOfDate(label.validFrom, 'YY/MM/DD') + '->' + getStringOfDate(label.validTo, 'YY/MM/DD')
        let site = label.site ?? t(tBase + 'noSite')

        return `${reference} - ${dutyStatus} - ${date} - ${site}`
    }

    return (
        <CustomDialog isOpen={isConfirmDealOpen}
            title={openAssignDealPopup
                ? t('stock.label.movement.assignDealDialogTitle')
                : t('stock.label.movement.createDealDialogTitle')}
            contentText={openAssignDealPopup
                ? t('stock.label.movement.dealToAssign') + ': ' + assignableDealLabelToLink()
                : (shouldChangeStatus || stockMovement?.movementStatus === MovementStatus.Forecast)
                    ? t('stock.label.movement.createDealDialogText') : ''}
            confirmButtonText={openAssignDealPopup
                ? t('stock.label.movement.assignDealButton')
                : t('stock.label.movement.createDealButton')}
            onConfirm={openAssignDealPopup
                ? assignDealFromMovementDebouncer.execute
                : createDealFromMovementDebouncer.execute}
            onClose={closeDealConfirmationPopup}
            onCancel={closeDealConfirmationPopup}
        />)
}

let styles = () =>
    createStyles({
        confirmButton: { ...defaultStyles.dialogPrimaryButton },
        cancelButton: { ...defaultStyles.dialogCloseButton },
        dialogContent: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        cellPadding: {
            padding: '0px 15px 0px 15px !important'
        },
        headerPadding: {
            padding: '10px 15px 10px 15px !important'
        }
    })

export let SelectAssignableDeals = withStyles(styles, muiOptions)(_SelectAssignableDeals)

export let ConfirmDealAssignation = withStyles(styles, muiOptions)(_ConfirmDealAssignation)